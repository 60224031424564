import React from 'react';
import { Container } from 'reactstrap';
import SummaryForm from './summaryForm';

const AdminPortalVendorSummary = () => {
  return (
    <div className="fr-container pb-40 fr-bg-admin">
      <Container>
        <h3 className="fr-page-title">Summary</h3>
        <div className="fr-page-container" style={{ minHeight: '400px' }}>
          <SummaryForm />
        </div>
      </Container>
    </div>
  );
};

export default AdminPortalVendorSummary;
