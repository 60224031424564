import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';
import { Col, Row } from 'reactstrap';
import Button from 'components/button';
import Input from 'components/input';
import { gR } from 'constants/helperMessage';
import UnsavedPopup from 'pages/home/unsavedPopup';

const useStyles = makeStyles(() => ({
  organizationAction: {
    justifyContent: 'center',
    display: 'flex',
    margin: '-20px 0 '
  }
}));

const OrganizationForm = ({ organization, onSubmit, isDisabled = false, cancelUrl = '' }) => {
  const { organizationAction } = useStyles();

  const handleOnSubmit = useCallback(
    (values) => {
      if (onSubmit) {
        onSubmit(values);
      }
    },
    [onSubmit]
  );

  const validation = {
    name: Yup.string().required(gR('Organization Type Name'))
  };

  const { values, handleSubmit, handleChange, touched, errors, initialValues, resetForm } =
    useFormik({
      initialValues: {
        name: organization.name ?? '',
        description: organization.description ?? ''
      },
      validationSchema: Yup.object(validation),
      onSubmit: handleOnSubmit,
      enableReinitialize: true
    });

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12} className="d-flex flex-column align-items-center">
          <Input
            color="tertiary"
            size="small"
            label="Organization Type Name*"
            className="m-2 min-w-300"
            name="name"
            onChange={handleChange}
            value={values.name}
            error={touched.name && !!errors.name}
            helperText={touched.name && errors.name}
          />
          <Col xs={12} className="d-flex flex-column align-items-center">
            <Input
              color="tertiary"
              size="small"
              label="Description"
              className="m-2 min-w-300"
              multiline
              rows={4}
              name="description"
              onChange={handleChange}
              value={values.description}
              error={touched.description && !!errors.description}
              helperText={touched.description && errors.description}
            />
          </Col>
          <div className={organizationAction}>
            <Button
              size="small"
              color="secondary"
              className="mr-1 text-white-hover"
              component={Link}
              to={cancelUrl}
            >
              Cancel
            </Button>
            <Button
              color="tertiary"
              size="small"
              className="ml-1"
              type="submit"
              disabled={isDisabled}
              minWidth={79.86}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
      <UnsavedPopup
        values={values}
        initialValues={initialValues}
        isSubmit={isDisabled}
        resetForm={resetForm}
      />
    </form>
  );
};

export default OrganizationForm;
