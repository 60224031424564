import TermOfUse from '.';

import { TERM_OF_USE_URL } from './url';

export const TERM_OF_USE_ROUTES = [
  {
    name: TERM_OF_USE_URL.TERM_OF_USE.NAME,
    path: TERM_OF_USE_URL.TERM_OF_USE.URL,
    component: TermOfUse,
    isPrivate: false
  }
];
