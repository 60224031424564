export const CHARITY_URL = {
  CHARITY: {
    URL: '/charities',
    NAME: 'Your Community'
  },
  DETAIL: {
    URL: '/charities/:id',
    NAME: 'Charitie Details'
  },
  SUPPORTING_VENDORS: {
    URL: '/charities/:id/campaignId',
    NAME: 'SUPPORTING VENDORS'
  }
};
