import { ADMIN_PORTAL_BASIC_ROUTES } from './basicRoute';
import { ADMIN_PORTAL_URL } from './url';
import AdminPortalVendorCardAndTransaction from './vendor/cardAndTransaction';
import AdminPortalCardTranDetail from './vendor/cardAndTransaction/detail';
import AdminPortalVendorInformation from './vendor/information';
import AdminPortalVendorReporting from './vendor/reporting';
import AdminPortalVendorSummary from './vendor/summary';
import AdminPortalVendorUser from './vendor/user';
import AdminPortaVendorlUserAdd from './vendor/user/add';
import AdminPortalVendorUserDetail from './vendor/user/detail';

export const VENDOR_MANAGE_ROUTES = [
  ...ADMIN_PORTAL_BASIC_ROUTES,
  {
    name: ADMIN_PORTAL_URL.VENDOR_SUMMARY.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_SUMMARY.URL,
    component: AdminPortalVendorSummary,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_INFORMATION.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_INFORMATION.URL,
    component: AdminPortalVendorInformation,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_CARD_TRANSACTION.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_CARD_TRANSACTION.URL,
    component: AdminPortalVendorCardAndTransaction,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_CARD_TRANSACTION_DETAIL.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_CARD_TRANSACTION_DETAIL.URL,
    component: AdminPortalCardTranDetail,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_REPORTING.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_REPORTING.URL,
    component: AdminPortalVendorReporting,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_USER.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_USER.URL,
    component: AdminPortalVendorUser,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_USER_ADD.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_USER_ADD.URL,
    component: AdminPortaVendorlUserAdd,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_USER_DETAIL.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_USER_DETAIL.URL,
    component: AdminPortalVendorUserDetail,
    isPrivate: true
  }
];
