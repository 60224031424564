import Api from 'api/public/api';
import { FUNRAYZR_API_URL } from 'configs';
import AxiosWrapper from 'utils/axios';

export class PublicService {
  constructor() {
    this._baseUrl = `${FUNRAYZR_API_URL}/Public`;
    const axios = AxiosWrapper();
    this._publicApi = Api(axios);
  }

  getCategoriesPopular() {
    return this._publicApi.getCategoriesPopular();
  }

  getCategories() {
    return this._publicApi.getCategories();
  }

  getVendor(id) {
    return this._publicApi.getVendor(id);
  }

  getCharities(pa) {
    return this._publicApi.getCharities(pa);
  }

  getCharity(id) {
    return this._publicApi.getCharity(id);
  }

  getCampaigns(pa) {
    return this._publicApi.getCampaigns(pa);
  }

  getCampaign(id) {
    return this._publicApi.getCampaign(id);
  }

  getVendorWithoutCategory(pa) {
    return this._publicApi.getVendorWithoutCategory(pa);
  }

  getCharitiesCampaigns(pa) {
    return this._publicApi.getCharitiesCampaigns(pa);
  }

  getOrganizationTypes() {
    return this._publicApi.getOrganizationTypes();
  }

  getRegions() {
    return this._publicApi.getRegions();
  }
}

const publicService = new PublicService();

export default publicService;
