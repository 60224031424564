import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import { PURPOSE } from 'constants/index';
import { theme } from 'constants/theme';
import OrganizationForm from './organizationForm';

const useStyles = makeStyles(() => ({
  border: {
    height: '1px',
    background: theme.colors.gray.border,
    margin: '0px 24px 20px 24px'
  }
}));

const OrganizationInfo = ({ purpose, organization, onSubmit, isDisabled, cancelUrl }) => {
  const { border } = useStyles();
  const isAdd = purpose === PURPOSE.ADD;

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} className="d-flex justify-content-between align-items-center">
            <h3 className="fr-page-title text-tertiary quicksand flex-1">
              {isAdd ? 'Add' : 'Edit'} Organization Type
            </h3>
          </Col>
          <Col xs={12}>
            <div className={border} />
          </Col>
        </Row>

        <Row className="pt-3 pb-4">
          <Col xs={12}>
            <OrganizationForm
              organization={organization}
              onSubmit={onSubmit}
              isAdd={isAdd}
              isDisabled={isDisabled}
              cancelUrl={cancelUrl}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OrganizationInfo;
