import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';
import { Col } from 'reactstrap';

const useStyle = makeStyles(() => ({
  barCode: {
    backgroundColor: '#000',
    borderRadius: '10px',
    padding: '10px 0 10px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto 10px',
    '@media (min-width: 770px)': {
      flexDirection: 'row'
    }
  },
  topContent: {
    color: theme.colors.white.text,
    fontSize: theme.fontSize.s12,

    '@media (min-width: 770px)': {
      margin: '10px 0 0',
      textAlign: 'center',
      display: 'block'
    }
  },
  priceStyle: {
    color: theme.colors.white.text,
    fontSize: theme.fontSize.s50,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '-20px',
    '@media (min-width: 770px)': {
      fontSize: theme.fontSize.s38,
      margin: '10px'
    }
  },
  codeIdStyle: {
    color: theme.colors.white.text,
    fontSize: theme.fontSize.s20,
    textAlign: 'center',
    margin: '15px 0 0',
    display: 'none',
    '@media (min-width: 770px)': {
      margin: '10px',
      display: 'block'
    }
  },
  codeIdBottom: {
    color: theme.colors.white.text,
    fontSize: theme.fontSize.s38,
    textAlign: 'center',
    margin: '15px 0',
    '@media (min-width: 770px)': {
      display: 'none'
    }
  },

  wrapperCode: {
    borderRadius: '10px',
    width: '100%',
    height: '100%',
    '& img': {
      width: '100%',
      objectFit: 'cover',
      borderRadius: '10px'
    }
  }
}));

const BarCode = ({ url, price, codeId, mdSize }) => {
  const { barCode, topContent, priceStyle, codeIdStyle, wrapperCode, codeIdBottom } = useStyle();

  const barCodeClassName = useMemo(() => {
    return mdSize === 1 ? 'w-100' : 'w-380';
  }, [mdSize]);
  return (
    <>
      <Col className={`${barCode} ${barCodeClassName}`}>
        <Col xs={12} md={6}>
          <span className={`${topContent}`}>Total</span>
          <p className={`${priceStyle}`}>${price.toFixed(2)}</p>
          <p className={`${codeIdStyle} top`}>{codeId}</p>
        </Col>
        <Col xs={12} md={6} className={`${wrapperCode}`}>
          <img src={url} alt="barcode" />
        </Col>
        <Col xs={12} md={6} className={`${wrapperCode}`}>
          <p className={`${codeIdBottom}`}>{codeId}</p>
        </Col>
      </Col>
    </>
  );
};

BarCode.propTypes = {
  url: PropTypes.string,
  price: PropTypes.number,
  codeId: PropTypes.string,
  mdSize: PropTypes.number
};
BarCode.defaultProps = {
  url: '',
  price: 0,
  codeId: '',
  mdSize: 1
};
export default BarCode;
