import { ERROR_MESSAGE } from 'constants/errorMessage';
import React, { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { REGISTER_URL } from 'pages/register/url';
import { theme } from 'constants/theme';
import Button from 'components/button';
import { Col, Row } from 'reactstrap';
import { Checkbox } from '@mui/material';
import Input from 'components/input';
import { gR } from 'constants/helperMessage';
import { PRIVACY_POLICY_URL } from 'pages/privacyPolicy/url';
import { makeStyles } from '@mui/styles';
import Select from 'components/select';
import { EULA_PAGE_URL } from 'pages/eula/url';

const useStyles = makeStyles(() => ({
  form: {
    fontFamily: `${theme.fontFamily.quicksandRegular}`,
    '& input, .MuiFormLabel-root': {
      fontFamily: `${theme.fontFamily.quicksandRegular}`
    }
  },
  title: {
    fontFamily: `${theme.fontFamily.quicksandRegular}`,
    marginBottom: '0.5rem'
  }
}));

const RegisterVendorForm = ({ submitDisabled = false, onSubmit, categories }) => {
  const { title, form } = useStyles();
  const [agreed, setAgreed] = useState(false);

  const initialValues = {
    legalName: '',
    businessNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    province: '',
    postalCode: '',
    businessCategory: '',
    firstName: '',
    lastName: '',
    email: '',
    workPhoneNumber: '',
    phoneNumber: ''
  };

  const onAgreeChange = useCallback(() => {
    setAgreed(!agreed);
  }, [agreed]);

  const handleOnSubmit = useCallback(
    (values) => {
      if (onSubmit) {
        const submitedData = {
          ...values,
          workPhoneNumber: `${values.workPhoneNumber}`,
          phoneNumber: `${values.phoneNumber}`
        };

        const {
          legalName,
          businessNumber,
          businessCategory,
          addressLine1,
          addressLine2,
          city,
          province,
          postalCode,
          firstName,
          lastName,
          email,
          workPhoneNumber,
          phoneNumber
        } = submitedData;

        const vendorInfo = {
          businessInfo: {
            legalName,
            businessNumber,
            categoryId: businessCategory,
            address: {
              addressLine1,
              addressLine2,
              city,
              province,
              postalCode
            }
          },
          primaryContact: {
            firstName,
            lastName,
            email,
            workPhoneNumber,
            phoneNumber
          }
        };

        onSubmit(vendorInfo);
      }
    },
    [onSubmit]
  );

  const registerValidateSchema = Yup.object().shape({
    legalName: Yup.string().required(gR('Legal Name of Business')),
    businessNumber: Yup.string().required(gR('Business Number (BN)')),
    addressLine1: Yup.string().required(gR('Address Line 1')),
    city: Yup.string().required(gR('City')),
    province: Yup.string().required(gR('Province')),
    postalCode: Yup.string().required(gR('Postal Code')),
    firstName: Yup.string().required(gR('First Name')),
    lastName: Yup.string().required(gR('Last Name')),
    businessCategory: Yup.string().required(gR('Business Category')),
    email: Yup.string().required(gR('Email')).email(ERROR_MESSAGE.EMAIL.VALID),
    workPhoneNumber: Yup.number().required(gR('Work Phone')).typeError(ERROR_MESSAGE.PHONE_NUMBER.VALID),
    phoneNumber: Yup.number().required(gR('Mobile Phone')).typeError(ERROR_MESSAGE.PHONE_NUMBER.VALID)
  });

  const getFormError = (touched, errors) => {
    const legalName = touched.legalName && !!errors.legalName;
    const businessNumber = touched.businessNumber && !!errors.businessNumber;
    const addressLine1 = touched.addressLine1 && !!errors.addressLine1;
    const addressLine2 = touched.addressLine2 && !!errors.addressLine2;
    const city = touched.city && !!errors.city;
    const province = touched.province && !!errors.province;
    const postalCode = touched.postalCode && !!errors.postalCode;
    const businessCategory = touched.businessCategory && !!errors.businessCategory;

    const firstName = touched.firstName && !!errors.firstName;
    const lastName = touched.lastName && !!errors.lastName;
    const email = touched.email && !!errors.email;
    const workPhoneNumber = touched.workPhoneNumber && !!errors.workPhoneNumber;
    const phoneNumber = touched.phoneNumber && !!errors.phoneNumber;

    return {
      legalName,
      businessNumber,
      addressLine1,
      addressLine2,
      city,
      province,
      postalCode,
      businessCategory,
      firstName,
      lastName,
      email,
      workPhoneNumber,
      phoneNumber
    };
  };

  const formik = useFormik({
    initialValues,
    validationSchema: registerValidateSchema,
    onSubmit: handleOnSubmit
  });

  const registerVendorForm = useMemo(() => {
    const { touched, errors, values, handleSubmit, handleChange, handleBlur } = formik;
    const formError = getFormError(touched, errors);

    return (
      <form className={form} onSubmit={handleSubmit}>
        <Row>
          <Col className={title}>Business Information</Col>
        </Row>
        <Row>
          <Col md={6} className="mb-4">
            <Input
              fullWidth
              name="legalName"
              type="text"
              label="Legal Name of Business*"
              size="small"
              variant="outlined"
              error={formError.legalName}
              helperText={formError.legalName && errors.legalName}
              value={values.legalName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={6} className="mb-4">
            <Input
              fullWidth
              name="businessNumber"
              type="text"
              label="Business Number (BN)*"
              size="small"
              variant="outlined"
              error={formError.businessNumber}
              helperText={formError.businessNumber && errors.businessNumber}
              value={values.businessNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-4">
            <Input
              fullWidth
              name="addressLine1"
              type="text"
              label="Address Line 1*"
              size="small"
              variant="outlined"
              error={formError.addressLine1}
              helperText={formError.addressLine1 && errors.addressLine1}
              value={values.addressLine1}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={6} className="mb-4">
            <Input
              fullWidth
              name="addressLine2"
              type="text"
              label="Address Line 2"
              size="small"
              variant="outlined"
              error={formError.addressLine2}
              helperText={formError.addressLine2 && errors.addressLine2}
              value={values.addressLine2}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-4">
            <Input
              fullWidth
              name="city"
              type="text"
              label="City*"
              size="small"
              variant="outlined"
              error={formError.city}
              helperText={formError.city && errors.city}
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={3} className="mb-4">
            <Input
              fullWidth
              name="province"
              type="text"
              label="Province*"
              size="small"
              variant="outlined"
              error={formError.province}
              helperText={formError.province && errors.province}
              value={values.province}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={3} className="mb-4">
            <Input
              fullWidth
              name="postalCode"
              type="text"
              label="Postal Code*"
              size="small"
              variant="outlined"
              error={formError.postalCode}
              helperText={formError.postalCode && errors.postalCode}
              value={values.postalCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Row>

        <Row>
          <Col className="mb-4 d-flex justify-content-center">
            <Select
              color="tertiary"
              size="small"
              className=""
              name="businessCategory"
              value={values.businessCategory}
              data={categories}
              labelInput="Business Category"
              onChange={handleChange}
              error={touched.businessCategory && !!errors.businessCategory}
              helperText={touched.businessCategory && errors.businessCategory}
            />
          </Col>
        </Row>

        <Row>
          <Col className={title}>Primary Contact</Col>
        </Row>
        <Row>
          <Col md={6} className="mb-4">
            <Input
              fullWidth
              name="firstName"
              type="text"
              label="First Name*"
              size="small"
              variant="outlined"
              error={formError.firstName}
              helperText={formError.firstName && errors.firstName}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={6} className="mb-4">
            <Input
              fullWidth
              name="lastName"
              type="text"
              label="Last Name*"
              size="small"
              variant="outlined"
              error={formError.lastName}
              helperText={formError.lastName && errors.lastName}
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-4">
            <Input
              fullWidth
              name="email"
              type="email"
              label="Email*"
              size="small"
              variant="outlined"
              error={formError.email}
              helperText={formError.email && errors.email}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={3} className="mb-4">
            <Input
              autoComplete="tel"
              fullWidth
              name="workPhoneNumber"
              type="text"
              label="Work Phone*"
              size="small"
              variant="outlined"
              error={formError.workPhoneNumber}
              helperText={formError.workPhoneNumber && errors.workPhoneNumber}
              value={values.workPhoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={3} className="mb-4">
            <Input
              autoComplete="tel"
              fullWidth
              name="phoneNumber"
              type="text"
              label="Mobile Phone*"
              size="small"
              variant="outlined"
              error={formError.phoneNumber}
              helperText={formError.phoneNumber && errors.phoneNumber}
              value={values.phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <p className="text-center fr-small">
              Please read and agree to the&nbsp;
              <a href={PRIVACY_POLICY_URL.PRIVACY_POLICY.URL} target="_blank" rel="noreferrer">
                Terms of Use
              </a>
              &nbsp;and&nbsp;
              <a href={EULA_PAGE_URL.EULA_PAGE.URL} target="_blank" rel="noreferrer">
                EULA
              </a>
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="text-center">
            <p className="text-center fr-small">
              I agree
              <Checkbox value={agreed} onChange={onAgreeChange} />
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="text-center">
            <Button
              wider
              type="submit"
              kind="button"
              variant="contained"
              color="primary"
              bgHover={[theme.colors.orange.button, theme.colors.white.text]}
              colorOnClick={[theme.colors.darkcyan.button]}
              minWidth={130}
              size="large"
              disabled={!agreed || submitDisabled}
            >
              <span className="text-uppercase">{REGISTER_URL.REGISTER.NAME}</span>
            </Button>
          </Col>
        </Row>
      </form>
    );
  }, [formik, form, title, categories, agreed, onAgreeChange, submitDisabled]);

  return <>{registerVendorForm}</>;
};

export default RegisterVendorForm;
