import React, { useCallback } from 'react';
import { Container, Row, Col } from 'reactstrap';
import useToast from 'components/toast/useToast';
import authService from 'services/authorization';
import { useHistory } from 'react-router-dom';
import { FR_APP_KEY } from 'configs';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import imageHelper from 'utils/image';
import ForgotPasswordForm from 'components/forgotPasswordForm';
import useLoading from 'components/loading/useLoading';
import { FORGOT_PASSWORD_URL } from './url';

const ForgotPassword = () => {
  const [showLoading, hideLoading] = useLoading();
  const history = useHistory();
  const { toastError } = useToast();

  const logoPath = imageHelper.getContentLogo();

  const handleOnSubmit = useCallback(
    async (values) => {
      const { email } = values;
      showLoading();

      const respone = await authService.forgotPassword(email);
      if (respone?.errorCode) {
        toastError(respone.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        history.push(FORGOT_PASSWORD_URL.FORGOT_PASSWORD_SUCCESS.URL);
      }

      hideLoading();
    },
    [history, hideLoading, showLoading, toastError]
  );

  return (
    <div className="fr-background fr-box-container">
      <div className="fr-box">
        <Container>
          <div className="fr-page-container fr-content">
            <Row className="text-center">
              <Col md={12} className="my-3">
                <h3 className="fr-content-title m-0">Welcome to</h3>
              </Col>
            </Row>
            <Row className="text-center">
              <Col md={12} className="my-3">
                <img className="mw-100" src={logoPath} alt={FR_APP_KEY} />
              </Col>
            </Row>
            <Row className="text-center">
              <Col md={12} className="my-1">
                <p className="fr-content-sub-title mb-0">Forgot your password?</p>
                <p className="fr-content-sub-title">
                  Please enter your email and we will send you a reset link.
                </p>
              </Col>
            </Row>
            <ForgotPasswordForm onSubmit={handleOnSubmit} />
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ForgotPassword;
