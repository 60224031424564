import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LocalizationProvider, DateRangePicker as MDateRangePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, TextField } from '@mui/material';
import moment from 'moment';
import { isMobileByWatchMedia, isValidDate } from 'utils/helper';

const DateRangePicker = ({
  size,
  value,
  color,
  onChange,
  error,
  helperText,
  className,
  ...rest
}) => {
  const [errorFormat, setErrorFormat] = useState(false);
  const [helperFormat, setHelperFormat] = useState('');
  const [open, setOpen] = useState(false);

  const isMobile = isMobileByWatchMedia();

  const handleChange = (e) => {
    setErrorFormat(false);
    setHelperFormat('');

    if (!isValidDate(e[0])) {
      onChange([value[0], e[1]]);
      setErrorFormat({ start: true });
      setHelperFormat({ start: 'Wrong date format' });
      return;
    }
    if (!e?.[1]) {
      onChange(e);
      return;
    }
    if (!isValidDate(e[1])) {
      onChange([e[0], value[1]]);
      setErrorFormat({ end: true });
      setHelperFormat({ end: 'Wrong date format' });
      return;
    }

    onChange(e);

    const startDate = moment(e[0]);
    const endDate = moment(e[1]);

    const diff = startDate.isAfter(endDate);

    if (diff) {
      setErrorFormat({ start: true, end: true });
      setHelperFormat({
        start: 'Start Date must be less than End Date',
        end: 'End Date must be greater than Start Date'
      });
    }

    if (e?.[0] && e?.[1] && `${value[1]}` !== `${e[1]}`) {
      setTimeout(() => {
        setOpen(false);
      }, 300);
    }
  };

  const handleOnClick = () => {
    setOpen(true);
  };

  return (
    <div className={className}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MDateRangePicker
          calendars={2}
          value={value}
          onChange={handleChange}
          showToolbar={false}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderInput={(startProps, endProps) => {
            return (
              <div
                className={`${
                  isMobile && 'w-100'
                } d-flex justify-content-between align-items-center`}
              >
                <TextField
                  {...startProps}
                  color={color}
                  size={size}
                  error={errorFormat?.start ?? error}
                  helperText={helperFormat?.start ?? helperText}
                  label="Start Date"
                  onClick={handleOnClick}
                />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField
                  {...endProps}
                  color={color}
                  size={size}
                  error={errorFormat?.end ?? error}
                  helperText={helperFormat?.end ?? helperText}
                  label="End Date"
                  onClick={handleOnClick}
                />
              </div>
            );
          }}
          {...rest}
        />
      </LocalizationProvider>
    </div>
  );
};

DateRangePicker.defaultProps = {
  value: [null, null],
  error: false,
  inputProps: {},
  label: 'Date Range',
  onChange: () => {}
};

DateRangePicker.propTypes = {
  value: PropTypes.arrayOf(PropTypes.any),
  error: PropTypes.bool,
  inputProps: PropTypes.objectOf(PropTypes.any),
  label: PropTypes.string,
  onChange: PropTypes.func
};

export default DateRangePicker;
