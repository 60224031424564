import useLoading from 'components/loading/useLoading';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { PURPOSE } from 'constants/index';
import CampaignForm from 'pages/adminPortal/charity/campaign/campaignForm';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import campaignService from 'services/charity/campaign';
import AdminPortalCharityView, { CampaignTab } from '../..';

const AdminPortalCharityViewCampaignEdit = () => {
  const { id, cid } = useParams();
  const [campaign, setCampaign] = useState(null);
  const history = useHistory();
  const { toastError } = useToast();
  const [showLoading, hideLoading] = useLoading();

  useEffect(() => {
    const fetchId = async () => {
      showLoading();
      const resp = await campaignService.getCharityDetail(id);
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        history.push(ADMIN_PORTAL_URL.USER.URL);
      } else {
        const { name, description, charityId, startDate, endDate, logoPath, campaignUrl } = resp;
        setCampaign({
          name,
          description,
          charityId,
          startDate,
          endDate,
          logoPath,
          campaignUrl
        });
      }
      hideLoading();
    };
    fetchId();
  }, [hideLoading, history, id, showLoading, toastError]);

  const campaignFormRender = useMemo(() => {
    if (!campaign) {
      return null;
    }
    return (
      <CampaignForm
        purpose={PURPOSE.EDIT}
        campaignInfo={campaign}
        id={id}
        charityId={cid}
        redirectUrl={ADMIN_PORTAL_URL.CHARITY_VIEW_CAMPAIGNS.URL.replace(':cid', cid)}
        backUrl={ADMIN_PORTAL_URL.CHARITY_VIEW_CAMPAIGNS.URL.replace(':cid', cid)}
      />
    );
  }, [campaign, cid, id]);

  return (
    <>
      <AdminPortalCharityView tab={CampaignTab.value}>{campaignFormRender}</AdminPortalCharityView>
    </>
  );
};

export default AdminPortalCharityViewCampaignEdit;
