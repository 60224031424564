import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { FormControl, FormGroup, FormControlLabel, FormHelperText, Checkbox } from '@mui/material';
import { CARD_AMOUNT } from 'constants/index';

const AMOUNT_ARR = [
  { text: 'amount25', value: CARD_AMOUNT.AMOUNT_25 },
  { text: 'amount50', value: CARD_AMOUNT.AMOUNT_50 },
  { text: 'amount75', value: CARD_AMOUNT.AMOUNT_75 },
  { text: 'amount100', value: CARD_AMOUNT.AMOUNT_100 }
];

const SPLIT_STR = ',';

const DEFAULT_AMOUNT = {
  [AMOUNT_ARR[0].text]: false,
  [AMOUNT_ARR[1].text]: false,
  [AMOUNT_ARR[2].text]: false,
  [AMOUNT_ARR[3].text]: false
};

const useStyles = makeStyles(() => ({
  formGroup: {
    flexDirection: 'row !important',
    '& .MuiFormControlLabel-root': {
      '& .Mui-checked': {
        marginLeft: '0 !important'
      }
    }
  }
}));

const VendorCardAmount = ({ label, helperText, value, name, error, onChange, ...rest }) => {
  const [state, setState] = useState(DEFAULT_AMOUNT);

  const { formGroup } = useStyles();

  const { amount25, amount50, amount75, amount100 } = state;

  useEffect(() => {
    if (!value) return;
    let valueCheckbox = value.split(SPLIT_STR);
    if (typeof valueCheckbox === 'string') {
      valueCheckbox = [valueCheckbox];
    }
    const setInit = (arr) => {
      const newState = { ...DEFAULT_AMOUNT };

      AMOUNT_ARR.forEach((item) => {
        if (arr.includes(item.value)) {
          newState[item.text] = true;
          return;
        }
        newState[item.text] = false;
      });

      setState(newState);
    };

    setInit(valueCheckbox);
  }, [value]);

  const handleChange = (event) => {
    const newState = { ...state, [event.target.name]: event.target.checked };

    setState(newState);

    const eventSimulator = {
      target: {
        name,
        value: convertToStr(newState)
      }
    };

    onChange(eventSimulator);
  };

  const convertToStr = (obj) => {
    let str = '';
    AMOUNT_ARR.forEach((item) => {
      if (obj[item.text]) {
        str = `${str}${item.value},`;
      }
    });
    const indexLastChar = str.length - SPLIT_STR.length;
    if (str[indexLastChar] === SPLIT_STR) {
      return str.substring(0, indexLastChar);
    }

    return str;
  };

  return (
    <>
      {label && <p className={error ? 'text-danger' : undefined}>{label}</p>}
      <FormControl
        className="c-vendor-amount"
        required
        component="fieldset"
        variant="standard"
        error={error}
        {...rest}
      >
        <FormGroup className={formGroup}>
          <FormControlLabel
            control={
              <Checkbox
                color="tertiary"
                checked={amount25}
                onChange={handleChange}
                name={AMOUNT_ARR[0].text}
              />
            }
            label="$25"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="tertiary"
                checked={amount50}
                onChange={handleChange}
                name={AMOUNT_ARR[1].text}
              />
            }
            label="$50"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="tertiary"
                checked={amount75}
                onChange={handleChange}
                name={AMOUNT_ARR[2].text}
              />
            }
            label="$75"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="tertiary"
                checked={amount100}
                onChange={handleChange}
                name={AMOUNT_ARR[3].text}
              />
            }
            label="$100"
          />
        </FormGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </>
  );
};

export default VendorCardAmount;
