import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';

const useStyles = makeStyles(() => ({
  heroContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    '& .logo': {
      maxWidth: '300px',
      '@media (max-width: 600px)': {
        maxWidth: '100px'
      }
    }
  },
  heroTitle: {
    color: theme.colors.white.title,
    fontSize: theme.fontSize.s40,
    fontFamily: theme.fontFamily.titanOneRegular,
    textAlign: 'center',
    textShadow: `0 3px 7px ${theme.colors.black.text}`,
    marginBottom: '2rem',
    padding: '0 15px',
    '@media (max-width: 600px)': {
      fontSize: theme.fontSize.s22
    }
  },
  heroText: {
    color: theme.colors.white.text,
    fontSize: theme.fontSize.s22,
    fontFamily: theme.fontFamily.titanOneRegular,
    textAlign: 'center',
    textShadow: `0 3px 7px ${theme.colors.black.text}`,
    marginBottom: '1.5rem',
    padding: '0 15px',
    '@media (max-width: 600px)': {
      fontSize: theme.fontSize.s13
    }
  },
  heroButton: {
    boxShadow: '0px 10px 20px #0000D0',
    '& .z-text': {
      fontFamily: theme.fontFamily.titanOneRegular
    },
    '&.rayzr': {
      position: 'absolute',
      top: '45px',
      left: '70px'
    },
    '@media (max-width: 768px)': {
      '&.rayzr': {
        top: '45px',
        left: '45px'
      }
    },
    '@media (max-width: 600px)': {
      padding: '10px 15px !important',
      '&.rayzr': {
        top: '22px',
        left: '25px',
        fontSize: `${theme.fontSize.s13} !important`
      }
    }
  },
  authenticationArea: {
    marginTop: '3rem',
    marginBottom: '3rem'
  },
  textArea: {
    '& p': {
      color: theme.colors.white.text,
      textAlign: 'center',
      marginBottom: 0
    }
  },
  buttonArea: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '0 auto',
    maxWidth: '550px',
    '@media (max-width: 768px)': {
      maxWidth: '375px'
    }
  },
  learnMore: {
    color: theme.colors.white.text,
    fontSize: theme.fontSize.s20,
    fontFamily: theme.fontFamily.titanOneRegular,
    textShadow: `0px 3px 7px ${theme.text.textShadowColor}`,
    '&:hover': {
      color: theme.colors.white.text,
      textDecoration: 'none'
    }
  },
  actionBlock: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '8px',
    padding: '20px 10px',
    boxShadow: '0px 10px 20px #00000029',
    maxHeight: '288px',
    marginBottom: '10px',
    '&.charity': {
      background: theme.colors.darkcyan.background
    },
    '&.vendor': {
      background: theme.colors.orange.background
    },
    '& .support': {
      position: 'absolute',
      fontFamily: theme.fontFamily.titanOneRegular,
      color: theme.colors.white.title,
      background: theme.colors.violet.background,
      width: '100px',
      height: '200px',
      top: '-75px',
      left: '-25px',
      transform: 'rotate(45deg)',
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      '& .text': {
        transform: 'rotate(-90deg)',
        paddingTop: '40px',
        fontSize: '20px',
        textShadow: `0px 3px 7px ${theme.colors.black.background}`
      }
    },
    '& h3': {
      color: theme.colors.white.title,
      fontFamily: theme.fontFamily.titanOneRegular,
      textShadow: `0px 3px 7px ${theme.colors.black.background}`
    },
    '& p': {
      color: theme.colors.white.title,
      fontFamily: theme.fontFamily.quicksandLight,
      marginBottom: 0
    },
    '& a': {
      fontFamily: `${theme.fontFamily.titanOneRegular}!important`,
      padding: '10px 25px !important',
      maxWidth: '130px',
      boxShadow: `0px 3px 7px ${theme.text.textShadowColor}`
    }
  },
  logoBlock: {
    margin: '5px -5px 10px -5px',
    minHeight: '86px',
    '& > div': {
      padding: '0 5px'
    },
    '& > div > div': {
      background: theme.colors.white.background,
      height: '86px',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 5px',
      '& .ellipsis': {
        display: '-webkit-box',
        '-webkit-line-clamp': '3',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden'
      }
    },
    '& .logo': {
      maxWidth: '100%',
      maxHeight: '80px'
    }
  },
  boxShadow: {
    boxShadow: `0px 3px 7px ${theme.text.textShadowColor}`,
    borderRadius: '8px'
  },
  eGiftCard: {
    background: theme.colors.orange.background,
    borderRadius: '9px',
    marginTop: '-8%',
    padding: '40px 0',
    '& .egift-card-title, .egift-card-content': {
      textAlign: 'center',
      color: theme.colors.white.text
    },
    '& .egift-card-title': {
      fontFamily: theme.fontFamily.titanOneRegular,
      textShadow: `0px 3px 7px ${theme.colors.black.text}`,
      marginBottom: '20px'
    },
    '& .egift-card-content': {
      marginBottom: '10px',
      padding: '0 18%',
      textAlign: 'left',
      lineHeight: '1.5'
    }
  }
}));

export default useStyles;
