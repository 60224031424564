import React from 'react';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import authService from 'services/authorization';
import { Container } from 'reactstrap';
import AdminPortalUserAddForm from 'pages/adminPortal/user/add/userAddForm';

const AdminPortaVendorlUserAdd = () => {
  const tenantId = authService.getTenantId();

  return (
    <div className="fr-container pb-40 fr-background quicksand">
      <Container>
        <div className="fr-page-container mt-5" style={{ minHeight: '400px' }}>
          <AdminPortalUserAddForm
            tenantId={tenantId}
            includeAdminRole={false}
            redirectUrl={ADMIN_PORTAL_URL.VENDOR_USER.URL}
            backUrl={ADMIN_PORTAL_URL.VENDOR_USER.URL}
          />
        </div>
      </Container>
    </div>
  );
};

export default AdminPortaVendorlUserAdd;
