import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import imageHelper from 'utils/image';
import { FR_APP_KEY } from 'configs';

const BoxInfo = ({ title }) => {
  const imgPath = imageHelper.getContentLogo();

  return (
    <>
      <Row>
        <Col md={12}>
          <h3 className="fr-content-title">{title}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="fr-box-logo">
            <img src={imgPath} alt={FR_APP_KEY} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="indicator-ontent">
            <div className="indicator-item" />
            <div className="indicator-item" />
            <div className="indicator-item" />
          </div>
        </Col>
      </Row>
    </>
  );
};

BoxInfo.propTypes = {
  title: PropTypes.string.isRequired
};

export default BoxInfo;
