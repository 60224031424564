import React from 'react';
import { Col, Container, Row } from "reactstrap";
import BoxInfo from 'components/boxInfo';

const ForgotPasswordSuccess = () => {

  return (
    <div className="fr-background fr-box-container">
      <div className="fr-box">
        <Container>
          <div className="fr-page-container fr-content">
            <BoxInfo title="Password Recovery" />
            <Row className="text-center mt-5">
              <Col md={12} className="my-1">
                <p className="fr-content-sub-title mb-0">We have sent you an email to your registered address with further instructions.</p>
                <p className="fr-content-sub-title">Please make sure to check your spam folder if you did not receive it.</p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ForgotPasswordSuccess;
