import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { IMG_LOCAL } from 'constants/index';
import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';
import { isURL } from 'utils/helper';

const useStyles = makeStyles(() => ({
  background: {
    borderRadius: '9px 0 0 9px',
    borderRight: `solid 2px ${theme.colors.gray.backgroundCard}`
  },
  campaignNameBox: {
    minWidth: '150px',
    maxWidth: '150px',
    minHeight: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.white.background,
    borderRadius: '8px',
    textAlign: 'center'
  },
  logoImage: {
    maxWidth: '140px',
    padding: '10px',
    boxShadow:
      '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)'
  }
}));

const ProviderInformation = ({ campaign, charity, vendor }) => {
  const { campaignNameBox, logoImage, background } = useStyles();
  const history = useHistory();

  const { logoPath, displayName } = vendor;

  const campaignLogoRender = useMemo(() => {
    if (!campaign?.logoPath) {
      return (
        <>
          <div className={campaignNameBox}>{campaign?.name}</div>
        </>
      );
    }

    return (
      <>
        <img
          src={campaign?.logoPath}
          alt="FunRayZR"
          loading="lazy"
          className={`img-fluid ${logoImage} br-5`}
        />
        <div className="mt-1 black-text font-weight-bolder">{campaign?.name}</div>
      </>
    );
  }, [campaign?.logoPath, campaign?.name, campaignNameBox, logoImage]);

  return (
    <div className={`violet-text ${background}`} style={{ height: '100%' }}>
      <div className="p-2 ">
        <h3
          className="pointer px-4 py-4 mb-0 font-weight-bolder black-text"
          aria-hidden="true"
          onClick={() => history.goBack()}
        >
          &lt; Back
        </h3>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img
            src={logoPath || IMG_LOCAL.FUNRAYZR_LOGO}
            alt="FunRayZR"
            loading="lazy"
            className={`img-fluid ${logoImage} rounded`}
          />
          <div className="mt-1 black-text font-weight-bolder">{displayName}</div>
        </div>
        {charity ? (
          <>
            <div className="text-center mt-3">Your purchase will support:</div>
            <div className="d-flex flex-column justify-content-center align-items-center my-4">
              {campaignLogoRender}
            </div>
            <div className="text-center">Sponsored by:</div>
            <div className="d-flex flex-column justify-content-center align-items-center my-4">
              <img
                src={charity?.logoPath || IMG_LOCAL.FUNRAYZR_LOGO}
                alt="FunRayZR"
                loading="lazy"
                className={`img-fluid ${logoImage}`}
              />
              <div className="mt-1 black-text font-weight-bolder">{charity.name}</div>
            </div>
          </>
        ) : (
          <div className="text-center mt-2 text-break">
            <p className="m-0">{vendor.address}</p>
            <p className="m-0">
              {vendor.city}, {vendor.province}, {vendor.postalCode}
            </p>
            {isURL(vendor.website) ? (
              <a href={vendor.website} target="blank" className="violet-text violet-text-hover">
                {vendor.website}
              </a>
            ) : (
              <p className="m-0">{vendor.website}</p>
            )}

            <p className="m-0 pt-4">{vendor.description}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProviderInformation;
