import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { isEmptyObj } from 'utils/helper';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';

const DATA = {
  TITLE: 'text',
  KEY: 'value',
  ICON_LINK: 'iconLink'
};

const KIND = {
  TEXT: 'text',
  LEFT_ICON: 'leftIcon',
  RIGHT_ICON: 'rightIcon'
};

const DELAY_DEBOUNCUE_MS = 500;
const DEFAULT_PAGE_SIZE = 20;
const FIRST_PAGE = 0;
const DEFAULT_WIDTH = 300;

const useStyles = makeStyles(() => ({
  searchDisable: ({ haveSearch }) => ({
    '& .MuiAutocomplete-inputRoot .MuiInputBase-input': {
      textAlign: 'left'
    },
    '& .MuiAutocomplete-inputRoot': {
      cursor: haveSearch ? 'text' : 'pointer'
    },
    '& .MuiAutocomplete-endAdornment': {
      cursor: haveSearch ? 'text' : 'pointer'
    }
  }),
  textLeftInput: ({ haveSearch }) => ({
    '& input': {
      textAlign: haveSearch ? 'left' : 'inherit'
    }
  }),
  loadingMore: {
    padding: '6px 16px'
  }
}));

const Select = ({
  async,
  service,
  api,
  formatData,
  defaultOption,
  kind,
  haveSearch,
  color,
  className,
  data,
  disabled,
  error,
  helperText,
  labelInput,
  onChange,
  value,
  name,
  width,
  fullWidth,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(data || []);
  const [page, setPage] = useState(FIRST_PAGE);
  const [searchTerm] = useState('');
  const [maxPage, setMaxPage] = useState(FIRST_PAGE);
  const [fetching, setFetching] = useState(false);
  const [valueAuto, setValueAuto] = useState(null);
  const { toastError } = useToast();

  const { searchDisable, textLeftInput, loadingMore } = useStyles({ haveSearch });

  const loading = open && options.length === 0;
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (async) {
      (async () => {
        // FETCH FIRST API WHEN CLICK SELECT
        setFetching(true);
        if (active) {
          // SET FIRST DATA WHEN FETCHED
          setOptions([]);

          setMaxPage(
            Math.ceil(
              // {TOTAL_COUNT} /
              DEFAULT_PAGE_SIZE
            )
          );
          setFetching(false);
        }
      })();
    }

    return () => {
      active = false;
    };
  }, [loading, async]);

  useEffect(() => {
    const fetchData = async () => {
      if (!api || !service) return;
      setFetching(true);
      const resp = await service[api]({
        pageIndex: page,
        pageSize: DEFAULT_PAGE_SIZE
      });
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        return;
      }
      const formatValue = typeof formatData === 'function' ? formatData(resp) : resp;
      if (page === FIRST_PAGE) {
        setOptions([defaultOption, ...formatValue]);
        setMaxPage(resp?.totalPages || page);
      } else {
        setOptions((prev) => [...prev, ...formatValue]);
      }
      setFetching(false);
    };
    if (async) {
      fetchData();
    }
  }, [page, async, api, service, toastError, formatData, defaultOption]);

  useEffect(() => {
    if (searchTerm !== '' && async) {
      const delayDebounceFn = setTimeout(() => {
        // FETCH MORE API WHEN TYPING SEARCH INPUT WITH PARAM searchTerm & page AND setOptions
        setFetching(true);
        setOptions((prev) => [
          ...prev
          // NEW DATA
        ]);
        setFetching(false);
      }, DELAY_DEBOUNCUE_MS);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchTerm, async]);

  useEffect(() => {
    const finalData = async ? options : data;
    if (!value) return;
    if (!finalData.length) return;
    if (isEmptyObj(finalData?.[0])) return;
    setValueAuto(finalData.find((opt) => opt.value === value));
  }, [value, data, options, async]);

  const handleOnScroll = (e) => {
    if (page < (maxPage - 1) && async && !fetching) {
      const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
      if (scrollHeight - scrollTop === clientHeight) {
        setPage((prev) => prev + 1);
      }
    }
  };

  const handleOnInputChange = () => {
    if (!async) {
      return;
    }
  };

  const handleOnChange = (event, valueOnChange) => {
    event.target.value = valueOnChange[DATA.KEY];
    event.target.id = name;
    onChange(event);
  };

  return (
    <Autocomplete
      className={`${searchDisable} ${className}`}
      ListboxProps={{ onScroll: handleOnScroll, role: 'list-box' }}
      disableClearable
      id={name}
      style={{ width: fullWidth || width || DEFAULT_WIDTH }}
      disabled={disabled}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={valueAuto}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      isOptionEqualToValue={(option, valueEqual) => {
        return option?.[DATA.KEY] === valueEqual?.[DATA.KEY];
      }}
      getOptionLabel={(option) => option?.[DATA.TITLE]}
      renderOption={(props, option) => {
        const isLoadingMore = async && option === options[options.length - 1] && fetching;
        const renderOptionEl =
          kind === KIND.TEXT ? (
            <div {...props} key={option?.[DATA.KEY]}>
              {option?.[DATA.TITLE]}
            </div>
          ) : kind === KIND.LEFT_ICON ? (
            <div {...props} key={option?.[DATA.KEY]}>
              <img
                src={option?.[DATA.ICON_LINK]}
                width="20"
                height="20"
                className="mr-2"
                alt={option?.[DATA.TITLE]}
                loading="lazy"
              />
              {option?.[DATA.TITLE]}
            </div>
          ) : (
            <div
              className="w-100 d-flex justify-content-between align-items-center"
              {...props}
              key={option?.[DATA.KEY]}
            >
              <div>{option?.[DATA.TITLE]}</div>
              <img
                src={option?.[DATA.ICON_LINK]}
                width="20"
                height="20"
                alt={option?.[DATA.TITLE]}
                loading="lazy"
              />
            </div>
          );
        return async ? (
          <>
            {renderOptionEl}
            {isLoadingMore && <div className={loadingMore}>Loading...</div>}
          </>
        ) : (
          renderOptionEl
        );
      }}
      options={async ? options : data}
      loading={loading && fetching}
      renderInput={(params) => (
        <TextField
          {...params}
          color={color}
          name={name}
          error={error}
          helperText={helperText}
          type={haveSearch ? 'text' : 'button'}
          label={labelInput}
          className={textLeftInput}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && fetching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
      fullWidth={fullWidth}
      {...rest}
    />
  );
};

Select.defaultProps = {
  kind: KIND.TEXT,
  async: false,
  haveSearch: false,
  disabled: false,
  error: false,
  color: 'primary',
  helperText: '',
  className: '',
  labelInput: '',
  data: [],
  onChange: () => {},
  name: null
};

Select.propTypes = {
  kind: PropTypes.oneOf([KIND.TEXT, KIND.LEFT_ICON, KIND.RIGHT_ICON]),
  async: PropTypes.bool,
  haveSearch: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  color: PropTypes.string,
  helperText: PropTypes.string,
  labelInput: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  data: PropTypes.arrayOf(PropTypes.any),
  onChange: PropTypes.func,
  name: PropTypes.string
};

export default Select;
