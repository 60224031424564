import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Pagination, CircularProgress, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { IMG_LOCAL } from 'constants/index';
import { CHARITY_URL } from '../url';

const useGrib = () => {
  const [data, setData] = useState({ items: [], totalItems: 0 });
  const setDataFetch = useCallback((dataFetch) => setData(dataFetch), [setData]);

  return [data, setDataFetch];
};

const CharityGrid = ({
  rowCells,
  pageSize,
  totalCount,
  totalPages,
  service,
  searchFields,
  searchText,
  organizationType,
  setDataFetch,
  api
}) => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { push } = useHistory();

  const showLoading = useCallback(() => {
    setLoading(true);
  }, [setLoading]);
  const hideLoading = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  useEffect(() => {
    setPage(1);
  }, [searchText, organizationType]);

  useEffect(() => {
    const fetch = async (pageIndex = 0) => {
      const params = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        SearchText: searchText || undefined,
        OrganizationId: organizationType === 'all' ? undefined : organizationType,
        SearchFields: searchText ? searchFields : undefined
      };
      showLoading();
      const resp = await service[api](params);
      hideLoading();
      setDataFetch(resp);
    };

    fetch(page - 1);
  }, [
    service,
    setDataFetch,
    api,
    showLoading,
    hideLoading,
    page,
    searchFields,
    searchText,
    organizationType,
    pageSize
  ]);

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  return (
    <>
      <div className="pb-3">
        {rowCells.length > 0 && (
          <Paper elevation={3} className="white-bg-o2 p-4">
            <Container className="x-container">
              <Row>
                {rowCells?.map((data) => (
                  <Col
                    xs={12}
                    md={3}
                    key={data.id}
                    className="d-flex align-items-center flex-column py-4 pointer"
                    onClick={() => push(CHARITY_URL.DETAIL.URL.replace(':id', data.id))}
                  >
                    <Paper elevation={3} className="c-charity-card rounded-9 ">
                      <img
                        src={data?.image || IMG_LOCAL.DEFAULT_VENDOR}
                        alt={data.id}
                        loading="lazy"
                        className="img-fluid"
                      />
                    </Paper>
                    <p className="white-title mt-2 text-center mb-1 font-weight-bolder">
                      {data.title}
                    </p>
                    <p className="text-white mb-0">{data?.introduction}</p>
                  </Col>
                ))}
              </Row>
            </Container>
          </Paper>
        )}
      </div>

      {loading && (
        <div
          className="w-100 d-flex justify-content-center align-items-center"
          style={{ minHeight: '200px' }}
        >
          <CircularProgress disableShrink />
        </div>
      )}

      {!totalCount && !loading && (
        <div
          className="w-100 d-flex justify-content-center align-items-center text-white font-weight-bolder"
          style={{ minHeight: '200px' }}
        >
          No Data
        </div>
      )}

      {rowCells.length > 0 && (
        <div className="c-charity-pagination">
          <Pagination
            className="c-pagination"
            page={page}
            count={totalPages}
            onChange={handleChangePage}
          />
        </div>
      )}
    </>
  );
};

CharityGrid.defaultProps = {
  pageSize: 12,
  service: {},
  api: null,
  searchFields: undefined,
  searchText: undefined,
  organizationType: undefined,
  setDataFetch: () => { }
};

CharityGrid.propTypes = {
  rowCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  service: PropTypes.objectOf(PropTypes.any),
  api: PropTypes.string,
  searchFields: PropTypes.string,
  searchText: PropTypes.string,
  organizationType: PropTypes.string,
  setDataFetch: PropTypes.func
};

export { CharityGrid, useGrib };
