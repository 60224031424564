import Api from 'api/charity/api';
import { STORAGE_KEY } from 'constants/index';
import AxiosWrapper from 'utils/axios';

export class CharityService {
  constructor() {
    const axios = AxiosWrapper();
    this._charityApi = Api(axios);
  }

  getCharity(params) {
    return this._charityApi.getCharity(params);
  }

  getCharityPublic(params) {
    const pa = {
      ...params,
      regionId: localStorage.getItem(STORAGE_KEY.REGION)
    };
    return this._charityApi.getCharityPublic(pa);
  }

  getCharityDetail(id) {
    return this._charityApi.getCharityDetail(id);
  }

  createCharity(charity) {
    return this._charityApi.createCharity(charity);
  }

  editCharity(id, charity) {
    return this._charityApi.editCharity(id, charity);
  }

  updateCharityStatus(id, status) {
    return this._charityApi.updateCharityStatus(id, status);
  }
}

const charityService = new CharityService();

export default charityService;
