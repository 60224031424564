import React from 'react';
import { makeStyles } from '@mui/styles';
import { Popup } from 'components/popup';
import { theme } from 'constants/theme';
import { FAQ_POPUP, REFUND_POLICY } from 'constants/globalContent';

const useStyles = makeStyles(() => ({
  titlePopup: {
    color: theme.colors.black.title,
    fontSize: theme.fontSize.s16,
    fontFamily: theme.fontFamily.quicksandBold,
    textAlign: 'start',
    padding: '0 !important',
    marginBottom: '30px',
    '@media (min-width: 1024px)': {
      padding: '0px 39px 8px 39px'
    }
  },
  textContent: {
    fontFamily: theme.fontFamily.quicksandRegular,
    fontSize: theme.fontSize.s14,
    color: theme.colors.black.text,
    marginBottom: '30px',
    textAlign: 'start'
  }
}));
const FAQPopup = ({ isOpen, close }) => {
  const { titlePopup, textContent } = useStyles();
  const handleOnClose = () => {
    close();
  };

  return (
    <Popup
      closeIcon
      kind="confirm"
      isOpen={isOpen}
      onCancel={handleOnClose}
      size="md"
      title=""
      onClose={handleOnClose}
      customAction
      colorButtonCancel="#000000"
    >
      <div>
        <h5 className={titlePopup}>{FAQ_POPUP.eGiftCardTitle}</h5>
        <p className={textContent}>{REFUND_POLICY.not_refundable_policy}</p>
        <p className={textContent}>{REFUND_POLICY.never_expire_policy}</p>
        <p className={textContent}>{REFUND_POLICY.not_transferable_policy}</p>
        <p className={textContent}>{REFUND_POLICY.delivered_policy}</p>
        <p className={textContent}>{REFUND_POLICY.redeemed_policy}</p>
        <p className={textContent}>{REFUND_POLICY.denominated_policy}</p>
        <p className={textContent}>{REFUND_POLICY.qr_policy}</p>
        <p className={textContent}>{REFUND_POLICY.taxable_policy}</p>

        <h5 className={titlePopup}>{REFUND_POLICY.close_policy}</h5>
        <p className={textContent}>{REFUND_POLICY.certificate_policy}</p>

        <h5 className={titlePopup}>{REFUND_POLICY.not_happy_policy}</h5>
        <p className={textContent}>{REFUND_POLICY.customers_policy}</p>
      </div>
    </Popup>
  );
};

export default FAQPopup;
