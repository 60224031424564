import ResetPassword from '.';
import ResetPasswordFailed from './resetPasswordFailed';
import ResetPasswordSuccess from './resetPasswordSuccess';
import { RESET_PASSWORD_URL } from './url';

export const RESET_PASSWORD_ROUTES = [
  {
    name: RESET_PASSWORD_URL.RESET_PASSWORD.NAME,
    path: RESET_PASSWORD_URL.RESET_PASSWORD.URL,
    component: ResetPassword,
    isPrivate: false
  },
  {
    name: RESET_PASSWORD_URL.RESET_PASSWORD_SUCCESS.NAME,
    path: RESET_PASSWORD_URL.RESET_PASSWORD_SUCCESS.URL,
    component: ResetPasswordSuccess,
    isPrivate: false
  },
  {
    name: RESET_PASSWORD_URL.RESET_PASSWORD_FAILED.NAME,
    path: RESET_PASSWORD_URL.RESET_PASSWORD_FAILED.URL,
    component: ResetPasswordFailed,
    isPrivate: false
  }
];
