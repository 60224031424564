import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import React from 'react';
import { Container } from 'reactstrap';
import AdminPortalUserAddForm from './userAddForm';

const AdminPortalUserAdd = () => {
  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <div className="fr-page-container mt-5" style={{ minHeight: '400px' }}>
          <AdminPortalUserAddForm
            redirectUrl={ADMIN_PORTAL_URL.USER.URL}
            backUrl={ADMIN_PORTAL_URL.USER.URL}
          />
        </div>
      </Container>
    </div>
  );
};

export default AdminPortalUserAdd;
