import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { IMG_LOCAL, BUCKET, APP_KEY } from 'constants/index';
import Button from 'components/button';
import { SIGNIN_URL } from 'pages/signIn/url';
import { REGISTER_URL } from 'pages/register/url';
import { Link } from 'react-router-dom';
import { COLOR, theme } from 'constants/theme';
import { ABOUT_URL } from 'pages/about/url';
import publicService from 'services/public';
import { CHARITY_URL } from 'pages/charity/url';
import { SHOP_VENDOR_URL } from 'pages/shopVendor/url';
import Carousel from 'components/carousel';
import fileService from 'services/file';
import { v4 as uuidv4 } from 'uuid';
import { FR_APP_KEY } from 'configs';
import PopularCategories from 'pages/shopVendor/popularCategory';
import { isMobileByWatchMedia } from 'utils/helper';

import authService from 'services/authorization';
import useStyles from './styles';

const Landing = () => {
  const {
    heroContent,
    heroTitle,
    heroText,
    heroButton,
    authenticationArea,
    buttonArea,
    learnMore,
    actionBlock,
    logoBlock,
    boxShadow,
    eGiftCard
  } = useStyles();
  const isCancelled = useRef(false);

  const [charityPage] = useState(0);
  const [vendorPage] = useState(0);

  const [charities, setCharities] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [popularCategories, setPopularCategories] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);

  const isMobile = isMobileByWatchMedia();

  useEffect(() => {
    const isAuthenticated = authService.isAuthenticated();
    setAuthenticated(isAuthenticated);
  }, []);

  useEffect(() => {
    const fetchVendors = async () => {
      const resp = await publicService.getVendorWithoutCategory({
        pageIndex: vendorPage,
        pageSize: 90,
        orderBy: 'legalName',
        direction: 'Asc'
      });
      if (!resp.errorCode && resp.items && !isCancelled.current) {
        const { items } = resp;
        const newVendors = [];
        for (let i = 1; i <= 10; i++) {
          const start = (i - 1) * 9;
          const end = i * 9;
          const nVendors = items.slice(start, end);
          if (nVendors.length > 0) {
            newVendors.push(nVendors);
          }
        }
        setVendors(newVendors);
      }
    };

    if (FR_APP_KEY === APP_KEY.FUNRAY_ZR) {
      fetchVendors();
    }

    const fetchCharities = async () => {
      const resp = await publicService.getCharities({
        pageIndex: charityPage,
        pageSize: 90,
        orderBy: 'legalName',
        direction: 'Asc'
      });
      if (!resp.errorCode && resp.items && !isCancelled.current) {
        const { items } = resp;
        const newCharities = [];
        for (let i = 1; i <= 10; i++) {
          const start = (i - 1) * 9;
          const end = i * 9;
          const nCharities = items.slice(start, end);
          if (nCharities.length > 0) {
            newCharities.push(nCharities);
          }
        }
        setCharities(newCharities);
      }
    };

    if (FR_APP_KEY === APP_KEY.FUNRAY_ZR) {
      fetchCharities();
    }

    const fetchCategories = async () => {
      const resp = await publicService.getCategoriesPopular();
      if (!resp?.errorCode) {
        setPopularCategories(resp);
      }
    };

    if (FR_APP_KEY === APP_KEY.RAY_ZR) {
      fetchCategories();
    }

    return () => {
      isCancelled.current = true;
    };
  }, [charityPage, vendorPage]);

  const element = document.getElementById("top-content-page");

  useEffect(() => {
    if (element) {
      element.scrollIntoView();
    }
  }, [element]);
  const charityView = useMemo(() => {
    const itemRender = (items) => {
      const renders = [];
      for (let i = 1; i <= 3; i++) {
        const start = (i - 1) * 3;
        const end = i * 3;
        const r = items.slice(start, end);
        renders.push(r);
      }

      return (
        <>
          {renders.map((render) => {
            return (
              <Row className={logoBlock} key={uuidv4()}>
                {render.map((r) => {
                  const { id, legalName: name, logoPath } = r;
                  const logo = logoPath
                    ? `${fileService._baseUrl}/${BUCKET.CHARITY}/${logoPath}`
                    : '';
                  return (
                    <Col xs={4} key={uuidv4()}>
                      <div>
                        <Link to={CHARITY_URL.DETAIL.URL.replace(':id', id)}>
                          {logo && <img className="logo" src={logo} alt={name} />}
                          {!logo && <div className="ellipsis">{name}</div>}
                        </Link>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </>
      );
    };
    return (
      <>
        <Carousel
          autoPlay={false}
          indicator={false}
          navigateControl={charities.length > 1}
          items={charities}
          itemRender={itemRender}
        />
      </>
    );
  }, [charities, logoBlock]);

  const vendorView = useMemo(() => {
    const itemRender = (items) => {
      const renders = [];
      for (let i = 1; i <= 3; i++) {
        const start = (i - 1) * 3;
        const end = i * 3;
        const r = items.slice(start, end);
        renders.push(r);
      }

      return (
        <>
          {renders.map((render) => {
            return (
              <Row className={logoBlock} key={uuidv4()}>
                {render.map((r) => {
                  const { id, legalName: name, logoPath } = r;
                  const logo = logoPath
                    ? `${fileService._baseUrl}/${BUCKET.VENDOR}/${logoPath}`
                    : '';
                  return (
                    <Col xs={4} key={uuidv4()}>
                      <div>
                        <Link to={SHOP_VENDOR_URL.DETAIL.URL.replace(':id', id)}>
                          {logo && <img className="logo" src={logo} alt={name} />}
                          {!logo && <div className="ellipsis">{name}</div>}
                        </Link>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </>
      );
    };
    return (
      <>
        <Carousel
          indicator={false}
          navigateControl={vendors.length > 1}
          items={vendors}
          itemRender={itemRender}
        />
      </>
    );
  }, [logoBlock, vendors]);

  const authenticationView = useMemo(() => {
    const signInPath = `${SIGNIN_URL.SIGNIN.URL}`;
    const registerPath = `${REGISTER_URL.REGISTER.URL}`;

    return (
      <div className={authenticationArea}>
        <div className={buttonArea}>
          {!authenticated && (
            <Button
              component={Link}
              to={signInPath}
              kind="button"
              variant="contained"
              color="primary"
              bgHover={[theme.colors.orange.button, theme.colors.white.text]}
              colorOnClick={[theme.colors.darkcyan.button]}
              minWidth={130}
              size="large"
            >
              <span className="text-uppercase">{SIGNIN_URL.SIGNIN.NAME}</span>
            </Button>
          )}
          <Button
            component={Link}
            to={registerPath}
            kind="button"
            size="large"
            variant="contained"
            color="primary"
            bgHover={[theme.colors.orange.button, theme.colors.white.text]}
            colorOnClick={[theme.colors.darkcyan.button]}
            minWidth={130}
            className="text-uppercase"
          >
            <span className="text-uppercase">{REGISTER_URL.REGISTER.NAME}</span>
          </Button>
        </div>
      </div>
    );
  }, [authenticated, authenticationArea, buttonArea]);

  const funRayZrHeroRender = useMemo(() => {
    return (
      <div className="position-relative">
        <img
          className="w-100"
          src={isMobile ? IMG_LOCAL.LANDING_STREET_SHOTS : IMG_LOCAL.LANDING_STREET_SHOTS_PC}
        />
        <div className={`${heroContent} pt-4 pb-5`}>
          <div>
          <h3 className={`${heroTitle}`}>Support Local Business + Support Local Fundraisers</h3>
            {/* {!isMobile && <h3 className={`${heroTitle}`}>Support Local Business + Support Local Fundraisers</h3>}
            {isMobile && (
              <>
                <h3 className={`${heroTitle} mb-0`}>Support Local Business</h3>
                <h3 className={`${heroTitle} my-1`}>+</h3>
                <h3 className={`${heroTitle}`}>Support Local Fundraisers</h3>
              </>
            )} */}
            <h3 className={`${heroText} mb-2`}>Buy a Gift Card to a Local Business.</h3>
            <h3 className={heroText}>The Business Donates to Your Favourite Cause!</h3>
          </div>
          <div>
            <Button
              component={Link}
              to={SHOP_VENDOR_URL.SHOP_VENDOR.URL}
              className={`${heroButton} mb-xxxl-7`}
              size="large"
              color="primary"
              bgHover={[theme.colors.orange.button, theme.colors.white.text]}
              colorOnClick={[theme.colors.darkcyan.button]}
            >
              Shop Now!
            </Button>
          </div>
        </div>
      </div>
    );
  }, [isMobile, heroButton, heroContent, heroText, heroTitle]);

  const rayZrHeroRender = useMemo(() => {
    return (
      <div className="position-relative">
        <img className="w-100" src={IMG_LOCAL.RAYZR_ABOUT_BG_1} />
        <div className={`${heroContent} pt-4 pb-5`}>
          {
            !isMobile &&
            <img className="logo w-100" src={IMG_LOCAL.RAYZR_LOGO} />
          }
          <Button
            component={Link}
            to={SHOP_VENDOR_URL.SHOP_VENDOR_LIST.URL}
            className={`${heroButton} rayzr`}
            size="large"
            color="primary"
            bgHover={[theme.colors.orange.button, theme.colors.white.text]}
            colorOnClick={[theme.colors.darkcyan.button]}
          >
            Shop Now!
          </Button>
        </div>
      </div>
    );
  }, [heroButton, heroContent, isMobile]);

  const rayZrEGiftCardRender = useMemo(() => {
    return (
      <Container fluid>
        <Row className="mb-5">
          <Col xs={12}>
            <Container>
              <Row className="mx-0">
                <Col xs={12} className={eGiftCard}>
                  <h3 className="egift-card-title">RayZR is a contactless eGift Card platform!</h3>
                  <p className="egift-card-content">
                    1. Simple, easy to use digital currency you can use to purchase online and
                    redeem at your favourite local business.
                  </p>
                  <p className="egift-card-content">
                    2. Our program is run totally online including sign up, ordering and payment.
                  </p>
                  <p className="egift-card-content">
                    3. We offer electronic gift cards for all of our retailers so there is zero wait
                    time.
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }, [eGiftCard]);

  const localCharitySupport = useMemo(() => {
    return (
      <Col md="6">
        <div className={`charity ${actionBlock}`}>
          <div className="support">
            <div className="text">Support</div>
          </div>
          <h3 className="my-4">Local Causes</h3>
          <p>A portion of your gift card purchase</p>
          <p className="mb-5 text-center">goes to a charity, association, team or group you choose!</p>
          <Button
            color={COLOR.WARNING}
            component={Link}
            to={CHARITY_URL.CHARITY.URL}
            kind="button"
            variant="contained"
            bgHover={[theme.colors.orange.button, theme.colors.white.text]}
            colorOnClick={[theme.colors.darkcyan.button]}
            className="text-uppercase"
            size="large"
          >
            Click Here
          </Button>
        </div>
      </Col>
    );
  }, [actionBlock]);

  const funRayZrContentRender = useMemo(() => {
    return (
      <>
        <div>
          <Container className="pt-5">
            <Row>
              {isMobile && localCharitySupport}
              <Col md="6" className={charities.length <= 0 ? boxShadow : ''}>
                {charityView}
              </Col>
              {!isMobile && localCharitySupport}
            </Row>
          </Container>
        </div>

        <div>
          <Container className="pt-5">
            <Row>
              <Col md="6" className="text-center">
                <div className={`vendor ${actionBlock}`}>
                  <div className="support">
                    <div className="text">Support</div>
                  </div>
                  <h3 className={`my-4 ${isMobile ? 'mt-5' : ''}`}>Local Businesses</h3>
                  <p>By purchasing a FunRayZR eGift card, you are</p>
                  <p>supporting a local business and providing much</p>
                  <p className="mb-4">needed funds to a deserving organization!</p>
                  <Button
                    color={COLOR.INFO}
                    component={Link}
                    to={SHOP_VENDOR_URL.SHOP_VENDOR.URL}
                    kind="button"
                    variant="contained"
                    bgHover={[theme.colors.orange.button, theme.colors.white.text]}
                    colorOnClick={[theme.colors.darkcyan.button]}
                    className="text-uppercase"
                    size="large"
                  >
                    Click Here
                  </Button>
                </div>
              </Col>
              <Col md="6" className={vendors.length <= 0 ? boxShadow : ''}>
                {vendorView}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }, [actionBlock, boxShadow, charities.length, charityView, isMobile, localCharitySupport, vendorView, vendors.length]);

  const rayZrContentRender = useMemo(() => {
    return (
      <>
        <PopularCategories data={popularCategories} />
      </>
    );
  }, [popularCategories]);

  return (
    <div className="fr-background" id="top-content-page">
      {FR_APP_KEY === APP_KEY.FUNRAY_ZR && funRayZrHeroRender}
      {FR_APP_KEY === APP_KEY.RAY_ZR && rayZrHeroRender}

      {FR_APP_KEY === APP_KEY.RAY_ZR && rayZrEGiftCardRender}

      {FR_APP_KEY === APP_KEY.FUNRAY_ZR && funRayZrContentRender}
      {FR_APP_KEY === APP_KEY.RAY_ZR && rayZrContentRender}

      <div>
        <Container className={`${FR_APP_KEY === APP_KEY.FUNRAY_ZR ? 'pt-5' : 'pt-3'}`}>
          <Col md="12 text-center">
            <Link className={learnMore} to={ABOUT_URL.ABOUT.URL}>
              Learn more…
            </Link>
          </Col>
        </Container>
      </div>

      <div>
        <Container className="pb-4">
          <Col md="12">{authenticationView}</Col>
        </Container>
      </div>
    </div>
  );
};

export default Landing;
