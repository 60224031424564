import React from 'react';
import { Container } from 'reactstrap';
import { ADMIN_PORTAL_URL } from '../url';
import UserList from './userList';

const AdminPortalUser = () => {
  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <h3 className="fr-page-title">Admin Portal - Users</h3>
        <div className="fr-page-container  bg-transparent" style={{ minHeight: '400px' }}>
          <UserList
            tableTitle="Users"
            addUrl={ADMIN_PORTAL_URL.USER_ADD.URL}
            editUrl={ADMIN_PORTAL_URL.USER_DETAIL.URL}
            showDeleteColumn={true}
          />
        </div>
      </Container>
    </div>
  );
};

export default AdminPortalUser;
