import React from 'react';
import { Container } from 'reactstrap';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import authService from 'services/authorization';
import CampaignList from './list';

const AdminPortalCharityCampaign = () => {
  const tenantId = authService.getTenantId();

  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <h3 className="fr-page-title">Admin Portal - Campaign</h3>
        <div className="fr-page-container bg-transparent" style={{ minHeight: '400px' }}>
          <CampaignList
            charityId={tenantId}
            addUrl={ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS_ADD.URL}
            editUrl={ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS_EDIT.URL}
          />
        </div>
      </Container>
    </div>
  );
};

export default AdminPortalCharityCampaign;
