import React, { useState } from 'react';
import { Container } from 'reactstrap';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import Button from 'components/button';
import charityService from 'services/charity';
import { Table, useTable } from 'components/table';
import { Link } from 'react-router-dom';
import { Popup, usePopup } from 'components/popup';
import { gS } from 'constants/helperMessage';
import useToast from 'components/toast/useToast';
import { STATUS } from 'constants/index';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import { theme } from 'constants/theme';

const headCells = [
  { id: 'displayName', label: 'Display Name' },
  { id: 'businessNumber', label: 'Business Number' },
  { id: 'isActive', label: 'Active' },
  { id: 'action', label: '', className: 'w-37 w-xl-31' }
];

const AdminPortalCharity = () => {
  const [selectedCharity, setSelectedCharity] = useState({});
  const [isDisabledConfirm, setIsDisabledConfirm] = useState(false);

  const [data, setData] = useTable();
  const [isOpen, open, close] = usePopup();
  const { toastSuccess, toastError } = useToast();

  const isActiveCharity = selectedCharity?.isActive?.key;

  const handleActiveCharity = (index) => {
    setSelectedCharity(data.items[index]);
    open();
    setIsDisabledConfirm(false);
  };

  const renderIsActivated = (isActive) => {
    return isActive ? (
      <CheckBoxOutlinedIcon color="tertiary" sx={{ fontSize: 28 }} key={true} />
    ) : (
      <CheckBoxOutlineBlankOutlinedIcon color="tertiary" sx={{ fontSize: 28 }} />
    );
  };

  const renderActionButton = (isActive, index, id) => {
    return (
      <div style={{ minWidth: '250px' }}>
        <Button
          color={isActive ? 'secondary' : 'tertiary'}
          size="small"
          className="mr-1"
          onClick={() => {
            handleActiveCharity(index);
          }}
          minWidth={theme.button.minWidth}
        >
          {isActive ? 'Deactivate' : 'Activate'}
        </Button>

        <Button
          size="small"
          color="warning"
          className="ml-1 text-white-hover"
          component={Link}
          to={ADMIN_PORTAL_URL.CHARITY_VIEW_INFORMATION.URL.replace(':cid', id)}
        >
          View Details
        </Button>

        <Button
          color="quaternary"
          size="small"
          className="ml-1 text-white-hover"
          component={Link}
          to={ADMIN_PORTAL_URL.CHARITY_EDIT.URL.replace(':id', id)}
        >
          Edit
        </Button>
      </div>
    );
  };

  const handleOnClickConfirm = async (charity) => {
    if (isDisabledConfirm) return;
    if (!charity) return;
    setIsDisabledConfirm(true);
    const resp = await charityService.updateCharityStatus(
      charity.id,
      isActiveCharity ? STATUS.DEACTIVATE : STATUS.ACTIVATE
    );
    if (resp?.errorCode) {
      toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
    } else {
      charity.isActive = renderIsActivated(!isActiveCharity);
      charity.action = renderActionButton(!isActiveCharity, charity.index, charity.id);
      toastSuccess(gS(isActiveCharity ? 'Deactivated' : 'Activated'));
      close();
    }
  };

  const convertDataToCells = (arr) => {
    if (!arr) return [];
    return arr.map((value, index) => {
      const keys = Object.keys(value);
      keys.forEach((key) => {
        if (typeof value[key] === 'boolean') {
          if (key === 'isActive') {
            value.index = index;
            value.action = renderActionButton(value[key], index, value.id);
            value[key] = renderIsActivated(value[key]);
          }
        }
      });
      return value;
    });
  };

  const dataConvert = convertDataToCells(data.items);
  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <h3 className="fr-page-title">Admin Portal - Charity</h3>

        <div className="fr-page-container bg-transparent" style={{ minHeight: '900px' }}>
          <Table
            headCells={headCells}
            rowCells={dataConvert}
            totalCount={data?.totalItems ?? 0}
            havePagination={true}
            haveSearch
            haveHeaderAction={
              <>
                <h2 className="h6 text-tertiary font-weight-bold m-0">Add Charity</h2>
                <Button
                  className="mx-3 text-white-hover"
                  size="small"
                  color="tertiary"
                  component={Link}
                  to={ADMIN_PORTAL_URL.CHARITY_ADD.URL}
                >
                  Add
                </Button>
              </>
            }
            title="Charities"
            service={charityService}
            api="getCharity"
            setDataFetch={setData}
          />
        </div>
      </Container>
      <Popup
        kind="confirm"
        isOpen={isOpen}
        onConfirm={() => {
          handleOnClickConfirm(selectedCharity);
        }}
        isDisabledConfirm={isDisabledConfirm}
        onCancel={close}
        size="sm"
        title={`${isActiveCharity ? 'Deactivate Charity' : 'Activate Charity'}`}
        confirm="Yes"
      >
        {`Are you sure you want to ${isActiveCharity ? 'deactivate' : 'activate'} ${
          selectedCharity?.displayName
        }?`}
      </Popup>
    </div>
  );
};

export default AdminPortalCharity;
