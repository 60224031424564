import React, { useEffect, useState } from 'react';
import { FormHelperText } from '@mui/material';
import Button from 'components/button';

const AmountRadio = ({ name, label, helperText, error, data, onChange, ...rest }) => {
  const [amount, setAmount] = useState('');

  const dataLastIndex = data?.length - 1;

  useEffect(() => {
    if (!data?.[0]) return;
    setAmount(data[0]);

    onChange({
      target: {
        name,
        value: data[0]
      }
    });
  }, [name, data, onChange]);

  const handleClick = (amountValue) => {
    setAmount(amountValue);
    const eventSimulator = {
      target: {
        name,
        value: amountValue
      }
    };

    onChange(eventSimulator);
  };
  return (
    <>
      {label && <p className={error ? 'text-danger' : undefined}>{label}</p>}
      <div className="c-amount-container">
        {data?.map((amountValue, i) => (
          <Button
            color="black"
            key={amountValue}
            size="small"
            className={i !== dataLastIndex ? 'mr-3' : 'mt-0 mt-lg-3 mt-xl-0'}
            variant={amount === amountValue ? 'contained' : 'outlined'}
            {...rest}
            onClick={() => {
              handleClick(amountValue);
            }}
          >
            ${amountValue}
          </Button>
        ))}
      </div>
      <FormHelperText>{helperText}</FormHelperText>
    </>
  );
};

export default AmountRadio;
