import Charity from '.';
import ShopCharitiyDetail from './detail';
import { CHARITY_URL } from './url';

export const CHARITY_ROUTES = [
  {
    name: CHARITY_URL.CHARITY.NAME,
    path: CHARITY_URL.CHARITY.URL,
    component: Charity,
    isPrivate: false
  },
  {
    name: CHARITY_URL.DETAIL.NAME,
    path: CHARITY_URL.DETAIL.URL,
    component: ShopCharitiyDetail,
    isPrivate: false
  }
];
