import React, { useMemo } from 'react';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';

import CardTransactionList from 'pages/scanVendorTransactions/list';
import { Container } from 'reactstrap';

const AdminPortalVendorCardAndTransaction = () => {
  const renderView = useMemo(() => {
    return <CardTransactionList detailUrl={ADMIN_PORTAL_URL.VENDOR_CARD_TRANSACTION_DETAIL.URL} />;
  }, []);

  return (
    <>
      <div className="fr-container pb-40 fr-bg-admin quicksand">
        <Container>
          <h3 className="fr-page-title">Cards & Transaction</h3>
          <div className="fr-page-container  bg-transparent" style={{ minHeight: '400px' }}>
            {renderView}
          </div>
        </Container>
      </div>
    </>
  );
};

export default AdminPortalVendorCardAndTransaction;
