import Api from 'api/file/api';
import AxiosWrapper from 'utils/axios';
import { FUNRAYZR_API_URL } from 'configs';

export class FileService {
  constructor() {
    this._baseUrl = `${FUNRAYZR_API_URL}/File/bucket`;
    const axios = AxiosWrapper();
    this._fileApi = Api(axios);
  }

  createBucket(bucket, formData) {
    return this._fileApi.createBucket(bucket, formData);
  }
}

const fileService = new FileService();

export default fileService;
