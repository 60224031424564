import SignedOut from '.';
import { SIGNEDOUT_URL } from './url';

export const SIGNEDOUT_ROUTES = [
  {
    name: SIGNEDOUT_URL.SIGNEDOUT.NAME,
    path: SIGNEDOUT_URL.SIGNEDOUT.URL,
    component: SignedOut,
    isPrivate: false
  }
];
