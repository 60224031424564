import qs from 'qs';
import { FUNRAYZR_API_URL } from 'configs';
import { mapResponse } from 'api/responseMapping';

const Api = (axios) => {
  const url = `${FUNRAYZR_API_URL}/Report`;

  const getVendorReport = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${url}/charity/by-vendor-manager?${params}`);

    return mapResponse(resp);
  };

  const getVendorReportByAdmin = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${url}/vendors?${params}`);

    return mapResponse(resp);
  };

  const getCharityReportByAdmin = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);

    const resp = await axios.get(`${url}/charities?${params}`);

    return mapResponse(resp);
  };

  const getCharityReport = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);

    const resp = await axios.get(`${url}/vendor/by-charity-manager?${params}`);

    return mapResponse(resp);
  };

  return {
    getVendorReport,
    getVendorReportByAdmin,
    getCharityReportByAdmin,
    getCharityReport
  };
};

export default Api;
