import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BUCKET, EDIT_TYPE } from 'constants/index';
import charityService from 'services/charity';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import useLoading from 'components/loading/useLoading';
import { gS } from 'constants/helperMessage';
import fileService from 'services/file';
import { CircularProgress } from '@mui/material';
import ManageCharityForm from '../charityInfo/manageCharityForm';

const AdminPortalCharityInformationForm = ({ charityId, notFoundRedirectUrl, redirectUrl }) => {
  const [loadding, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [charityFetched, setCharityFetched] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [logo, setLogo] = useState();

  const history = useHistory();
  const { toastSuccess, toastError } = useToast();
  const [showLoading, hideLoading] = useLoading();

  useEffect(() => {
    const fetchId = async () => {
      const resp = await charityService.getCharityDetail(charityId);
      if (resp?.errorCode) {
        if (notFoundRedirectUrl) {
          history.push(notFoundRedirectUrl);
        }
      } else {
        const {
          legalName,
          introduction,
          website,
          description,
          logoPath,
          address,
          addressLine1 = address?.addressLine1,
          addressLine2 = address?.addressLine2,
          city = address?.city,
          province = address?.province,
          postalCode = address?.postalCode,
          displayName,
          businessNumber,
          organizationId,
          regionId,
          institutionNumber,
          transitNumber,
          accountNumber
        } = resp;

        if (logoPath) {
          setLogo(`${fileService._baseUrl}/${BUCKET.CHARITY}/${logoPath}`);
        }

        setCharityFetched({
          displayName,
          introduction,
          businessNumber,
          organizationId,
          regionId,
          institutionNumber,
          transitNumber,
          accountNumber,
          logoPath
        });

        setInitialValues({
          legalName: legalName ?? '',
          introduction: introduction ?? '',
          website: website ?? '',
          description: description ?? '',
          logoPath: logoPath ?? '',
          addressLine1: addressLine1 ?? '',
          addressLine2: addressLine2 ?? '',
          city: city ?? '',
          province: province ?? '',
          postalCode: postalCode ?? ''
        });
      }
      setLoading(false);
    };
    fetchId();
  }, [charityId, hideLoading, history, notFoundRedirectUrl, showLoading, toastError]);

  const onSubmit = useCallback(
    async (values) => {
      setIsDisabled(true);
      showLoading();

      const {
        legalName,
        website,
        description,
        introduction,
        city,
        province,
        logoPath,
        addressLine1,
        addressLine2,
        postalCode
      } = values;

      let logoKey = '';

      if (typeof logoPath === 'object') {
        const respImg = await fileService.createBucket(BUCKET.CHARITY, logoPath);
        if (respImg?.errorCode) {
          toastError(respImg?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        }
        logoKey = respImg.key;
      }

      const charity = {
        ...charityFetched,
        legalName,
        introduction,
        website,
        description,
        logoPath: logoKey || charityFetched.logoPath,
        address: { addressLine1, addressLine2, city, province, postalCode },
        type: EDIT_TYPE.BY_MANAGER
      };
      const resp = await charityService.editCharity(charityId, charity);
      hideLoading();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        setInitialValues({
          legalName: legalName ?? '',
          introduction: introduction ?? '',
          website: website ?? '',
          description: description ?? '',
          logoPath: logoPath ?? '',
          addressLine1: addressLine1 ?? '',
          addressLine2: addressLine2 ?? '',
          city: city ?? '',
          province: province ?? '',
          postalCode: postalCode ?? ''
        });
        toastSuccess(gS('Edit charity information'));
        if (redirectUrl) {
          history.push(redirectUrl);
        }
      }
      setIsDisabled(false);
    },
    [
      charityFetched,
      charityId,
      hideLoading,
      history,
      redirectUrl,
      showLoading,
      toastError,
      toastSuccess
    ]
  );

  return loadding ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <ManageCharityForm
      initialValues={initialValues}
      onSubmit={onSubmit}
      isDisabled={isDisabled}
      logo={logo}
      setLogo={setLogo}
      charityId={charityId}
    />
  );
};

export default AdminPortalCharityInformationForm;
