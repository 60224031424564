import React, { useState } from 'react';
import { FormHelperText } from '@mui/material';
import Button from 'components/button';

const GiftRadio = ({ name, label, helperText, error, onChange }) => {
  const [isGift, setIsGift] = useState(false);

  const handleClickNo = () => {
    setIsGift(false);
    onChange({
      target: {
        name,
        value: false
      }
    });
  };

  const handleClickYes = () => {
    setIsGift(true);
    onChange({
      target: {
        name,
        value: true
      }
    });
  };
  return (
    <>
      {label && <p className={error ? 'text-danger' : undefined}>{label}</p>}
      <Button
        color="black"
        onClick={handleClickNo}
        variant={isGift ? 'outlined' : 'contained'}
        size="small"
        className="mr-3"
      >
        NO
      </Button>
      <Button color="black" onClick={handleClickYes} variant={isGift ? 'contained' : 'outlined'} size="small">
        YES
      </Button>
      <FormHelperText>{helperText}</FormHelperText>
    </>
  );
};

export default GiftRadio;
