import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import React from 'react';
import { Container } from 'reactstrap';
import authService from 'services/authorization';
import CardTransactionView from './cardTransaction';

const AdminPortalCardTranDetail = () => {
  const tenantId = authService.getTenantId();

  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <div className="fr-page-container mt-5" style={{ minHeight: '400px' }}>
          <CardTransactionView
            tenantId={tenantId}
            notFoundRedirectUrl={ADMIN_PORTAL_URL.VENDOR_CARD_TRANSACTION.URL}
          />
        </div>
      </Container>
    </div>
  );
};

export default AdminPortalCardTranDetail;
