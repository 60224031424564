import qs from 'qs';
import { FUNRAYZR_API_URL } from 'configs';
import { mapResponse } from 'api/responseMapping';

const Api = (axios) => {
  const url = `${FUNRAYZR_API_URL}/Cards`;

  const getCards = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${url}?${params}`);

    return mapResponse(resp, true);
  };

  const getCardDetail = async (id, isMine) => {
    if (!id) return;
    const params = qs.stringify({ isMine });
    const resp = await axios.get(`${url}/${id}?${params}`);

    return mapResponse(resp);
  };

  const getTransactions = async (pa, id) => {
    if (!id) return;
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${url}/${id}/transactions?${params}`);

    return mapResponse(resp, true);
  };

  const getCardByCodes = async (code) => {
    if (!code) return;

    const resp = await axios.get(`${url}/codes/${code}`);

    return mapResponse(resp, true);
  };

  const putRedeem = async (id, value) => {
    const resp = await axios.put(`${url}/${id}/redeemed`, value);

    return mapResponse(resp);
  };

  const deactivateCard = async (id) => {
    const resp = await axios.put(`${url}/${id}/deactived`);

    return mapResponse(resp);
  };

  const resendEmail = async (id, recipientEmail) => {
    const resp = await axios.post(`${url}/${id}/resend-gift-card`, recipientEmail);
    return mapResponse(resp);
  };

  return {
    getCards,
    getCardDetail,
    getTransactions,
    getCardByCodes,
    putRedeem,
    deactivateCard,
    resendEmail
  };
};

export default Api;
