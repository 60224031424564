import useLoading from 'components/loading/useLoading';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { gS } from 'constants/helperMessage';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import regionService from 'services/region';
import RegionForm from '../regionForm';

const AdminPortalRegionEdit = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [region, setRegion] = useState(null);

  const history = useHistory();
  const { toastSuccess, toastError } = useToast();
  const [showLoading, hideLoading] = useLoading();
  const { id } = useParams();

  useEffect(() => {
    const fetchRegion = async () => {
      if (id) {
        const resp = await regionService.getRegion(id);
        setRegion(resp);
      }
    };

    fetchRegion();
  }, [id]);

  const onSubmit = useCallback(
    async (values) => {
      setIsDisabled(true);
      showLoading();

      const resp = await regionService.editRegion(id, values);
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        history.push(ADMIN_PORTAL_URL.REGION.URL);
        toastSuccess(gS('Save'));
      }

      hideLoading();
      setIsDisabled(false);
    },
    [hideLoading, history, id, showLoading, toastError, toastSuccess]
  );

  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <div className="fr-page-container mt-5" style={{ minHeight: '400px' }}>
          {region && (<RegionForm
            initialValues={region}
            title="Edit Region"
            cancelUrl={ADMIN_PORTAL_URL.REGION.URL}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
          />
          )}
        </div>
      </Container>
    </div>
  );
};

export default AdminPortalRegionEdit;
