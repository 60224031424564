import Api from 'api/user/api';
import AxiosWrapper from 'utils/axios';

export class UserService {
  constructor() {
    const axios = AxiosWrapper();
    this._userApi = Api(axios);
  }

  getAccountUserDetail(id) {
    return this._userApi.getAccountUserDetail(id);
  }

  getAccountUser(params) {
    return this._userApi.getAccountUser(params);
  }

  editUser(id, user) {
    return this._userApi.editUser(id, user);
  }

  statusRole(id, status) {
    return this._userApi.statusRole(id, status);
  }

  resendEmailConfirm(params) {
    return this._userApi.resendEmailConfirm(params);
  }

  statusUser(email, accountStatus) {
    return this._userApi.statusUser(email, accountStatus);
  }

  invitedUser(user) {
    return this._userApi.invitedUser(user);
  }
}

const userService = new UserService();

export default userService;
