import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BoxInfo from 'components/boxInfo';
import { CONFIRM_PURPOSE, URL_PARAM } from 'constants/index';
import { getParam } from 'utils/axios/helper';
import userService from 'services/user';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { useHistory } from 'react-router-dom';
import useToast from 'components/toast/useToast';
import { theme } from 'constants/theme';
import { makeStyles } from '@mui/styles';
import { gS } from 'constants/helperMessage';
import { RESET_PASSWORD_URL } from '../url';

const useStyles = makeStyles(() => ({
  here: {
    cursor: 'pointer',
    color: theme.colors.darkcyan.main,
    fontWeight: 600
  }
}));

const ResetPasswordFailed = () => {
  const history = useHistory();
  const { toastSuccess, toastError } = useToast();
  const { here } = useStyles();

  const handleClickHere = async () => {
    const email = getParam(history.location.search.substring(1), URL_PARAM.EMAIL);

    const params = {
      Email: email,
      SuccessReturnUrl: `${window.location.origin}${RESET_PASSWORD_URL.RESET_PASSWORD.URL}`,
      FailedReturnUrl: `${window.location.origin}${RESET_PASSWORD_URL.RESET_PASSWORD_FAILED.URL}?${URL_PARAM.EMAIL}=${email}`,
      Purpose: CONFIRM_PURPOSE.RESET
    };
    const resp = await userService.resendEmailConfirm(params);

    if (resp?.errorCode) {
      toastError(resp.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      return;
    }
    toastSuccess(gS('Resend email confirmation'));
  };

  return (
    <>
      <div className="fr-background fr-box-container">
        <div className="fr-box">
          <Container>
            <div className="fr-page-container fr-content">
              <BoxInfo title={RESET_PASSWORD_URL.RESET_PASSWORD_FAILED.NAME} />
              <Row className="text-center mt-5">
                <Col md={12} className="my-1">
                  <p className="fr-content-sub-title mb-3">
                    It was not possible to reset your password. This may happen if your link is invalid
                    or expired.
                  </p>
                  <p className="fr-content-sub-title mb-3">
                    Please click
                    <span
                      onClick={handleClickHere}
                      onKeyDown={handleClickHere}
                      aria-hidden="true"
                      className={here}
                    >
                      &nbsp;here&nbsp;
                    </span>
                    to resend email confirmation.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordFailed;
