import EulaPage from '.';

import { EULA_PAGE_URL } from './url';

export const EULA_PAGE_ROUTES = [
  {
    name: EULA_PAGE_URL.EULA_PAGE.NAME,
    path: EULA_PAGE_URL.EULA_PAGE.URL,
    component: EulaPage,
    isPrivate: false
  }
];
