import React from 'react';
import { Container } from 'reactstrap';

const AdminPortal = () => {
  return (
    <div className="fr-container pb-40 fr-background">
      <Container>
        <h3 className="fr-page-title">Admin Portal</h3>
        <div className="fr-page-container" style={{ minHeight: '400px' }} />
      </Container>
    </div>
  );
};

export default AdminPortal;
