import qs from 'qs';

import { FUNRAYZR_API_URL } from 'configs';
import { mapResponse } from 'api/responseMapping';

const Api = (axios) => {
  const regionUrl = `${FUNRAYZR_API_URL}/Regions`;

  const createRegion = async (region) => {
    const resp = await axios.post(regionUrl, region);
    return mapResponse(resp);
  };

  const editRegion = async (id, region) => {
    const resp = await axios.put(`${regionUrl}/${id}`, region);
    return mapResponse(resp);
  };

  const getRegions = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${regionUrl}?${params}`);
    return mapResponse(resp, true);
  };

  const getRegion = async (id) => {
    const resp = await axios.get(`${regionUrl}/${id}`);
    return mapResponse(resp);
  };

  const statusRegion = async (id, regionStatus) => {
    const resp = await axios.put(`${regionUrl}/${id}/status/${regionStatus}`);
    return mapResponse(resp);
  };

  return {
    createRegion,
    editRegion,
    getRegions,
    getRegion,
    statusRegion
  };
};

export default Api;
