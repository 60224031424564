import { ERROR_MESSAGE } from "constants/errorMessage";

const defaultError = {
  status: '',
  traceId: '',
  errorCode: ERROR_MESSAGE.UNKNOWN.CODE,
  errorMessage: ERROR_MESSAGE.UNKNOWN.MESSAGE
};

export const mapError = (result) => {
  if (!result) return null;
  const { status, data } = result;
  if (status === 401) return { ...defaultError, errorCode: undefined };
  if (!data) return defaultError;

  const { traceId, errorCode, errorMessage } = data;

  return {
    status,
    traceId,
    errorCode,
    errorMessage
  };
};
