import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BUCKET, IMG_LOCAL } from 'constants/index';
import { CircularProgress } from '@mui/material';
import vendorService from 'services/vendor';
import fileService from 'services/file';
import cardService from 'services/card';
import CardTransactionInfo from '../cardTransactionInfo';

const CardTransactionView = ({ tenantId, notFoundRedirectUrl }) => {
  const [loading, setLoading] = useState(true);
  const [card, setCard] = useState({});
  const [vendor, setVendor] = useState({});
  const { id } = useParams();
  const { push } = useHistory();

  useEffect(() => {
    const getVendor = async () => {
      const resp = await vendorService.getVendorDetail(tenantId);
      if (resp?.errorCode) {
        if (notFoundRedirectUrl) {
          push(notFoundRedirectUrl);
        }
      } else {
        const { displayName, address, logoPath } = resp;

        setVendor({
          displayName,
          logoPath: logoPath
            ? `${fileService._baseUrl}/${BUCKET.VENDOR}/${logoPath}`
            : IMG_LOCAL.DEFAULT_VENDOR,
          address: address?.addressLine1
        });
      }
    };
    if (tenantId) {
      getVendor();
    }
  }, [notFoundRedirectUrl, push, tenantId]);

  useEffect(() => {
    const getUser = async () => {
      const resp = await cardService.getCardDetail(id);
      if (resp?.errorCode) {
        if (notFoundRedirectUrl) {
          push(notFoundRedirectUrl);
        }
      } else {
        const {
          vendorId,
          cardNumber,
          firstName,
          lastName,
          email,
          cardBalance,
          cardType,
          charityId,
          charityName,
          charityLogoPath,
          campaignId,
          campaignName,
          campaignLogoPath,
          isGift,
          to,
          recipientEmail,
          qrCode,
          isDeactive,
          recentTransaction
        } = resp;

        setLoading(false);
        setCard({
          vendorId,
          cardNumber,
          firstName,
          lastName,
          email,
          cardBalance,
          cardType,
          charity: charityId
            ? {
                charityId,
                name: charityName,
                logoPath: charityLogoPath
              }
            : {},
          campaign: campaignId
            ? {
                campaignId,
                name: campaignName,
                logoPath: campaignLogoPath
              }
            : {},
          isGift,
          to,
          recipientEmail,
          qrCode,
          isDeactive,
          recentTransaction
        });
      }
    };
    getUser();
  }, [id, notFoundRedirectUrl, push]);

  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <CardTransactionInfo id={id} vendor={vendor} card={card} />
  );
};

export default CardTransactionView;
