import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { theme } from 'constants/theme';
import Button from 'components/button';
import { Col, Row } from 'reactstrap';
import Input from 'components/input';
import { gR } from 'constants/helperMessage';

const ForgotPasswordForm = ({ submitDisabled = false, onSubmit }) => {
  const initialValues = {
    email: ''
  };

  const validateSchema = Yup.object().shape({
    email: Yup.string().required(gR('Email')).email(ERROR_MESSAGE.EMAIL.VALID)
  });

  const getFormError = (touched, errors) => {
    const email = touched.email && !!errors.email;

    return {
      email
    };
  };

  const handleOnSubmit = useCallback(
    (values) => {
      if (onSubmit) {
        onSubmit(values);
      }
    },
    [onSubmit]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: validateSchema,
    onSubmit: handleOnSubmit
  });

  const forgotPasswordForm = useMemo(() => {
    const { touched, errors, values, handleSubmit, handleChange, handleBlur } = formik;
    const formError = getFormError(touched, errors);

    return (
      <>
        <form onSubmit={handleSubmit}>
          <Row className="text-center">
            <Col
              lg={{ size: 4, offset: 4 }}
              md={{ size: 6, offset: 3 }}
              sm={{ size: 8, offset: 2 }}
              className="mt-4 mb-4"
            >
              <Input
                fullWidth
                name="email"
                type="email"
                label="Email"
                size="small"
                variant="outlined"
                error={formError.email}
                helperText={formError.email && errors.email}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center mt-4">
              <Button
                wider
                disabled={submitDisabled}
                type="submit"
                kind="button"
                variant="contained"
                color="primary"
                bgHover={[theme.colors.orange.button, theme.colors.white.text]}
                colorOnClick={[theme.colors.darkcyan.button]}
              >
                RESET
              </Button>
            </Col>
          </Row>
        </form>
      </>
    );
  }, [formik, submitDisabled]);

  return <>{forgotPasswordForm}</>;
};

export default ForgotPasswordForm;
