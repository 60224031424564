import React, { createContext, useReducer } from 'react';
import Loading from 'components/loading';

const reducer = (state, action) => {
  switch (action.type) {
    case 'showLoading':
      return { ...state, loading: true };
    case 'hideLoading':
      return { ...state, loading: false };
    case 'cartTotalCount':
      return { ...state, cartTotalCount: action.payload };
    default:
      return state;
  }
};

export const LayoutContext = createContext();

const LayoutContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { loading: false, cartTotalCount: 0 });

  return (
    <LayoutContext.Provider value={{ state, dispatch }}>
      {children}
      <Loading loading={state.loading} overlayOpacity />
    </LayoutContext.Provider>
  );
};

export default LayoutContextProvider;
