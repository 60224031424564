import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from '@mui/material/';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from 'components/button';
import { useHistory } from 'react-router-dom';
import { ADMIN_URL } from 'constants/index';

const KIND = {
  DEFAULT: 'default',
  CONFIRM: 'confirm'
};

const usePopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  return [isOpen, open, close];
};

const useStyles = makeStyles(() => ({
  container: ({ minHeight }) => ({
    '& .MuiDialog-container .MuiPaper-root': {
      minHeight: `${minHeight}px`
    }
  }),
  confirmPopup: {
    '& .MuiDialog-container .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      '& h2': {
        flex: 0
      },
      '& div': {
        flex: 0
      }
    },
    '& .MuiDialogContent-root': {
      paddingTop: '2px !important'
    }
  }
}));

const Popup = ({
  kind,
  size,
  minHeight,
  title,
  children,
  confirm,
  colorConfirm,
  colorCancel,
  cancel,
  isOpen,
  onCancel,
  onConfirm,
  haveCancel,
  haveConfirm,
  customAction,
  disableClickOutside,
  closeIcon,
  isDisabledConfirm,
  colorButtonCancel,
  ...rest
}) => {
  const { container, confirmPopup } = useStyles({ minHeight });
  const { location } = useHistory();

  const isAdmin = location.pathname.includes(ADMIN_URL);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={size}
      open={isOpen}
      onClose={
        disableClickOutside
          ? (event, reason) => {
              if (reason !== 'backdropClick') {
                onCancel(event, reason);
              }
            }
          : onCancel
      }
      aria-labelledby="max-width-dialog-title"
      className={`${container} ${kind === KIND.CONFIRM && confirmPopup}`}
      {...rest}
    >
      {closeIcon && (
        <div className="mb-4">
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => colorButtonCancel || theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}

      {title && (
        <DialogTitle
          id="max-width-dialog-title"
          className={`text-center text-${isAdmin ? 'tertiary' : 'black'}`}
        >
          {title}
        </DialogTitle>
      )}
      {children && (
        <DialogContent>
          <DialogContentText component="div" className="text-center pt-1">
            {children}
          </DialogContentText>
        </DialogContent>
      )}
      {!customAction ? (
        <DialogActions className="justify-content-center">
          {haveCancel && (
            <Button onClick={onCancel} color={colorCancel} autoFocus size="small" className="mr-2">
              {cancel}
            </Button>
          )}
          {haveConfirm && (
            <Button
              disabled={isDisabledConfirm}
              onClick={onConfirm}
              color={colorConfirm}
              autoFocus
              size="small"
              minWidth={80.2}
            >
              {isDisabledConfirm ? <CircularProgress size={12} /> : confirm}
            </Button>
          )}
        </DialogActions>
      ) : (
        <DialogActions>{customAction}</DialogActions>
      )}
    </Dialog>
  );
};

Popup.defaultProps = {
  kind: KIND.DEFAULT,
  size: 'md',
  title: 'Popup Title',
  confirm: 'Confirm',
  cancel: 'Cancel',
  haveCancel: true,
  haveConfirm: true,
  customAction: '',
  children: null,
  colorConfirm: 'warning',
  colorCancel: 'secondary',
  minHeight: 350,
  disableClickOutside: false,
  closeIcon: false,
  isDisabledConfirm: false
};

Popup.propTypes = {
  kind: PropTypes.oneOf([KIND.DEFAULT, KIND.CONFIRM]),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  minHeight: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.objectOf(PropTypes.any)
  ]),
  confirm: PropTypes.string,
  colorConfirm: PropTypes.string,
  colorCancel: PropTypes.string,
  cancel: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  haveCancel: PropTypes.bool,
  haveConfirm: PropTypes.bool,
  disableClickOutside: PropTypes.bool,
  closeIcon: PropTypes.bool,
  isDisabledConfirm: PropTypes.bool,
  customAction: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export { Popup, usePopup };
