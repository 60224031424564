import React from 'react';
import { Container } from 'reactstrap';
import { ADMIN_PORTAL_URL } from '../url';
import RegionList from './regionList';

const RegionManagement = () => {
  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <h3 className="fr-page-title">Admin Portal - Region</h3>
        <div className="fr-page-container bg-transparent" style={{ minHeight: '400px' }}>
          <RegionList
            tableTitle="REGION LIST"
            addUrl={ADMIN_PORTAL_URL.REGION_ADD.URL}
            editUrl={ADMIN_PORTAL_URL.REGION_EDIT.URL}
          />
        </div>
      </Container>
    </div>
  );
};

export default RegionManagement;
