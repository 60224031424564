import React, { useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import Button from 'components/button';
import { theme } from 'constants/theme';
import { Table, useTable } from 'components/table';
import { formatDateMoment, isMobile } from 'utils/helper';
import { FR_APP_KEY } from 'configs';
import cardService from 'services/card';
import authService from 'services/authorization';
import BarCode from 'components/barCode';
import HeaderCardDetail from 'components/headerCardDetail';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { gS } from 'constants/helperMessage';
import { Popup, usePopup } from 'components/popup';
import { TRANSACTION_TYPE, CARD_TYPE, CART_DETAILS_TYPE, BUCKET } from 'constants/index';
import RedeemPopup from 'pages/scanVendorTransactions/redeemPopup';
import ShopVendorCard from 'pages/shopVendor/detail/component/shopVendorCard';
import fileService from 'services/file';
import CardGiftcardRoundedIcon from '@mui/icons-material/CardGiftcardRounded';
import VendorCard from '../../card';

const useStyles = makeStyles(() => ({
  border: {
    height: '1px',
    background: theme.colors.gray.border,
    margin: '0px 0px 20px 0px'
  },
  wrapperBalance: ({ isViewMode }) => ({
    display: 'none',
    '@media (min-width: 768px)': {
      display: isViewMode ? 'none' : 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '211px'
    }
  }),

  currentBalanceMobile: ({ isViewMode }) => ({
    textAlign: 'center',
    display: 'none',
    '@media (min-width: 768px)': {
      borderBottom: 0,
      textAlign: 'left',
      display: isViewMode ? 'none' : 'block'
    }
  }),
  currentBalanceViewMode: {
    textAlign: 'center',
    margin: '25px  0'
  },
  currentBalance: ({ isViewMode }) => ({
    padding: '25px 0',
    textAlign: 'center',
    borderBottom: `${theme.colors.gray.border} 1px solid`,
    display: isViewMode ? 'none' : 'block',
    '@media (min-width: 768px)': {
      borderBottom: 0,
      textAlign: 'center',
      display: 'none'
    }
  }),
  giftIcon: {
    marginRight: '2.5rem;'
  },
  desCode: {
    margin: '20px 0 10px',
    fontWeight: 'bold',
    fontSize: theme.fontSize.s12,
    textAlign: 'center',
    '@media (min-width: 768px)': {
      margin: '25px 0 15px'
    }
  },
  btnDisable: {
    opacity: '0.5'
  }
}));

const headCells = [
  { id: 'date', label: 'Date' },
  { id: 'amount', label: 'Amount' },
  { id: 'transactionType', label: 'Type' },
  { id: 'balance', label: 'Balance' },
  { id: 'cardType', label: 'Card Type' }
];

const headCellsMobile = [
  { id: 'date', label: 'Date' },
  { id: 'amount', label: 'Amount' },
  { id: 'transactionType', label: 'Type' }
];

const apiParams = {};

const CardTransactionInfo = ({
  vendor,
  card,
  id,
  isMine = false,
  haveRedeem = true,
  isStorefront = false
}) => {
  const [rerender, setRerender] = useState();
  const [deactivatedTime, setDeactivatedTime] = useState();
  const [isDisabledConfirm, setIsDisableConfirm] = useState(false);
  apiParams.isMine = isMine;
    const [isRedeem, openRedeem, closeRedeem] = usePopup();
  const { toastSuccess, toastError } = useToast();
  const [isDeactive, openDeactive, closeDeactive] = usePopup();
  const [data, setData] = useTable();
  const isMobileDevice = isMobile();

  const insertImg = (src, transactionType) => {
    if (!src) return;
    if (src === CARD_TYPE[0] || transactionType !== TRANSACTION_TYPE[0]) {
      return <p className="my-2 py-1">&nbsp;</p>;
    }
    return <img src={src} alt={FR_APP_KEY} style={{ maxHeight: 44 }} />;
  };
  const curUrl = useLocation();
  const isViewMode = curUrl.search === '?isView=true';
  const {
    border,
    currentBalance,
    giftIcon,
    desCode,
    currentBalanceMobile,
    currentBalanceViewMode,
    wrapperBalance,
    btnDisable
  } = useStyles({
    isViewMode
  });

  const isDisableDeactiveBtn = useMemo(() => {
    return !(authService.isSuperAdministrator());
  }, []);

  const dataConvert = useMemo(() => {
    const convertToRowCells = (arr) => {
      if (!arr) return [];

      return arr.map((value) => {
        value.date = value.date ? formatDateMoment(value.date) : '';
        value.amount = `$${parseFloat(value?.amount).toFixed(2)}`;
        value.balance = `$${parseFloat(value?.balance).toFixed(2)}`;
        value.transactionType = TRANSACTION_TYPE[value.transactionType];
        value.cardType = insertImg(CARD_TYPE[card.cardType], value.transactionType);
        if (!isMobileDevice) {
          return value;
        }

        return value;
      });
    };

    return convertToRowCells(data.items);
  }, [data.items, card.cardType, isMobileDevice]);

  const renderTable = useMemo(() => {
    return (
      <Table
        headCells={isMobileDevice ? headCellsMobile : headCells}
        rowCells={dataConvert}
        totalCount={data?.totalItems ?? 0}
        colorPagination={theme.colors.black.text}
        havePagination
        noBorderTop
        title="Transaction History"
        haveHeaderAction={
          haveRedeem &&
          !isViewMode && (
            <Button
              className="mr-0 mr-md-4 text-white-hover"
              size="small"
              color="warning"
              onClick={openRedeem}
              disabled={card.cardBalance <= 0}
            >
              Redeem
            </Button>
          )
        }
        service={cardService}
        api="getTransactions"
        apiParams={apiParams}
        id={id}
        setDataFetch={setData}
        rerender={rerender}
        pageSize={5}
      />
    );
  }, [
    isMobileDevice,
    dataConvert,
    data?.totalItems,
    isViewMode,
    openRedeem,
    card.cardBalance,
    id,
    setData,
    rerender,
    haveRedeem
  ]);

  const redeemSuccess = useCallback(
    (value) => {
      if (card && card.cardBalance > 0) {
        card.cardBalance -= value;
        card.cardBalance = card.cardBalance > 0 ? card.cardBalance : 0;
      }
    },
    [card]
  );

  const handleConfirmDeactivate = async () => {
    if (isDisabledConfirm || !id) return;
    setIsDisableConfirm(true);
    const resp = await cardService.deactivateCard(id);
    if (resp?.errorCode) {
      toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
    } else {
      setDeactivatedTime(new Date().toISOString());
      toastSuccess(gS('Deactivated'));
      closeDeactive();
    }
  };
  const cardView = useMemo(() => {
    const { charity, campaign, cardType } = card;
    if (charity) {
      charity.logoPath = charity.logoPath
        ? `${fileService._baseUrl}/${BUCKET.CHARITY}/${charity.logoPath}`
        : '';
    }
    if (campaign) {
      campaign.logoPath = campaign.logoPath
        ? `${fileService._baseUrl}/${BUCKET.CAMPAIGN}/${campaign.logoPath}`
        : '';
    }
    if (cardType === CART_DETAILS_TYPE.FUNRAY_ZR) {
      return (
        <ShopVendorCard
          vendor={vendor}
          charity={charity || {}}
          campaign={campaign || {}}
          cardType={cardType}
        />
      );
    }

    return (
      <VendorCard
        className="mx-auto"
        vendorLogo={vendor.logoPath}
        name={vendor.displayName}
        address={vendor.address}
        isChairityCard
        cardType={cardType}
      />
    );
  }, [card, vendor]);
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <HeaderCardDetail codeId={card?.cardNumber} />
          <Col xs={12} md={6} className="mt-5">
            {cardView}
            <Row>
              {!isViewMode ? (
                <Col xs={12}>
                  <Col xs={12}>
                    <p className={`${desCode}`}>Present this QR code at the register to redeem</p>
                  </Col>
                  <Col xs={12}>
                    <BarCode
                      codeId={card?.cardNumber}
                      price={card?.cardBalance}
                      url={card?.qrCode}
                      mdSize={card.cardType}
                    />
                  </Col>
                </Col>
              ) : (
                <Col xs={12}>
                  <p
                    className={`${currentBalanceViewMode} quicksand flex-1 h4 h3-md font-weight-bolder text-warning `}
                  >
                    CURRENT BALANCE: ${card?.cardBalance?.toFixed(2)}
                  </p>
                </Col>
              )}

              <Col xs={12}>
                <p
                  className={`${currentBalance} quicksand flex-1 h4 h3-md font-weight-bolder text-warning `}
                >
                  CURRENT BALANCE: ${card?.cardBalance?.toFixed(2)}
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="mt-md-5 mt-4">
            <Container fluid>
              <Row
                style={{
                  minHeight: card?.cardType === CART_DETAILS_TYPE.FUNRAY_ZR ? '245px' : '211px'
                }}
              >
                <Col xs={12} className="d-flex justify-content-between">
                  <p className="font-weight-bolder text-tertiary mb-0">Card Details</p>
                  {!isStorefront &&
                    (card?.isDeactive || deactivatedTime ? (
                      <div className="d-flex flex-column ">
                        <b className="text-danger">DEACTIVATED</b>
                        <span>
                          ({formatDateMoment(deactivatedTime || card?.recentTransaction)})
                        </span>
                      </div>
                    ) : (
                      <Button
                        color="secondary"
                        size="small"
                        className={isDisableDeactiveBtn ? btnDisable : ''}
                        disabled={isDisableDeactiveBtn}
                        onClick={() => {
                          openDeactive();
                        }}
                        minWidth={theme.button.minWidth}
                      >
                        Deactivate
                      </Button>
                    ))}
                </Col>
                <Col xs={12} md={6}>
                  <Container fluid>
                    <Row>
                      <Col xs={5} md={12} className="px-0 py-0 pt-md-4">
                        <p className="mb-0">First Name:</p>
                      </Col>
                      <Col xs={7} md={12} className="px-0 py-0 pb-md-4">
                        <p>
                          <b>{card?.firstName}</b>
                        </p>
                      </Col>
                      <Col xs={5} md={12} className="px-0">
                        <p className="mb-0">Email Address:</p>
                      </Col>
                      <Col xs={7} md={12} className="px-0">
                        <p className="text-break">
                          <b>{card?.email}</b>
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </Col>
                <Col xs={12} md={6}>
                  <Container fluid>
                    <Row>
                      <Col xs={5} md={12} className="px-0 py-0 pt-md-4">
                        <p className="mb-0">Last Name:</p>
                      </Col>
                      <Col xs={7} md={12} className="px-0 py-0 pb-md-4">
                        <p>
                          <b>{card?.lastName}</b>
                        </p>
                      </Col>
                      <Col xs={5} md={12} className="px-0">
                        <p className="mb-0">Card Number:</p>
                      </Col>
                      <Col xs={7} md={12} className="px-0 pb-4 pb-md-0">
                        <p>
                          <b>{card?.cardNumber}</b>
                        </p>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>

              {card?.isGift && (
                <>
                  <Row>
                    <Col className="pr-0" xs={12}>
                      <div className={`${border} ${isMobileDevice ? ' mb-1' : ''}`} />
                    </Col>
                  </Row>

                  <Row className="mb-2">
                    <Col xs={12} md={6}>
                      <Container
                        fluid
                        className={!isMobileDevice ? 'd-flex flex-row align-items-center' : ''}
                      >
                        <div className={`${giftIcon} ${isMobileDevice ? 'row' : ''}`}>
                          <CardGiftcardRoundedIcon fontSize="large" color="primary" />
                        </div>

                        <Row>
                          <Col xs={5} md={12} className="px-0 py-0">
                            <p className="mb-0">To:</p>
                          </Col>
                          <Col xs={7} md={12} className="px-0 py-0">
                            <p className="m-0">
                              <b>{card?.to || ''}</b>
                            </p>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                    <Col xs={12} md={6}>
                      <Container
                        fluid
                        className={!isMobileDevice ? 'd-flex flex-row align-items-center' : ''}
                      >
                        <Row>
                          <Col xs={5} md={12} className="px-0 py-0">
                            <p className="mb-0">Email Address:</p>
                          </Col>
                          <Col xs={7} md={12} className="px-0 py-0">
                            <p className="m-0 text-break">
                              <b>{card?.recipientEmail || ''}</b>
                            </p>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </>
              )}
              <Col
                xs={12}
                className={wrapperBalance}
                style={{
                  marginTop:
                    card?.isGift && card?.cardType === CART_DETAILS_TYPE.FUNRAY_ZR
                      ? '-30px'
                      : card?.cardType === CART_DETAILS_TYPE.FUNRAY_ZR
                      ? '50px'
                      : '20px'
                }}
              >
                <p
                  className={`${currentBalanceMobile} quicksand flex-1 h4 h3-md font-weight-bolder text-warning`}
                >
                  CURRENT BALANCE: ${card?.cardBalance?.toFixed(2)}
                </p>
              </Col>
            </Container>
          </Col>

          <Col xs={12}>
            <div className={border} />
          </Col>
        </Row>

        <Row className="pb-4">
          <Col xs={12}>{renderTable}</Col>
        </Row>
      </Container>
      <RedeemPopup
        isOpenRedeem={isRedeem}
        closeRedeem={closeRedeem}
        redeemSuccess={redeemSuccess}
        email={card?.email}
        id={id}
        setRerender={setRerender}
      />
      <Popup
        closeIcon={true}
        kind="confirm"
        isOpen={isDeactive}
        onConfirm={() => {
          handleConfirmDeactivate();
        }}
        isDisabledConfirm={isDisabledConfirm}
        onCancel={closeDeactive}
        size="sm"
        title="Deactivate Card"
        confirm="Yes"
      >
        {`Are you sure you want to deactivate the card number ${card?.cardNumber}?`}
      </Popup>
    </>
  );
};

export default CardTransactionInfo;
