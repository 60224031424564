import ForgotPassword from '.';
import ForgotPasswordSuccess from './forgotPasswordSuccess';
import { FORGOT_PASSWORD_URL } from './url';

export const FORGOT_PASSWORD_ROUTES = [
  {
    name: FORGOT_PASSWORD_URL.FORGOT_PASSWORD.NAME,
    path: FORGOT_PASSWORD_URL.FORGOT_PASSWORD.URL,
    component: ForgotPassword,
    isPrivate: false
  },
  {
    name: FORGOT_PASSWORD_URL.FORGOT_PASSWORD_SUCCESS.NAME,
    path: FORGOT_PASSWORD_URL.FORGOT_PASSWORD_SUCCESS.URL,
    component: ForgotPasswordSuccess,
    isPrivate: false
  }
];
