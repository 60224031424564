import React from 'react';
import { PURPOSE } from 'constants/index';
import CharityInfo from "../charityInfo";

const AdminPortalCharityAdd = () => {
  return (
    <CharityInfo
      purpose={PURPOSE.ADD}
    />
  );
};

export default AdminPortalCharityAdd;
