import React, { useCallback } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import Button from 'components/button';
import { theme } from 'constants/theme';
import Input from 'components/input';
import Select from 'components/select';
import { IMG_LOCAL, PURPOSE } from 'constants/index';
import { gR } from 'constants/helperMessage';
import { isEmptyObj } from 'utils/helper';
import UnsavedPopup from 'pages/home/unsavedPopup';
import { ERROR_MESSAGE } from 'constants/errorMessage';

const useStyles = makeStyles(() => ({
  border: {
    height: '1px',
    background: theme.colors.gray.border,
    margin: '20px 24px 20px 24px'
  },
  paddingCol: {
    padding: '10px 24px 10px 24px'
  },
  financialImg: {
    maxHeight: '215px'
  }
}));

const charityRates = [
  { text: '10%', value: 0.1 },
  { text: '9%', value: 0.09 },
  { text: '8%', value: 0.08 },
  { text: '7%', value: 0.07 },
  { text: '6%', value: 0.06 },
  { text: '5%', value: 0.05 }
];

const VendorInfo = ({ purpose, initialValues, onSubmit, isDisabled, categories, regions }) => {
  const { border, paddingCol, financialImg } = useStyles();

  const isAdd = purpose === PURPOSE.ADD;

  const handleOnSubmit = useCallback(
    (values) => {
      if (onSubmit) {
        onSubmit(values);
      }
    },
    [onSubmit]
  );

  const blankInitialValues = {
    legalName: '',
    firstName: '',
    lastName: '',
    displayName: '',
    workPhoneNumber: '',
    phoneNumber: '',
    businessNumber: '',
    email: '',
    categoryId: '',
    regionId: '',
    website: '',
    institutionNumber: '',
    transitNumber: '',
    accountNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    province: '',
    postalCode: '',
    charityRate: 0.1
  };

  const formik = useFormik({
    initialValues: isEmptyObj(initialValues) ? blankInitialValues : initialValues,
    validationSchema: Yup.object({
      legalName: Yup.string().required(gR('Legal Name')),
      firstName: Yup.string().required(gR('Primary Contact First Name')),
      lastName: Yup.string().required(gR('Primary Contact Last Name')),
      displayName: Yup.string().required(gR('Display Name')),
      workPhoneNumber: Yup.number()
        .required(gR('Work Phone'))
        .typeError(ERROR_MESSAGE.PHONE_NUMBER.VALID),
      phoneNumber: Yup.number()
        .required(gR('Mobile Phone'))
        .typeError(ERROR_MESSAGE.PHONE_NUMBER.VALID),
      businessNumber: Yup.string().required(gR('Business Number')),
      email: Yup.string().required(gR('Email')),
      categoryId: Yup.string().required(gR('Category')),
      regionId: Yup.string().required(gR('Region/Territory')),
      website: Yup.string().url(ERROR_MESSAGE.WEBSITE.VALID),
      institutionNumber: Yup.string().required(gR('Institution Number')),
      transitNumber: Yup.string().required(gR('Transit Number')),
      accountNumber: Yup.string().required(gR('Account Number')),
      addressLine1: Yup.string().required(gR('Address Line 1')),
      city: Yup.string().required(gR('City')),
      province: Yup.string().required(gR('Province')),
      postalCode: Yup.string().required(gR('Postal Code'))
    }),
    onSubmit: handleOnSubmit,
    enableReinitialize: true
  });

  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container className="x-container">
        <div className="fr-page-container mt-5" style={{ minHeight: '400px' }}>
          <Container>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col>
                  <Row>
                    <Col xs={12}>
                      <div className="d-flex justify-content-between align-items-center pt-4 mx-4">
                        <h2 className="h6 text-tertiary font-weight-bold flex-1 m-0">Vendors</h2>

                        {isAdd && <div className="flex-1 text-center text-12">Add Vendor</div>}

                        <div className="d-flex  flex-1 justify-content-end align-items-center">
                          {!isAdd && (
                            <h2 className="h6 text-tertiary font-weight-bold m-0">Edit Vendor</h2>
                          )}
                          <Button
                            disabled={isDisabled}
                            className="mx-3 text-white-hover"
                            size="small"
                            color="tertiary"
                            type="submit"
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12}>
                      <div className={border} />
                    </Col>
                    <Col xs={12} className="pb-2 text-center text-12">
                      {!isAdd && formik.values.displayName}
                    </Col>
                  </Row>

                  <Row className={paddingCol}>
                    <Col xs={6}>
                      <Input
                        color="tertiary"
                        fullWidth
                        size="small"
                        label="Legal Name*"
                        name="legalName"
                        onChange={formik.handleChange}
                        value={formik.values.legalName}
                        error={formik.touched.legalName && !!formik.errors.legalName}
                        helperText={formik.touched.legalName && formik.errors.legalName}
                      />
                    </Col>
                    <Col xs={3}>
                      <Input
                        color="tertiary"
                        fullWidth
                        size="small"
                        label="Primary Contact First Name*"
                        name="firstName"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        error={formik.touched.firstName && !!formik.errors.firstName}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                      />
                    </Col>
                    <Col xs={3}>
                      <Input
                        color="tertiary"
                        fullWidth
                        size="small"
                        label="Primary Contact Last Name*"
                        name="lastName"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        error={formik.touched.lastName && !!formik.errors.lastName}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                      />
                    </Col>
                  </Row>

                  <Row className={paddingCol}>
                    <Col xs={6}>
                      <Input
                        color="tertiary"
                        fullWidth
                        size="small"
                        label="Display Name*"
                        name="displayName"
                        onChange={formik.handleChange}
                        value={formik.values.displayName}
                        error={formik.touched.displayName && !!formik.errors.displayName}
                        helperText={formik.touched.displayName && formik.errors.displayName}
                      />
                    </Col>
                    <Col xs={3}>
                      <Input
                        color="tertiary"
                        fullWidth
                        size="small"
                        label="Work Phone*"
                        name="workPhoneNumber"
                        onChange={formik.handleChange}
                        value={formik.values.workPhoneNumber}
                        error={formik.touched.workPhoneNumber && !!formik.errors.workPhoneNumber}
                        helperText={formik.touched.workPhoneNumber && formik.errors.workPhoneNumber}
                      />
                    </Col>
                    <Col xs={3}>
                      <Input
                        color="tertiary"
                        fullWidth
                        size="small"
                        label="Mobile Phone*"
                        name="phoneNumber"
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber}
                        error={formik.touched.phoneNumber && !!formik.errors.phoneNumber}
                        helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                      />
                    </Col>
                  </Row>

                  <Row className={paddingCol}>
                    <Col xs={6}>
                      <Input
                        color="tertiary"
                        fullWidth
                        size="small"
                        label="Business Number*"
                        name="businessNumber"
                        onChange={formik.handleChange}
                        value={formik.values.businessNumber}
                        error={formik.touched.businessNumber && !!formik.errors.businessNumber}
                        helperText={formik.touched.businessNumber && formik.errors.businessNumber}
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        color="tertiary"
                        fullWidth
                        size="small"
                        label="Email Address*"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        error={formik.touched.email && !!formik.errors.email}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Col>
                  </Row>

                  <Row className={paddingCol}>
                    <Col xs={12} className={`${paddingCol} mt-1 text-12`}>
                      Additional Information
                    </Col>
                  </Row>

                  <Row className={paddingCol}>
                    <Col xs={3}>
                      <Select
                        style={{ maxWidth: '100%' }}
                        color="tertiary"
                        haveSearch
                        size="small"
                        name="categoryId"
                        value={formik.values.categoryId}
                        data={categories}
                        labelInput="Category*"
                        onChange={formik.handleChange}
                        error={formik.touched.categoryId && !!formik.errors.categoryId}
                        helperText={formik.touched.categoryId && formik.errors.categoryId}
                      />
                    </Col>
                    <Col xs={3}>
                      <Select
                        style={{ maxWidth: '100%' }}
                        color="tertiary"
                        haveSearch
                        size="small"
                        name="regionId"
                        value={formik.values.regionId}
                        data={regions}
                        labelInput="Region/Territory*"
                        onChange={formik.handleChange}
                        error={formik.touched.regionId && !!formik.errors.regionId}
                        helperText={formik.touched.regionId && formik.errors.regionId}
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        color="tertiary"
                        fullWidth
                        size="small"
                        label="Website"
                        name="website"
                        onChange={formik.handleChange}
                        value={formik.values.website}
                        error={formik.touched.website && !!formik.errors.website}
                        helperText={formik.touched.website && formik.errors.website}
                      />
                    </Col>
                  </Row>

                  <Row className={paddingCol}>
                    <Col xs={12} className={`${paddingCol} mt-1 text-12`}>
                      Financial Information
                    </Col>
                  </Row>

                  <Row className={paddingCol}>
                    <Col xs={3}>
                      <Row>
                        <Col xs={12} className="py-2">
                          <Input
                            color="tertiary"
                            fullWidth
                            size="small"
                            label="Transit Number*"
                            name="transitNumber"
                            onChange={formik.handleChange}
                            value={formik.values.transitNumber}
                            error={formik.touched.transitNumber && !!formik.errors.transitNumber}
                            helperText={formik.touched.transitNumber && formik.errors.transitNumber}
                          />
                        </Col>
                        <Col xs={12} className="py-2">
                          <Input
                            color="tertiary"
                            fullWidth
                            size="small"
                            label="Institution Number*"
                            name="institutionNumber"
                            onChange={formik.handleChange}
                            value={formik.values.institutionNumber}
                            error={
                              formik.touched.institutionNumber && !!formik.errors.institutionNumber
                            }
                            helperText={
                              formik.touched.institutionNumber && formik.errors.institutionNumber
                            }
                          />
                        </Col>
                        <Col xs={12} className="py-2">
                          <Input
                            color="tertiary"
                            fullWidth
                            size="small"
                            label="Account Number*"
                            name="accountNumber"
                            onChange={formik.handleChange}
                            value={formik.values.accountNumber}
                            error={formik.touched.accountNumber && !!formik.errors.accountNumber}
                            helperText={formik.touched.accountNumber && formik.errors.accountNumber}
                          />
                        </Col>
                        <Col xs={12} className="py-2">
                          <Select
                            fullWidth
                            labelInput="FunRayZR Charity Rate"
                            name="charityRate"
                            color="tertiary"
                            size="small"
                            data={charityRates}
                            value={formik.values.charityRate}
                            onChange={formik.handleChange}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={9}>
                      <img className={financialImg} src={IMG_LOCAL.FINANCIAL_INFORMATION} />
                    </Col>
                  </Row>

                  <Row className={paddingCol}>
                    <Col xs={12} className={`${paddingCol} mt-1 text-12`}>
                      Address
                    </Col>
                  </Row>

                  <Row className={paddingCol}>
                    <Col xs={6}>
                      <Input
                        color="tertiary"
                        fullWidth
                        size="small"
                        label="Address Line 1*"
                        name="addressLine1"
                        onChange={formik.handleChange}
                        value={formik.values.addressLine1 ?? ''}
                        error={formik.touched.addressLine1 && !!formik.errors.addressLine1}
                        helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
                      />
                    </Col>
                    <Col xs={6}>
                      <Input
                        color="tertiary"
                        fullWidth
                        size="small"
                        label="Address Line 2"
                        name="addressLine2"
                        onChange={formik.handleChange}
                        value={formik.values.addressLine2}
                        error={formik.touched.addressLine2 && !!formik.errors.addressLine2}
                        helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
                      />
                    </Col>
                  </Row>

                  <Row className={paddingCol}>
                    <Col xs={6} className="mb-4">
                      <Input
                        color="tertiary"
                        fullWidth
                        size="small"
                        label="City*"
                        name="city"
                        onChange={formik.handleChange}
                        value={formik.values.city}
                        error={formik.touched.city && !!formik.errors.city}
                        helperText={formik.touched.city && formik.errors.city}
                      />
                    </Col>
                    <Col xs={3} className="mb-4">
                      <Input
                        color="tertiary"
                        fullWidth
                        size="small"
                        label="Province*"
                        name="province"
                        onChange={formik.handleChange}
                        value={formik.values.province}
                        error={formik.touched.province && !!formik.errors.province}
                        helperText={formik.touched.province && formik.errors.province}
                      />
                    </Col>

                    <Col xs={3} className="mb-4">
                      <Input
                        color="tertiary"
                        fullWidth
                        size="small"
                        label="Postal Code*"
                        name="postalCode"
                        onChange={formik.handleChange}
                        value={formik.values.postalCode ?? ''}
                        error={formik.touched.postalCode && !!formik.errors.postalCode}
                        helperText={formik.touched.postalCode && formik.errors.postalCode}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </form>
          </Container>
        </div>
      </Container>
      <UnsavedPopup
        values={formik.values}
        initialValues={formik.initialValues}
        isSubmit={isDisabled}
        resetForm={formik.resetForm}
      />
    </div>
  );
};

export default VendorInfo;
