import useLoading from 'components/loading/useLoading';
import RegisterCharityForm from 'components/registerCharityForm';
import useToast from 'components/toast/useToast';
import { FR_APP_KEY } from 'configs';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import authService from 'services/authorization';
import publicService from 'services/public';
import { REGISTER_URL } from '../url';

const RegisterCharity = () => {
  const [organizationTypes, setOrganizationTypes] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showLoading, hideLoading] = useLoading();
  const history = useHistory();
  const { toastError } = useToast();
  const isCancelled = useRef(false);

  useEffect(() => {
    const fetchCategories = async () => {
      const resp = await publicService.getOrganizationTypes();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        return;
      }

      if (!isCancelled.current && Array.isArray(resp)) {
        setOrganizationTypes(resp);
      }
    };

    fetchCategories();

    return () => {
      isCancelled.current = true;
    };
  }, [toastError]);

  const handleOnSubmit = useCallback(
    async (charityInfo) => {
      setIsDisabled(true);
      showLoading();

      const registerCharity = {
        ...charityInfo,
        primaryContact: {
          ...charityInfo.primaryContact,
          appName: FR_APP_KEY
        },
        returnUrl: `${window.location.origin}${ADMIN_PORTAL_URL.USER.URL}`
      };

      const respone = await authService.registerCharity(registerCharity);
      if (respone?.errorCode) {
        toastError(respone.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        history.push(REGISTER_URL.REGISTER_CHARITY_SUCCESS.URL);
      }

      setIsDisabled(false);
      hideLoading();
    },
    [showLoading, hideLoading, toastError, history]
  );

  return (
    <>
      <div className="fr-background fr-box-container">
        <div className="fr-box">
          <Container>
            <div className="fr-page-container fr-content">
              <h3 className="fr-content-title mb-5">{REGISTER_URL.REGISTER_CHARITY.NAME}</h3>
              <RegisterCharityForm
                onSubmit={handleOnSubmit}
                submitDisabled={isDisabled}
                organizationTypes={organizationTypes}
              />
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default RegisterCharity;
