import React, { useCallback } from 'react';
import { Popup } from 'components/popup';
import authService from 'services/authorization';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';

const AdministrationPopup = ({ isOpenAdmin, closeAdmin, email }) => {
  const { toastSuccess, toastError } = useToast();

  const handleConfirm = useCallback(async () => {
    const respone = await authService.forgotPassword(email);
    if (respone?.errorCode) {
      toastError(respone.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
    } else {
      toastSuccess(respone);
      closeAdmin();
    }
  }, [toastSuccess, toastError, closeAdmin, email]);

  return (
    <Popup
      kind="confirm"
      isOpen={isOpenAdmin}
      onConfirm={handleConfirm}
      onCancel={closeAdmin}
      size="sm"
      title="Administration"
      confirm="Send Password Reset"
      colorConfirm="primary"
    >
      <>
        Email will be delivered to:
        <p className="mt-3 text-dark font-weight-bold">{email}</p>
      </>
    </Popup>
  );
};

export default AdministrationPopup;
