import SignIn from "pages/signIn";
import SetupPassword from "./setupPassword";
import SetupPasswordFailed from "./setupPassword/setupPasswordFalied";
import { ADMIN_PORTAL_URL } from "./url";

export const ADMIN_PORTAL_BASIC_ROUTES = [
  {
    name: ADMIN_PORTAL_URL.SIGNIN.NAME,
    path: ADMIN_PORTAL_URL.SIGNIN.URL,
    component: SignIn,
    isPrivate: false
  },
  {
    name: ADMIN_PORTAL_URL.SETUP_PASSWORD.NAME,
    path: ADMIN_PORTAL_URL.SETUP_PASSWORD.URL,
    component: SetupPassword,
    isPrivate: false
  },
  {
    name: ADMIN_PORTAL_URL.SETUP_PASSWORD_FAILED.NAME,
    path: ADMIN_PORTAL_URL.SETUP_PASSWORD_FAILED.URL,
    component: SetupPasswordFailed,
    isPrivate: false
  }
];
