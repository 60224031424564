import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import AdminPortalUserEditForm from 'pages/adminPortal/user/detail/userEditForm';
import React from 'react';
import { useParams } from 'react-router-dom';
import AdminPortalVendorViev, { UserManagementTab } from '../..';

const AdminPortalVendorViewUserEdit = () => {
  const { vid } = useParams();

  return (
    <>
      <AdminPortalVendorViev vendorTab={UserManagementTab.value}>
        <AdminPortalUserEditForm
          hasTenantField={false}
          includeAdminRole={false}
          redirectUrl={ADMIN_PORTAL_URL.VENDOR_VIEW_USERS.URL.replace(':vid', vid)}
          backUrl={ADMIN_PORTAL_URL.VENDOR_VIEW_USERS.URL.replace(':vid', vid)}
        />
      </AdminPortalVendorViev>
    </>
  );
};

export default AdminPortalVendorViewUserEdit;
