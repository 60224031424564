import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { BUCKET, IMG_LOCAL } from 'constants/index';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import { CircularProgress } from '@mui/material';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import publicService from 'services/public';
import fileService from 'services/file';
import ShopVendorDetailComponent from './component';

const ShopVendorDetail = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [vendor, setVendor] = useState({});

  const { toastError } = useToast();

  useEffect(() => {
    const fetchId = async () => {
      setLoading(true);
      const resp = await publicService.getVendor(match.params.id);

      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        history.push(ADMIN_PORTAL_URL.VENDOR.URL);
      } else {
        const { displayName, address = {}, website, description, logoPath, amount, id } = resp;

        setLoading(false);

        setVendor({
          displayName,
          addressLine1: address.addressLine1,
          city: address.city,
          province: address.province,
          postalCode: address.postalCode,
          website,
          description,
          logoSrc: logoPath
            ? `${fileService._baseUrl}/${BUCKET.VENDOR}/${logoPath}`
            : IMG_LOCAL.DEFAULT_VENDOR,
          amount: amount
            .replace(/ /g, '')
            .replaceAll('Amount', '')
            .split(',')
            .sort((a, b) => a - b),
          id
        });
      }
    };
    fetchId();
  }, [history, match.params.id, toastError]);

  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <ShopVendorDetailComponent vendor={vendor} />
  );
};

export default withRouter(ShopVendorDetail);
