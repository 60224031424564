import React from 'react';
import { TableBody, TableCell, TableHead, TableRow, Table, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(() => ({
  table: {
    marginTop: '16px',
    borderCollapse: 'collapse',
    position: 'relative'
  },
  trHead: ({ noBorderTop, stickyHeader }) => ({
    '& th': {
      fontWeight: '600',
      color: theme.colors.gray.title,
      backgroundColor: theme.colors.white.background,
      paddingTop: '20px',
      borderTop: !noBorderTop ? `1px solid ${theme.colors.gray.border}` : 0,
      borderBottom: '5px solid transparent',
      position: stickyHeader ? 'sticky' : '',
      top: 0
    }
  }),
  tr: {
    '&:hover': {
      backgroundColor: theme.colors.yellow.hover
    }
  },
  td: {
    fontWeight: '300',
    background: theme.colors.gray.backgroundTable,
    borderTop: '5px solid transparent',
    borderBottom: '0px',
    backgroundClip: 'padding-box'
  }
}));

const TableReporting = ({ headCells, rowCells, loading, noBorderTop, stickyHeader, ...rest }) => {
  const { table, trHead, tr, td } = useStyles({ noBorderTop, stickyHeader });

  return (
    <>
      <Table className={table} {...rest}>
        <TableHead>
          <TableRow className={trHead}>
            {headCells?.map((headCell) => (
              <TableCell
                key={headCell.id}
                width={headCell.width}
                align={headCell.align ? headCell.align : 'center'}
                className={headCell.className ? headCell.className : null}
              >
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {!loading && (
          <TableBody>
            {rowCells?.map((item) => (
              <TableRow key={item.id || uuidv4()} className={tr}>
                {headCells?.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.align ? headCell.align : 'center'}
                    className={td}
                  >
                    {item[headCell.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>

      {loading && (
        <div
          className="w-100 d-flex justify-content-center align-items-center bg-white"
          style={{ minHeight: '250px' }}
        >
          <CircularProgress disableShrink />
        </div>
      )}
    </>
  );
};

export default TableReporting;
