import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';
import useToast from 'components/toast/useToast';
import useLoading from 'components/loading/useLoading';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { FR_APP_KEY } from 'configs';
import orderService from 'services/oder';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import { Paper } from '@mui/material';
import cartService from 'services/cart';
import { CART_URL } from 'pages/cart/url';
import { LayoutContext } from 'layout/layoutContext';
import storageHelper from 'utils/storage';
import CardDetailAuthorize from 'components/checkout/cardDetailAuthorize';

import authService from 'services/authorization';
import { CHECKOUT_URL } from './url';

const useStyles = makeStyles(() => ({
  border: {
    height: '2px',
    background: theme.colors.gray.border,
    margin: '10px 24px 10px 24px'
  },
  paddingCol: {
    padding: '10px 39px 10px 39px'
  }
}));

const checkout = {};
const Checkout = () => {
  const [cart, setCart] = useState(null);
  const [isDisabledPayNow, setIsDisabledPayNow] = useState(true);
  const [reloaded, setReloaded] = useState(false);
  const { dispatch } = useContext(LayoutContext);

  const history = useHistory();
  const { toastError } = useToast();
  const [showLoading, hideLoading] = useLoading();

  useEffect(() => {
    const getCart = async () => {
      showLoading();
      const cartRes = await cartService.getCart();
      if (cartRes?.errorCode) {
        toastError(cartRes.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        setTimeout(() => {
          history.push(CART_URL.CART.URL);
        }, 100);
      } else {
        setCart(cartRes);
        checkout.cartId = cartRes.id;
      }
      hideLoading();
    };

    getCart();
  }, [hideLoading, history, showLoading, toastError]);

  const handleCheckout = useCallback(
    async (values, cardName) => {
      if (cart?.id !== checkout.cartId) {
        // Don't know why call multitime with old value
        return;
      }
      showLoading();
      const { dataDescriptor, dataValue } = values;
      const reqBody = {
        amount: cart?.total ?? 0,
        orderNumber: cart.id,
        cardHolderName: cardName,
        opaqueDataValue: dataValue,
        opaqueDataDescriptor: dataDescriptor,
        appName: FR_APP_KEY
      };

      const respone = await orderService.checkout(reqBody);
      if (respone?.errorCode) {
        toastError(respone.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        dispatch({ type: 'cartTotalCount', payload: 0 });
        const user = authService.getDecryptedUser();
        user.cartTotalCount = 0;
        storageHelper.setEncryptUser({
          ...user
        });
        history.push(CHECKOUT_URL.CHECKOUT_SUCCESS.URL);
      }
      setIsDisabledPayNow(true);
      setTimeout(() => {
        hideLoading();
      }, 100);
    },
    [cart?.id, cart?.total, dispatch, hideLoading, history, showLoading, toastError]
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsDisabledPayNow(false);
  };

  const renderCardDetail = useMemo(() => {
    return (
      <CardDetailAuthorize
        onSubmit={handleSubmit}
        cart={cart}
        handleCheckout={handleCheckout}
        isDisabledPayNow={isDisabledPayNow}
      />
    );
  }, [cart, handleCheckout, isDisabledPayNow]);

  const { border } = useStyles();

  const renderView = useMemo(() => {
    if (!cart?.id) {
      return null;
    }
    return (
      <div className="fr-background py-4 quicksand c-rayzr-card-container fr-box-container">
        <div className="fr-box">
          <Container
            fluid
            className="x-container py-4 py-md-0 px-0 container my-4 rounded-20 bg-white"
          >
            <div className="align-items-center pt-4 pb-1 justify-content-center text-center text-md-center">
              <h4 className="text-info font-weight-bolder flex-1 mb-3 mb-md-0">Enter Credit Card Details</h4>
            </div>
            <div className={border} />
            <Row>
              <Col xs={12} md={9}>
                {renderCardDetail}
              </Col>
              <Col xs={12} md={3}>
                <Paper
                  elevation={3}
                  className="p-4 mt-4 mr-4 ml-4 ml-md-0 mb-4 text-center text-md-left"
                >
                  <h4 className="text-info font-weight-bolder pb-5">ORDER SUMMARY</h4>
                  <p className="h4">Total ({cart?.cartDetails?.length} items)</p>
                  <p className="h3 pb-5">$ {cart?.total}.00</p>
                </Paper>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }, [cart, border, renderCardDetail]);

  useEffect(() => {
    const reloadCount = sessionStorage.getItem('reloadCount');
    if (reloadCount < 1) {
      sessionStorage.setItem('reloadCount', String(reloadCount + 1));
      window.location.reload();
      return;
    }
    setReloaded(true);
    sessionStorage.removeItem('reloadCount');
  }, []);

  return reloaded && renderView;
};

export default Checkout;
