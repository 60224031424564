import Transactions from '.';
import TransactionDetail from './detail';
import { TRANSACTION_URL } from './url';

export const TRANSACTION_ROUTES = [
  {
    name: TRANSACTION_URL.TRANSACTION.NAME,
    path: TRANSACTION_URL.TRANSACTION.URL,
    component: Transactions,
    isPrivate: true
  }, {
    name: TRANSACTION_URL.TRANSACTION_DETAIL.NAME,
    path: TRANSACTION_URL.TRANSACTION_DETAIL.URL,
    component: TransactionDetail,
    isPrivate: true
  }
];
