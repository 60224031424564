import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import CardTransactionList from 'pages/scanVendorTransactions/list';
import React from 'react';
import { useParams } from 'react-router-dom';
import AdminPortalVendorView, { CardTransactionTab } from '..';

const AdminPortalVendorViewCardAndTransaction = () => {
  const { vid } = useParams();

  return (
    <>
      <AdminPortalVendorView vendorTab={CardTransactionTab.value} className="manager-view-paging">
        <CardTransactionList
          tenantId={vid}
          scanRedirectUrl={ADMIN_PORTAL_URL.VENDOR_VIEW_CARD_TRANSACTION_DETAIL.URL.replace(
            ':vid',
            vid
          )}
          detailUrl={ADMIN_PORTAL_URL.VENDOR_VIEW_CARD_TRANSACTION_DETAIL.URL.replace(':vid', vid)}
        />
      </AdminPortalVendorView>
    </>
  );
};

export default AdminPortalVendorViewCardAndTransaction;
