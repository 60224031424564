import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table, useTable } from 'components/table';
import ButtonView from 'components/button';
import { Button } from 'reactstrap';
import cardService from 'services/card';
import CardGiftcardRoundedIcon from '@mui/icons-material/CardGiftcardRounded';
import { isMobile, toUtc } from 'utils/helper';

import { IMG_LOCAL, CART_DETAILS_TYPE } from 'constants/index';
import { theme } from 'constants/theme';
import { makeStyles } from '@mui/styles';
import { FR_APP_KEY } from 'configs';
import { usePopup } from 'components/popup';
import ResendPopup from './resendPopup';

const headCells = [
  { id: 'vendorName', label: 'Vendor' },
  { id: 'cardNumberCol', label: 'Card Number' },
  { id: 'cardBalance', label: 'Card Balance' },
  { id: 'charityName', label: 'Charity Support' },
  { id: 'cardTypeView', label: 'Card Type' },
  { id: 'action', label: '' }
];

const headCellsMobile = [
  { id: 'cardNumberCol', label: 'Card Number', className: 'min-w-50' },
  { id: 'cardBalance', label: 'Card Balance' },
  { id: 'resendEmail', label: '' }
];

const apiParams = {
  isMine: true
};

const useStyles = makeStyles(() => ({
  fontQuick: {
    fontFamily: theme.fontFamily.quicksandMedium
  },
  colorBlue: {
    color: theme.colors.blue.link
  },
  button: {
    backgroundColor: theme.colors.yellow.rbc,
    fontSize: theme.fontSize.s12,
    fontFamily: theme.fontFamily.quicksandMedium,
    color: theme.colors.blue.text,
    '&:hover': {
      boxShadow: theme.button.boxShadow
    },
    '&:focus': {
      boxShadow: 'none !important'
    }
  },
  iconResend: {
    lineHeight: 0,
    border: 'none',
    padding: 0,
    '&:focus': {
      boxShadow: 'none !important'
    }
  }
}));

const TransactionList = ({ detailUrl }) => {
  const { fontQuick, button, iconResend, colorBlue } = useStyles();
  const [data, setData] = useTable();
  const isMobileDevice = isMobile();
  const [isOpen, open, close] = usePopup();
  const [cardId, setCardId] = useState();
  const renderAction = (id, vendorId, isGiftCard, recipientEmail, cardBalance) => {
    return (
      <>
        {!isGiftCard && recipientEmail ? (
          cardBalance.slice(1) * 1 > 0 && (
            <Button
              size="small"
              className={`ml-1 text-white-hover ${button}`}
              color="quaternary"
              onClick={() => {
                setCardId(id);
                open();
              }}
            >
              Resend mail
            </Button>
          )
        ) : (
          <ButtonView
            size="small"
            className="ml-1 text-white-hover"
            component={Link}
            to={detailUrl.replace(':vid', vendorId).replace(':id', id)}
          >
            &emsp;View&emsp;
          </ButtonView>
        )}
      </>
    );
  };
  const renderCardNumber = (value, isGiftCard, recipientEmail) => {
    return (
      <div
        className={`d-flex justify-content-center align-items-center ${
          !isGiftCard && recipientEmail && fontQuick
        }`}
      >
        {recipientEmail && (
          <CardGiftcardRoundedIcon fontSize="medium" color="primary" className="mlp-2" />
        )}
        <span>{value}</span>
      </div>
    );
  };
  const renderLink = (value, id, vendorId, isGiftCard, recipientEmail) => {
    return (
      <div className="d-flex justify-content-center align-items-center">
        {recipientEmail && (
          <CardGiftcardRoundedIcon fontSize="medium" color="primary" className="mlp-2" />
        )}
        {!isGiftCard && recipientEmail ? (
          <div className={`${fontQuick} ${colorBlue}`}>{value}</div>
        ) : (
          <Link to={detailUrl.replace(':vid', vendorId).replace(':id', id)}>{value}</Link>
        )}
      </div>
    );
  };

  const renderResendEmail = (id, isGiftCard, recipientEmail, cardBalance) => {
    return (
      <div>
        {!isGiftCard && recipientEmail && cardBalance.slice(1) * 1 > 0 && (
          <Button
            color="link"
            className={iconResend}
            onClick={() => {
              setCardId(id);
              open();
            }}
          >
            <img
              src={IMG_LOCAL.RESEND_MAIL_ICON}
              alt="resendMail"
              width={theme.icon.width}
              height={theme.icon.width}
            />
          </Button>
        )}
      </div>
    );
  };

  const renderCardType = (cardType) => {
    if (!cardType) return null;
    let src = IMG_LOCAL.FUNRAYZR_LOGO;
    if (cardType === CART_DETAILS_TYPE.RAY_ZR) {
      src = IMG_LOCAL.RAYZR_LOGO;
    }
    return <img src={src} alt={FR_APP_KEY} style={{ maxHeight: 44 }} />;
  };

  const convertToRowCells = (arr) => {
    if (!arr) return [];

    return arr.map((value) => {
      value.cardTypeView = renderCardType(value.cardType);

      if (!value.converted) {
        value.cardBalance = `$${parseFloat(value?.cardBalance).toFixed(2)}`;
        value.recentTransaction = value?.recentTransaction
          ? moment(toUtc(value?.recentTransaction)).format('MMM, Do YYYY')
          : '';
        value.converted = true;
      }

      value.action = renderAction(
        value.id,
        value.vendorId,
        value.isGift,
        value.recipientEmail,
        value.cardBalance
      );

      if (!isMobileDevice) {
        value.cardNumberCol = renderCardNumber(
          value.cardNumber,
          value.isGift,
          value.recipientEmail
        );
        return value;
      }

      if (isMobileDevice) {
        value.cardNumberCol = renderLink(
          value.cardNumber,
          value.id,
          value.vendorId,
          value.isGift,
          value.recipientEmail
        );
        value.resendEmail = renderResendEmail(
          value.id,
          value.isGift,
          value.recipientEmail,
          value.cardBalance
        );
      }
      return value;
    });
  };

  const dataConvert = convertToRowCells(data.items);

  return (
    <>
      <Table
        noBorderTop
        headCells={isMobileDevice ? headCellsMobile : headCells}
        rowCells={dataConvert}
        totalCount={data?.totalItems ?? 0}
        havePagination
        service={cardService}
        api="getCards"
        apiParams={apiParams}
        setDataFetch={setData}
      />
      <ResendPopup isOpen={isOpen} close={close} cardId={cardId} />
    </>
  );
};

export default TransactionList;
