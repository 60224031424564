import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Button from 'components/button';
import { Link } from 'react-router-dom';
import { SIGNIN_URL } from 'pages/signIn/url';
import { theme } from 'constants/theme';
import BoxInfo from 'components/boxInfo';

const ResetPasswordSuccess = () => {

  return (
    <>
      <div className="fr-background fr-box-container">
        <div className="fr-box">
          <Container>
            <div className="fr-page-container fr-content">
              <BoxInfo title="Done!" />
              <Row className="text-center my-5">
                <Col md={12} className="my-1">
                  <p className="fr-content-sub-title-0 mb-0">Your password has been reset.</p>
                </Col>
              </Row>
              <Row className="text-center">
                <Col md={12}>
                  <Button
                    component={Link}
                    to={SIGNIN_URL.SIGNIN.URL}
                    kind="button"
                    variant="contained"
                    color="primary"
                    size="large"
                    minWidth={130}
                    bgHover={[theme.colors.orange.button, theme.colors.white.text]}
                    colorOnClick={[theme.colors.darkcyan.button]}
                  >
                    <span className="text-uppercase">{SIGNIN_URL.SIGNIN.NAME}</span>
                  </Button>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordSuccess;
