import React from 'react';
import authService from 'services/authorization';
import { Container } from 'reactstrap';
import AdminPortalVendorReportingForm from './reportingForm';

const AdminPortalVendorReporting = () => {
  const tenantId = authService.getTenantId();

  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <div className="fr-page-container mt-5 py-5 px-3" style={{ minHeight: '400px' }}>
          <Container>
            <AdminPortalVendorReportingForm tenantId={tenantId} />
          </Container>
        </div>
      </Container>
    </div>
  );
};

export default AdminPortalVendorReporting;
