import React from 'react';
import { useHistory } from 'react-router-dom';
import { CONFIRM_PURPOSE, SET_PASSWORD_PURPOSE, URL_PARAM, VERIFICATION } from 'constants/index';
import { Col, Container, Row } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import BoxInfo from 'components/boxInfo';
import userService from 'services/user';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { theme } from 'constants/theme';
import { gS } from 'constants/helperMessage';
import { getParam } from 'utils/axios/helper';

const useStyles = makeStyles(() => ({
  here: {
    cursor: 'pointer',
    color: theme.colors.darkcyan.main,
    fontWeight: 600
  }
}));

const SetupPasswordFailed = () => {
  const history = useHistory();
  const { toastSuccess, toastError } = useToast();
  const { here } = useStyles();

  const handleClickHere = async () => {
    const email = getParam(history.location.search.substring(1), URL_PARAM.EMAIL);
    const purpose = getParam(history.location.search.substring(1), URL_PARAM.PURPOSE);

    const params = {
      Email: email,
      SuccessReturnUrl: encodeURIComponent(`${window.location.origin}${ADMIN_PORTAL_URL.SETUP_PASSWORD.URL}?${VERIFICATION.PURPOSE}=${purpose ? SET_PASSWORD_PURPOSE.APPROVAL : ''}`),
      FailedReturnUrl: encodeURIComponent(`${window.location.origin}${ADMIN_PORTAL_URL.SETUP_PASSWORD_FAILED.URL}?${URL_PARAM.EMAIL}=${email}&${URL_PARAM.PURPOSE}=${purpose}`),
      Purpose: purpose || CONFIRM_PURPOSE.INVITE
    };
    const resp = await userService.resendEmailConfirm(params);

    if (resp?.errorCode) {
      toastError(resp.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      return;
    }
    toastSuccess(gS('Resend email confirmation'));
  };

  return (
    <div className="fr-container pb-40 fr-background fr-box-container">
      <div className="fr-box">
        <Container>
          <div className="fr-page-container fr-content">
            <BoxInfo title={ADMIN_PORTAL_URL.SETUP_PASSWORD_FAILED.NAME} />
            <Row className="text-center mt-5">
              <Col md={12} className="my-1">
                <p className="fr-content-sub-title mb-3">
                  It was not possible to set a new password. This may happen if your link is invalid
                  or expired.
                </p>
                <p className="fr-content-sub-title mb-3">
                  Please click
                  <span
                    onClick={handleClickHere}
                    onKeyDown={handleClickHere}
                    aria-hidden="true"
                    className={here}
                  >
                    &nbsp;here&nbsp;
                  </span>
                  to resend email confirmation.
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default SetupPasswordFailed;
