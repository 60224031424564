export const RESET_PASSWORD_URL = {
  RESET_PASSWORD: {
    URL: '/reset-password',
    NAME: 'Reset Your Password'
  },
  RESET_PASSWORD_SUCCESS: {
    URL: '/reset-password-success',
    NAME: 'Reset Password Success'
  },
  RESET_PASSWORD_FAILED: {
    URL: '/reset-password-failed',
    NAME: 'Reset Password Failed'
  }
};
