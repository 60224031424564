import { FUNRAYZR_API_URL } from 'configs';
import { mapResponse } from 'api/responseMapping';

const Api = (axios) => {
  const url = `${FUNRAYZR_API_URL}/Carts`;

  const getCart = async () => {
    const resp = await axios.get(`${url}/me`);

    return mapResponse(resp);
  };

  const createCart = async (cart) => {
    const resp = await axios.post(`${url}/items`, cart);

    return mapResponse(resp);
  };

  const editCart = async (cart) => {
    const resp = await axios.put(`${url}/items`, cart);

    return mapResponse(resp);
  };

  const deleteCartItem = async (id) => {
    const resp = await axios.delete(`${url}/items/${id}`);

    return mapResponse(resp);
  };

  return {
    getCart,
    editCart,
    deleteCartItem,
    createCart
  };
};

export default Api;
