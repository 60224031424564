import React from 'react';
import authService from 'services/authorization';
import { Container } from 'reactstrap';
import AdminPortalVendorInformationForm from './informationForm';

const AdminPortalVendorInformation = () => {
  const vendorId = authService.getTenantId();

  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <div className="fr-page-container mt-5" style={{ minHeight: '400px' }}>
          <Container>
            <AdminPortalVendorInformationForm vendorId={vendorId} />
          </Container>
        </div>
      </Container>
    </div>
  );
};

export default AdminPortalVendorInformation;
