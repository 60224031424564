import { STORAGE_KEY } from 'constants/index';
import { AES, enc } from 'crypto-js';

const getEncryptData = () => {
  const key = localStorage.getItem(STORAGE_KEY.KEY);
  const encrypt = localStorage.getItem(STORAGE_KEY.USER);
  return {
    key,
    encrypt
  };
};

const getDecryptedUser = () => {
  try {
    const { key, encrypt } = getEncryptData();
    if (key && encrypt) {
      const decrypted = AES.decrypt(encrypt, key).toString(enc.Utf8);
      return JSON.parse(decrypted);
    }
  } catch {
    /* continue regardless of error */
  }
  return null;
};

const setEncryptUser = (user) => {
  if (user) {
    const { userId } = user;
    const userEncrypt = AES.encrypt(JSON.stringify(user), userId).toString();
    localStorage.setItem(STORAGE_KEY.KEY, userId);
    localStorage.setItem(STORAGE_KEY.USER, userEncrypt);
  }
};

const storageHelper = {
  setEncryptUser,
  getDecryptedUser
};

window.setTokenExpired = () => {
  const user = getDecryptedUser();
  if (user) {
    user.accessToken = `${user.accessToken}exp`;
    user.expiredAt = parseInt(new Date().getTime() / 1000);
  }
  setEncryptUser(user);
};

export default storageHelper;
