import { APP_KEY } from 'constants/index';

require('dotenv').config();

const {
  REACT_APP_FUNRAYZR_ENDPOINT,
  REACT_APP_KEY,
  REACT_APP_FUNRAYZR,
  REACT_APP_RAYZR,
  REACT_APP_CUSTOM_CHECKOUT_JS_URL,
  REACT_APP_ENVIROMENT,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_PUBLIC_KEY_AUTHORIZE,
  REACT_APP_AUTHORIZE_API_LOGIN_ID,
  REACT_APP_SITE_KEY_CAPCHA
} = process.env;

export const FUNRAYZR_ENDPOINT = REACT_APP_FUNRAYZR_ENDPOINT;
export const FUNRAYZR_API_URL = `${REACT_APP_FUNRAYZR_ENDPOINT}/api/v1`;
export const FR_APP_KEY = REACT_APP_KEY || APP_KEY.FUNRAY_ZR;
export const FUNRAYZR_URL = REACT_APP_FUNRAYZR;
export const RAYZR_URL = REACT_APP_RAYZR;
export const CUSTOM_CHECKOUT_JS_URL = REACT_APP_CUSTOM_CHECKOUT_JS_URL;
export const ENV = REACT_APP_ENVIROMENT;
export const GOOGLE_API_KEY = REACT_APP_GOOGLE_API_KEY;
export const TOKENIZATION = REACT_APP_PUBLIC_KEY_AUTHORIZE;
export const AUTHORIZE_API_LOGIN_ID = REACT_APP_AUTHORIZE_API_LOGIN_ID;
export const SITE_KEY_CAPCHA = REACT_APP_SITE_KEY_CAPCHA;
