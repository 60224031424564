import React from 'react';
import { useHistory } from 'react-router-dom';
import { Paper } from '@mui/material';
import { Col, Container, Row } from 'reactstrap';
import { BUCKET } from 'constants/index';
import { COLOR, theme } from 'constants/theme';
import fileService from 'services/file';
import Button from 'components/button';
import { SHOP_VENDOR_URL } from '../url';

const SHOP_VENDOR_POPULAR_COLOR = [
  { background: theme.colors.darkcyan.steel, btn: COLOR.WARNING },
  { background: theme.colors.orange.indian, btn: COLOR.INFO },
  { background: theme.colors.violet.dark, btn: COLOR.WARNING },
  { background: theme.colors.darkcyan.steel, btn: COLOR.PRIMARY },
  { background: theme.colors.orange.indian, btn: COLOR.WARNING },
  { background: theme.colors.violet.dark, btn: COLOR.INFO }
];

const PopularCategories = ({ data }) => {
  const history = useHistory();
  return (
    <Row className="m-0">
      <Col xs="12" className="p-0">
        <Container>
          <Row>
            {data?.map(({ id, urlText, logoPath, popularName }, i) => {
              const isOdd = i & 1;

              const handleClick = () => {
                history.push(SHOP_VENDOR_URL.SHOP_VENDOR_LIST.URL_SEARCH.replace(':id', id));
              };

              const image = (
                <img
                  src={fileService._baseUrl + '/' + BUCKET.CATEGORY + '/' + logoPath}
                  alt={popularName}
                  className="img-fluid"
                  loading="lazy"
                />
              );

              const content = (
                <Col
                  xs={12}
                  xl={6}
                  className="d-flex flex-column justify-content-center align-items-center text-white text-center"
                >
                  <h3 className="c-title m-0 titanOne my-3 h1">{popularName}</h3>
                  {/* <div className="mb-3" dangerouslySetInnerHTML={{ __html: description }} /> */}
                  <Button
                    color={SHOP_VENDOR_POPULAR_COLOR[i].btn}
                    className="titanOne mb-4 mb-xxl-0 mt-3"
                    onClick={handleClick}
                  >
                    {urlText}
                  </Button>
                </Col>
              );

              return (
                <Col xs={12} className="mb-5" key={id}>
                  <Paper
                    elevation={3}
                    className="c-vendor-popular-paper"
                    style={{ background: SHOP_VENDOR_POPULAR_COLOR[i].background }}
                  >
                    <Container fluid>
                      <Row>
                        {isOdd ? (
                          <>
                            <Col xs={12} xl={6} className="px-0 d-xl-none">
                              {image}
                            </Col>
                            {content}
                            <Col xs={12} xl={6} className="px-0 d-none d-xl-block">
                              {image}
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col xs={12} xl={6} className="px-0">
                              {image}
                            </Col>
                            {content}
                          </>
                        )}
                      </Row>
                    </Container>
                  </Paper>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

export default PopularCategories;
