import { ERROR_MESSAGE } from 'constants/errorMessage';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { REGISTER_URL } from 'pages/register/url';
import { theme } from 'constants/theme';
import Button from 'components/button';
import { SITE_KEY_CAPCHA } from 'configs';
import { Col, Row } from 'reactstrap';
import { Checkbox } from '@mui/material';
import Input from 'components/input';
import { gR } from 'constants/helperMessage';
import { PRIVACY_POLICY_URL } from 'pages/privacyPolicy/url';
import { EULA_PAGE_URL } from 'pages/eula/url';

const RegisterForm = ({ submitDisabled = true, onSubmit, tokenGCapcha }) => {
  const [agreed, setAgreed] = useState(false);
  const [lockedForm, setLockedForm] = useState(false);
  const initialValues = {
    firstName: '',
    lastName: '',
    address: '',
    aptUnitNumber: '',
    city: '',
    province: '',
    postalCode: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: ''
  };
  useEffect(() => {
    if (!submitDisabled) {
      setLockedForm(false);
    }
  }, [submitDisabled]);

  const onAgreeChange = useCallback(() => {
    setAgreed(!agreed);
  }, [agreed]);

  const handleOnSubmit = useCallback(
    (values) => {
      if (onSubmit) {
        setLockedForm(true);
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(SITE_KEY_CAPCHA, { action: 'submit' }).then((token) => {
            if (token) {
              const submitedData = {
                ...values,
                phoneNumber: `${values.phoneNumber}`,
                reCaptchaToken: token
              };
              onSubmit(submitedData);
            }
          });
        });
      }
    },
    [onSubmit]
  );
  const registerValidateSchema = Yup.object().shape({
    firstName: Yup.string().required(gR('First Name')),
    lastName: Yup.string().required(gR('Last Name')),
    address: Yup.string().required(gR('Address')),
    city: Yup.string().required(gR('City')),
    province: Yup.string().required(gR('Province')),
    postalCode: Yup.string().required(gR('Postal Code')),
    phoneNumber: Yup.number().typeError(ERROR_MESSAGE.PHONE_NUMBER.VALID),
    email: Yup.string().required(gR('Email')).email(ERROR_MESSAGE.EMAIL.VALID),
    password: Yup.string()
      .min(6, ERROR_MESSAGE.PASSWORD.VALID)
      .max(100, ERROR_MESSAGE.PASSWORD.VALID)
      .required(gR('Password')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], ERROR_MESSAGE.CONFIRM_PASSWORD.VALID)
      .required(ERROR_MESSAGE.CONFIRM_PASSWORD.VALID)
  });
  const getFormError = (touched, errors) => {
    const firstName = touched.firstName && !!errors.firstName;
    const lastName = touched.lastName && !!errors.lastName;
    const address = touched.address && !!errors.address;
    const city = touched.city && !!errors.city;
    const province = touched.province && !!errors.province;
    const postalCode = touched.postalCode && !!errors.postalCode;
    const phoneNumber = touched.phoneNumber && !!errors.phoneNumber;
    const email = touched.email && !!errors.email;
    const password = touched.password && !!errors.password;
    const confirmPassword = touched.confirmPassword && !!errors.confirmPassword;

    return {
      firstName,
      lastName,
      address,
      city,
      province,
      postalCode,
      phoneNumber,
      email,
      password,
      confirmPassword
    };
  };

  const formik = useFormik({
    initialValues,
    validationSchema: registerValidateSchema,
    onSubmit: handleOnSubmit
  });

  const registerForm = useMemo(() => {
    const { touched, errors, values, handleSubmit, handleChange, handleBlur } = formik;
    const formError = getFormError(touched, errors);
    const disabledSubmit = () => {
      return (!tokenGCapcha || !agreed ||
        !values.firstName ||
        !values.lastName ||
        !values.address ||
        !values.city ||
        !values.province ||
        !values.postalCode ||
        !values.email ||
        !values.password
        || Object.keys(errors).length !== 0
      ) || lockedForm;
    };

    return (
      <form onSubmit={handleSubmit} id="formRes">
        <Row>
          <Col md={6} className="mb-4">
            <Input
              fullWidth
              name="firstName"
              type="text"
              label="First Name*"
              size="small"
              variant="outlined"
              error={formError.firstName}
              helperText={formError.firstName && errors.firstName}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={6} className="mb-4">
            <Input
              fullWidth
              name="lastName"
              type="text"
              label="Last Name*"
              size="small"
              variant="outlined"
              error={formError.lastName}
              helperText={formError.lastName && errors.lastName}
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Row>

        <Row>
          <Col md={8} className="mb-4">
            <Input
              fullWidth
              name="address"
              type="text"
              label="Address*"
              size="small"
              variant="outlined"
              error={formError.address}
              helperText={formError.address && errors.address}
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={4} className="mb-4">
            <Input
              fullWidth
              name="aptUnitNumber"
              type="text"
              label="Apt / Unit"
              size="small"
              variant="outlined"
              value={values.aptUnitNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Row>

        <Row>
          <Col md={4} className="mb-4">
            <Input
              fullWidth
              name="city"
              type="text"
              label="City*"
              size="small"
              variant="outlined"
              error={formError.city}
              helperText={formError.city && errors.city}
              value={values.city}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={4} className="mb-4">
            <Input
              fullWidth
              name="province"
              type="text"
              label="Province*"
              size="small"
              variant="outlined"
              error={formError.province}
              helperText={formError.province && errors.province}
              value={values.province}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={4} className="mb-4">
            <Input
              fullWidth
              name="postalCode"
              type="text"
              label="Postal Code*"
              size="small"
              variant="outlined"
              error={formError.postalCode}
              helperText={formError.postalCode && errors.postalCode}
              value={values.postalCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-4">
            <Input
              fullWidth
              name="email"
              type="email"
              label="Email*"
              size="small"
              variant="outlined"
              error={formError.email}
              helperText={formError.email && errors.email}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={6} className="mb-4">
            <Input
              autoComplete="tel"
              fullWidth
              name="phoneNumber"
              type="text"
              label="Phone # (optional)"
              size="small"
              variant="outlined"
              error={formError.phoneNumber}
              helperText={formError.phoneNumber && errors.phoneNumber}
              value={values.phoneNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6} className="mb-4">
            <Input
              fullWidth
              autoComplete="new-password"
              name="password"
              type="password"
              label="Password*"
              size="small"
              variant="outlined"
              error={formError.password}
              helperText={formError.password && errors.password}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
          <Col md={6} className="mb-4">
            <Input
              autoComplete="new-password"
              fullWidth
              name="confirmPassword"
              type="password"
              label="Confirm Password*"
              size="small"
              variant="outlined"
              error={formError.confirmPassword}
              helperText={formError.confirmPassword && errors.confirmPassword}
              value={values.confirmPassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <p className="text-center fr-small">
              Please read and agree to the&nbsp;
              <a href={PRIVACY_POLICY_URL.PRIVACY_POLICY.URL} target="_blank" rel="noreferrer">
                Terms of Use
              </a>
              &nbsp;and&nbsp;
              <a href={EULA_PAGE_URL.EULA_PAGE.URL} target="_blank" rel="noreferrer">
                EULA
              </a>
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="text-center">
            <p className="text-center fr-small">
              I agree
              <Checkbox value={agreed} onChange={onAgreeChange} />
            </p>
          </Col>
        </Row>

        <Row>
          <Col md={12} className="text-center">
            <Button
              wider
              type="submit"
              kind="button"
              variant="contained"
              color="primary"
              bgHover={[theme.colors.orange.button, theme.colors.white.text]}
              colorOnClick={[theme.colors.darkcyan.button]}
              minWidth={130}
              size="large"
              disabled={disabledSubmit()}
            >
              <span className="text-uppercase">{REGISTER_URL.REGISTER.NAME}</span>
            </Button>
          </Col>
        </Row>
      </form>
    );
  }, [formik, agreed, onAgreeChange, tokenGCapcha, lockedForm]);
  return <>{registerForm}</>;
};

export default RegisterForm;
