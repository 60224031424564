import SignIn from '.';
import { SIGNIN_URL } from './url';

export const SIGNIN_ROUTES = [
  {
    name: SIGNIN_URL.SIGNIN.NAME,
    path: SIGNIN_URL.SIGNIN.URL,
    component: SignIn,
    isPrivate: false
  }
];
