import Api from 'api/organization';
import AxiosWrapper from 'utils/axios';

export class OrganizationService {
  constructor() {
    const axios = AxiosWrapper();
    this._organizationApi = Api(axios);
  }

  getOrganizations(params) {
    return this._organizationApi.getOrganizations(params);
  }

  getOrganizationById(id) {
    return this._organizationApi.getOrganizationById(id);
  }

  statusOrganization(id, organizationStatus) {
    return this._organizationApi.statusOrganization(id, organizationStatus);
  }

  createOrganization(organization) {
    return this._organizationApi.createOrganization(organization);
  }

  editOrganization(id, organization) {
    return this._organizationApi.editOrganization(id, organization);
  }
}

const organizationService = new OrganizationService();

export default organizationService;
