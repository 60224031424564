import { FUNRAYZR_API_URL } from 'configs';
import { mapResponse } from 'api/responseMapping';

const Api = (axios) => {
  const url = `${FUNRAYZR_API_URL}/File/bucket`;

  const createBucket = async (bucket, formData) => {
    const resp = await axios.post(`${url}/${bucket}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });

    return mapResponse(resp);
  };

  return {
    createBucket
  };
};

export default Api;
