import React, { memo, useEffect, useState, useRef, useContext } from 'react';
import { Paper } from '@mui/material';
import { Col, Container, Row } from 'reactstrap';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CardGiftcardRoundedIcon from '@mui/icons-material/CardGiftcardRounded';
import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';
import Button from 'components/button';
import { IMG_LOCAL, APP_KEY, BUCKET, CART_DETAILS_TYPE } from 'constants/index';
import cartService from 'services/cart';
import VendorCard from 'pages/adminPortal/vendor/card';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import fileService from 'services/file';
import { gS } from 'constants/helperMessage';
import useLoading from 'components/loading/useLoading';
import { Link, useHistory } from 'react-router-dom';
import { Popup, usePopup } from 'components/popup';
import authService from 'services/authorization';
import { REGISTER_URL } from 'pages/register/url';
import { SIGNIN_URL } from 'pages/signIn/url';
import { CHECKOUT_URL } from 'pages/checkout/url';
import { HOME_URL } from 'pages/home/url';

import ShopVendorCard from 'pages/shopVendor/detail/component/shopVendorCard';
import storageHelper from 'utils/storage';
import { LayoutContext } from 'layout/layoutContext';

import { CART_URL } from './url';

const useStyles = makeStyles(() => ({
  border: {
    height: '2px',
    background: theme.colors.gray.border,
    margin: '20px 14px 20px 14px'
  }
}));

// WE STASH THIS CODE IN CASE NEW REQUIREMNT
// const DELAY_DEBOUNCUE_MS = 500;

const Cart = () => {
  const isCancelled = useRef(false);
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState({});
  const [funrayList, setFunrayList] = useState([]);
  const [rayList, setRayList] = useState([]);
  const { dispatch } = useContext(LayoutContext);

  const history = useHistory();
  const { border } = useStyles();
  const [isOpen, open] = usePopup();
  const { toastSuccess, toastError } = useToast();
  const [showLoading, hideLoading] = useLoading();

  const isLogin = !authService.isExpired();

  // WE STASH THIS CODE IN CASE NEW REQUIREMNT
  // const postCart = async (req) => {
  //   const respone = await cartService.editCart(req);
  //   if (respone?.errorCode) {
  //     toastError(respone.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
  //   } else {
  //     setCart(respone);
  //     toastSuccess(gS('Change Quantity'));
  //   }
  // };

  // const postCartDebounce = useCallback(debounce(postCart, DELAY_DEBOUNCUE_MS), []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLogin) {
      const cartLocal = JSON.parse(localStorage.getItem('products'));

      if (!cartLocal) {
        setLoading(false);
        return;
      }

      let fuList = [];
      let raList = [];

      cartLocal?.cartDetails?.forEach((value) => {
        if (value.campaignId) {
          fuList = [...fuList, value];
        } else {
          raList = [...raList, value];
        }
      });

      setCart(cartLocal);
      setFunrayList(fuList);
      setRayList(raList);
      setLoading(false);

      return;
    }

    const getCart = async () => {
      setLoading(true);
      showLoading();
      let vend = await cartService.getCart();
      if (vend?.errorCode) {
        toastError(vend.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        let fuList = [];
        let raList = [];

        const cartLocal = JSON.parse(localStorage.getItem('products'));
        if (!vend.total || cartLocal?.total) {
          vend = cartLocal;
          if (!vend || !vend.total) {
            setLoading(false);
            hideLoading();
            return;
          }

          const { total: totalPatch, cartDetails, isLocal } = vend;

          const cartDetailsPatch = cartDetails.map(
            ({
              id,
              campaignId,
              cardName,
              confirmEmail,
              from,
              giftMessage,
              isGift,
              price,
              quantity,
              recipientEmail,
              to,
              total,
              type,
              vendorId
            }) => ({
              id: !isLocal ? id : undefined,
              cardName,
              confirmEmail,
              campaignId,
              from,
              giftMessage,
              isGift,
              price,
              quantity,
              recipientEmail,
              to,
              total,
              type,
              vendorId
            })
          );

          const respone = await cartService.createCart({
            total: totalPatch,
            cartDetails: cartDetailsPatch
          });

          if (respone?.errorCode) {
            toastError(respone.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
          } else {
            localStorage.removeItem('products');
            const responeGetCart = await cartService.getCart();
            if (responeGetCart?.errorCode) {
              toastError(responeGetCart.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
            } else {
              vend = responeGetCart;
            }
          }
        }
        vend.cartDetails?.forEach((value) => {
          if (value?.vendor?.logoPath) {
            value.vendor.logoPath = value.vendor.logoPath
              ? fileService._baseUrl + '/' + BUCKET.VENDOR + '/' + value.vendor.logoPath
              : IMG_LOCAL.DEFAULT_VENDOR;
          }

          if (value?.campaign) {
            value.campaign.logoPath = value.campaign.logoPath
              ? fileService._baseUrl + '/' + BUCKET.CAMPAIGN + '/' + value.campaign.logoPath
              : '';
          }

          if (value?.charity) {
            value.charity.logoPath = value.charity.logoPath
              ? fileService._baseUrl + '/' + BUCKET.CHARITY + '/' + value.charity.logoPath
              : '';
          }

          if (value.campaignId) {
            fuList = [...fuList, value];
          } else {
            raList = [...raList, value];
          }
        });

        if (!isCancelled.current) {
          setLoading(false);
          hideLoading();
          setCart(vend);
          setFunrayList(fuList);
          setRayList(raList);
        }
      }
    };
    getCart();
    return () => {
      isCancelled.current = true;
    };
  }, [isLogin, hideLoading, showLoading, toastError]);
  const element = document.getElementById("summary");
  useEffect(() => {
    dispatch({ type: 'cartTotalCount', payload: cart?.cartDetails?.length ?? 0 });
    const scroll = () => {
        if (element) {
          setTimeout(() => {
            element.scrollIntoView();
          }, 500);
        }
    };
    scroll();
    const user = authService.getDecryptedUser();

    if (!user) return;

    storageHelper.setEncryptUser({
      ...user,
      cartTotalCount: cart?.cartDetails?.length ?? 0
    });
  }, [cart, dispatch, element]);

  // WE STASH THIS CODE IN CASE NEW REQUIREMNT
  // const formik = useFormik({
  //   initialValues: {
  //     ...funrayList.reduce((o, key) => ({ ...o, [key.id]: key.quantity }), {}),
  //     ...rayList.reduce((o, key) => ({ ...o, [key.id]: key.quantity }), {})
  //   },
  //   validationSchema: Yup.object({
  //     amount: Yup.string().required(gR('Amount')),
  //     quantity: Yup.number()
  //       .typeError(ERROR_MESSAGE.QUANTITY.VALID)
  //       .required(gR('Quantity'))
  //       .min(1, ERROR_MESSAGE.QUANTITY.MIN)
  //       .max(999, ERROR_MESSAGE.QUANTITY.MAX)
  //   }),
  //   enableReinitialize: true
  // });

  // const handleChangeQuantity = async (event) => {
  //   if (event.target.value <= 0) {
  //     event.target.value = 1;
  //   }

  //   formik.handleChange(event);

  //   const { cartDetails, ...rest } = cart;

  //   if (!cartDetails) return;

  //   let matchIndex = 0;

  //   const neww = { id: event.target.name, quantity: Number(event.target.value) };

  //   const obc = cartDetails.map((obj, index) => {
  //     if (neww.id === obj.id) {
  //       matchIndex = index;
  //       return { ...obj, ...neww, total: Number(event.target.value) * obj.price };
  //     }
  //     return obj;
  //   });

  //   const req = {
  //     ...rest,
  //     total: obc.reduce((acc, val) => acc + val.price * val.quantity, 0),
  //     cartDetails: [...obc]
  //   };

  //   if (!isLogin) {
  //     localStorage.setItem('products', JSON.stringify(req));
  //     setCart(req);
  //     toastSuccess(gS('Change Quantity'));
  //     return;
  //   }

  //   postCartDebounce({ cartDetails: [req.cartDetails[matchIndex]] });
  // };

  const spliceObjectById = (arr, id) => {
    if (!arr?.length && !id) return;
    const indexMatch = arr.findIndex((i) => i.id === id);
    if (indexMatch < 0) return;
    arr.splice(indexMatch, 1);
  };

  const handleRemove = async (id) => {
    if (!isLogin) {
      cart.total -= cart.cartDetails.find((x) => x.id === id).total;
      spliceObjectById(cart.cartDetails, id);
      spliceObjectById(funrayList, id);
      spliceObjectById(rayList, id);

      localStorage.setItem('products', JSON.stringify(cart));
      toastSuccess(gS('Remove'));
      setCart({ ...cart });
      setFunrayList([...funrayList]);
      setRayList([...rayList]);
      return;
    }
    const respone = await cartService.deleteCartItem(id);

    if (respone?.errorCode) {
      toastError(respone.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
    } else {
      cart.total -= cart.cartDetails.find((x) => x.id === id).total;
      spliceObjectById(cart.cartDetails, id);
      spliceObjectById(funrayList, id);
      spliceObjectById(rayList, id);

      toastSuccess(gS('Remove'));
      setCart({ ...cart });
      setFunrayList([...funrayList]);
      setRayList([...rayList]);
    }
  };

  const handleCheckout = () => {
    if (!isLogin) {
      open();
      return;
    }
    history.push(CHECKOUT_URL.CHECKOUT.URL);
  };

  const cardTypeRender = (value) => {
    const { displayName, address, logoPath } = value.vendor ?? {};
    const vendor = {
      displayName,
      address: address?.addressLine1,
      logoPath
    };

    if (value.type === CART_DETAILS_TYPE.FUNRAY_ZR) {
      const { name: campaignName, logoPath: campaignLogoPath } = value.campaign ?? {};
      const campaign = {
        name: campaignName,
        logoPath: campaignLogoPath
      };

      const { legalName: charityName, logoPath: charityLogoPath } = value.charity ?? {};
      const charity = {
        name: charityName,
        logoPath: charityLogoPath
      };
      return <ShopVendorCard vendor={vendor} charity={charity} campaign={campaign} />;
    }

    return (
      <VendorCard
        vendorLogo={vendor?.logoPath}
        name={vendor?.displayName}
        address={vendor?.address}
        isChairityCard
      />
    );
  };

  const renderCardInfo = (value) => {
    return (
      <Col xs={12} key={value?.id ?? value.vendorId + value.price}>
        <Container fluid>
          <Row>
            <Col xs={12} md={6} className="my-4">
              {cardTypeRender(value)}
            </Col>
            <Col xs={12} md={6} className="mb-4 mt-0 mt-md-4">
              <div className="pt-0 pt-md-2 pb-2 pb-md-5 d-flex justify-content-between">
                <p className="text-primary font-weight-bolder h4 my-1">
                  {value?.vendor?.displayName ?? value.cardName} eGift card
                </p>
                {value.isGift && (
                  <Paper elevation={3} className="c-cart-gift mr-4 mr-md-0">
                    <CardGiftcardRoundedIcon color="primary" />
                  </Paper>
                )}
              </div>

              <div className="d-flex justify-content-between c-shop-cart-action">
                {/* WE STASH THIS CODE IN CASE NEW REQUIREMNT */}
                {/* <Input
                disabled
                size="small"
                label="Quantity"
                name={value.id}
                // onChange={handleChangeQuantity}
                value={formik.values[value.id] ?? 1}
                error={formik.touched[value.id] && !!formik.errors[value.id]}
                helperText={formik.touched[value.id] && formik.errors[value.id]}
              /> */}
                <Button
                  color="warning"
                  variant="text"
                  className="c-cart-btn-remove"
                  onClick={() => {
                    handleRemove(value.id);
                  }}
                >
                  <b>Remove</b>
                </Button>
                <p className="m-0 text-right">$ {value.price}.00</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Col>
    );
  };

  return (
    !loading && (
      <div className="fr-background py-4 quicksand c-rayzr-card-container fr-box-container">
        <div className="fr-box">
          <Container className="x-container py-4 py-md-0 my-4 rounded-20 bg-white">
            <div className="d-flex justify-content-between align-items-center pt-4 pb-1 flex-column flex-md-row">
              <div className="flex-1" />
              <h4 className="text-info font-weight-bolder flex-1 mb-3 mb-md-0">MY SHOPPING CART</h4>
              <Button
                variant="text"
                className="mr-2"
                component={Link}
                to={HOME_URL.HOME.URL}
              >
                <b>Continue Shopping</b>
              </Button>
            </div>
            <div className={border} />
            <Row>
              {!cart?.cartDetails?.length ? (
                <Col xs={12} className="c-cart-empty">
                  <ShoppingCartIcon color="primary" fontSize="large" className="mb-2" />
                  <p className="mb-0">Your shopping cart is empty</p>
                </Col>
              ) : (
                <>
                  <Col xs={12} md={9}>
                    <Container fluid>
                      <Row>
                        {!!funrayList?.length && (
                          <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center px-3">
                              <img
                                src={IMG_LOCAL.FUNRAYZR_LOGO}
                                alt={APP_KEY.FUNRAY_ZR}
                                height={80}
                              />
                              <p className="mb-0 font-weight-bolder h4">Price</p>
                            </div>
                          </Col>
                        )}

                        {funrayList.map((value) => renderCardInfo(value))}

                        {!!rayList?.length && !!funrayList?.length && (
                          <Col xs={12}>
                            <div className={border} />
                          </Col>
                        )}

                        {!!rayList?.length && (
                          <Col xs={12}>
                            <div className="d-flex justify-content-between align-items-center px-3">
                              <img src={IMG_LOCAL.RAYZR_LOGO} alt={APP_KEY.RAY_ZR} height={80} />
                              {!funrayList?.length && (
                                <p className="mb-0 font-weight-bolder h4">Price</p>
                              )}
                            </div>
                          </Col>
                        )}

                        {rayList.map((value) => renderCardInfo(value))}
                      </Row>
                    </Container>
                  </Col>
                  <Col xs={12} md={3} id="summary">
                    <Paper
                      elevation={3}
                      className="p-4 mt-4 mr-4 ml-4 ml-md-0 mb-4 text-center text-md-left"
                    >
                      <h4 className="text-info font-weight-bolder pb-5">ORDER SUMMARY</h4>
                      <p className="h4">Total ({cart?.cartDetails?.length} items)</p>
                      <p className="h3 pb-5">$ {cart?.total}.00</p>
                      <Button className="px-0" onClick={handleCheckout}>
                        Proceed to checkout
                      </Button>
                    </Paper>
                  </Col>
                  {!!rayList?.length && <Col xs={12} className="p-2" />}
                </>
              )}
            </Row>
          </Container>
        </div>
        {!isLogin && (
          <Popup
            kind="confirm"
            isOpen={isOpen}
            onConfirm={() => {
              history.push(REGISTER_URL.REGISTER.URL);
            }}
            onCancel={() => {
              localStorage.setItem('redirectUrl', JSON.stringify(CART_URL.CART.URL));
              history.push(SIGNIN_URL.SIGNIN.URL);
            }}
            size="sm"
            title=""
            confirm="Register"
            cancel={SIGNIN_URL.SIGNIN.NAME}
            colorCancel="primary"
          >
            <>
              <p>Please sign in to continue or</p>
              <p>if you don&apos;t have an account, please register your account</p>
            </>
          </Popup>
        )}
      </div>
    )
  );
};

export default memo(Cart);
