import jwt from 'jwt-decode';

const decodeUser = (accessToken) => {
  if (!accessToken) {
    return null;
  }
  const decoded = jwt(accessToken);
  const expiredAt = decoded.exp;

  return {
    accessToken,
    expiredAt
  };
};

const jwtHelper = {
  decodeUser
};

export default jwtHelper;
