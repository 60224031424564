import RefundOnly from '.';

import { REFUND_ONLY_URL } from './url';

export const REFUND_ONLY_ROUTES = [
  {
    name: REFUND_ONLY_URL.REFUND_ONLY.NAME,
    path: REFUND_ONLY_URL.REFUND_ONLY.URL,
    component: RefundOnly,
    isPrivate: false
  }
];
