import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { Col } from 'reactstrap';
import { theme } from 'constants/theme';

const HeaderCardDetail = ({ codeId }) => {
  const history = useHistory();

  const useStyle = makeStyles(() => ({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: theme.fontSize.s26,
      height: '60px',
      padding: '45px',
      alignItems: 'center',
      color: '#3B3B3B',
      marginBottom: '-40px',
      '& b': {
        fontFamily: `${theme.fontFamily.titanOneRegular} !important`
      },
      '@media (min-width: 770px)': {
        display: 'none',
        marginBottom: '0'
      }
    }
  }));

  const { header } = useStyle();
  return (
    <Col xs={12} className={`${header}`}>
      <ArrowBackIosIcon onClick={() => history.goBack()} />
      <b>{codeId}</b>
      <div />
    </Col>
  );
};

HeaderCardDetail.propTypes = {
  codeId: PropTypes.string
};
HeaderCardDetail.defaultProps = {
  codeId: ''
};
export default HeaderCardDetail;
