export const TRANSACTION_URL = {
  TRANSACTION: {
    URL: '/transactions',
    NAME: 'My Wallet'
  },
  TRANSACTION_DETAIL: {
    URL: '/transactions/:vid/:id',
    NAME: 'Transaction Detail'
  }
};
