import React from 'react';
import { useParams } from 'react-router-dom';
import AdminPortalCharityView, { ReportingTab } from '..';
import AdminPortalCharityReportingForm from '../../reporting/reportingForm';

const AdminPortalCharityViewReporting = () => {
  const { cid } = useParams();

  return (
    <>
      <AdminPortalCharityView tab={ReportingTab.value}>
        <AdminPortalCharityReportingForm
          noTitle
          tenantId={cid}
        />
      </AdminPortalCharityView>
    </>
  );
};

export default AdminPortalCharityViewReporting;
