export const theme = {
  menu: {
    height: '60px',
    fontSize: '16px',
    background: '#FFFFFF'
  },
  fontFamily: {
    quicksandLight: 'Quicksand Light',
    quicksandRegular: 'Quicksand Regular',
    quicksandMedium: 'Quicksand Medium',
    quicksandBold: 'Quicksand Bold',
    quicksandSemiBold: 'Quicksand SemiBold',
    titanOneRegular: 'TitanOne Regular'
  },
  fontSize: {
    s8: '8px',
    s10: '10px',
    s12: '12px',
    s13: '13px',
    s14: '14px',
    s15: '15px',
    s16: '16px',
    s18: '18px',
    s20: '20px',
    s22: '22px',
    s24: '24px',
    s26: '26px',
    s28: '28px',
    s30: '30px',
    s32: '32px',
    s34: '34px',
    s36: '36px',
    s38: '38px',
    s40: '40px',
    s50: '50px'
  },
  text: {
    textShadowColor: '#4B006CAF',
    textDarkShadow: '#000000'
  },
  colors: {
    body: {
      topLeft: '#4965DB',
      middle: '#A220FF',
      bottomRight: '#E94E4E'
    },
    gray: {
      main: '#888888',
      menu: '#888888',
      button: '#888888',
      title: '#888888',
      text: '#888888',
      background: '#888888',
      switch: '#F5F5F5',
      backgroundTable: '#E7ECF8',
      backgroundCard: '#E5E5E5',
      o5: 'rgba(136, 136, 136, .5)',
      placeHolder: '#BABABA',
      border: '#BFBFBF'
    },
    violet: {
      main: '#9E27B5',
      menu: '#9033AF',
      button: '#9E27B5',
      title: '#9131AF',
      text: '#9131AF',
      background: '#9E27B5',
      sidebar: '#BA39E5',
      dark: '#8F2FC7',
      o7: 'rgba(158, 39, 181, .7)'
    },
    darkcyan: {
      main: '#017299',
      menu: '#017299',
      button: '#017299',
      title: '#307095',
      text: '#307095',
      background: '#017299',
      toast: '#03A9F4',
      steel: '#1877B7',
      o7: 'rgba(1, 114, 153, .7)'
    },
    orange: {
      main: '#FA4D09',
      menu: '#E45A2B',
      button: '#E45A2B',
      title: '#E75B2B',
      text: '#E75B2B',
      background: '#FA4D09',
      toast: '#FF9800',
      indian: '#C1556A',
      o7: 'rgba(250, 77, 9, .7)'
    },
    red: {
      toast: '#EF5350'
    },
    white: {
      main: '#FFFFFF',
      button: '#FFFFFF',
      title: '#FFFFFF',
      text: '#FFFFFF',
      background: '#FFFFFF',
      o5: 'rgba(255, 255, 255, .5)',
      o2: 'rgba(255, 255, 255, .2)'
    },
    black: {
      main: '#000000',
      border: '#000000',
      button: '#000000',
      title: '#000000',
      text: '#000000',
      background: '#000000',
      loadingBg: 'rgba(0, 0, 0, 0.5)'
    },
    green: {
      main: '#41A940',
      toast: '#4CAF50'
    },
    blue: {
      background: '#F2F8FA',
      rbc: '#005DAA',
      text: '#005DAA',
      link: '#007BFF'
    },
    yellow: {
      background: '#F7D347',
      hover: '#FFFBF2',
      rbc: '#FFD200'
    },
    transparent: 'transparent'
  },
  button: {
    minWidth: 104.67,
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
  },
  icon: {
    width: 21
  },
  popup: {
    maxWidth: '400px'
  }
};

export const COLOR = {
  INHERIT: 'inherit',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
  TERTIARY: 'tertiary',
  QUATERNARY: 'quaternary',
  TRANSPARENT: 'transparent',
  BLACK: 'black',
  OCEAN: 'ocean'
};
