import React, { useCallback, useMemo } from 'react';
import { useFormik } from 'formik';
import Input from 'components/input';
import * as Yup from 'yup';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { theme } from 'constants/theme';
import Button from 'components/button';
import { Col, Row } from 'reactstrap';
import { gR } from 'constants/helperMessage';

const SetupPasswordForm = ({ submitDisabled = false, onSubmit }) => {
  const initialValues = {
    password: '',
    confirmPassword: ''
  };

  const validateSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, ERROR_MESSAGE.NEW_PASSWORD.VALID)
      .max(100, ERROR_MESSAGE.NEW_PASSWORD.VALID)
      .required(gR('New Password')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], ERROR_MESSAGE.CONFIRM_PASSWORD.NEW_VALID)
      .required(ERROR_MESSAGE.CONFIRM_PASSWORD.NEW_VALID)
  });

  const getFormError = (touched, errors) => {
    const password = touched.password && !!errors.password;
    const confirmPassword = touched.confirmPassword && !!errors.confirmPassword;

    return {
      password,
      confirmPassword
    };
  };

  const handleOnSubmit = useCallback(
    (values) => {
      if (onSubmit) {
        onSubmit(values);
      }
    },
    [onSubmit]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: validateSchema,
    onSubmit: handleOnSubmit
  });

  const setNewPasswordForm = useMemo(() => {
    const { touched, errors, values, handleSubmit, handleChange, handleBlur } = formik;
    const formError = getFormError(touched, errors);

    return (
      <>
        <form onSubmit={handleSubmit}>
          <Row>
            <Col md={12} className="mt-4 mb-4">
              <Input
                fullWidth
                name="password"
                type="password"
                label="New Password*"
                size="small"
                variant="outlined"
                error={formError.password}
                helperText={formError.password && errors.password}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-4">
              <Input
                fullWidth
                name="confirmPassword"
                type="password"
                label="Confirm Password*"
                size="small"
                variant="outlined"
                error={formError.confirmPassword}
                helperText={formError.confirmPassword && errors.confirmPassword}
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center">
              <Button
                wider
                type="submit"
                kind="button"
                variant="contained"
                color="primary"
                bgHover={[theme.colors.orange.button, theme.colors.white.text]}
                colorOnClick={[theme.colors.darkcyan.button]}
                disabled={submitDisabled}
              >
                Save password
              </Button>
            </Col>
          </Row>
        </form>
      </>
    );
  }, [formik, submitDisabled]);

  return <>{setNewPasswordForm}</>;
};

export default SetupPasswordForm;
