import authService from 'services/authorization';
import axios from 'axios';

const AxiosWrapper = (args = {}) => {
  const instance = axios.create({ ...args });
  instance.interceptors.request.use(
    async (config) => {
      const accessToken = authService.getAccessToken();
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      let response = error?.response;
      if (!response) {
        response = { status: 503, statusText: 'ServiceUnavailable' };
        if (error.message === 'Network Error') {
          response.status = 404;
          response.data = {
            errorCode: 'NOTFOUND'
          };
        }
      }
      const { status } = response;
      switch (status) {
        case 401: {
          authService.signOut();
          return Promise.resolve({
            ...response,
            errorCode: "UNAUTHORIZED"
          });
        }
        case 403: {
          response = {
            status: 403,
            data: {
              errorCode: 'PERMISSION',
              errorMessage: 'You do not have permission to do this action'
            }
          };
          return Promise.resolve(response);
        }
        case 400:
        case 404:
        case 500:
        case 502:
        case 503:
          return Promise.resolve(response);
        default:
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export default AxiosWrapper;
