import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, withRouter } from 'react-router-dom';
import { PURPOSE } from 'constants/index';
import organizationService from 'services/organization';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import { CircularProgress } from '@mui/material';
import useLoading from 'components/loading/useLoading';
import { gS } from 'constants/helperMessage';
import OrganizationInfo from '../organizationInfo';

const AdminPortalOrganizationEditForm = ({ notFoundRedirectUrl, redirectUrl, backUrl }) => {
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const { toastSuccess, toastError } = useToast();
  const [showLoading, hideLoading] = useLoading();

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    const fetchOrganizationById = async () => {
      const resp = await organizationService.getOrganizationById(id);
      if (resp?.errorCode) {
        history.push(notFoundRedirectUrl);
      } else {
        const { name, description } = resp;
        setLoading(false);
        setOrganization({ name, description });
      }
    };
    fetchOrganizationById();
  }, [history, id, notFoundRedirectUrl, toastError]);

  const onSubmit = useCallback(
    async ({ name, description }) => {
      setIsDisabled(true);
      showLoading();
      const resp = await organizationService.editOrganization(id, {
        name,
        description
      });
      hideLoading();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        history.push(redirectUrl);
        toastSuccess(gS('Edit organization'));
      }
      setIsDisabled(false);
    },
    [hideLoading, history, id, redirectUrl, showLoading, toastError, toastSuccess]
  );

  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <OrganizationInfo
      purpose={PURPOSE.MANAGE}
      organization={organization}
      cancelUrl={backUrl}
      onSubmit={onSubmit}
      isDisabled={isDisabled}
    />
  );
};

export default withRouter(AdminPortalOrganizationEditForm);
