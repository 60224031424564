import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import DateRangePicker from 'components/dateRangePicker';
import Button from 'components/button';
import { CircularProgress } from '@mui/material';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import TableReporting from 'components/tableReporting';
import { formatDate, numberFormat, printContent, toUtc } from 'utils/helper';
import reportService from 'services/report';

const summaryHeadCells = [
  { id: 'totalCardsSold', label: 'Total Cards Sold', align: 'left', width: '33.33%' },
  { id: 'totalPurchasedAmount', label: 'Total Purchased Amount', align: 'left', width: '33.33%' },
  { id: 'totalFunrayRevenue', label: 'Total Funrayzr Revenue', align: 'left', width: '33.33%' }
];

const detailHeadCells = [
  { id: 'index', label: 'No', align: 'left' },
  { id: 'vendorName', label: 'Vendor Name', align: 'left' },
  { id: 'totalCardSold', label: 'Total Cards Sold', align: 'left' },
  { id: 'totalPurchasedAmount', label: 'Total Purchased Amount', align: 'left' },
  { id: 'totalRedeemedAmount', label: 'Total Redeemed Amount', align: 'left' },
  { id: 'totalBalance', label: 'Total Balance', align: 'left' },
  { id: 'vendorRevenue', label: 'Vendor Revenue', align: 'left' },
  { id: 'totalFunrayRevenue', label: 'Funrayzr Revenue', align: 'left' }
];

const AdminPortalVendorReportByAd = () => {
  const history = useHistory();
  const { toastError } = useToast();
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const initDate = [new Date(y, m, 1), new Date(y, m + 1, 0)];
  const [inputDate, setInputDate] = useState(initDate ?? [null, null]);
  const [loading, setLoading] = useState(true);
  const [reporting, setReporting] = useState({
    summary: {
      totalCardsSold: 0,
      totalPurchasedAmount: 0,
      totalFunrayRevenue: 0
    },
    transactions: []
  });

  useEffect(() => {
    const fetch = async () => {
      const [startDate, eDate] = inputDate;
      let endDate = null;
      if (eDate) {
        endDate = new Date(eDate.getTime());
        endDate.setHours(0);
        endDate.setMinutes(0);
        endDate.setSeconds(0);
      }

      const params = {
        startDate,
        endDate
      };

      const resp = await reportService.getVendorReportByAdmin(params);
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else if (resp) {
        const { totalCardsSold, totalFunrayRevenue, totalPurchasedAmount, vendorSummaries } = resp;
        const res = {
          summary: { totalCardsSold, totalFunrayRevenue, totalPurchasedAmount },
          transactions: vendorSummaries || []
        };
        setReporting(res);
      }

      setTimeout(() => {
        setLoading(false);
      }, 100);
    };

    fetch();
  }, [history, inputDate, toastError]);

  const controlRender = useMemo(() => {
    return (
      <DateRangePicker
        color="tertiary"
        startText="Date"
        endText="Date"
        value={inputDate}
        onChange={(newValue) => {
          setInputDate(newValue);
        }}
        size="small"
      />
    );
  }, [inputDate]);

  const summaryConvertToRowCells = (arr) => {
    if (!arr) return [];

    return arr.map((value) => {
      if (!value.converted) {
        value.totalPurchasedAmount = `$${numberFormat(parseFloat(value?.totalPurchasedAmount))}`;
        value.totalFunrayRevenue = `$${numberFormat(parseFloat(value?.totalFunrayRevenue))}`;
        value.converted = true;
      }
      return value;
    });
  };

  const reportSummaryRender = useMemo(() => {
    const { summary } = reporting;
    const rowCells = summaryConvertToRowCells([summary]);
    return <TableReporting noBorderTop headCells={summaryHeadCells} rowCells={rowCells} />;
  }, [reporting]);

  const detailConvertToRowCells = (arr) => {
    if (!arr) return [];

    return arr.map((value) => {
      if (!value.converted) {
        value.date = formatDate(toUtc(value.date));
        value.totalPurchasedAmount = `$${numberFormat(parseFloat(value?.totalPurchasedAmount))}`;
        value.totalRedeemedAmount = `$${numberFormat(parseFloat(value?.totalRedeemedAmount))}`;
        value.totalBalance = `$${numberFormat(parseFloat(value?.totalBalance))}`;
        value.vendorRevenue = `$${numberFormat(parseFloat(value?.vendorRevenue))}`;
        value.totalFunrayRevenue = `$${numberFormat(parseFloat(value?.totalFunrayRevenue))}`;
        value.converted = true;
      }
      return value;
    });
  };
  const reportDetailRender = useMemo(() => {
    const { transactions } = reporting;

    const rowCells = detailConvertToRowCells(transactions);

    return (
      <TableReporting stickyHeader noBorderTop headCells={detailHeadCells} rowCells={rowCells} />
    );
  }, [reporting]);

  const downloadHandle = useCallback(() => {
    printContent('report-view', 'Vendor Report');
  }, []);

  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <div className="fr-page-container mt-5 py-5 px-3" style={{ minHeight: '400px' }}>
          <Container>
            {loading ? (
              <div
                className="w-100 d-flex justify-content-center align-items-center bg-white"
                style={{ minHeight: '200px' }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <h5 className="text-uppercase font-weight-bold mb-5">Vendor Report</h5>

                <div className="mb-2 text-right">
                  <Button size="small" onClick={downloadHandle} color="tertiary">
                    Download
                  </Button>
                </div>

                <div id="report-view">
                  <div className="my-4">{controlRender}</div>

                  <h6 className="mt-4 font-weight-bold">Summary</h6>
                  <div>{reportSummaryRender}</div>
                  <h6 className="mt-5 font-weight-bold">Details</h6>
                  <div>{reportDetailRender}</div>
                </div>
              </>
            )}
          </Container>
        </div>
      </Container>
    </div>
  );
};

export default AdminPortalVendorReportByAd;
