import { SIGNIN_URL } from 'pages/signIn/url';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import authService from 'services/authorization';

const AuthorizeRoute = (props) => {
  const isCancelled = useRef(false);
  const [ready, setReady] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const populateAuthenticationState = () => {
      const isAuthenticated = authService.isAuthenticated();
      if (isCancelled.current) return;
      setAuthenticated(isAuthenticated);
      setReady(true);
    };

    const authenticationChanged = () => {
      if (!isCancelled.current) {
        setReady(false);
        setAuthenticated(false);
      }
      populateAuthenticationState();
    };

    const subscription = authService.subscribe(() => authenticationChanged());
    populateAuthenticationState();
    return () => {
      isCancelled.current = true;
      authService.unsubscribe(subscription);
    };
  }, []);

  const routeRender = useMemo(() => {
    const link = document.createElement('a');
    link.href = props.path;

    const redirectUrl = SIGNIN_URL.SIGNIN.URL;
    if (!ready) {
      return <div />;
    }
    const { children, ...rest } = props;

    if (!authenticated) {
      return <Redirect to={redirectUrl} />;
    }

    return <Route {...rest}>{children}</Route>;
  }, [authenticated, props, ready]);

  return <>{routeRender}</>;
};

export default AuthorizeRoute;
