import React, { useMemo } from 'react';
import { Container } from 'reactstrap';
import CardTransactionList from './list';
import { SCAN_VENDOR_TRANSACTIONS_URL } from './url';

const ScanVendorTransactions = () => {
  const renderView = useMemo(() => {
    return (
      <CardTransactionList
        detailUrl={SCAN_VENDOR_TRANSACTIONS_URL.DETAIL.URL}
      />
    );
  }, []);

  return (
    <>
      <div className="fr-background quicksand">
        <Container>
          <h3 className="fr-page-title">Cards & Transaction</h3>
          <div className="fr-page-container bg-transparent" style={{ minHeight: '400px' }}>
            {renderView}
          </div>
        </Container>
      </div>
    </>
  );
};

export default ScanVendorTransactions;
