export const ADMIN_PORTAL_URL = {
  ADMIN_PORTAL: {
    URL: '/admin',
    NAME: 'Admin Portal'
  },
  SIGNIN: {
    URL: '/admin/signin',
    NAME: 'Admin SignIn'
  },
  USER: {
    URL: '/admin/users',
    NAME: 'User Management'
  },
  USER_ADD: {
    URL: '/admin/users/add',
    NAME: 'Add User'
  },
  USER_DETAIL: {
    URL: '/admin/users/:id',
    NAME: 'Manage User'
  },
  VENDOR: {
    URL: '/admin/vendors',
    NAME: 'Vendor Management'
  },
  VENDOR_REPORT: {
    URL: '/admin/vendor-report',
    NAME: 'Vendor Report'
  },
  VENDOR_ADD: {
    URL: '/admin/vendors/add',
    NAME: 'Add Vendor'
  },
  VENDOR_SUMMARY: {
    URL: '/admin/vendor/summary',
    NAME: 'Summary'
  },
  VENDOR_INFORMATION: {
    URL: '/admin/vendor/information',
    NAME: 'Vendor Information'
  },
  VENDOR_CARD_TRANSACTION: {
    URL: '/admin/vendor/cards-transaction',
    NAME: 'Cards & Transaction'
  },
  VENDOR_CARD_TRANSACTION_DETAIL: {
    URL: '/admin/vendor/cards-transaction/:id',
    NAME: 'Cards & Transaction Detail'
  },
  VENDOR_REPORTING: {
    URL: '/admin/vendor/reporting',
    NAME: 'Reporting'
  },
  VENDOR_USER: {
    URL: '/admin/vendor/users',
    NAME: 'User Management'
  },
  VENDOR_USER_ADD: {
    URL: '/admin/vendor/users/add',
    NAME: 'User Management - Add'
  },
  VENDOR_USER_DETAIL: {
    URL: '/admin/vendor/users/:id',
    NAME: 'User Management - Detail'
  },
  VENDOR_DETAIL: {
    URL: '/admin/vendors/:id',
    NAME: 'Manage Vendor'
  },
  VENDOR_VIEW_SUMMARY: {
    URL: '/admin/vendors/view/:vid/summary',
    NAME: 'Summary'
  },
  VENDOR_VIEW_USERS: {
    URL: '/admin/vendors/view/:vid/users',
    NAME: 'User Management'
  },
  VENDOR_VIEW_USERS_ADD: {
    URL: '/admin/vendors/view/:vid/users/add',
    NAME: 'User Management Add'
  },
  VENDOR_VIEW_USERS_EDIT: {
    URL: '/admin/vendors/view/:vid/users/:id',
    NAME: 'User Management Edit'
  },
  VENDOR_VIEW_INFORMATION: {
    URL: '/admin/vendors/view/:vid/information',
    NAME: 'Vendor Information'
  },
  VENDOR_VIEW_CARD_TRANSACTION: {
    URL: '/admin/vendors/view/:vid/cards-transaction',
    NAME: 'Cards & Transaction'
  },
  VENDOR_VIEW_CARD_TRANSACTION_DETAIL: {
    URL: '/admin/vendors/view/:vid/cards-transaction/:id',
    NAME: 'Cards & Transaction Detail'
  },
  VENDOR_VIEW_REPORTING: {
    URL: '/admin/vendors/view/:vid/reporting',
    NAME: 'Reporting'
  },
  CHARITY: {
    URL: '/admin/charities',
    NAME: 'Charity Management'
  },
  CHARITY_REPORT: {
    URL: '/admin/charity-report',
    NAME: 'Charity Report'
  },
  CHARITY_INFORMATION: {
    URL: '/admin/charity/information',
    NAME: 'Charity Information'
  },
  CHARITY_CAMPAIGNS: {
    URL: '/admin/charity/campaigns',
    NAME: 'Campaigns'
  },
  CHARITY_CAMPAIGNS_ADD: {
    URL: '/admin/charity/campaigns/add',
    NAME: 'Add Campaigns'
  },
  CHARITY_CAMPAIGNS_EDIT: {
    URL: '/admin/charity/campaigns/:id',
    NAME: 'Edit Campaigns'
  },
  CHARITY_REPORTING: {
    URL: '/admin/charity/reporting',
    NAME: 'Reporting'
  },
  CHARITY_ADD: {
    URL: '/admin/charities/add',
    NAME: 'Add Charity'
  },
  CHARITY_EDIT: {
    URL: '/admin/charities/:id',
    NAME: 'Edit Charity'
  },
  CHARITY_VIEW_INFORMATION: {
    URL: '/admin/charities/view/:cid/information',
    NAME: 'Charity Information'
  },
  CHARITY_VIEW_CAMPAIGNS: {
    URL: '/admin/charities/view/:cid/campaigns',
    NAME: 'Campaigns'
  },
  CHARITY_VIEW_CAMPAIGNS_ADD: {
    URL: '/admin/charities/view/:cid/campaigns/add',
    NAME: 'Add Campaign'
  },
  CHARITY_VIEW_CAMPAIGNS_EDIT: {
    URL: '/admin/charities/view/:cid/campaigns/:id',
    NAME: 'Edit Campaign'
  },
  CHARITY_VIEW_REPORTING: {
    URL: '/admin/charities/view/:cid/reporting',
    NAME: 'Reporting'
  },
  REPORTING: {
    URL: '/admin/reporting',
    NAME: 'Reporting'
  },
  REGION: {
    URL: '/admin/regions',
    NAME: 'Region/Territories'
  },
  REGION_ADD: {
    URL: '/admin/regions/add',
    NAME: 'Add Region'
  },
  REGION_EDIT: {
    URL: '/admin/regions/edit/:id',
    NAME: 'Edit Region'
  },
  ORGANIZATION: {
    URL: '/admin/organization',
    NAME: 'Organization Types'
  },
  ORGANIZATION_ADD: {
    URL: '/admin/organization/add',
    NAME: 'Add Organization'
  },
  ORGANIZATION_DETAIL: {
    URL: '/admin/organization/detail/:id',
    NAME: 'Edit Organization'
  },
  SETUP_PASSWORD: {
    URL: '/admin/setup-password',
    NAME: 'Set Password'
  },
  SETUP_PASSWORD_FAILED: {
    URL: '/admin/setup-password-failed',
    NAME: 'Set new password failed'
  }
};
