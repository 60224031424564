import { FR_APP_KEY } from 'configs';
import { IMG_LOCAL, APP_KEY } from 'constants/index';

const getContentLogo = () => {
  let path = IMG_LOCAL.FUNRAYZR_CONTENT_LOGO;
  if (FR_APP_KEY === APP_KEY.RAY_ZR) {
    path = IMG_LOCAL.RAYZR_CONTENT_LOGO;
  }
  return path;
};

const getLogo = (isAbout) => {
  let path = isAbout ? IMG_LOCAL.FUNRAYZR_FOOTER_LOGO_WHITE : IMG_LOCAL.FUNRAYZR_LOGO;
  if (FR_APP_KEY === APP_KEY.RAY_ZR) {
    path = IMG_LOCAL.RAYZR_LOGO;
  }
  return path;
};

const getOtherLogo = () => {
  let path = IMG_LOCAL.FUNRAYZR_LOGO;
  if (FR_APP_KEY !== APP_KEY.RAY_ZR) {
    path = IMG_LOCAL.RAYZR_LOGO;
  }
  return path;
};

const getAvatarText = (displayName) => {
  const [firstName, lastName] = displayName.split(' ');
  const f = (firstName || '')[0] || '';
  let l = (lastName || '')[0] || '';
  if (!l) {
    l = (firstName || '')[1] || '';
  }
  return `${f}${l}`.toUpperCase();
};

const downloadImage = (dataUrl, fileName) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = dataUrl;
  link.click();
};

const imageHelper = {
  getLogo,
  getOtherLogo,
  getContentLogo,
  getAvatarText,
  downloadImage
};

export default imageHelper;
