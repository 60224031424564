import React from 'react';
import { Container } from 'reactstrap';
import { ADMIN_PORTAL_URL } from '../url';

import OrganizationList from './organizationList';

const Organization = () => {
  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
      <h3 className="fr-page-title">Admin Portal - Organization Types</h3>
        <div className="fr-page-container bg-transparent" style={{ minHeight: '400px' }}>
          <OrganizationList
            tableTitle="ORGANIZATION TYPES"
            addUrl={ADMIN_PORTAL_URL.ORGANIZATION_ADD.URL}
            editUrl={ADMIN_PORTAL_URL.ORGANIZATION_DETAIL.URL}
          />
        </div>
      </Container>
    </div>
  );
};

export default Organization;
