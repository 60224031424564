import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import React from 'react';
import { useParams } from 'react-router-dom';
import CardTransactionView from 'pages/adminPortal/vendor/cardAndTransaction/detail/cardTransaction';
import AdminPortalVendorView, { CardTransactionTab } from '../..';

const AdminPortalVendorViewCardAndTransactionDetail = () => {
  const { vid } = useParams();

  return (
    <>
      <AdminPortalVendorView vendorTab={CardTransactionTab.value}>
        <CardTransactionView
          tenantId={vid}
          notFoundRedirectUrl={ADMIN_PORTAL_URL.VENDOR_VIEW_CARD_TRANSACTION_DETAIL.URL.replace(':vid', vid)}
        />
      </AdminPortalVendorView>
    </>
  );
};

export default AdminPortalVendorViewCardAndTransactionDetail;
