import React from 'react';
import { Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import ConsumerCardTransaction from 'pages/scanVendorTransactions/detail/cardTransaction';
import { TRANSACTION_URL } from '../url';

const TransactionDetail = () => {
  const { vid } = useParams();

  return (
    <div className="fr-background quicksand">
      <Container className="py-5">
        <div className="fr-page-container" style={{ minHeight: '400px' }}>
          <ConsumerCardTransaction
            tenantId={vid}
            haveRedeem={false}
            isMine={true}
            notFoundRedirectUrl={TRANSACTION_URL.TRANSACTION.URL}
          />
        </div>
      </Container>
    </div>
  );
};

export default TransactionDetail;
