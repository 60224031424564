import { FUNRAYZR_API_URL } from 'configs';
import { mapResponse } from 'api/responseMapping';

const Api = (axios) => {
  const url = `${FUNRAYZR_API_URL}/Tenants`;

  const getTenants = async () => {
    const resp = await axios.get(url);

    return mapResponse(resp);
  };

  return {
    getTenants
  };
};

export default Api;
