import Api from "api/role/api";
import AxiosWrapper from "utils/axios";

export class RoleService {
  constructor() {
    const axios = AxiosWrapper();
    this._roleApi = Api(axios);
  }

  async getRoles(pa) {
    return this._roleApi.getRoles(pa);
  }
}

const roleService = new RoleService();

export default roleService;
