import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useLoading from 'components/loading/useLoading';
import charityService from 'services/charity';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { gS } from 'constants/helperMessage';
import { ERROR_CODES } from 'utils/axios/helper';
import publicService from 'services/public';
import CharityForm from "./charityForm";
import { PURPOSE } from "../../../../constants";

const CharityInfo = ({ purpose, charityInfo, id }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [organizationTypes, setOrganizationTypes] = useState([{}]);
  const [regions, setRegions] = useState([{}]);

  const history = useHistory();
  const [showLoading, hideLoading] = useLoading();
  const { toastSuccess, toastError } = useToast();

  const isAdd = purpose === PURPOSE.ADD;
  const message = isAdd ? 'Created charity' : 'Edited charity';

  const onSubmit = useCallback(
    async (values) => {
      setIsDisabled(true);
      showLoading();

      const {
        legalName,
        firstName,
        lastName,
        displayName,
        workPhoneNumber,
        phoneNumber,
        businessNumber,
        email,
        organizationId,
        regionId,
        website,
        institutionNumber,
        transitNumber,
        accountNumber,
        addressLine1,
        addressLine2,
        city,
        province,
        postalCode
      } = values;

      const charity = {
        legalName,
        displayName,
        businessNumber,
        organizationId,
        regionId,
        website,
        institutionNumber,
        transitNumber,
        accountNumber,
        address: { addressLine1, addressLine2, city, province, postalCode },
        primaryContact: { firstName, lastName, email, workPhoneNumber, phoneNumber }
      };

      const resp = isAdd ? await charityService.createCharity(charity)
        : await charityService.editCharity(id, charity);
      hideLoading();

      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        setIsDisabled(false);
        return;
      }

      if (ERROR_CODES.includes(resp?.status)) {
        toastError(resp?.statusText || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        setIsDisabled(false);
        return;
      }

      history.push(ADMIN_PORTAL_URL.CHARITY.URL);

      toastSuccess(gS(message));
    },
    [hideLoading, history, showLoading, toastError, toastSuccess, id, isAdd, message]
  );

  useEffect(() => {
    const fetchOrganizationTypes = async () => {
      const resp = await publicService.getOrganizationTypes();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        return;
      }
      setOrganizationTypes(resp);
    };

    const fetchRegions = async () => {
      const resp = await publicService.getRegions();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        return;
      }
      setRegions(resp);
    };

    fetchOrganizationTypes();
    fetchRegions();
  }, [toastError]);

  return (
    <CharityForm
      initialValues={charityInfo}
      purpose={purpose}
      onSubmit={onSubmit}
      isDisabled={isDisabled}
      organizationTypes={organizationTypes}
      regions={regions}
    />
  );
};

export default CharityInfo;
