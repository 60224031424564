import React from 'react';

import { makeStyles } from '@mui/styles';
import { Col, Container, Row } from 'reactstrap';

import { theme } from 'constants/theme';
import { PRIVACY_POLICY } from 'constants/globalContent';

const useStyles = makeStyles(() => ({
  border: {
    height: '1px',
    background: theme.colors.gray.border,
    margin: '50px 0px 20px 0px'
  }
}));

const PrivacyPolicy = () => {
  const { border } = useStyles();

  return (
    <div className="fr-bg-admin pb-md-5">
      <h1 className="fr-page-title text-uppercase" id="headePage">
        Privacy Policy
      </h1>
      <Container className="about-container bg-white rounded">
        <Row>
          <Col xs={12}>
            <div className={border} />
          </Col>
          <Col xs={12}>
            <h1 className="h2 font-weight-bolder">{PRIVACY_POLICY.privacyPolicyFunrayZR}</h1>

            <p>
              {PRIVACY_POLICY.accessible_policy}
            </p>

            <p>
              {PRIVACY_POLICY.additional_policy}
            </p>

            <p>
              {PRIVACY_POLICY.appliesOnly_policy}
            </p>

            <h2 className="h3 font-weight-bolder">{PRIVACY_POLICY.consent}</h2>

            <p>
              {PRIVACY_POLICY.website_policy}
            </p>

            <h2 className="h3 font-weight-bolder">{PRIVACY_POLICY.information_policy}</h2>

            <p>
              {PRIVACY_POLICY.personal_policy}
            </p>
            <p>
              {PRIVACY_POLICY.contact_policy}
            </p>
            <p>
              {PRIVACY_POLICY.register_policy}
            </p>

            <h2 className="h3 font-weight-bolder">{PRIVACY_POLICY.weUse_policy}</h2>

            <p>{PRIVACY_POLICY.collect_policy}</p>

            <ul>
              <li>{PRIVACY_POLICY.provide_policy}</li>
              <li>{PRIVACY_POLICY.improve_policy}</li>
              <li>{PRIVACY_POLICY.understand_policy}</li>
              <li>{PRIVACY_POLICY.develop_policy}</li>
              <li>
                {PRIVACY_POLICY.communicate_policy}
              </li>
              <li>{PRIVACY_POLICY.sendEmail_policy}</li>
              <li>{PRIVACY_POLICY.find_policy}</li>
            </ul>

            <h2 className="h3 font-weight-bolder">{PRIVACY_POLICY.logFiles_policy}</h2>

            <p>
              {PRIVACY_POLICY.follows_policy}
            </p>

            <h2 className="h3 font-weight-bolder">{PRIVACY_POLICY.cookies_policy}</h2>

            <p>
              {PRIVACY_POLICY.likeAny_policy}
            </p>

            <h2 className="h3 font-weight-bolder">{PRIVACY_POLICY.advertising_policy}</h2>

            <p>
              {PRIVACY_POLICY.consult_policy}
            </p>

            <p>
              {PRIVACY_POLICY.thirdParty_policy}
            </p>

            <p>
              {PRIVACY_POLICY.note_policy}
            </p>

            <h2 className="h3 font-weight-bolder">{PRIVACY_POLICY.third_policy}</h2>

            <p>
              {PRIVACY_POLICY.advertisers_policy}
            </p>

            <p>
              {PRIVACY_POLICY.choose_policy}
            </p>

            <h2 className="h3 font-weight-bolder">
              {PRIVACY_POLICY.CCPA_policy}
            </h2>

            <p>{PRIVACY_POLICY.under_policy}</p>
            <p>
              {PRIVACY_POLICY.request_policy}
            </p>
            <p>
              {PRIVACY_POLICY.business_policy}
            </p>
            <p>
              {PRIVACY_POLICY.consumer_policy}
            </p>
            <p>
              {PRIVACY_POLICY.make_policy}
            </p>

            <h2 className="h3 font-weight-bolder">{PRIVACY_POLICY.data_policy}</h2>

            <p>
              {PRIVACY_POLICY.entitled_policy}
            </p>
            <p>
              {PRIVACY_POLICY.access_policy}
            </p>
            <p>
              {PRIVACY_POLICY.right_policy}
            </p>
            <p>
              {PRIVACY_POLICY.erasure_policy}
            </p>
            <p>
              {PRIVACY_POLICY.restrict_policy}
            </p>
            <p>
              {PRIVACY_POLICY.object_policy}
            </p>
            <p>
              {PRIVACY_POLICY.portability_policy}
            </p>
            <p>
              {PRIVACY_POLICY.month_policy}
            </p>

            <h2 className="h3 font-weight-bolder">{PRIVACY_POLICY.children_policy}</h2>

            <p>
              {PRIVACY_POLICY.another_policy}
            </p>

            <p>
              {PRIVACY_POLICY.knowingly_policy}
            </p>
            {/* <h2 className="h3 font-weight-bolder" id="policies">
              FunRayZR Refund Policy and Product Description
            </h2>
            <p>
              FunRayZR Refund Policy and Product Description FunRayZR digital gift certificates are
              not refundable. They can be redeemed for goods and services only with the specified
              vendor.
            </p>

            <p>FunRayZR digital gift certificates never expire.</p>
            <p>
              FunRayZR digital gift certificates are not transferable. They should be used by the
              purchaser or the person they were purchased for.
            </p>
            <p>
              FunRayZR digital gift cards are delivered by email, usually within 1 hour of purchase.
            </p>
            <p>
              FunRayZR digital gift cards have no cash value, they can be redeemed for goods or
              services offered by the specific vendor.
            </p>
            <p>
              FunRayZR digital gift cards are denominated in Canadian Dollars. FunRayZR gift cards
              can be purchased by anyone using a credit card addressed in Canada or the United
              States.
            </p>
            <p>
              FunRayZR gift cards are redeemed by displaying your unique QR code to the vendor at
              time of purchase. The QR code can be displayed on your mobile device or printed out.
            </p>
            <p>
              There are no additional taxes or delivery charges when you purchase a FunRayZR digital
              gift card, the card can be used to purchase taxable goods and services.
            </p> */}

            <h2 className="h3 font-weight-bolder">
              {PRIVACY_POLICY.close_policy}
            </h2>
            <p>
              {PRIVACY_POLICY.certificate_policy}
            </p>
            <h2 className="h3 font-weight-bolder">
              {PRIVACY_POLICY.not_happy_policy}
            </h2>
            <p>
              {PRIVACY_POLICY.customers_policy}
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
