import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Table, useTable } from 'components/table';
import { usePopup } from 'components/popup';
import Button from 'components/button';
import cardService from 'services/card';
import { isMobile, isMobileByWatchMedia, toUtc } from 'utils/helper';
import { SCAN_VENDOR_TRANSACTIONS_URL } from '../url';
import DesktopScanPopup from '../qrCode/desktopScan';

const headCells = [
  { id: 'cardNumber', label: 'Card Number' },
  { id: 'displayName', label: 'Name' },
  { id: 'email', label: 'Email Address' },
  { id: 'cardBalance', label: 'Card Balance' },
  { id: 'recentTransaction', label: 'Recent Transaction' },
  { id: 'action', label: '' }
];

const headCellsMobile = [
  { id: 'cardNumber', label: 'Card Number' },
  { id: 'cardBalance', label: 'Card Balance' }
];

const apiParams = {};

const CardTransactionList = ({ tableTitle, detailUrl, scanRedirectUrl, tenantId }) => {
  const [data, setData] = useTable();
  const isMobileDevice = isMobile();
  const isMobileByWM = isMobileByWatchMedia();
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const initDate = [new Date(y, m, 1), new Date(y, m + 1, 0)];
  const history = useHistory();
  const [isOpen, open, close] = usePopup();
  if (tenantId) {
    apiParams.tenantId = tenantId;
  }

  const clickOnScanHandle = () => {
    if (isMobileDevice) {
      history.push(SCAN_VENDOR_TRANSACTIONS_URL.SCAN.URL);
    } else {
      open();
    }
  };

  const renderAction = (id) => {
    return (
      <Button
        color="tertiary"
        size="small"
        className="ml-1 text-white-hover"
        component={Link}
        to={`${detailUrl.replace(':id', id)}?isView=true`}
      >
        View
      </Button>
    );
  };

  const renderLink = (value, id) => {
    return <Link to={`${detailUrl.replace(':id', id)}?isView=true`}>{value}</Link>;
  };

  const convertToRowCells = (arr) => {
    if (!arr) return [];

    return arr.map((value) => {
      value.action = renderAction(value.id);
      value.displayName = `${value.firstName} ${value.lastName}`;
      if (!value.converted) {
        value.cardBalance = `$${parseFloat(value?.cardBalance).toFixed(2)}`;
        value.recentTransaction = value?.recentTransaction
          ? moment(toUtc(value?.recentTransaction)).format('MMM, Do YYYY')
          : '';
        value.converted = true;
      }

      if (!isMobileDevice) {
        return value;
      }

      value.displayName = renderLink(value.displayName, value.id);
      if (isMobileDevice) {
        value.cardNumber = renderLink(value.cardNumber, value.id);
      }
      return value;
    });
  };

  const desktopScanPopupRender = useMemo(() => {
    return <DesktopScanPopup redirectUrl={scanRedirectUrl} isOpen={isOpen} close={close} />;
  }, [close, isOpen, scanRedirectUrl]);

  const dataConvert = convertToRowCells(data.items);

  return (
    <>
      <Table
        headCells={isMobileDevice ? headCellsMobile : headCells}
        rowCells={dataConvert}
        totalCount={data?.totalItems ?? 0}
        havePagination
        haveFilterByDate
        title={tableTitle}
        haveSearch
        initDate={initDate}
        haveHeaderAction={
          <Button
            className={`${isMobileByWM ? 'w-100 mt-3' : 'mr-4'} text-white-hover`}
            size="small"
            color="tertiary"
            onClick={clickOnScanHandle}
          >
            Scan
          </Button>
        }
        service={cardService}
        api="getCards"
        apiParams={apiParams}
        setDataFetch={setData}
      />
      {desktopScanPopupRender}
    </>
  );
};

export default CardTransactionList;
