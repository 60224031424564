import Api from 'api/region';
import AxiosWrapper from 'utils/axios';

export class RegionService {
  constructor() {
    const axios = AxiosWrapper();
    this._regionApi = Api(axios);
  }

  createRegion(region) {
    return this._regionApi.createRegion(region);
  }

  editRegion(id, region) {
    return this._regionApi.editRegion(id, region);
  }

  getRegion(id) {
    return this._regionApi.getRegion(id);
  }

  getRegions(params) {
    return this._regionApi.getRegions(params);
  }

  statusRegion(id, regionStatus) {
    return this._regionApi.statusRegion(id, regionStatus);
  }
}

const regionService = new RegionService();

export default regionService;
