import Api from 'api/charity/campaign/api';
import AxiosWrapper from 'utils/axios';

export class CampaignService {
  constructor() {
    const axios = AxiosWrapper();
    this._campaignApi = Api(axios);
  }

  getCampaign(params) {
    return this._campaignApi.getCampaign(params);
  }

  getCharityDetail(id) {
    return this._campaignApi.getCampaignDetail(id);
  }

  updateCampaignStatus(id, status) {
    return this._campaignApi.updateCampaignStatus(id, status);
  }

  createCampaign(campaign) {
    return this._campaignApi.createCampaign(campaign);
  }

  editCampaign(id, campaign) {
    return this._campaignApi.editCampaign(id, campaign);
  }
}

const campaignService = new CampaignService();

export default campaignService;
