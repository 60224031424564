import React from 'react';
import { PURPOSE } from 'constants/index';
import { Container } from 'reactstrap';
import authService from 'services/authorization';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import CampaignForm from '../campaignForm';

const AdminPortalCampaignAdd = () => {
  const tenantId = authService.getTenantId();

  return (
    <>
      <div className="fr-container pb-40 fr-bg-admin quicksand">
        <Container>
          <h3 className="fr-page-title"> </h3>
          <div className="fr-page-container" style={{ minHeight: '400px' }}>
            <Container>
              <CampaignForm
                charityId={tenantId}
                purpose={PURPOSE.ADD}
                redirectUrl={ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS.URL}
                backUrl={ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS.URL}
              />
            </Container>
          </div>
        </Container>
      </div>
    </>
  );
};

export default AdminPortalCampaignAdd;
