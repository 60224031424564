import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { Col, Container, Row } from 'reactstrap';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import publicService from 'services/public';
import { BUCKET, CART_DETAILS_TYPE, IMG_LOCAL } from 'constants/index';
import fileService from 'services/file';
import { gS } from 'constants/helperMessage';
import { FAQ_POPUP } from 'constants/globalContent';
import VendorCardForm from 'pages/shopVendor/detail/component/vendorCardForm';
import authService from 'services/authorization';
import cartService from 'services/cart';
import { CART_URL } from 'pages/cart/url';
import VendorCard from 'pages/adminPortal/vendor/card';
import Button from 'components/button';
import { usePopup } from 'components/popup';
import { FR_APP_KEY } from 'configs';
import { SHOP_VENDOR_URL } from '../url';
import ProviderInformation from '../funrayzrCard/providerInfo';
import FAQPopup from '../funrayzrCard/faqPopup';
import Description from '../funrayzrCard/descriptionCard';

const ShopVendorRayzrCard = ({ match, history }) => {
  const isCancelled = useRef(false);
  const [loading, setLoading] = useState(true);
  const [vendor, setVendor] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [isOpen, open, close] = usePopup();

  const { toastSuccess, toastError } = useToast();

  const { vendorId } = match.params;

  useEffect(() => {
    const fetchVendorId = async () => {
      if (!isCancelled.current) {
        setLoading(true);
      }
      const resp = await publicService.getVendor(match.params.vendorId);

      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        history.push(SHOP_VENDOR_URL.SHOP_VENDOR_LIST.URL);
      } else {
        const { displayName, address, logoPath, amount, website, description } = resp;

        if (!amount.includes(match.params?.amount)) {
          history.push(SHOP_VENDOR_URL.DETAIL.URL.replace(':id', match.params.vendorId));
        }

        if (!isCancelled.current) {
          setVendor({
            displayName,
            logoPath: logoPath
              ? `${fileService._baseUrl}/${BUCKET.VENDOR}/${logoPath}`
              : IMG_LOCAL.DEFAULT_VENDOR,
            address: address?.addressLine1,
            city: address?.city,
            province: address?.province,
            postalCode: address?.postalCode,
            website,
            description,
            amount:
              amount === '0'
                ? []
                : amount
                  .replace(/ /g, '')
                  .replaceAll('Amount', '')
                  .split(',')
                  .sort((a, b) => a - b)
          });
          setLoading(false);
        }
      }
    };
    fetchVendorId();

    return () => {
      isCancelled.current = true;
    };
  }, [history, match.params.vendorId, match.params.amount, toastError]);

  const handleOnSubmit = async ({
    quantity,
    amount,
    isGift,
    to,
    recipientEmail,
    confirmEmail,
    from,
    message
  }) => {
    const price = +amount.replace('Amount', '');
    const total = quantity * price;

    const cart = {
      total,
      isLocal: true,
      cartDetails: [
        {
          vendorId,
          vendor: {
            displayName: vendor?.displayName,
            logoPath: vendor?.logoPath,
            address: vendor?.address
          },
          cardName: vendor?.displayName,
          type: CART_DETAILS_TYPE.RAY_ZR,
          quantity: +quantity,
          price,
          total,
          isGift,
          to,
          recipientEmail,
          confirmEmail,
          from,
          giftMessage: message
        }
      ]
    };
    const isLogin = !authService.isExpired();

    let products = {};
    const productsLocal = JSON.parse(localStorage.getItem('products'));
    // WE STASH THIS CODE IN CASE NEW REQUIREMNT
    // const id = vendorId + price + isGift + to + recipientEmail + from + message;
    const id = uuidv4();
    if (productsLocal?.total) {
      // WE STASH THIS CODE IN CASE NEW REQUIREMNT
      // const indexMatch = productsLocal.cartDetails.findIndex((i) => i.id === id);

      // if (indexMatch >= 0) {
      // products = {
      //   cartDetails: [...productsLocal.cartDetails],
      //   total: productsLocal.total + cart.total
      // };
      // products.cartDetails[indexMatch] = {
      //   ...products.cartDetails[indexMatch],
      //   quantity: products.cartDetails[indexMatch].quantity + +quantity,
      //   total: price * (products.cartDetails[indexMatch].quantity + +quantity)
      // };
      //   products = {
      //     cartDetails: [...productsLocal.cartDetails],
      //     total: productsLocal.total
      //   };
      //   toastError('This card is already in the cart');
      //   return;
      // }
      products = {
        isLocal: true,
        cartDetails: [
          ...productsLocal.cartDetails,
          {
            ...cart.cartDetails[0],
            logoPath: vendor.logoPath,
            address: vendor.address,
            id
          }
        ],
        total: productsLocal.total + cart.total
      };
    } else {
      products = {
        ...cart,
        cartDetails: [
          {
            ...cart.cartDetails[0],
            logoPath: vendor.logoPath,
            address: vendor.address,
            id
          }
        ]
      };
    }

    localStorage.setItem('products', JSON.stringify(products));

    if (isLogin) {
      setIsDisabled(true);
      const respone = await cartService.createCart(cart);

      if (respone?.errorCode) {
        toastError(respone.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        localStorage.removeItem('products');
      }

      setIsDisabled(false);
    }

    history.push(CART_URL.CART.URL);
    toastSuccess(gS('Add to Cart'));
  };

  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div className="fr-background pb-0 py-4 text-white quicksand">
      <Container fluid className="x-container p-0 container my-4 rounded-9 bg-white">
        <Row className="mx-0 h-full">
          <Col xs={12} md={5} lg={4} className="p-0">
            <ProviderInformation vendor={vendor} />
          </Col>
          <Col xs={12} md={7} lg={8} className="px-4">
            <div className="black-text p-0 p-xs-4">
              <div className="my-4 py-4">
                <Row className="mx-0">
                  <Col className="px-0 py-sm-0">
                    <h3 className="font-weight-bolder text-center text-md-left">
                      Select your eGift card amount
                    </h3>
                  </Col>
                </Row>

                <Row className="mx-0">
                  <Col xs={12} lg={6} className="px-0 py-sm-0 py-md-5">
                    <VendorCard
                      className="mx-auto"
                      vendorLogo={vendor.logoPath}
                      name={vendor.displayName}
                      address={vendor.address}
                      isChairityCard
                    />
                    <div className="d-none d-lg-block mt-0 mt-lg-3">
                      <Col xs={12} className="d-flex justify-content-center flex-wrap">
                        <Description title="RayZR eGift Cards" type={FR_APP_KEY} />
                        <Button
                          color="black"
                          className="text-white-hover"
                          size="medium"
                          onClick={open}
                        >
                          {FAQ_POPUP.eGiftCardFAQ}
                        </Button>
                      </Col>
                    </div>
                  </Col>
                  <Col xs={12} lg={6} className="px-0 py-sm-0 py-lg-5">
                    <VendorCardForm
                      vendor={vendor}
                      onSubmit={handleOnSubmit}
                      isDisabled={isDisabled}
                    />
                  </Col>
                  <Col
                    xs={12}
                    className="d-flex justify-content-center flex-wrap d-block d-lg-none"
                  >
                    <Description title="RayZR eGift Cards" type={FR_APP_KEY} />
                    <Button color="black" className="text-white-hover" size="medium" onClick={open}>
                      {FAQ_POPUP.eGiftCardFAQ}
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <FAQPopup isOpen={isOpen} close={close} />
      </Container>
    </div>
  );
};

export default withRouter(ShopVendorRayzrCard);
