import qs from 'qs';
import { FUNRAYZR_API_URL } from 'configs';
import { mapResponse } from 'api/responseMapping';

const Api = (axios) => {
  const campaignsUrl = `${FUNRAYZR_API_URL}/Charities/campaigns`;

  const getCampaign = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${campaignsUrl}?${params}`);

    return mapResponse(resp, true);
  };

  const createCampaign = async (campaign) => {
    const resp = await axios.post(campaignsUrl, { ...campaign });

    return mapResponse(resp);
  };

  const getCampaignDetail = async (id) => {
    const resp = await axios.get(`${campaignsUrl}/${id}`);

    return mapResponse(resp);
  };

  const editCampaign = async (id, campaign) => {
    const resp = await axios.put(`${campaignsUrl}/${id}`, campaign);

    return mapResponse(resp);
  };

  const updateCampaignStatus = async (id, status) => {
    const resp = await axios.put(`${campaignsUrl}/${id}/status/${status}`);

    return mapResponse(resp);
  };

  return {
    getCampaign,
    getCampaignDetail,
    createCampaign,
    editCampaign,
    updateCampaignStatus
  };
};

export default Api;
