import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';

const { makeStyles } = require('@mui/styles');
const { theme } = require('constants/theme');

const useStyles = makeStyles({
  tabList: {
    '& .MuiButtonBase-root': {
      textTransform: 'none',
      fontFamily: `${theme.fontFamily.quicksandMedium} !important`,
      '&.Mui-selected': {
        fontWeight: 'bold'
      }
    },
    '& .Mui-selected': {
      color: `${theme.colors.blue.rbc} !important`
    },
    '& .MuiTabs-indicator': {
      background: `${theme.colors.blue.rbc} !important`
    }
  }
});

const TabUrl = ({ children, tab, tabs }) => {
  const { tabList } = useStyles();
  const history = useHistory();

  const contentRender = useMemo(
    () => (
      <TabPanel value={tab} className="px-0">
        {children}
      </TabPanel>
    ),
    [children, tab]
  );

  const handleChange = (event, newValue) => {
    if (event && newValue) {
      const obj = tabs.find((f) => {
        return f.value === newValue;
      });
      if (obj?.url) {
        history.push(obj.url);
      }
    }
  };

  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 2, borderColor: 'divider' }}>
        <TabList onChange={handleChange} className={tabList}>
          {tabs.map((tabMap) => {
            return <Tab key={tabMap.value} label={tabMap.name} value={tabMap.value} />;
          })}
        </TabList>
      </Box>
      {contentRender}
    </TabContext>
  );
};

TabUrl.propTypes = {
  tab: PropTypes.string.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    })
  ).isRequired
};

export default TabUrl;
