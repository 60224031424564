import Landing from ".";
import { LANDING_URL } from "./url";

export const LANDING_ROUTES = [
  {
    name: LANDING_URL.LANDING.NAME,
    path: LANDING_URL.LANDING.URL,
    component: Landing,
    isPrivate: false
  }
];
