import { FUNRAYZR_API_URL } from 'configs';
import { mapResponse } from 'api/responseMapping';

const Api = (axios) => {
  const url = `${FUNRAYZR_API_URL}/Orders`;

  const checkout = async (card) => {
    const resp = await axios.post(`${url}/checkout`, card);
    return mapResponse(resp);
  };

  return {
    checkout
  };
};

export default Api;
