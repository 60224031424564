import { makeStyles } from '@mui/styles';
import { IMG_LOCAL } from 'constants/index';
import { theme } from 'constants/theme';

const useStyles = makeStyles(() => ({
  funrayLogo: {
    marginTop: '-75px'
  },
  aboutBorder: {
    border: 'solid 1px #707070'
  },
  aboutTitle: {
    fontSize: theme.fontSize.s34,
    fontFamily: theme.fontFamily.titanOneRegular,
    textAlign: 'center',
    color: theme.colors.white.title,
    '&.rayzr': {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0
    },
    '&.funrayzr': {
      margin: '40px 0'
    },
    '@media (max-width: 414px)': {
      fontSize: theme.fontSize.s24
    }
  },
  heroContent: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0
  },
  heroTitle: {
    color: theme.colors.white.title,
    fontSize: theme.fontSize.s40,
    fontFamily: theme.fontFamily.titanOneRegular,
    textAlign: 'center',
    textShadow: `0 3px 7px ${theme.text.textDarkShadow}`,
    marginBottom: '1.5rem',
    padding: '0 15px',
    '@media (max-width: 414px)': {
      fontSize: theme.fontSize.s30
    }
  },
  heroText: {
    color: theme.colors.white.text,
    fontSize: theme.fontSize.s30,
    fontFamily: theme.fontFamily.titanOneRegular,
    textAlign: 'center',
    textShadow: `0 3px 7px ${theme.text.textDarkShadow}`,
    marginBottom: '1.5rem',
    padding: '0 15px',
    '@media (max-width: 414px)': {
      fontSize: theme.fontSize.s16
    }
  },
  giftCardTitle: {
    fontSize: theme.fontSize.s50,
    fontFamily: theme.fontFamily.titanOneRegular,
    textAlign: 'center',
    color: theme.colors.white.title,
    margin: '40px 0',
    textShadow: `0 3px 7px ${theme.text.textShadowColor}`,
    '@media (max-width: 414px)': {
      fontSize: theme.fontSize.s28
    }
  },
  marginTop60: {
    marginTop: '60px'
  },
  backgroundArea: {
    marginTop: '40px',
    paddingBottom: '60px',
    backgroundImage: `url(${IMG_LOCAL.RAYZR_ABOUT_BG_2})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '120%',
    backgroundPosition: 'center 50%'
  },
  buyGiftCard: {
    padding: '160px 0',
    '& h3': {
      color: theme.colors.white.title,
      fontSize: theme.fontSize.s20,
      textShadow: `0 3px 7px ${theme.text.textShadowColor}`,
      textAlign: 'center',
      marginBottom: '2rem',
      '&:last-child': {
        marginBottom: 0
      }
    },
    '@media (max-width: 414px)': {
      padding: '100px 0'
    }
  },
  boxContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  box: {
    background: theme.colors.white.background,
    padding: '20px 40px',
    borderRadius: '15px',
    minHeight: '200px',
    maxWidth: '375px',
    minWidth: '375px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%'
    },
    '& h3': {
      color: theme.colors.black.title,
      fontSize: theme.fontSize.s38,
      fontFamily: theme.fontFamily.quicksandLight,
      textAlign: 'center'
    },
    '& p': {
      color: theme.colors.black.title,
      fontSize: theme.fontSize.s16,
      fontFamily: theme.fontFamily.quicksandRegular,
      textAlign: 'center'
    },
    '&.text': {
      padding: '20px 20px'
    },
    '@media (max-width: 414px)': {
      maxWidth: '100%',
      minWidth: '100%'
    }
  },
  textBox: {
    height: '100%',
    padding: '20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& p': {
      color: theme.colors.white.text,
      fontSize: theme.fontSize.s16,
      fontFamily: theme.fontFamily.quicksandRegular,
      textAlign: 'center',
      marginBottom: '1rem'
    },
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& p': {
        fontWeight: 'bold'
      }
    }
  },
  textInfoAbout: {
    '& p': {
      color: theme.colors.black.text,
      fontSize: theme.fontSize.s20,
      fontFamily: theme.fontFamily.quicksandBold,
      textAlign: 'center',
      marginBottom: '0.75rem'
    }
  },
  authenticationArea: {
    marginTop: '40px'
  },
  textArea: {
    '& p': {
      color: theme.colors.white.text,
      textAlign: 'center',
      marginBottom: 0
    }
  },
  buttonArea: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: '0 auto',
    maxWidth: '550px',
    padding: '60px 0',
    '@media (max-width: 768px)': {
      maxWidth: '375px'
    }
  },
  covidArea: {
    width: '80%',
    '@media (max-width: 768px)': {
      width: '100%'
    }
  }
}));

export default useStyles;
