import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';

const useStyles = makeStyles(() => ({
  passwordContainer: {
    '&.container': {
      maxWidth: '500px'
    }
  },
  title: {
    fontSize: '24px',
    fontFamily: theme.fontFamily.quicksandLight,
    color: theme.colors.black.title
  }
}));

export default useStyles;
