import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { PURPOSE } from 'constants/index';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import { CircularProgress } from '@mui/material';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import campaignService from 'services/charity/campaign';
import authService from 'services/authorization';
import { Container } from 'reactstrap';
import CampaignForm from '../campaignForm';

const AdminPortalCampaignEdit = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [campaign, setCampaign] = useState({});
  const { toastError } = useToast();
  const tenantId = authService.getTenantId();

  useEffect(() => {
    const fetchId = async () => {
      const resp = await campaignService.getCharityDetail(match.params.id);
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        history.push(ADMIN_PORTAL_URL.USER.URL);
      } else {
        const { name, description, charityId, startDate, endDate, logoPath, campaignUrl } = resp;
        setCampaign({
          name,
          description,
          charityId,
          startDate,
          endDate,
          logoPath,
          campaignUrl
        });
        setLoading(false);
      }
    };
    fetchId();
  }, [history, match.params.id, toastError]);

  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <h3 className="fr-page-title"> </h3>
        <div className="fr-page-container" style={{ minHeight: '400px' }}>
          <Container>
            <CampaignForm
              purpose={PURPOSE.EDIT}
              campaignInfo={campaign}
              id={match.params.id}
              charityId={tenantId}
              redirectUrl={ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS.URL}
              backUrl={ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS.URL}
            />
          </Container>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(AdminPortalCampaignEdit);
