import ShopVendor from '.';
import ShopVendorList from './list';
import ShopVendorDetail from './detail';
import ShopVendorFunrayzrCard from './funrayzrCard';
import ShopVendorRayzrCard from './rayzrCard';
import { SHOP_VENDOR_URL } from './url';

export const SHOP_VENDOR_ROUTES = [
  {
    name: SHOP_VENDOR_URL.SHOP_VENDOR.NAME,
    path: SHOP_VENDOR_URL.SHOP_VENDOR.URL,
    component: ShopVendor,
    isPrivate: false
  },
  {
    name: SHOP_VENDOR_URL.SHOP_VENDOR_LIST.NAME,
    path: SHOP_VENDOR_URL.SHOP_VENDOR_LIST.URL,
    component: ShopVendorList,
    isPrivate: false
  },
  {
    name: SHOP_VENDOR_URL.DETAIL.NAME,
    path: SHOP_VENDOR_URL.DETAIL.URL,
    component: ShopVendorDetail,
    isPrivate: false
  },
  {
    name: SHOP_VENDOR_URL.FUNRAYZR_CARD.NAME,
    path: SHOP_VENDOR_URL.FUNRAYZR_CARD.URL,
    component: ShopVendorFunrayzrCard,
    isPrivate: false
  },
  {
    name: SHOP_VENDOR_URL.RAYZR_CARD.NAME,
    path: SHOP_VENDOR_URL.RAYZR_CARD.URL,
    component: ShopVendorRayzrCard,
    isPrivate: false
  }
];
