import React from 'react';
import { FormControlLabel, Switch as MSwitch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { theme } from 'constants/theme';

const IOSSwitch = styled((props) => (
  <MSwitch focusVisibleClassName=".Mui-focusVisible" {...props} />
))(({ theme: themee }) => ({
  width: 52,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.colors.blue.rbc,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.colors.green.main,
      border: `6px solid ${theme.colors.white.background}`
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.colors.gray.switch
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: themee.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: themee.transitions.create(['background-color'], {
      duration: 500
    })
  }
}));

const Switch = ({ label, checked, ...rest }) => {
  return (
    <FormControlLabel
      className="m-0"
      control={<IOSSwitch sx={{ m: 1 }} />}
      label={label}
      labelPlacement="start"
      checked={checked}
      {...rest}
    />
  );
};

export default Switch;
