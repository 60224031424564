export const FORGOT_PASSWORD_URL = {
  FORGOT_PASSWORD: {
    URL: '/forgot-password',
    NAME: 'Forgot Password'
  },
  FORGOT_PASSWORD_SUCCESS: {
    URL: '/forgot-password-success',
    NAME: 'Forgot Password Success'
  }
};
