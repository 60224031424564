import Cart from '.';
import { CART_URL } from './url';

export const CART_ROUTES = [
  {
    name: CART_URL.CART.NAME,
    path: CART_URL.CART.URL,
    component: Cart,
    isPrivate: false
  }
];
