import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FR_APP_KEY } from 'configs';
import { CARD_TYPE } from 'constants/index';
import { theme } from 'constants/theme';
import React, { useMemo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import imageHelper from 'utils/image';

const useStyles = makeStyles(() => ({
  vendorContainer: {
    padding: '10px'
  },
  vendorLogo: {
    minHeight: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      maxWidth: '120px'
    }
  },
  vendorInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& .display-name, .address': {
      color: theme.colors.black.text,
      fontFamily: `${theme.fontFamily.quicksandMedium} !important`,
      textAlign: 'center'
    },
    '& .display-name': {
      fontSize: '16px'
    },
    '& .address': {
      fontSize: '12px'
    }
  },
  campaignContainer: {
    padding: '10px 0',
    background: 'rgba(255, 255, 255, .19)'
  },
  logoContainer: {
    '& .logo-img': {
      maxWidth: '80px',
      maxHeight: '100%',
      borderRadius: '5px',
      '@media(max-width: 414px)': {
        maxWidth: '60px'
      }
    },
    '& .box': {
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      background: theme.colors.white.background,
      borderRadius: '8px',
      padding: '8px',
      height: '82px'
    },
    '& .plus': {
      color: theme.colors.violet.text,
      fontWeight: 'bold'
    }
  },
  campaignNameBox: {
    minWidth: '75px',
    maxWidth: '75px',
    minHeight: '100%',
    background: theme.colors.white.background,
    textAlign: 'center',
    color: theme.colors.violet.text,
    fontSize: '12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '4',
    '-webkit-box-orient': 'vertical'
  }
}));

const ShopVendorCard = ({ vendor, charity, campaign = {}, cardType }) => {
  const { vendorLogo, vendorInfo, campaignContainer, logoContainer, campaignNameBox } = useStyles();

  const defaultLogoPath = imageHelper.getLogo();

  const vendorRender = useMemo(() => {
    const { displayName, address, logoPath } = vendor ?? {};
    return (
      <>
        <Col xs={7} className={vendorLogo}>
          <img
            src={logoPath ?? defaultLogoPath}
            alt={displayName ?? FR_APP_KEY}
            loading="lazy"
            className="img-fluid rounded"
          />
        </Col>
        <Col xs={5} className={vendorInfo}>
          <p className="display-name mb-0">{displayName}</p>
          <p className="address mb-0">{address}</p>
        </Col>
      </>
    );
  }, [defaultLogoPath, vendor, vendorInfo, vendorLogo]);

  const campaignLogoRender = useMemo(() => {
    const { logoPath: campaignLogoPath, name } = campaign;
    if (!campaignLogoPath && name) {
      return (
        <>
          <div className={campaignNameBox}>
            <div title={name}>{name}</div>
          </div>
        </>
      );
    }

    return (
      <img
        src={campaignLogoPath || defaultLogoPath}
        alt={name || FR_APP_KEY}
        loading="lazy"
        className="logo-img"
      />
    );
  }, [campaign, campaignNameBox, defaultLogoPath]);

  const campaignRender = useMemo(() => {
    if (!campaign || !charity) {
      return <></>;
    }

    const { logoPath: charityLogoPath, name } = charity;

    return (
      <div>
        <Row className={`${campaignContainer} mt-3`}>
          <Col xs={7} className={logoContainer}>
            <div className="box">
              <img
                src={charityLogoPath || defaultLogoPath}
                alt={name || FR_APP_KEY}
                loading="lazy"
                className="logo-img"
              />
              <span className="plus">+</span>
              {campaignLogoRender}
            </div>
          </Col>
          <Col xs={5} className="d-flex align-items-center">
            <img
              src={CARD_TYPE[cardType] ?? defaultLogoPath}
              alt={FR_APP_KEY}
              loading="lazy"
              className="img-fluid"
            />
          </Col>
        </Row>
      </div>
    );
  }, [
    campaign,
    campaignContainer,
    campaignLogoRender,
    cardType,
    charity,
    defaultLogoPath,
    logoContainer
  ]);

  return (
    <>
      <Paper elevation={3} className="c-card-item">
        <Container>
          <Row>{vendorRender}</Row>
          {campaignRender}
        </Container>
      </Paper>
    </>
  );
};

export default ShopVendorCard;
