import React from 'react';
import { Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import TabUrl from 'components/tabUrl';
import PropTypes from 'prop-types';

export const SummaryTab = {
  name: ADMIN_PORTAL_URL.VENDOR_VIEW_SUMMARY.NAME,
  value: 'summary'
};

export const UserManagementTab = {
  name: ADMIN_PORTAL_URL.VENDOR_VIEW_USERS.NAME,
  value: 'user_management'
};

export const InformationTab = {
  name: ADMIN_PORTAL_URL.VENDOR_VIEW_INFORMATION.NAME,
  value: 'information'
};

export const CardTransactionTab = {
  name: ADMIN_PORTAL_URL.VENDOR_VIEW_CARD_TRANSACTION.NAME,
  value: 'card_transaction'
};

export const VendorReportingTab = {
  name: ADMIN_PORTAL_URL.VENDOR_VIEW_REPORTING.NAME,
  value: 'reporting'
};

const AdminPortalVendorView = ({ vendorTab, children, className }) => {
  const { vid } = useParams();

  const vendorTabs = [
    {
      ...SummaryTab,
      url: ADMIN_PORTAL_URL.VENDOR_VIEW_SUMMARY.URL.replace(':vid', vid)
    },
    {
      ...UserManagementTab,
      url: ADMIN_PORTAL_URL.VENDOR_VIEW_USERS.URL.replace(':vid', vid)
    },
    {
      ...InformationTab,
      url: ADMIN_PORTAL_URL.VENDOR_VIEW_INFORMATION.URL.replace(':vid', vid)
    },
    {
      ...CardTransactionTab,
      url: ADMIN_PORTAL_URL.VENDOR_VIEW_CARD_TRANSACTION.URL.replace(':vid', vid)
    },
    {
      ...VendorReportingTab,
      url: ADMIN_PORTAL_URL.VENDOR_VIEW_REPORTING.URL.replace(':vid', vid)
    }
  ];

  return (
    <>
      <div className="fr-container pb-40 fr-bg-admin quicksand">
        <Container>
          <div
            className={`tab-background fr-page-container bg-white mt-5 ${className}`}
            style={{ minHeight: '400px' }}
          >
            <TabUrl tabs={vendorTabs} tab={vendorTab}>
              {children}
            </TabUrl>
          </div>
        </Container>
      </div>
    </>
  );
};

AdminPortalVendorView.propTypes = {
  vendorTab: PropTypes.oneOf([
    SummaryTab.value,
    UserManagementTab.value,
    InformationTab.value,
    CardTransactionTab.value,
    VendorReportingTab.value
  ]).isRequired
};

export default AdminPortalVendorView;
