import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BUCKET, EDIT_TYPE } from 'constants/index';
import vendorService from 'services/vendor';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import useLoading from 'components/loading/useLoading';
import { gS } from 'constants/helperMessage';
import fileService from 'services/file';
import { CircularProgress } from '@mui/material';
import ManageEditVendorInfo from '../vendorInfo/manageEdit';

const AdminPortalVendorInformationForm = ({ vendorId, notFoundRedirectUrl, redirectUrl }) => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [vendorFetched, setVendorFetched] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [logo, setLogo] = useState();

  const history = useHistory();
  const { toastSuccess, toastError } = useToast();
  const [showLoading, hideLoading] = useLoading();

  useEffect(() => {
    const fetchId = async () => {
      const resp = await vendorService.getVendorDetail(vendorId);
      if (resp?.errorCode) {
        if (notFoundRedirectUrl) {
          history.push(notFoundRedirectUrl);
        }
      } else {
        const {
          legalName,
          amount = '0',
          website,
          description,
          logoPath,
          address = {},
          addressLine1 = address.addressLine1,
          addressLine2 = address.addressLine2,
          city = address.city,
          province = address.province,
          postalCode = address.postalCode,
          displayName,
          businessNumber,
          categoryId,
          regionId,
          institutionNumber,
          transitNumber,
          accountNumber,
          primaryContact = {},
          charityRate
        } = resp;

        setLogo(logoPath && `${fileService._baseUrl}/${BUCKET.VENDOR}/${logoPath}`);

        setVendorFetched({
          displayName,
          businessNumber,
          categoryId,
          regionId,
          institutionNumber,
          transitNumber,
          accountNumber,
          logoPath,
          primaryContact
        });

        setInitialValues({
          legalName,
          cardAmount: amount === '0' ? '' : amount.replace(/ /g, ''),
          website,
          description: description ?? '',
          logoPath: logoPath ?? '',
          addressLine1: addressLine1 ?? '',
          addressLine2,
          city,
          province,
          postalCode: postalCode ?? '',
          charityRate
        });
      }
      setLoading(false);
    };
    fetchId();
  }, [hideLoading, history, notFoundRedirectUrl, showLoading, toastError, vendorId]);

  const onSubmit = useCallback(
    async (values) => {
      setIsDisabled(true);
      showLoading();
      const {
        legalName,
        cardAmount,
        logoPath,
        website,
        description,
        addressLine1,
        addressLine2,
        city,
        province,
        postalCode,
        charityRate
      } = values;

      let logoKey = '';

      if (typeof logoPath === 'object') {
        const respImg = await fileService.createBucket(BUCKET.VENDOR, logoPath);
        if (respImg?.errorCode) {
          toastError(respImg?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        }
        logoKey = respImg.key;
      }
      const vendor = {
        ...vendorFetched,
        legalName,
        cardAmount,
        website,
        description,
        logoPath: logoKey || vendorFetched.logoPath,
        address: { addressLine1, addressLine2, city, province, postalCode },
        editType: EDIT_TYPE.BY_MANAGER,
        charityRate
      };

      const resp = await vendorService.editVendor(vendorId, vendor);
      hideLoading();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        setInitialValues({
          legalName,
          cardAmount,
          website,
          description: description ?? '',
          logoPath: logoPath ?? '',
          addressLine1: addressLine1 ?? '',
          addressLine2,
          city,
          province,
          postalCode: postalCode ?? '',
          charityRate
        });
        toastSuccess(gS('Edit vendor information'));
        if (redirectUrl) {
          history.push(redirectUrl);
        }
      }
      setIsDisabled(false);
    },
    [
      hideLoading,
      history,
      redirectUrl,
      showLoading,
      toastError,
      toastSuccess,
      vendorFetched,
      vendorId
    ]
  );

  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <ManageEditVendorInfo
      vendorId={vendorId}
      initialValues={initialValues}
      onSubmit={onSubmit}
      isDisabled={isDisabled}
      logo={logo}
      setLogo={setLogo}
    />
  );
};

export default AdminPortalVendorInformationForm;
