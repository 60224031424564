import { useCallback } from 'react';
import useLayoutContext from 'layout/useLayoutContext';

const useLoading = () => {
  const { dispatch } = useLayoutContext();

  const showLoading = useCallback(() => dispatch({ type: 'showLoading' }), [dispatch]);

  const hideLoading = useCallback(() => dispatch({ type: 'hideLoading' }), [dispatch]);

  return [showLoading, hideLoading];
};

export default useLoading;
