import { mapError } from "./errorMapping";
import { mapPage } from "./pageMapping";

export function mapResponse(response, isPagingable) {
  const { data, status } = response;
  switch (status) {
    case 200:
      if (isPagingable) {
        return mapPage(data);
      }
      return data;
    case 204:
      return data;
    default:
      return mapError(response);
  }
}
