import React, { useEffect, useState } from 'react';
import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import { SHOP_VENDOR_URL } from 'pages/shopVendor/url';

const useStyles = makeStyles(() => ({
  colCharity: {
    maxWidth: '30%',
    '@media (max-width: 576px)': {
      maxWidth: '100%'
    }
  }
}));

const LocalTab = ({ vendor }) => {
  const [data, setData] = useState([]);
  const { push } = useHistory();

  const { colCharity } = useStyles();

  useEffect(() => {
    if (!vendor.amount) return;
    if (vendor.amount === '0') return;

    setData(vendor.amount);
  }, [vendor]);

  return (
    <div>
      <h5 className="text-12 mb-4">Available eGift card</h5>
      <Container fluid>
        <ul className="row justify-content-between py-0 px-3 list-style-none">
          {data.map((value) => (
            <li
              key={value}
              xs={12}
              md={4}
              className={`${colCharity} mb-4 p-0 col-xs-12 col-md-4 pointer`}
            >
              <Paper
                onClick={() => {
                  push(
                    SHOP_VENDOR_URL.RAYZR_CARD.URL.replace(':vendorId', vendor?.id).replace(
                      ':amount',
                      value
                    )
                  );
                }}
                elevation={3}
                className="py-4 px-3 d-flex flex-column justify-content-center align-items-center rounded-9"
              >
                <p className="m-0 pb-3">{vendor?.displayName}</p>
                <img
                  src={vendor?.logoSrc}
                  alt={value}
                  className="img-fluid"
                  loading="lazy"
                  style={{ maxHeight: '80px' }}
                />
                <p className="m-0 pt-3">{vendor?.addressLine1}</p>
              </Paper>
              <p className="font-weight-bolder mt-3 mb-2 text-center">${value}</p>
            </li>
          ))}
          <li xs={12} md={4} className={`${colCharity} mb-4 p-0 col-xs-12 col-md-4`} />
        </ul>
      </Container>
    </div>
  );
};

export default LocalTab;
