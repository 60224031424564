import Register from '.';
import RegisterConfirmationFailed from './registerConfirmationFailed';
import RegisterSuccess from './registerSuccess';
import RegisterConfirmation from './registerConfirmation';
import { REGISTER_URL } from './url';
import RegisterVendor from './registerVendor';
import RegisterVendorSuccess from './registerVendorSuccess';
import RegisterCharity from './registerCharity';
import RegisterCharitySuccess from './registerCharitySuccess';

export const REGISTER_ROUTES = [
  {
    name: REGISTER_URL.REGISTER.NAME,
    path: REGISTER_URL.REGISTER.URL,
    component: Register,
    isPrivate: false
  },
  {
    name: REGISTER_URL.REGISTER_SUCCESS.NAME,
    path: REGISTER_URL.REGISTER_SUCCESS.URL,
    component: RegisterSuccess,
    isPrivate: false
  },
  {
    name: REGISTER_URL.REGISTER_CONFIRMATION_FAILED.NAME,
    path: REGISTER_URL.REGISTER_CONFIRMATION_FAILED.URL,
    component: RegisterConfirmationFailed,
    isPrivate: false
  },
  {
    name: REGISTER_URL.REGISTER_CONFIRMATION.NAME,
    path: REGISTER_URL.REGISTER_CONFIRMATION.URL,
    component: RegisterConfirmation,
    isPrivate: false
  },
  {
    name: REGISTER_URL.REGISTER_VENDOR.NAME,
    path: REGISTER_URL.REGISTER_VENDOR.URL,
    component: RegisterVendor,
    isPrivate: false
  },
  {
    name: REGISTER_URL.REGISTER_VENDOR_SUCCESS.NAME,
    path: REGISTER_URL.REGISTER_VENDOR_SUCCESS.URL,
    component: RegisterVendorSuccess,
    isPrivate: false
  },
  {
    name: REGISTER_URL.REGISTER_CHARITY.NAME,
    path: REGISTER_URL.REGISTER_CHARITY.URL,
    component: RegisterCharity,
    isPrivate: false
  },
  {
    name: REGISTER_URL.REGISTER_CHARITY_SUCCESS.NAME,
    path: REGISTER_URL.REGISTER_CHARITY_SUCCESS.URL,
    component: RegisterCharitySuccess,
    isPrivate: false
  }
];
