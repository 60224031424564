import Api from 'api/tenant/api';
import { FUNRAYZR_API_URL } from 'configs';
import AxiosWrapper from 'utils/axios';

export class TenantService {
  constructor() {
    this._baseUrl = `${FUNRAYZR_API_URL}/Tenants`;
    const axios = AxiosWrapper();
    this._categoryApi = Api(axios);
  }

  getTenants() {
    return this._categoryApi.getTenants();
  }
}

const tenantService = new TenantService();

export default tenantService;
