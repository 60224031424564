import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Loading from 'components/loading';
import useToast from 'components/toast/useToast';
import { useHistory } from 'react-router-dom';
import urlHelper from 'utils/url';
import authService from 'services/authorization';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { Container } from 'reactstrap';
import { VERIFICATION } from 'constants/index';
import ResetPasswordForm from 'components/resetPasswordForm';
import useLoading from 'components/loading/useLoading';
import { RESET_PASSWORD_URL } from './url';

const ResetPassword = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [verifyCode, setVerifyCode] = useState('');
  const [email, setEmail] = useState('');
  const [purpose, setPurpose] = useState('');
  const [showLoading, hideLoading] = useLoading();
  const history = useHistory();
  const { toastError } = useToast();

  const setResetVerificationState = useCallback(
    (verifyRs) => {
      if (verifyRs) {
        setVerifyCode(verifyRs);
      } else {
        history.push(RESET_PASSWORD_URL.RESET_PASSWORD_FAILED.URL);
      }
    },
    [history]
  );

  useEffect(() => {
    let isMounted = false;
    const resetVerification = async () => {
      if (isMounted) {
        const code = urlHelper.getSearchParam(VERIFICATION.CODE);
        setResetVerificationState(code);
        const e = urlHelper.getSearchParam(VERIFICATION.EMAIL);
        setEmail(e);
        const pur = urlHelper.getSearchParam(VERIFICATION.PURPOSE);
        setPurpose(pur);
      }
    };
    if (!verifyCode) {
      isMounted = true;
      if (isMounted) {
        resetVerification();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [setResetVerificationState, verifyCode]);

  const handleOnSubmit = useCallback(
    async (values) => {
      const { password } = values;
      setIsDisabled(true);
      showLoading();
      const resetResponse = await authService.setNewPassword(email, password, verifyCode, purpose);
      if (resetResponse?.errorCode) {
        toastError(resetResponse.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        history.push(RESET_PASSWORD_URL.RESET_PASSWORD_SUCCESS.URL);
      }
      setIsDisabled(false);
      hideLoading();
    },
    [email, hideLoading, history, purpose, showLoading, toastError, verifyCode]
  );

  const verifyLoading = useMemo(() => {
    if (verifyCode) {
      return null;
    }
    return <Loading loading />;
  }, [verifyCode]);

  return (
    <>
      <div className="fr-background fr-box-container">
        <div className="fr-box">
          <Container>
            <div className="fr-page-container fr-content">
              <h3 className="fr-content-title">{RESET_PASSWORD_URL.RESET_PASSWORD.NAME}</h3>
              <div>
                <ResetPasswordForm onSubmit={handleOnSubmit} submitDisabled={isDisabled} />
              </div>
              <div>{verifyLoading}</div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
