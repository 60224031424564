import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import campaignService from 'services/charity/campaign';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import * as Yup from 'yup';
import useLoading from 'components/loading/useLoading';
import useToast from 'components/toast/useToast';
import Button from 'components/button';
import Input from 'components/input';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { gR, gS, gMax } from 'constants/helperMessage';
import { PURPOSE, BUCKET } from 'constants/index';
import { theme } from 'constants/theme';
import UnsavedPopup from 'pages/home/unsavedPopup';
import { ERROR_CODES } from 'utils/axios/helper';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ImageUpload from 'components/imageUpload';
import fileService from 'services/file';

const useStyles = makeStyles(() => ({
  border: {
    height: '1px',
    background: theme.colors.gray.border,
    margin: '20px 24px 20px 24px'
  },
  paddingCol: {
    padding: '10px 39px 10px 39px'
  },
  select: {
    maxWidth: '150px'
  }
}));

const CampaignForm = ({ purpose, campaignInfo, id, charityId, redirectUrl, backUrl }) => {
  const [logo, setLogo] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const history = useHistory();
  const [showLoading, hideLoading] = useLoading();
  const { toastSuccess, toastError } = useToast();
  const { border, paddingCol } = useStyles();
  const isAdd = purpose === PURPOSE.ADD;
  const message = isAdd ? 'Created campaign' : 'Edited campaign';
  const initialDate = isAdd ? [null, null] : [campaignInfo.startDate, campaignInfo.endDate];
  const [inputDate, setInputDate] = useState(initialDate);
  const [imageError, setImageError] = useState(false);
  const checkLogoValid = useCallback((data) => {
    setImageError(data);
  }, []);
  const initialValues = {
    name: '',
    description: '',
    startDate: '',
    endDate: '',
    charityId,
    logoPath: '',
    campaignUrl: ''
  };

  const handleOnSubmit = useCallback(
    async (values) => {
      values.startDate = moment
        .utc(values.startDate)
        .add(23, 'hours')
        .add(59, 'minutes')
        .add(59, 'seconds')._d;

      setIsDisabled(true);
      showLoading();
      let logoKey = '';
      const { logoPath } = values;
      if (typeof logoPath === 'object') {
        const respImg = await fileService.createBucket(BUCKET.CAMPAIGN, logoPath);
        if (respImg?.errorCode) {
          toastError(respImg?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
          hideLoading();
          return;
        }
        logoKey = respImg.key;
      }

      const resp = isAdd
        ? await campaignService.createCampaign({
          ...values,
          logoPath: logoKey
        })
        : await campaignService.editCampaign(id, {
          ...values,
          logoPath: logoKey || campaignInfo?.logoPath
        });
      hideLoading();

      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        setIsDisabled(false);
        return;
      }

      if (ERROR_CODES.includes(resp?.status)) {
        toastError(resp?.statusText || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        setIsDisabled(false);
        return;
      }

      history.push(redirectUrl);

      toastSuccess(gS(message));
    },
    [
      campaignInfo?.logoPath,
      hideLoading,
      history,
      id,
      isAdd,
      message,
      redirectUrl,
      showLoading,
      toastError,
      toastSuccess
    ]
  );

  if (campaignInfo?.logoPath && !logo) {
    setLogo(`${fileService._baseUrl}/${BUCKET.CAMPAIGN}/${campaignInfo.logoPath}`);
  }

  const formik = useFormik({
    initialValues: isAdd ? initialValues : campaignInfo,
    validationSchema: Yup.object({
      name: Yup.string().required(gR('Campaign Name')).max(255, gMax('Campaign Name', 255)),
      startDate: Yup.date().typeError('Wrong date format.').nullable().required(gR('Start Date')),
      endDate: Yup.date()
        .typeError('Wrong date format.')
        .nullable()
        .required(gR('End Date'))
        .min(Yup.ref('startDate'), () => 'End Date must be greater than Start Date.')
        .min(Date(Date.now()), 'End Date must be greater than today'),
      campaignUrl: Yup.string().url(ERROR_MESSAGE.WEBSITE.VALID)
    }),
    onSubmit: handleOnSubmit
  });

  useEffect(() => {
    // eslint-disable-next-line prefer-destructuring
    formik.values.startDate = inputDate[0];
    // eslint-disable-next-line prefer-destructuring
    formik.values.endDate = inputDate[1];
  }, [inputDate, formik.values]);

  const logoPathRender = useMemo(() => {
    return (
      <ImageUpload
        label="Logo"
        setPreview={setLogo}
        name="logoPath"
        logoSrc={{ preview: logo }}
        onChange={formik.handleChange}
        checkError={checkLogoValid}
        error={formik.touched.logoPath && !!formik.errors.logoPath}
        helperText={formik.touched.logoPath && formik.errors.logoPath}
      />
    );
  }, [checkLogoValid, formik.errors.logoPath, formik.handleChange, formik.touched.logoPath, logo]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center pt-4 mx-4">
              {
                <Link className="flex-1 text-decoration-none" to={backUrl}>
                  <h2 className="h6 text-teritary font-weight-bold flex-1 m-0">Campaigns</h2>
                </Link>
              }
              {isAdd && <div className="flex-1 text-center text-12">Add Campaign</div>}
              <div className="d-flex  flex-1 justify-content-end align-items-center">
                {!isAdd && <h2 className="h6 text-tertiary font-weight-bold m-0">Edit Campaign</h2>}
                <Button
                  disabled={isDisabled || imageError}
                  className="mx-3 text-white-hover"
                  size="small"
                  color="tertiary"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className={border} />
          </Col>
          <Col xs={12} className={`${paddingCol} mt-1 text-12`}>
            Campaign Details
          </Col>
          <Col xs={12} className={paddingCol}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Campaign Name*"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Col>
          <Col xs={12} className={paddingCol}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Description (this will show on the vendor page)"
              multiline
              rows={4}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              error={formik.touched.description && !!formik.errors.description}
              helperText={formik.touched.description && formik.errors.description}
            />
          </Col>
          <Col xs={12} className={`${paddingCol} ${isAdd && 'mb-4'}`}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateRangePicker
                startText="Start Date*"
                size="small"
                endText="End Date*"
                value={inputDate}
                onChange={(newValue) => {
                  setInputDate(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      {...startProps}
                      color="tertiary"
                      error={formik.touched.startDate && !!formik.errors.startDate}
                      helperText={formik.touched.startDate && formik.errors.startDate}
                      size="small"
                    />
                    <span className="mr-4" />
                    <TextField
                      {...endProps}
                      color="tertiary"
                      error={formik.touched.endDate && !!formik.errors.endDate}
                      helperText={formik.touched.endDate && formik.errors.endDate}
                      size="small"
                    />
                  </>
                )}
              />
            </LocalizationProvider>
          </Col>
          <Col xs={12} className={paddingCol}>
            {logoPathRender}
          </Col>
          <Col xs={12} className={`${paddingCol} my-4`}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Campaign URL"
              name="campaignUrl"
              onChange={formik.handleChange}
              value={formik.values.campaignUrl}
              error={formik.touched.campaignUrl && !!formik.errors.campaignUrl}
              helperText={formik.touched.campaignUrl && formik.errors.campaignUrl}
            />
          </Col>
        </Row>
      </form>
      <UnsavedPopup
        values={formik.values}
        initialValues={formik.initialValues}
        isSubmit={isDisabled}
        resetForm={formik.resetForm}
      />
    </>
  );
};

export default CampaignForm;
