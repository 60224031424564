import React, { useCallback, useState, useMemo, useEffect, useRef, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LoginMenu from 'components/userMenu/loginMenu';
import { ABOUT_URL } from 'pages/about/url';
import { SHOP_VENDOR_URL } from 'pages/shopVendor/url';
import { CHARITY_URL } from 'pages/charity/url';
import { LANDING_URL } from 'pages/landing/url';
import { APP_KEY, STORAGE_KEY } from 'constants/index';
import { FR_APP_KEY, FUNRAYZR_URL, RAYZR_URL } from 'configs';
import urlHelper from 'utils/url';
import authService from 'services/authorization';
import imageHelper from 'utils/image';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import { CART_URL } from 'pages/cart/url';
import { getIsFunRayZR, isMobileByWatchMedia } from 'utils/helper';
import Button from 'components/button';
import Select from 'components/select';
import publicService from 'services/public';
import { LayoutContext } from './layoutContext';

const ALL_REGION = 'all';

const NavMenu = () => {
  const isCancelled = useRef(false);
  const [collapsed, setCollapsed] = useState(true);
  const [cartTotalCount, setCartTotalCount] = useState(0);
  const [isAdminPortal, setIsAdminPortal] = useState(
    urlHelper.isMatchUrl(ADMIN_PORTAL_URL.ADMIN_PORTAL.URL)
  );
  const [locationPath, setLocationPath] = useState(window.location.pathname);
  const { state } = useContext(LayoutContext);
  const [regions, setRegions] = useState([]);
  const [regionId, setRegionId] = useState('');

  const { listen } = useHistory();

  const isFunRayZR = getIsFunRayZR();
  const otherLogoPath = imageHelper.getOtherLogo();
  const isMobile = isMobileByWatchMedia();
  const toggleNavbar = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  useEffect(() => {
    if (!isCancelled.current) {
      setStateValue(window.location);
    }
    return () => {
      isCancelled.current = true;
    };
  }, []);

  useEffect(() => {
    listen(async (loca) => {
      const isAuthenticated = authService.isAuthenticated();
      if (isAuthenticated) {
        const isExpired = authService.isExpired();
        if (isExpired) {
          await authService.getAccessTokenSilent();
        }
      }
      if (!isCancelled.current) {
        setStateValue(loca);
      }
    });

    const fetchRegions = async () => {
      const resp = await publicService.getRegions();

      if (!isCancelled.current) {
        let rgs = [{ value: ALL_REGION, text: 'All Regions' }];
        if (Array.isArray(resp)) {
          rgs = [...rgs, ...resp];
        }
        setRegions(rgs);
        const sRg = localStorage.getItem(STORAGE_KEY.REGION) || ALL_REGION;
        const rg = rgs.find((f) => {
          return f.value === sRg;
        });
        const rId = rg?.value || rgs[0]?.value;
        localStorage.setItem(STORAGE_KEY.REGION, rId === ALL_REGION ? '' : rId);
        setRegionId(rId);
      }
    };

    fetchRegions();

    return () => {
      isCancelled.current = true;
    };
  }, [listen]);

  const setStateValue = (loca) => {
    const { pathname } = loca;
    const isAdmin = urlHelper.isMatchUrl(ADMIN_PORTAL_URL.ADMIN_PORTAL.URL, pathname);
    setIsAdminPortal(isAdmin);
    setLocationPath(pathname);
  };

  const handleCloseMenu = useCallback(() => {
    if (!collapsed) {
      setCollapsed(true);
    }
  }, [collapsed]);

  const externalView = useMemo(() => {
    if (isFunRayZR) {
      return (
        <NavbarBrand
          className="mr-0 text-center"
          tag={Link}
          target="_blank"
          rel="noopener noreferrer"
          to={{ pathname: isFunRayZR ? RAYZR_URL : FUNRAYZR_URL }}
        >
          <img
            className="hamburger-menu-logo"
            src={otherLogoPath}
            alt={APP_KEY[isFunRayZR ? 'RAY_ZR' : 'FUNRAY_ZR']}
            loading="lazy"
          />
        </NavbarBrand>
      );
    }
    return <></>;
  }, [isFunRayZR, otherLogoPath]);

  const handleChangeRegion = (e) => {
    const rId = e.target.value;
    setRegionId(rId);
    localStorage.setItem(STORAGE_KEY.REGION, rId === ALL_REGION ? '' : rId);
    window.location.reload(false);
  };

  const regionView = useMemo(() => {
    return (
      <NavItem className="fr-nav-item custom-mobile">
        <Select
          color="transparent"
          haveSearch
          width={!isMobile && 175}
          size="small"
          className="bg-white rounded c-vendor-list-category c-custom-select"
          name="role"
          value={regionId}
          data={regions}
          labelInput=""
          onChange={handleChangeRegion}
        />
      </NavItem>
    );
  }, [isMobile, regionId, regions]);

  const menuView = useMemo(() => {
    return (
      <>
        {!isAdminPortal && (
          <>
            <NavItem className="fr-nav-item">
              <NavLink
                tag={Link}
                className={`fr-nav-link ${urlHelper.getUrlStatus(
                  ABOUT_URL.ABOUT.URL,
                  locationPath
                )}`}
                to={ABOUT_URL.ABOUT.URL}
                onClick={handleCloseMenu}
              >
                {ABOUT_URL.ABOUT.NAME}
              </NavLink>
            </NavItem>
            <NavItem className="fr-nav-item">
              <NavLink
                tag={Link}
                className={`fr-nav-link ${urlHelper.getUrlStatus(
                  SHOP_VENDOR_URL.SHOP_VENDOR.URL,
                  locationPath
                )}`}
                to={
                  isFunRayZR
                    ? SHOP_VENDOR_URL.SHOP_VENDOR.URL
                    : SHOP_VENDOR_URL.SHOP_VENDOR_LIST.URL
                }
                onClick={handleCloseMenu}
              >
                {SHOP_VENDOR_URL.SHOP_VENDOR.NAME}
              </NavLink>
            </NavItem>
            {FR_APP_KEY === APP_KEY.FUNRAY_ZR && (
              <NavItem className="fr-nav-item">
                <NavLink
                  tag={Link}
                  className={`fr-nav-link ${urlHelper.getUrlStatus(
                    CHARITY_URL.CHARITY.URL,
                    locationPath
                  )}`}
                  to={CHARITY_URL.CHARITY.URL}
                  onClick={handleCloseMenu}
                >
                  {CHARITY_URL.CHARITY.NAME}
                </NavLink>
              </NavItem>
            )}
          </>
        )}
        <LoginMenu
          isAdminPortal={isAdminPortal}
          locationPath={locationPath}
          onMenuClick={handleCloseMenu}
        />
        {!isAdminPortal && regionView}
        {isMobile && externalView}
      </>
    );
  }, [
    isAdminPortal,
    locationPath,
    handleCloseMenu,
    isFunRayZR,
    regionView,
    isMobile,
    externalView
  ]);

  const logoView = useMemo(() => {
    const logoPath = imageHelper.getLogo();

    return (
      <NavbarBrand className="fr-navbar-brand" tag={Link} to={LANDING_URL.LANDING.URL}>
        <img src={logoPath} alt={FR_APP_KEY} loading="lazy" />
      </NavbarBrand>
    );
  }, []);

  const cart = useMemo(
    () => (
      <Button
        kind="icon"
        component={Link}
        to={CART_URL.CART.URL}
        className={isMobile ? 'mr-3' : 'mr-2'}
      >
        <ShoppingCartIcon color="primary" fontSize="large" />
        {!!cartTotalCount && <span className="fr-nav-right-cart-span">{cartTotalCount}</span>}
      </Button>
    ),
    [isMobile, cartTotalCount]
  );

  useEffect(() => {
    setCartTotalCount(() => {
      if (state?.cartTotalCount) return state.cartTotalCount;

      const productsLocal = JSON.parse(localStorage.getItem('products'));
      if (productsLocal?.cartDetails?.length) return productsLocal.cartDetails.length;

      const user = authService.getDecryptedUser();
      if (user?.cartTotalCount) return user.cartTotalCount;

      return 0;
    });
  }, [state?.cartTotalCount, locationPath]);

  return (
    <header className="fr-header">
      <Navbar className="navbar-expand-md navbar-toggleable-md fr-navbar " light fixed="top">
        <div className="fr-nav-left">{logoView}</div>
        <div className="fr-navbar-toggler">
          {isMobile && cart}
          <NavbarToggler onClick={toggleNavbar} />
        </div>
        <Collapse
          className="d-md-inline-flex flex-md-row-reverse fr-content"
          isOpen={!collapsed}
          navbar
        >
          <div className="container-lg container-md position-relative">
            <ul className={`navbar-nav fr-nav ${isAdminPortal ? 'fr-admin-nav' : ''}`}>
              {menuView}
            </ul>
          </div>
        </Collapse>
        <div className="fr-nav-right">
          {!isMobile && cart}
          {isFunRayZR && (
            <NavbarBrand
              className="fr-navbar-brand mr-0"
              tag={Link}
              target="_blank"
              rel="noopener noreferrer"
              to={{ pathname: RAYZR_URL }}
            >
              <img src={otherLogoPath} alt={APP_KEY.RAY_ZR} loading="lazy" />
            </NavbarBrand>
          )}
        </div>
      </Navbar>
    </header>
  );
};

export default NavMenu;
