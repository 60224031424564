import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

const QRCodeId = ({ id, data }) => {
  return <QRCode id={`qrCodeEl-${id}`} size={90} value={data} />;
};

QRCodeId.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.string.isRequired
};

export default QRCodeId;
