import React, { useEffect, useMemo, useState } from 'react';
import Button from 'components/button';
import { FR_APP_KEY } from 'configs';
import { APP_KEY, IMG_LOCAL } from 'constants/index';
import { theme } from 'constants/theme';
import { GLOBAL_ELEMENT } from 'constants/globalElement';
import { ABOUT_FUNRAYZR, ABOUT_RAYZR } from 'constants/globalContent';
import { SIGNIN_URL } from 'pages/signIn/url';
import { REGISTER_URL } from 'pages/register/url';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import imageHelper from 'utils/image';
import { isMobileByWatchMedia } from 'utils/helper';
import authService from 'services/authorization';
import useStyles from './styles';

const About = () => {
  const [authenticated, setAuthenticated] = useState(false);

  const classes = useStyles();

  const logoPath = imageHelper.getLogo(true);
  const isMobile = isMobileByWatchMedia();

  const element = document.getElementById(GLOBAL_ELEMENT.top_page);

  useEffect(() => {
    if (element) {
      element.scrollIntoView();
    }
  }, [element]);

  useEffect(() => {
    const isAuthenticated = authService.isAuthenticated();
    setAuthenticated(isAuthenticated);
  }, []);

  const authenticationView = useMemo(() => {
    const signInPath = `${SIGNIN_URL.SIGNIN.URL}`;
    const registerPath = `${REGISTER_URL.REGISTER.URL}`;

    return (
      <div className={classes.authenticationArea}>
        <div className={classes.buttonArea}>
          {!authenticated && (
            <Button
              component={Link}
              to={signInPath}
              kind="button"
              variant="contained"
              color="primary"
              bgHover={[theme.colors.orange.button, theme.colors.white.text]}
              colorOnClick={[theme.colors.darkcyan.button]}
              minWidth={130}
              size="large"
            >
              <span className="text-uppercase">{SIGNIN_URL.SIGNIN.NAME}</span>
            </Button>
          )}

          <Button
            component={Link}
            to={registerPath}
            kind="button"
            variant="contained"
            color="primary"
            bgHover={[theme.colors.orange.button, theme.colors.white.text]}
            colorOnClick={[theme.colors.darkcyan.button]}
            minWidth={130}
            className="text-uppercase"
            size="large"
          >
            <span className="text-uppercase">{REGISTER_URL.REGISTER.NAME}</span>
          </Button>
        </div>
      </div>
    );
  }, [authenticated, classes.authenticationArea, classes.buttonArea]);

  const aboutFunRayZrView = useMemo(() => {
    return (
      <>
        <div className="position-relative">
          <img className="w-100" src={IMG_LOCAL.FUNRAYZR_ABOUT_BG_1} loading="lazy" />
          <div className={`${classes.heroContent} flex-column`}>
            <img
              src={logoPath}
              alt={FR_APP_KEY}
              className={`${classes.funrayLogo} ${isMobile && 'm-0'}`}
              style={{ maxWidth: isMobile ? '150px' : '300px' }}
              loading="lazy"
            />
            <div>
              <h3 className={classes.heroTitle}>{ABOUT_FUNRAYZR.aboutFunRayZR}</h3>
              <h3 className={`mb-0 ${classes.heroText}`}>
                {ABOUT_FUNRAYZR.funRayZRHelpsLocal1} <br /> {ABOUT_FUNRAYZR.funRayZRHelpsLocal2}
              </h3>
            </div>
            <div />
          </div>
        </div>
        <div className="bg-infor-about rounded-9 c-about-funray">
          <Container>
            <Row className="py-4">
              <Col md="12">
                <div className={classes.textInfoAbout}>
                  <div>
                    <p className="mb-4">
                      {ABOUT_FUNRAYZR.atFunRayZR_textInfoAbout}
                    </p>

                    <p className="mb-4">
                      {ABOUT_FUNRAYZR.justLike_textInfoAbout1} <br />
                      {ABOUT_FUNRAYZR.justLike_textInfoAbout2} <br />
                      {ABOUT_FUNRAYZR.justLike_textInfoAbout3}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-danger rounded-9 c-about-funray">
          <Container>
            <Row className="py-4">
              <Col md="12">
                <div className={classes.textBox}>
                  <div>
                    <h3 className={classes.heroTitle}>
                      {ABOUT_FUNRAYZR.aboutFunRayZRTitle_textBox}
                    </h3>
                    <p>
                      {ABOUT_FUNRAYZR.eachYear_textBox}
                    </p>
                    <p>
                      {ABOUT_FUNRAYZR.justImagine_textBox1} <br /> {ABOUT_FUNRAYZR.justImagine_textBox2}
                    </p>

                    <p>
                      {ABOUT_FUNRAYZR.earn_textBox}
                    </p>
                    <p className="mb-3">
                      {ABOUT_FUNRAYZR.reachOut_textInfoAbout1} <br />
                      {ABOUT_FUNRAYZR.reachOut_textInfoAbout2}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-tertiary rounded-9 ">
          <Container>
            <Row className="py-4">
              <Col md="12">
                <div className={classes.textBox}>
                  <div>
                    <h3 className={classes.heroTitle}>{ABOUT_FUNRAYZR.localBusinessesTitle_textBox}</h3>

                    <p className="mb-3">
                      {ABOUT_FUNRAYZR.helpsLocal_textBox1} <br />
                      {ABOUT_FUNRAYZR.helpsLocal_textBox2} <br />
                      {ABOUT_FUNRAYZR.helpsLocal_textBox3} <br />
                      {ABOUT_FUNRAYZR.helpsLocal_textBox4}
                    </p>

                    <p className="mb-3">
                      {ABOUT_FUNRAYZR.because_localSmall_textBox} <br />
                      {ABOUT_FUNRAYZR.localSmall_textBox1} <br />
                      {ABOUT_FUNRAYZR.localSmall_textBox2}
                    </p>

                    <p className="mb-3">
                      {ABOUT_FUNRAYZR.provides_textBox1} <br />
                      {ABOUT_FUNRAYZR.provides_textBox2}
                    </p>

                    <p className="mb-3">
                      {ABOUT_FUNRAYZR.justLike_textBox1} <br />
                      {ABOUT_FUNRAYZR.justLike_textBox2}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-primary rounded-9">
          <Container>
            <Row className="py-4">
              <Col md="12">
                <div className={classes.textBox}>
                  <div>
                    <h3 className={classes.heroTitle}>{ABOUT_FUNRAYZR.consumersTitle_textBox}</h3>

                    <p className="mb-3">
                      {ABOUT_FUNRAYZR.helpsConsumers_textBox1} <br /> {ABOUT_FUNRAYZR.helpsConsumers_textBox2}
                    </p>
                    <p>
                      {ABOUT_FUNRAYZR.everyConsumers_textBox1} <br />
                      {ABOUT_FUNRAYZR.everyConsumers_textBox2} <br />
                      {ABOUT_FUNRAYZR.everyConsumers_textBox3} <br />
                      {ABOUT_FUNRAYZR.everyConsumers_textBox4}
                    </p>

                    <p className="mb-3">
                      {ABOUT_FUNRAYZR.because_shoppersConsumers_textBox} <br />
                      {ABOUT_FUNRAYZR.shoppersConsumers_textBox1} <br />
                      {ABOUT_FUNRAYZR.shoppersConsumers_textBox2}
                    </p>
                    <p className="mb-3">
                      {ABOUT_FUNRAYZR.usingConsumers_textBox1} <br />
                      {ABOUT_FUNRAYZR.usingConsumers_textBox2}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <Container>
            <Col md="12">{authenticationView}</Col>
          </Container>
        </div>
      </>
    );
  }, [
    authenticationView,
    isMobile,
    logoPath,
    classes.funrayLogo,
    classes.heroContent,
    classes.heroText,
    classes.heroTitle,
    classes.textInfoAbout,
    classes.textBox
  ]);

  const aboutRayZrView = useMemo(() => {
    return (
      <>
        <div className="position-relative">
          <img
            className="w-100"
            src={isMobile ? IMG_LOCAL.RAYZR_ABOUT_BG_2 : IMG_LOCAL.RAYZR_ABOUT_BG_PC}
            loading="lazy"
          />
          <div className={`${classes.heroContent} flex-column`}>
            <img
              src={logoPath}
              alt={FR_APP_KEY}
              className={`${classes.funrayLogo} ${isMobile && 'm-0'}`}
              style={{ maxWidth: isMobile ? '150px' : '300px' }}
              loading="lazy"
            />
            <div>
              <h3 className={classes.heroTitle}>{ABOUT_RAYZR.aboutRayZR}</h3>
              <h3 className={`mb-0 ${classes.heroText}`}>
                {ABOUT_RAYZR.rayZRHelpsLocal1} <br /> {ABOUT_RAYZR.rayZRHelpsLocal2}
              </h3>
            </div>
            <div />
          </div>
        </div>
        <div className="bg-primary rounded-9 c-about-funray">
          <Container>
            <Row className="py-4">
              <Col md="12">
                <div className={classes.textBox}>
                  <div>
                    <h3 className={classes.heroTitle}>{ABOUT_RAYZR.here_rayZR}</h3>

                    <p className="mb-0">
                      {ABOUT_RAYZR.each_rayZR}
                    </p>
                    <p>{ABOUT_RAYZR.includes_rayZR}</p>

                    <p className="mb-0">
                      {ABOUT_RAYZR.allows_rayZR1}
                    </p>
                    <p>
                      {ABOUT_RAYZR.allows_rayZR2}
                    </p>

                    <p className="mb-0">
                      {ABOUT_RAYZR.just_rayZR}
                    </p>
                    <p>{ABOUT_RAYZR.much_rayZR}</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-info rounded-9 c-about-funray">
          <Container>
            <Row className="py-4">
              <Col md="12">
                <div className={classes.textBox}>
                  <div>
                    <h3 className={classes.heroTitle}>{ABOUT_RAYZR.card_rayZR}</h3>

                    <p>{ABOUT_RAYZR.fast_rayZR}</p>

                    <p className="mb-0">
                      {ABOUT_RAYZR.free_rayZR}
                    </p>
                    <p>
                      {ABOUT_RAYZR.reporting_rayZR}
                    </p>

                    <p className="mb-0">
                      {ABOUT_RAYZR.supporters_rayZR}
                    </p>
                    <p>
                      {ABOUT_RAYZR.even_rayZR}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bg-danger rounded-9 c-about-funray">
          <Container>
            <Row className="py-4">
              <Col md="12">
                <div className={classes.textBox}>
                  <div>
                    <h3 className={classes.heroTitle}>{ABOUT_RAYZR.contactless_rayZR}</h3>

                    <p className="mb-0">
                      {ABOUT_RAYZR.contactless1_rayZR}
                    </p>
                    <p>{ABOUT_RAYZR.handling_rayZR}</p>

                    <p>
                      {ABOUT_RAYZR.contactless2_rayZR}
                    </p>

                    <p>
                      {ABOUT_RAYZR.contactless3_rayZR}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <Container className="pb-4">
            <Col md="12">{authenticationView}</Col>
          </Container>
        </div>
      </>
    );
  }, [
    authenticationView,
    classes.funrayLogo,
    classes.heroContent,
    classes.heroText,
    classes.heroTitle,
    classes.textBox,
    isMobile,
    logoPath
  ]);

  const renderView = useMemo(() => {
    if (FR_APP_KEY === APP_KEY.RAY_ZR) {
      return aboutRayZrView;
    }
    return aboutFunRayZrView;
  }, [aboutFunRayZrView, aboutRayZrView]);

  return (
    <div className="fr-bg-admin pb-3" id={GLOBAL_ELEMENT.top_page}>
      <Container className="about-container">{renderView}</Container>
    </div>
  );
};

export default About;
