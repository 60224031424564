import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { theme } from 'constants/theme';
import Button from 'components/button';
import { Col, Row } from 'reactstrap';
import Input from 'components/input';
import { gR } from 'constants/helperMessage';

const ResetPasswordForm = ({ submitDisabled = false, onSubmit }) => {
  const initialResetValues = {
    password: '',
    confirmPassword: ''
  };

  const resetValidateSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, ERROR_MESSAGE.NEW_PASSWORD.VALID)
      .max(100, ERROR_MESSAGE.NEW_PASSWORD.VALID)
      .required(gR('New Password')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], ERROR_MESSAGE.CONFIRM_PASSWORD.NEW_VALID)
      .required(ERROR_MESSAGE.CONFIRM_PASSWORD.NEW_VALID)
  });

  const getResetFormError = (touched, errors) => {
    const password = touched.password && !!errors.password;
    const confirmPassword = touched.confirmPassword && !!errors.confirmPassword;

    return {
      password,
      confirmPassword
    };
  };

  const handleOnSubmit = useCallback(
    (values) => {
      if (onSubmit) {
        onSubmit(values);
      }
    },
    [onSubmit]
  );

  const formik = useFormik({
    initialValues: initialResetValues,
    validationSchema: resetValidateSchema,
    onSubmit: handleOnSubmit
  });

  const resetPasswordForm = useMemo(() => {
    const { touched, errors, values, handleSubmit, handleChange, handleBlur } = formik;
    const resetFormError = getResetFormError(touched, errors);

    return (
      <div className="mt-5">
        <form onSubmit={handleSubmit}>
          <Row className="my-3 py-5">
            <Col md={6} className="mb-4">
              <Input
                fullWidth
                name="password"
                type="password"
                label="New Password*"
                size="small"
                variant="outlined"
                error={resetFormError.password}
                helperText={resetFormError.password && errors.password}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
            <Col md={6} className="mb-4">
              <Input
                fullWidth
                name="confirmPassword"
                type="password"
                label="Confirm Password*"
                size="small"
                variant="outlined"
                error={resetFormError.confirmPassword}
                helperText={resetFormError.confirmPassword && errors.confirmPassword}
                value={values.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center">
              <Button
                wider
                size="large"
                type="submit"
                kind="button"
                variant="contained"
                color="primary"
                bgHover={[theme.colors.orange.button, theme.colors.white.text]}
                colorOnClick={[theme.colors.darkcyan.button]}
                disabled={submitDisabled}
              >
                CONFIRM
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    );
  }, [formik, submitDisabled]);

  return <>{resetPasswordForm}</>;
};

export default ResetPasswordForm;
