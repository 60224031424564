import { FR_APP_KEY } from 'configs';
import { APP_KEY } from 'constants/index';
import moment from 'moment';

const isEmptyObj = (obj) => {
  if (!obj) return true;
  return !Object.keys(obj).length;
};

const isHaveValues = (obj) => {
  if (!obj) return;
  return Object.values(obj).some((value) => value);
};

const getIsFunRayZR = () => {
  if (FR_APP_KEY === APP_KEY.RAY_ZR) return false;
  return true;
};

const isMobile = () => {
  const details = navigator.userAgent;
  const regexp = /android|iphone|kindle|ipad/i;

  return regexp.test(details);
};

const isMobileByWatchMedia = () => {
  if (!window?.matchMedia) return;
  return window.matchMedia('(max-width: 577px)').matches;
};

const isValidDate = (d) => {
  if (!d) return;
  return !Number.isNaN(d.getTime());
};

const getTotalPage = (items, size) => {
  if (items === 0) {
    return 0;
  }
  const total = Math.round(items / size);
  if (items / size > total) {
    return total + 1;
  }
  return total;
};

const getHasNextPage = (totalPages, pageIndex) => {
  return totalPages - 1 - pageIndex > 0;
};

const toUtc = (str) => {
  if (!str) {
    return null;
  }
  return str.indexOf('Z') !== -1 ? str : `${str}Z`;
};

const numberFormat = (num, digit = 0) => {
  if (!num) {
    return '0';
  }
  return num.toLocaleString(undefined, { minimumFractionDigits: digit });
};

const formatDate = (stringDate) => {
  const date = new Date(stringDate);
  return date.toLocaleString('en-US', {
    day: 'numeric',
    year: 'numeric',
    month: 'short'
  });
};

const formatDateMoment = (value) => {
  return moment(toUtc(value)).format('MMM, Do YYYY');
};

const printContent = (elementId, printTitle) => {
  // Create new window
  const printWindow = window.open('', 'Print', 'height=400,width=600');

  // Get head & content
  const cTitle = document.title;
  document.title = printTitle;
  const headInnerHtml = document.getElementsByTagName('head')[0].innerHTML;
  document.title = cTitle;
  let printHtml = document.getElementById(elementId).innerHTML;
  if (printTitle) {
    printHtml = `<h2 class="text-center mt-3">${printTitle}</h2>${printHtml}`;
  }
  // Write content to window
  printWindow.document.write(
    `<html><head>${headInnerHtml}</head><body class="px-3" style="min-height: auto;">${printHtml}</body></html>`
  );

  // Listen to load event and execute callback function when the document is fully loaded
  printWindow.addEventListener('load', () => {
    printWindow.print(false, null);

    const userAgent = navigator.userAgent.toLowerCase();
    const isSafari = userAgent.indexOf('safari') !== -1 && userAgent.indexOf('chrome') === -1;

    if (isSafari) {
      printWindow.onafterprint = () => {
        printWindow.close();
      };
    } else {
      setTimeout(() => {
        printWindow.close();
      }, 200);
    }
  });
  printWindow.document.close();
  printWindow.focus();
};

const isURL = (url) => {
  const regex = /^(http[s]?:\/\/)(www\.)?[^\s$.?#].[^\s]*$/;
  return regex.test(url);
};

export {
  isEmptyObj,
  isHaveValues,
  getIsFunRayZR,
  isMobile,
  isMobileByWatchMedia,
  isValidDate,
  getTotalPage,
  toUtc,
  numberFormat,
  getHasNextPage,
  formatDate,
  formatDateMoment,
  printContent,
  isURL
};
