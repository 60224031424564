import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import { Box, Paper, Typography, Tabs, Tab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SHOP_VENDOR_URL } from 'pages/shopVendor/url';
import { FR_APP_KEY } from 'configs';
import imageHelper from 'utils/image';
import { getIsFunRayZR, isURL } from 'utils/helper';
import CharityTab from './charityTab';
import LocalTab from './localTab';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box>
        <Typography component="div">{children}</Typography>
      </Box>
    )}
  </div>
);

const useStyles = makeStyles(() => ({
  tabs: {
    marginBottom: '48px',
    '@media (max-width: 576px)': {
      '& .MuiTabs-flexContainer': {
        flexDirection: 'column',
        alignItems: 'center'
      }
    },
    '& .Mui-selected': {
      opacity: '1 !important'
    },
    '& .MuiTab-root': {
      opacity: 0.5
    },
    '& .MuiTabs-indicator': {
      background: 'transparent'
    }
  }
}));

const ShopVendorDetailComponent = ({ vendor }) => {
  const history = useHistory();
  const [value, setValue] = useState(0);

  const { tabs } = useStyles();

  const logoPath = imageHelper.getLogo();
  const otherlogoPath = imageHelper.getOtherLogo();
  const isFunRayZR = getIsFunRayZR();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBackClick = () => {
    history.push(SHOP_VENDOR_URL.SHOP_VENDOR_LIST.URL);
  };

  return (
    <div className="fr-background pb-0 text-white quicksand">
      <Container fluid className="x-container p-0">
        <Row className="mx-0">
          <Col
            xs={12}
            md={3}
            className="white-bg-o2 px-0 py-sm-0 py-md-5 d-flex flex-column align-items-center c-shop-vendor-sidebar"
          >
            <div className="p-4 w-100 text-center violet-bg-o7">
              <span
                className="pointer"
                onKeyDown={handleBackClick}
                aria-hidden="true"
                onClick={handleBackClick}
              >
                &lt; Back to Vendors
              </span>
            </div>
            <div className="m-sm-0 m-md-5 p-4 text-center text-break">
              <img
                src={vendor.logoSrc}
                alt={FR_APP_KEY}
                className="img-fluid mw-85 rounded-9"
                loading="lazy"
              />
              <p className="m-0 pt-4">{vendor.displayName}</p>
              <p className="m-0">{vendor.addressLine1}</p>
              <p className="m-0">
                {vendor.city}, {vendor.province}, {vendor.postalCode}
              </p>
              {isURL(vendor.website) ? (
                <a href={vendor.website} target="blank" className="white-title text-white-hover">
                  <u>{vendor.website}</u>
                </a>
              ) : (
                <p className="m-0"><u>{vendor.website}</u></p>
              )}

              <p className="m-0 pt-4">{vendor.description}</p>
            </div>
          </Col>
          <Col xs={12} md={9} className="px-4 py-5">
            {!isFunRayZR && (
              <>
                <h5 className="text-12">Select the eGift card type</h5>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="icon position tabs example"
                  className={tabs}
                >
                  <Tab
                    icon={
                      <div>
                        <Paper elevation={3} className="py-3 px-4 rounded-9">
                          <img
                            src={logoPath}
                            alt={FR_APP_KEY}
                            style={{ maxHeight: 80 }}
                            className="img-fluid"
                            loading="lazy"
                          />
                        </Paper>
                        <p className="text-white mb-0 mt-2 h6">
                          {isFunRayZR ? "CHARITY'S" : 'LOCAL BUSINESS'} EGIFT CARD
                        </p>
                      </div>
                    }
                  />
                  <Tab
                    icon={
                      <div>
                        <Paper elevation={3} className="py-3 px-4 rounded-9">
                          <img
                            src={otherlogoPath}
                            alt={FR_APP_KEY}
                            style={{ maxHeight: 80 }}
                            className="img-fluid"
                            loading="lazy"
                          />
                        </Paper>
                        <p className="text-white mb-0 mt-2 h6">
                          {isFunRayZR ? 'LOCAL BUSINESS' : "CHARITY'S"} EGIFT CARD
                        </p>
                      </div>
                    }
                  />
                </Tabs>
              </>
            )}

            <TabPanel value={value} index={isFunRayZR ? 0 : 1}>
              <CharityTab vendorId={vendor.id} />
            </TabPanel>
            <TabPanel value={value} index={isFunRayZR ? 1 : 0}>
              <LocalTab vendor={vendor} />
            </TabPanel>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ShopVendorDetailComponent;
