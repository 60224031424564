import React from 'react';
import ContainerFooterLink from 'components/containerFooterLink';
import { SHIPPING_POLICY } from 'constants/globalContent';

const ShippingOnly = () => {
  return (
    <ContainerFooterLink title={SHIPPING_POLICY.title} id="shipping">
      <h2 className="h3 font-weight-bolder">{SHIPPING_POLICY.titleDesc}</h2>

      <p>{SHIPPING_POLICY.titleDescContent.p1}</p>

      <p>
        {SHIPPING_POLICY.titleDescContent.p2}
      </p>

      <p>{SHIPPING_POLICY.titleDescContent.p3}</p>

      <p>
        {SHIPPING_POLICY.titleDescContent.p4}
      </p>

      <p>
        {SHIPPING_POLICY.titleDescContent.p5}
      </p>

      <p>
        {SHIPPING_POLICY.titleDescContent.p6}
      </p>
    </ContainerFooterLink>
  );
};

export default ShippingOnly;
