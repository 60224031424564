import ShippingOnly from '.';

import { SHIPPING_ONLY_URL } from './url';

export const SHIPPING_ONLY_ROUTES = [
  {
    name: SHIPPING_ONLY_URL.SHIPPING_ONLY.NAME,
    path: SHIPPING_ONLY_URL.SHIPPING_ONLY.URL,
    component: ShippingOnly,
    isPrivate: false
  }
];
