import Api from 'api/cart/api';
import { FUNRAYZR_API_URL } from 'configs';
import AxiosWrapper from 'utils/axios';

export class CartService {
  constructor() {
    this._baseUrl = `${FUNRAYZR_API_URL}/Carts`;
    const axios = AxiosWrapper();
    this._cartApi = Api(axios);
  }

  getCart() {
    return this._cartApi.getCart();
  }

  createCart(cart) {
    return this._cartApi.createCart(cart);
  }

  editCart(cart) {
    return this._cartApi.editCart(cart);
  }

  deleteCartItem(id) {
    return this._cartApi.deleteCartItem(id);
  }
}

const cartService = new CartService();

export default cartService;
