import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DateRangePicker from 'components/dateRangePicker';
import Select from 'components/select';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { APP_KEY, CART_DETAILS_TYPE } from 'constants/index';
import { theme } from 'constants/theme';
import { Col, Container, Row } from 'reactstrap';
import vendorService from 'services/vendor';
import { numberFormat } from 'utils/helper';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';

const useStyles = makeStyles(() => ({
  titleContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: theme.fontSize.s13,
    textAlign: 'center',
    color: theme.colors.black.title,
    fontFamily: theme.fontFamily.quicksandMedium,
    marginLeft: '2px'
  },
  amount: {
    textAlign: 'center',
    fontSize: theme.fontSize.s30,
    color: theme.colors.gray.title,
    fontFamily: theme.fontFamily.quicksandBold
  },
  paper: {
    border: `solid 1px ${theme.colors.blue.rbc}`,
    minHeight: '100px',
    position: 'relative',
    padding: '1rem .5rem !important'
  },
  paperIcon: {
    color: theme.colors.blue.rbc
  },
  select: {
    maxWidth: '150px'
  }
}));

const cardTypes = [
  { text: 'All', value: '0' },
  { text: APP_KEY.FUNRAY_ZR, value: CART_DETAILS_TYPE.FUNRAY_ZR },
  { text: APP_KEY.RAY_ZR, value: CART_DETAILS_TYPE.RAY_ZR }
];

const SummaryForm = ({ tenantId }) => {
  const { toastError } = useToast();
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const initDate = [new Date(y, m, 1), new Date(y, m + 1, 0)];
  const [inputDate, setInputDate] = useState(initDate ?? [null, null]);
  const [cardType, setCardType] = useState(cardTypes[0].value);
  const [loading, setLoading] = useState(true);
  const [summary, setSummary] = useState({
    totalRevenue: 0,
    totalCardSold: 0,
    totalRedemption: 0,
    totalBalance: 0
  });
  const { titleContent, title, amount, paper, paperIcon, select } = useStyles();
  const { location } = useHistory();

  const isManager = location.pathname === ADMIN_PORTAL_URL.VENDOR_SUMMARY.URL;

  const handleTypeChange = useCallback((event) => {
    setCardType(event.target.value);
  }, []);

  useEffect(() => {
    const getSummary = async () => {
      const [startDate, eDate] = inputDate;
      let endDate = null;
      if (eDate) {
        endDate = new Date(eDate.getTime());
        endDate.setHours(0);
        endDate.setMinutes(0);
        endDate.setSeconds(0);
      }

      const params = {
        tenantId,
        cardType,
        startDate,
        endDate
      };

      const resp = await vendorService.getSummary(params);
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        setSummary(resp);
      }
      setLoading(false);
    };

    getSummary();
  }, [cardType, inputDate, toastError, tenantId]);

  const controlRender = useMemo(() => {
    return (
      <>
        <Select
          color="tertiary"
          size="small"
          className={`${select} m-2`}
          name="cardType"
          value={cardType}
          data={cardTypes}
          labelInput="Card"
          onChange={handleTypeChange}
        />

        <DateRangePicker
          color="tertiary"
          value={inputDate}
          onChange={(newValue) => {
            setInputDate(newValue);
          }}
          size="small"
        />
      </>
    );
  }, [cardType, handleTypeChange, inputDate, select]);

  const renderSummary = useMemo(() => {
    return (
      <>
        <Col xs={12} md={3}>
          <Paper className={`${paper} px-3 py-3`}>
            <div className={titleContent}>
              <PaidOutlinedIcon className={paperIcon} size="medium" />
              <div className={title}>Total Purchased Amount</div>
            </div>
            <div className={amount}>{numberFormat(summary.totalRevenue)}</div>
          </Paper>
        </Col>
        <Col xs={12} md={3}>
          <Paper className={`${paper} px-3 py-3`}>
            <div className={titleContent}>
              <CreditCardOutlinedIcon className={paperIcon} size="medium" />
              <div className={title}>Total Cards Sold</div>
            </div>
            <div className={amount}>{numberFormat(summary.totalCardSold)}</div>
          </Paper>
        </Col>
        <Col xs={12} md={3}>
          <Paper className={`${paper} px-3 py-3`}>
            <div className={titleContent}>
              <ExitToAppOutlinedIcon className={paperIcon} size="medium" />
              <div className={title}>Total Redemption</div>
            </div>
            <div className={amount}>{numberFormat(summary.totalRedemption)}</div>
          </Paper>
        </Col>
        <Col xs={12} md={3}>
          <Paper className={`${paper} px-3 py-3`}>
            <div className={titleContent}>
              <PaidOutlinedIcon className={paperIcon} size="medium" />
              <div className={title}>Total Balance</div>
            </div>
            <div className={amount}>{numberFormat(summary.totalBalance)}</div>
          </Paper>
        </Col>
      </>
    );
  }, [
    amount,
    paper,
    paperIcon,
    summary.totalBalance,
    summary.totalCardSold,
    summary.totalRedemption,
    summary.totalRevenue,
    title,
    titleContent
  ]);

  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <Container fluid className={`py-5 px-${isManager ? '4' : '1'} px-xxl-5`}>
      <Row>
        <Col xs={12}>
          <div className="d-flex">
            <div className="flex-1 d-flex justify-content-end align-items-center">
              {controlRender}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-5">{renderSummary}</Row>
    </Container>
  );
};

export default SummaryForm;
