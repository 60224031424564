import qs from 'qs';
import { FUNRAYZR_API_URL } from 'configs';
import { mapResponse } from 'api/responseMapping';

const Api = (axios) => {
  const url = `${FUNRAYZR_API_URL}/Public`;

  const getCategoriesPopular = async () => {
    const resp = await axios.get(`${url}/Categories/popular`);

    return mapResponse(resp);
  };

  const getCategories = async () => {
    const resp = await axios.get(`${url}/Categories`);

    return mapResponse(resp);
  };

  const getVendor = async (id) => {
    if (!id) return;

    const resp = await axios.get(`${url}/vendor/${id}`);

    return mapResponse(resp);
  };

  const getCharity = async (id) => {
    if (!id) return;

    const resp = await axios.get(`${url}/charities/${id}`);

    return mapResponse(resp);
  };

  const getCampaigns = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${url}/charities/campaigns?${params}`);

    return mapResponse(resp);
  };

  const getCharities = async (pa) => {
    const params = pa ? qs.stringify(pa) : '';
    const resp = await axios.get(`${url}/charities?${params}`);

    return mapResponse(resp, true);
  };

  const getCharitiesCampaigns = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${url}/charities/campaigns?${params}`);

    return mapResponse(resp, true);
  };

  const getCampaign = async (id) => {
    if (!id) return;

    const resp = await axios.get(`${url}/charities/campaigns/${id}`);

    return mapResponse(resp);
  };

  const getVendorWithoutCategory = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${url}/vendors/all?${params}`);

    return mapResponse(resp, true);
  };

  const getOrganizationTypes = async () => {
    const resp = await axios.get(`${url}/Organizations`);

    return mapResponse(resp);
  };

  const getRegions = async () => {
    const resp = await axios.get(`${url}/Regions`);

    return mapResponse(resp);
  };

  return {
    getCategoriesPopular,
    getCategories,
    getVendor,
    getCharity,
    getCampaigns,
    getCharities,
    getCharitiesCampaigns,
    getCampaign,
    getVendorWithoutCategory,
    getOrganizationTypes,
    getRegions
  };
};

export default Api;
