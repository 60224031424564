import React, { useState, useRef, useCallback } from 'react';
import { FormHelperText } from '@mui/material';
import { Col, Row } from 'reactstrap';
import Button from 'components/button';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { AUTHORIZE_API_LOGIN_ID, TOKENIZATION } from 'configs';
import moment from 'moment';

const CardDetailAuthorize = ({ backUrl, handleCheckout, isDisabledPayNow }) => {
  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
  const [cardNumberError, setCardNumberError] = useState('');
  const [isCardNameComplete, setIsCardNameComplete] = useState(false);
  const [cardNameError, setCardNameError] = useState('');
  const [cardNameInput, setCardNameInput] = useState('');
  const [cardNumberInput, setCardNumberInput] = useState('');
  const cardNumberRef = useRef();
  const cardNameRef = useRef();
  const checkoutFormRef = useRef();
  const { toastError } = useToast();
  const [cardCodeInput, setCardCodeInput] = useState('');
  const [cardCodeError, setCardCodeError] = useState('');
  const [isCardCodeComplete, setIsCardCodeComplete] = useState(false);

  const [cardExpYearError, setCardExpYearError] = useState('');
  const [cardExpMonthError, setCardExpMonthError] = useState('');

  const [cardMonthInput, setCardExpMonthInput] = useState('');
  const [cardYearInput, setCardExpYearInput] = useState('');

  const [isCardExpMonthComplete, setIsCardMonthComplete] = useState(false);
  const [isCardExpYearComplete, setIsCardYearComplete] = useState(false);

  const updateInputCardNumberValue = (e) => {
    setCardNumberInput(creditCardFormat(e.target.value));
  };

  const stripeCardNumberValidation = (cardNumber) => {
    const regexPattern = {
      MASTERCARD: new RegExp(/^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/),
      VISA: new RegExp(/^4[0-9]{2,}$/),
      AMERICAN_EXPRESS: new RegExp(/^3[47][0-9]{5,}$/),
      DISCOVER: new RegExp(/^6(?:011|5[0-9]{2})[0-9]{3,}$/),
      DINERS_CLUB: new RegExp(/^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/),
      JCB: new RegExp(/^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/)
    };

    for (let index = 0; index < 6; index++) {
      const outputCard = cardNumber.replace(/[^\d]/g, '');
      let regExp = regexPattern.MASTERCARD;
      switch (index) {
        case 1:
          regExp = regexPattern.VISA;
          break;
        case 2:
          regExp = regexPattern.AMERICAN_EXPRESS;
          break;
        case 3:
          regExp = regexPattern.DISCOVER;
          break;
        case 4:
          regExp = regexPattern.DINERS_CLUB;
          break;
        case 5:
          regExp = regexPattern.JCB;
          break;
        default:
          break;
      }

      if (outputCard.match(regExp)) {
        if (cardNumber) {
          if (outputCard.trim().length < 14) {
            return !!/^4[0-9 ]{12}(?:[0-9 ]{3})?$/i.test(outputCard.trim());
          }
          return !!/^[1-6]{1}[0-9]{14,15}$/i.test(outputCard.trim());
        }
      }
    }
    return false;

  };
  const creditCardFormat = (value) => {
    const v = value.replace(/\s+/g, '').replace(/\D/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || '';
    const parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      return parts.join(' ');
    }
    return value;
  };
  const onBlurCardNumber = () => {
    if (!cardNumberInput) {
      setIsCardNumberComplete(false);
      setCardNumberError('Card Number is required');
      return;
    }
    if (stripeCardNumberValidation(cardNumberInput)) {
      setIsCardNumberComplete(true);
      setCardNumberError('');
    } else {
      setIsCardNumberComplete(false);
      setCardNumberError('Please provide valid credit card number');
    }
  };
  const updateInputValue = (evt) => {
    setCardNameInput(evt.target.value);
    if (!evt.target.value) {
      setIsCardNameComplete(false);
      setCardNameError('Cardholder Name is required.');
    } else {
      setIsCardNameComplete(true);
      setCardNameError('');
    }
  };

  const updateCardcodeInputValue = (evt) => {
    setCardCodeInput(evt.target.value);
    if (!evt.target.value) {
      setIsCardCodeComplete(false);
      setCardCodeError('Card code is required.');
    }
  };
  const onBlurCardCode = () => {
    if (!cardCodeInput) {
      return;
    }
    if (cardCodeInput.toString().length > 4 || cardCodeInput.toString().length < 3) {
      setIsCardCodeComplete(false);
      setCardCodeError('Please provide valid card code.');
    } else {
      setIsCardCodeComplete(true);
      setCardCodeError('');
    }
  };
  const onBlurExpMonth = () => {
    if (!cardMonthInput) return;
    if (/^(0?[1-9]|1[012])$/.test(cardMonthInput.trim()) === false) {
      setIsCardMonthComplete(false);
      setCardExpMonthError('Please provide valid expiration month');
      } else {
        setIsCardMonthComplete(true);
        setCardExpMonthError('');
      }
  };
  const onBlurExpYear = () => {
    if (!cardYearInput) return;
    if (Number(cardYearInput) < moment().year()) {
      setIsCardYearComplete(false);
      setCardExpYearError('Please provide valid expiration year');
      } else {
        setIsCardMonthComplete(true);
        setCardExpMonthError('');
      }
  };
  const updateExpMonthInputValue = (evt) => {
    if (/^[0-9]*$/.test(evt.target.value.trim()) === false) {
      return;
    }
    setCardExpMonthInput(evt.target.value);
    if (!evt.target.value) {
      setIsCardMonthComplete(false);
      setCardExpMonthError('Expiration Month is required.');
    } else {
      setIsCardMonthComplete(true);
      setCardExpMonthError('');
    }
  };
  const updateExpYearInputValue = (evt) => {
    if (/^[0-9]*$/.test(evt.target.value.trim()) === false) {
      return;
    }
    setCardExpYearInput(evt.target.value);
    if (!evt.target.value) {
      setIsCardYearComplete(false);
      setCardExpYearError('Expiration Year is required.');
    } else {
      setIsCardYearComplete(true);
      setCardExpYearError('');
    }
  };

  const handleCheckoutValue = useCallback(
    (token) => {
    const cardNameValue = cardNameRef.current.value;
    document.getElementById("expYear").value = "";
    document.getElementById("expMonth").value = "";
    document.getElementById("cardCode").value = "";
    document.getElementById("nameOnAccount").value = "";
    document.getElementById("cardNumber").value = "";
    setCardCodeError('');
    setCardExpYearInput('');
    setCardExpMonthInput('');
    setCardNameInput('');
    setCardNumberInput('');
    handleCheckout(token, cardNameValue);
    },
    [handleCheckout]
  );
  function paymentFormUpdate(opaqueData) {
    handleCheckoutValue(opaqueData);
  }
  const sendPaymentDataToAnet = async () => {
    const cardData = {
      cardNumber: cardNumberInput.replace(/\s/g, ''),
      month: cardMonthInput,
      year: cardYearInput,
      cardCode: cardCodeInput
    };
    const authData = {
      clientKey: TOKENIZATION,
      apiLoginID: AUTHORIZE_API_LOGIN_ID
    };
    window.Accept.dispatchData({ authData, cardData }, responseHandler);
    function responseHandler(response) {
      if (response.messages.resultCode === "Error") {
          let i = 0;
          while (i < response.messages.message.length) {
              toastError(response.messages.message[i].text || ERROR_MESSAGE.UNKNOWN.MESSAGE);
              i += 1;
          }
      } else {
          paymentFormUpdate(response.opaqueData);
      }
  }
  };

  return (
    <form id="paymentForm" className="c-card-form" ref={checkoutFormRef}>
      <Row className="mb-4">
        <Col md={12}>
          <h3 className="violet-title"> Pay with </h3>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={12}>
          <p className="c-field-title"> Card Number * </p>
          <input
            className="c-card-name form-control"
            type="text"
            value={cardNumberInput}
            name="cardNumber"
            id="cardNumber"
            ref={cardNumberRef}
            onBlur={onBlurCardNumber}
            placeholder="cardNumber"
            onChange={(e) => updateInputCardNumberValue(e)}
          />
          {cardNumberError && (
            <FormHelperText className="c-field-error" error>
              {cardNumberError}
            </FormHelperText>
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={12}>
          <p className="c-field-title"> Cardholder Name *</p>
          <input
            id="nameOnAccount"
            placeholder="cardholderName"
            className="c-card-name form-control"
            ref={cardNameRef}
            value={cardNameInput}
            maxLength="64"
            onChange={(e) => updateInputValue(e)}
          />
          {cardNameError && (
            <FormHelperText className="c-field-error" error>
              {cardNameError}
            </FormHelperText>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={3} className="mb-4">
          <p className="c-field-title"> Exp Year * </p>
          <input
            className="form-control"
            type="text"
            name="expYear"
            maxLength="4"
            value={cardYearInput}
            id="expYear"
            placeholder="expYear"
            onBlur={onBlurExpYear}
            onChange={(e) => updateExpYearInputValue(e)}
          />
          {cardExpYearError && (
            <FormHelperText className="c-field-error" error>
              {cardExpYearError}
            </FormHelperText>
          )}
        </Col>
        <Col md={3} className="mb-4">
          <p className="c-field-title"> Exp Month * </p>
          <input
            className="form-control"
            type="text"
            name="expMonth"
            maxLength="2"
            value={cardMonthInput}
            id="expMonth"
            placeholder="expMonth"
            onBlur={onBlurExpMonth}
            onChange={(e) => updateExpMonthInputValue(e)}
          />
          {cardExpMonthError && (
            <FormHelperText className="c-field-error" error>
              {cardExpMonthError}
            </FormHelperText>
          )}
        </Col>
        <Col md={6} className="mb-4">
          <p className="c-field-title"> Card Code * </p>
          <input
            className="form-control"
            type="number"
            name="cardCode"
            maxLength="4"
            value={cardCodeInput}
            id="cardCode"
            placeholder="cardCode"
            onBlur={onBlurCardCode}
            onChange={(e) => updateCardcodeInputValue(e)}
          />
          {cardCodeError && (
            <FormHelperText className="c-field-error" error>
              {cardCodeError}
            </FormHelperText>
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="text-left" md={4} sm={4}>
          {backUrl && (
            <a className="back-url" href={backUrl}>
              & #60; Back
            </a>
          )}
        </Col>
        <Col className="text-center" md={12}>
          <Button
            disabled={
              !(
                isCardNumberComplete &&
                isCardNameComplete &&
                isCardCodeComplete &&
                isCardExpYearComplete &&
                isCardExpMonthComplete &&
                isDisabledPayNow
              )
            }
            onClick={sendPaymentDataToAnet}
          >
            PAY NOW
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default CardDetailAuthorize;
