import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import React from 'react';
import { useParams } from 'react-router-dom';
import AdminPortalCharityView, { CampaignTab } from '..';
import CampaignList from '../../campaign/list';

const AdminPortalCharityViewCampaign = () => {
  const { cid } = useParams();

  return (
    <>
      <AdminPortalCharityView tab={CampaignTab.value} className="manager-view-paging">
        <CampaignList
          charityId={cid}
          addUrl={ADMIN_PORTAL_URL.CHARITY_VIEW_CAMPAIGNS_ADD.URL.replace(':cid', cid)}
          editUrl={ADMIN_PORTAL_URL.CHARITY_VIEW_CAMPAIGNS_EDIT.URL.replace(':cid', cid)}
        />
      </AdminPortalCharityView>
    </>
  );
};

export default AdminPortalCharityViewCampaign;
