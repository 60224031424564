import Button from 'components/button';
import { Popup, usePopup } from 'components/popup';
import { Table, useTable } from 'components/table';
import useToast from 'components/toast/useToast';
import React, { useState } from 'react';
import campaignService from 'services/charity/campaign';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { Link } from 'react-router-dom';
import { STATUS } from 'constants/index';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { gS } from 'constants/helperMessage';
import { formatDate } from 'utils/helper';
import { theme } from 'constants/theme';

const headCells = [
  { id: 'name', label: 'Campaign ID Name' },
  { id: 'startDate', label: 'Start Date' },
  { id: 'endDate', label: 'End Date' },
  { id: 'isActive', label: 'Active' },
  { id: 'action', label: '', className: 'w-30 w-xl-25' }
];
const apiParams = {};

const CampaignList = ({ charityId, addUrl, editUrl }) => {
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [isDisabledConfirm, setIsDisabledConfirm] = useState(false);
  const [data, setData] = useTable();
  const [isOpen, open, close] = usePopup();
  const { toastSuccess, toastError } = useToast();
  const isActiveCampaign = selectedCampaign?.isActive?.key;
  apiParams.CharityId = charityId;

  const handleActiveCampaign = (index) => {
    setSelectedCampaign(data.items[index]);
    open();
    setIsDisabledConfirm(false);
  };

  const renderIsActivated = (isActive) => {
    return isActive ? (
      <CheckBoxOutlinedIcon color="tertiary" sx={{ fontSize: 28 }} key={true} />
    ) : (
      <CheckBoxOutlineBlankOutlinedIcon color="tertiary" sx={{ fontSize: 28 }} />
    );
  };

  const renderActionButton = (isActive, index, id) => {
    return (
      <div>
        <Button
          color={isActive ? 'secondary' : 'tertiary'}
          size="small"
          className="mr-1"
          onClick={() => {
            handleActiveCampaign(index);
          }}
          minWidth={theme.button.minWidth}
        >
          {isActive ? 'Deactivate' : 'Activate'}
        </Button>

        <Button
          color="tertiary"
          size="small"
          className="ml-1 text-white-hover"
          component={Link}
          to={editUrl.replace(':id', id)}
        >
          Edit
        </Button>
      </div>
    );
  };

  const handleOnClickConfirm = async (campaign) => {
    if (isDisabledConfirm) return;
    if (!campaign) return;
    setIsDisabledConfirm(true);
    const resp = await campaignService.updateCampaignStatus(
      campaign.id,
      isActiveCampaign ? STATUS.DEACTIVATE : STATUS.ACTIVATE
    );
    if (resp?.errorCode) {
      toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
    } else {
      campaign.action = renderActionButton(!isActiveCampaign, campaign.index, campaign.id);
      campaign.isActive = renderIsActivated(!isActiveCampaign);
      toastSuccess(gS(isActiveCampaign ? 'Deactivated' : 'Activated'));
      close();
    }
  };

  const convertDataToCells = (arr) => {
    if (!arr) return [];
    return arr.map((value, index) => {
      const keys = Object.keys(value);
      keys.forEach((key) => {
        if (typeof value[key] === 'boolean') {
          if (key === 'isActive') {
            value.index = index;
            value.action = renderActionButton(value[key], index, value.id);
            value[key] = renderIsActivated(value[key]);
          }
        }
        if (key === 'startDate' || key === 'endDate') {
          value[key] = formatDate(value[key]);
        }
      });
      return value;
    });
  };

  const dataConvert = convertDataToCells(data.items);

  return (
    <>
      <Table
        headCells={headCells}
        rowCells={dataConvert}
        totalCount={data?.totalItems ?? 0}
        havePagination={true}
        haveHeaderAction={
          <>
            <h2 className="h6 text-tertiary font-weight-bold m-0">Add Campaign</h2>
            <Button
              className="mx-3 text-white-hover"
              size="small"
              color="tertiary"
              component={Link}
              to={addUrl}
            >
              Add
            </Button>
          </>
        }
        title="Campaigns"
        service={campaignService}
        api="getCampaign"
        apiParams={apiParams}
        setDataFetch={setData}
      />
      <Popup
        kind="confirm"
        isOpen={isOpen}
        onConfirm={() => {
          handleOnClickConfirm(selectedCampaign);
        }}
        isDisabledConfirm={isDisabledConfirm}
        onCancel={close}
        size="sm"
        title={`${isActiveCampaign ? 'Deactivate Campaign' : 'Activate Campaign'}`}
        confirm="Yes"
      >
        {`Are you sure you want to ${isActiveCampaign ? 'deactivate' : 'activate'} ${
          selectedCampaign?.name
        }?`}
      </Popup>
    </>
  );
};

export default CampaignList;
