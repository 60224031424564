import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@mui/styles';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { Col, Row } from 'reactstrap';
import Button from 'components/button';
import Input from 'components/input';
import { gR } from 'constants/helperMessage';
import UnsavedPopup from 'pages/home/unsavedPopup';
import Select from 'components/select';

const useStyles = makeStyles(() => ({
  profileAction: {
    marginBottom: '14px',
    justifyContent: 'center',
    display: 'flex'
  }
}));

const ADMIN_TEXT = 'Administrator';

const ProfileForm = ({
  profile,
  onSubmit,
  isAdd,
  isDisabled = false,
  roles,
  tenants,
  cancelUrl = '',
  hasTenantField = true
}) => {
  const [isDisabledTenants, setIsDisabledTenants] = useState(false);

  const { profileAction } = useStyles();

  const adminValue = roles?.find((role) => role.text === ADMIN_TEXT)?.value;

  const handleOnSubmit = useCallback(
    (values) => {
      if (onSubmit) {
        onSubmit(values);
      }
    },
    [onSubmit]
  );

  const validation = {
    email: Yup.string().email(ERROR_MESSAGE.EMAIL.VALID).required(gR('Email')),
    firstName: Yup.string().required(gR('First Name')),
    lastName: Yup.string().required(gR('Last Name')),
    role: Yup.string().required(gR('Assign Role'))
  };
  if (hasTenantField) {
    validation.tenant = Yup.string().when('role', {
      is: adminValue,
      then: Yup.string(),
      otherwise: Yup.string().required(gR('Assign Vendor or Charities'))
    });
  }
  const {
    values,
    setFieldValue,
    handleSubmit,
    handleChange,
    touched,
    errors,
    initialValues,
    resetForm
  } = useFormik({
    initialValues: {
      email: profile.email ?? '',
      firstName: profile.firstName ?? '',
      lastName: profile.lastName ?? '',
      role: profile.role ?? '',
      tenant: profile.tenant ?? ''
    },
    validationSchema: Yup.object(validation),
    onSubmit: handleOnSubmit,
    enableReinitialize: true
  });

  useEffect(() => {
    if (!values.role || values.role === adminValue) {
      setIsDisabledTenants(true);
      setFieldValue('tenant', '');
    } else {
      setIsDisabledTenants(false);
    }
  }, [values.role, setFieldValue, adminValue]);
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        {/* STASH this code in case requirement changes */}
        {/* <Col xs={3} className="text-center d-flex flex flex-column justify-content-between">
          <p className="text-primary font-weight-bold mt-3">Profile</p>
          <div className={profileAction}>
            {isAdd ? (
              <>
                <Button
                  size="small"
                  color="secondary"
                  className="mr-1 text-white-hover"
                  component={Link}
                  to={ADMIN_PORTAL_URL.USER.URL}
                >
                  Cancel
                </Button>
                <Button size="small" className="ml-1" type="submit" disabled={isDisabled}>
                  Save
                </Button>
              </>
            ) : (
              <Button size="small" type="submit">
                Edit
              </Button>
            )}
          </div>
        </Col> */}
        <Col xs={12} className="d-flex flex-column align-items-center">
          <Input
            color="tertiary"
            size="small"
            label="First Name*"
            className="m-2 min-w-300"
            name="firstName"
            onChange={handleChange}
            value={values.firstName}
            error={touched.firstName && !!errors.firstName}
            helperText={touched.firstName && errors.firstName}
          />
          <Input
            color="tertiary"
            size="small"
            label="Last Name*"
            className="m-2 min-w-300"
            name="lastName"
            onChange={handleChange}
            value={values.lastName}
            error={touched.lastName && !!errors.lastName}
            helperText={touched.lastName && errors.lastName}
          />
          <Input
            color="tertiary"
            size="small"
            label="Email*"
            className="m-2 min-w-300"
            name="email"
            disabled={!isAdd}
            onChange={handleChange}
            value={values.email}
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
          />

          <Select
            color="tertiary"
            size="small"
            className="m-2"
            name="role"
            value={values.role}
            data={roles}
            labelInput="Assign Role*"
            onChange={handleChange}
            error={touched.role && !!errors.role}
            helperText={touched.role && errors.role}
          />

          {!isDisabledTenants && hasTenantField && (
            <Select
              color="tertiary"
              haveSearch
              size="small"
              className="m-2"
              name="tenant"
              data={tenants}
              value={values.tenant}
              labelInput="Assign Vendor or Charity"
              onChange={handleChange}
              error={touched.tenant && !!errors.tenant}
              helperText={touched.tenant && errors.tenant}
              disabled={isDisabledTenants}
            />
          )}

          {isDisabledTenants && (
            <Select
              color="tertiary"
              size="small"
              className="m-2"
              labelInput="Assign Vendor or Charity"
              disabled={isDisabledTenants}
            />
          )}

          <div className={`${profileAction} mt-5`}>
            <Button
              size="small"
              color="secondary"
              className="mr-1 text-white-hover"
              component={Link}
              to={cancelUrl}
            >
              Cancel
            </Button>
            <Button
              color="tertiary"
              size="small"
              className="ml-1"
              type="submit"
              disabled={isDisabled}
              minWidth={79.86}
            >
              Save
            </Button>
          </div>
        </Col>
      </Row>
      <UnsavedPopup
        values={values}
        initialValues={initialValues}
        isSubmit={isDisabled}
        resetForm={resetForm}
      />
    </form>
  );
};

export default ProfileForm;
