import React, { useEffect, useMemo } from 'react';
import { Container } from 'reactstrap';
import { GLOBAL_ELEMENT } from 'constants/globalElement';
import TransactionList from './list';
import { TRANSACTION_URL } from './url';

const Transactions = () => {

  useEffect(() => {
    const topEl = document.getElementById(GLOBAL_ELEMENT.top_page);
    if (topEl) {
      setTimeout(() => {
        topEl.scrollIntoView();
      }, 500);
    }
  }, []);
  const renderView = useMemo(() => {
    return (
      <>
        <TransactionList detailUrl={TRANSACTION_URL.TRANSACTION_DETAIL.URL} />
      </>
    );
  }, []);

  return (
    <>
      <div className="fr-background quicksand">
        <Container>
          <h3 className="fr-page-title" id={GLOBAL_ELEMENT.top_page}>My Wallet</h3>
          <div className="fr-page-container bg-transparent" style={{ minHeight: '400px' }}>
            {renderView}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Transactions;
