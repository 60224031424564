import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import RegisterForm from 'components/registerForm';
import useToast from 'components/toast/useToast';
import { SITE_KEY_CAPCHA } from 'configs';

import { useHistory } from 'react-router-dom';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import authService from 'services/authorization';
import useLoading from 'components/loading/useLoading';
import { REGISTER_URL } from './url';

const Register = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [showLoading, hideLoading] = useLoading();
  const [tokenGCapcha, settokenGCapcha] = useState('');

  const history = useHistory();
  const { toastError } = useToast();
  const goBack = () => history.goBack();

  useEffect(() => {
    const loadCaptcha = (id, url, getCaptcha) => {
      const elRegisterForm = document.getElementById("register-form");
      const isLoaded = document.getElementById(id);

      if (!isLoaded) {
        const src = document.createElement("script");
        src.type = "text/javascript";
        src.id = id;
        src.src = url;
        src.onload = () => {
          if (getCaptcha) getCaptcha();
        };
        elRegisterForm.appendChild(src);
      }

      if (isLoaded && getCaptcha) getCaptcha();
    };

    loadCaptcha("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY_CAPCHA}`, () => {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute(SITE_KEY_CAPCHA, { action: 'submit' }).then((token) => {
          settokenGCapcha(token);
        });
      });
    });
    return () => {
      const script = document.querySelector("recaptcha-key");
      if (script) {
          script.remove();
      }
      const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
      if (recaptchaElems.length) {
          recaptchaElems[0].remove();
      }
    };
  }, []);

const handleOnSubmit = useCallback(
  async (values) => {
    setIsDisabled(true);
    showLoading();
    const respone = await authService.register(values);
    if (respone?.errorCode) {
      toastError(respone.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
    } else {
      history.push(REGISTER_URL.REGISTER_SUCCESS.URL);
    }
    setIsDisabled(false);
    hideLoading();
  },
  [showLoading, hideLoading, toastError, history]
);

return (
  <>
    <div className="fr-background fr-box-container" id="register-form">
      <div className="fr-box">
        <Container>
          <div className="fr-page-container fr-content">
            <div className="d-flex justify-content-between mb-5">
              <div />
              <h3 className="fr-content-title ">{REGISTER_URL.REGISTER.NAME}</h3>
              <button
                className="close-popup font-bold"
                onClick={goBack}
                type="button"
                tabIndex="0"
              >X
              </button>
            </div>
            <RegisterForm onSubmit={handleOnSubmit} submitDisabled={isDisabled} tokenGCapcha={tokenGCapcha} />
          </div>
        </Container>
      </div>
    </div>
  </>
);
};

export default Register;
