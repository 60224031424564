import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import UserList from 'pages/adminPortal/user/userList';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import vendorService from 'services/vendor';
import AdminPortalVendorViev, { UserManagementTab } from '..';

const AdminPortalVendorViewUser = () => {
  const [vendor, setVendor] = useState({});
  const history = useHistory();
  const { vid } = useParams();

  useEffect(() => {
    const getVendor = async () => {
      const resp = await vendorService.getVendorDetail(vid);
      if (resp?.errorCode) {
        history.push(ADMIN_PORTAL_URL.VENDOR.URL);
      } else {
        setVendor(resp);
      }
    };
    if (vid) {
      getVendor();
    }
  }, [history, vid]);

  const renderView = useMemo(() => {

    return (
      <UserList
        tableTitle={vendor.displayName}
        addUrl={ADMIN_PORTAL_URL.VENDOR_VIEW_USERS_ADD.URL.replace(':vid', vid)}
        editUrl={ADMIN_PORTAL_URL.VENDOR_VIEW_USERS_EDIT.URL.replace(':vid', vid)}
        tenantId={vid}
      />
    );
  }, [vendor.displayName, vid]);

  return (
    <>
      <AdminPortalVendorViev vendorTab={UserManagementTab.value} className="manager-view-paging">
        {renderView}
      </AdminPortalVendorViev>
    </>
  );
};

export default AdminPortalVendorViewUser;
