import React, { useEffect, useRef, useState } from 'react';
import SideBar from 'components/sidebar';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import authService from 'services/authorization';
import AdminNavMenu from './adminNavMenu';

const AdminPortalLayout = (props) => {
  const isCancelled = useRef(false);
  const [isSuperAdministrator, setIsSuperAdministrator] = useState(false);
  const [isAdministrator, setIsAdministrator] = useState(false);
  const [isVendorManager, setIsVendorManager] = useState(false);
  const [isCharityManager, setIsCharityManager] = useState(false);

  useEffect(() => {
    const populateAdminState = async () => {
      const isSuperAdmin = authService.isSuperAdministrator();
      const isAdmin = authService.isAdministrator();
      const isVenManager = authService.isManager() && authService.isVendor();
      const isChaManager = authService.isManager() && authService.isCharity();

      if (isCancelled.current) return;
      setIsSuperAdministrator(isSuperAdmin);
      setIsAdministrator(isAdmin);
      setIsVendorManager(isVenManager);
      setIsCharityManager(isChaManager);
    };

    const adminSubscription = authService.subscribe(() => populateAdminState());
    populateAdminState();

    return () => {
      isCancelled.current = true;
      if (adminSubscription) {
        authService.unsubscribe(adminSubscription);
      }
    };
  }, []);

  let sidebarMenu = [
    {
      name: ADMIN_PORTAL_URL.USER.NAME,
      url: ADMIN_PORTAL_URL.USER.URL
    },
    {
      name: ADMIN_PORTAL_URL.VENDOR.NAME,
      url: ADMIN_PORTAL_URL.VENDOR.URL,
      subMenus: []
    },
    {
      name: ADMIN_PORTAL_URL.CHARITY.NAME,
      url: ADMIN_PORTAL_URL.CHARITY.URL,
      disabled: false,
      subMenus: []
    },
    {
      name: ADMIN_PORTAL_URL.VENDOR_REPORT.NAME,
      url: ADMIN_PORTAL_URL.VENDOR_REPORT.URL
    },
    {
      name: ADMIN_PORTAL_URL.CHARITY_REPORT.NAME,
      url: ADMIN_PORTAL_URL.CHARITY_REPORT.URL
    }
  ];

  if (isSuperAdministrator) {
    sidebarMenu = [
      ...sidebarMenu,
      {
        name: ADMIN_PORTAL_URL.REGION.NAME,
        url: ADMIN_PORTAL_URL.REGION.URL
      },
      {
        name: ADMIN_PORTAL_URL.ORGANIZATION.NAME,
        url: ADMIN_PORTAL_URL.ORGANIZATION.URL
      }
    ];
  }

  const vendorManageSidebarMenu = [
    {
      name: ADMIN_PORTAL_URL.VENDOR.NAME,
      url: ADMIN_PORTAL_URL.VENDOR_INFORMATION.URL,
      subMenus: [
        {
          subName: ADMIN_PORTAL_URL.VENDOR_SUMMARY.NAME,
          subUrl: ADMIN_PORTAL_URL.VENDOR_SUMMARY.URL
        },
        {
          subName: ADMIN_PORTAL_URL.VENDOR_USER.NAME,
          subUrl: ADMIN_PORTAL_URL.VENDOR_USER.URL
        },
        {
          subName: ADMIN_PORTAL_URL.VENDOR_INFORMATION.NAME,
          subUrl: ADMIN_PORTAL_URL.VENDOR_INFORMATION.URL
        },
        {
          subName: ADMIN_PORTAL_URL.VENDOR_CARD_TRANSACTION.NAME,
          subUrl: ADMIN_PORTAL_URL.VENDOR_CARD_TRANSACTION.URL,
          disabled: false
        },
        {
          subName: ADMIN_PORTAL_URL.VENDOR_REPORTING.NAME,
          subUrl: ADMIN_PORTAL_URL.VENDOR_REPORTING.URL
        }
      ]
    }
  ];

  const charityManageSidebarMenu = [
    {
      name: ADMIN_PORTAL_URL.CHARITY.NAME,
      url: ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS.URL,
      subMenus: [
        {
          subName: ADMIN_PORTAL_URL.CHARITY_INFORMATION.NAME,
          subUrl: ADMIN_PORTAL_URL.CHARITY_INFORMATION.URL
        },
        {
          subName: ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS.NAME,
          subUrl: ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS.URL
        },
        {
          subName: ADMIN_PORTAL_URL.CHARITY_REPORTING.NAME,
          subUrl: ADMIN_PORTAL_URL.CHARITY_REPORTING.URL
        }
      ]
    }
  ];

  return (
    <div>
      <AdminNavMenu />
      <div className="fr-body">
        {(isSuperAdministrator || isAdministrator) && <SideBar menus={sidebarMenu} />}
        {isVendorManager && <SideBar menus={vendorManageSidebarMenu} />}
        {isCharityManager && <SideBar menus={charityManageSidebarMenu} />}
        <div className="content">
          <div>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default AdminPortalLayout;
