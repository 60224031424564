import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DateRangePicker from 'components/dateRangePicker';
import Button from 'components/button';
import { CircularProgress } from '@mui/material';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import TableReporting from 'components/tableReporting';
import { formatDate, numberFormat, printContent, toUtc } from 'utils/helper';
import { useHistory } from 'react-router-dom';
import reportService from 'services/report';
import vendorService from 'services/vendor';

const summaryHeadCells = [
  { id: 'totalPurchasedAmount', label: 'Total Purchased Amount', align: 'left' },
  { id: 'totalCardsSold', label: 'Total Cards Sold', align: 'left' },
  { id: 'totalRedemption', label: 'Total Redemption', align: 'left' },
  { id: 'totalBalance', label: 'Total Balance', align: 'left' },
  { id: 'actualRevenue', label: 'Actual Revenue', align: 'left' }
];

const detailHeadCells = [
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'cardNumber', label: 'Card Number', align: 'left' },
  { id: 'purchased', label: 'Purchased', align: 'left' },
  { id: 'redemption', label: 'Redemption', align: 'left' },
  { id: 'balance', label: 'Balance', align: 'left' },
  { id: 'actualRevenue', label: 'Actual Revenue', align: 'left' },
  { id: 'chairtyRate', label: 'Charity Rate', align: 'left' },
  { id: 'charitySponsor', label: 'Charity Sponsor', align: 'left' }
];

const AdminPortalVendorReportingForm = ({ tenantId, notFoundRedirectUrl, noTitle }) => {
  const history = useHistory();
  const { toastError } = useToast();
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const initDate = [new Date(y, m, 1), new Date(y, m + 1, 0)];
  const [inputDate, setInputDate] = useState(initDate ?? [null, null]);
  const [loading, setLoading] = useState(true);
  const [reporting, setReporting] = useState({
    summary: {
      totalPurchasedAmount: 0,
      totalCardsSold: 0,
      totalRedemption: 0,
      totalBalance: 0,
      actualRevenue: 0
    },
    transactions: []
  });
  const [vendor, setVendor] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const [startDate, eDate] = inputDate;
      let endDate = null;
      if (eDate) {
        endDate = new Date(eDate.getTime());
        endDate.setHours(0);
        endDate.setMinutes(0);
        endDate.setSeconds(0);
      }

      const params = {
        tenantId,
        startDate,
        endDate
      };
      const vResp = await vendorService.getVendorDetail(tenantId);
      if (vResp?.errorCode) {
        if (notFoundRedirectUrl) {
          history.push(notFoundRedirectUrl);
        }
      } else if (vResp) {
        setVendor(vResp);
      }

      const resp = await reportService.getVendorReport(params);
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else if (resp) {
        const {
          totalPurchasedAmount,
          totalCardsSold,
          totalRedemption,
          totalBalance,
          actualRevenue,
          transactions
        } = resp;
        const res = {
          summary: {
            totalPurchasedAmount,
            totalCardsSold,
            totalRedemption,
            totalBalance,
            actualRevenue
          },
          transactions: transactions || []
        };
        setReporting(res);
      }

      setTimeout(() => {
        setLoading(false);
      }, 100);
    };

    fetch();
  }, [history, inputDate, notFoundRedirectUrl, tenantId, toastError]);

  const controlRender = useMemo(() => {
    return (
      <>
        <DateRangePicker
          color="tertiary"
          startText="Date"
          endText="Date"
          value={inputDate}
          onChange={(newValue) => {
            setInputDate(newValue);
          }}
          size="small"
        />
      </>
    );
  }, [inputDate]);

  const summaryConvertToRowCells = (arr) => {
    if (!arr) return [];

    return arr.map((value) => {
      if (!value.converted) {
        value.totalPurchasedAmount = `$${numberFormat(parseFloat(value?.totalPurchasedAmount), 2)}`;
        value.totalCardsSold = numberFormat(parseFloat(value?.totalCardsSold));
        value.totalRedemption = `$${numberFormat(parseFloat(value?.totalRedemption), 2)}`;
        value.totalBalance = `$${numberFormat(parseFloat(value?.totalBalance), 2)}`;
        value.actualRevenue = `$${numberFormat(parseFloat(value?.actualRevenue), 2)}`;
        value.converted = true;
      }
      return value;
    });
  };

  const reportSummaryRender = useMemo(() => {
    const { summary } = reporting;
    const rowCells = summaryConvertToRowCells([summary]);
    return <TableReporting noBorderTop headCells={summaryHeadCells} rowCells={rowCells} />;
  }, [reporting]);

  const detailConvertToRowCells = (arr) => {
    if (!arr) return [];

    return arr.map((value) => {
      if (!value.converted) {
        value.date = formatDate(toUtc(value.date));
        value.purchased = `$${numberFormat(parseFloat(value?.purchased), 2)}`;
        value.redemption = `$${numberFormat(parseFloat(value?.redemption), 2)}`;
        value.balance = `$${numberFormat(parseFloat(value?.balance), 2)}`;
        value.actualRevenue = `$${numberFormat(parseFloat(value?.actualRevenue), 2)}`;
        value.chairtyRate = `${(value.chairtyRate * 100).toFixed(0)}%`;
        value.converted = true;
      }
      return value;
    });
  };
  const reportDetailRender = useMemo(() => {
    const { transactions } = reporting;

    const rowCells = detailConvertToRowCells(transactions);

    return (
      <TableReporting stickyHeader noBorderTop headCells={detailHeadCells} rowCells={rowCells} />
    );
  }, [reporting]);

  const downloadHandle = useCallback(() => {
    printContent('report-view', `${vendor?.displayName || ''} - Report`);
  }, [vendor?.displayName]);

  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      {!noTitle && <h5 className="text-uppercase font-weight-bold mb-5">Reporting</h5>}
      <div className="mb-2 text-right">
        <Button size="small" onClick={downloadHandle} color="tertiary">
          Download
        </Button>
      </div>

      <div id="report-view">
        <div className="my-4">{controlRender}</div>

        <h6 className="mt-4 font-weight-bold">Summary</h6>
        <div>{reportSummaryRender}</div>
        <h6 className="mt-5 font-weight-bold">Details</h6>
        <div>{reportDetailRender}</div>
      </div>
    </>
  );
};

export default AdminPortalVendorReportingForm;
