import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import React from 'react';
import { useParams } from 'react-router-dom';
import AdminPortalVendorView, { InformationTab } from '..';
import AdminPortalVendorInformationForm from '../../information/informationForm';

const AdminPortalVendorViewInformation = () => {
  const { vid } = useParams();

  return (
    <>
      <AdminPortalVendorView vendorTab={InformationTab.value}>
        <AdminPortalVendorInformationForm
          vendorId={vid}
          notFoundRedirectUrl={ADMIN_PORTAL_URL.VENDOR.URL}
        />
      </AdminPortalVendorView>
    </>
  );
};

export default AdminPortalVendorViewInformation;
