import qs from 'qs';
import { FUNRAYZR_API_URL } from 'configs';
import { mapResponse } from 'api/responseMapping';

const Api = (axios) => {
  const charityUrl = `${FUNRAYZR_API_URL}/Charities`;
  const publicCharityUrl = `${FUNRAYZR_API_URL}/Public/Charities`;

  const getCharity = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${charityUrl}?${params}`);

    return mapResponse(resp, true);
  };

  const getCharityDetail = async (id) => {
    const resp = await axios.get(`${charityUrl}/${id}`);

    return mapResponse(resp);
  };

  const createCharity = async (charity) => {
    const resp = await axios.post(charityUrl, { ...charity });

    return mapResponse(resp);
  };

  const editCharity = async (id, charity) => {
    const resp = await axios.put(`${charityUrl}/${id}`, charity);

    return mapResponse(resp);
  };

  const updateCharityStatus = async (id, status) => {
    const resp = await axios.put(`${charityUrl}/${id}/status/${status}`);

    return mapResponse(resp);
  };

  const getCharityPublic = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${publicCharityUrl}?${params}`);

    return mapResponse(resp, true);
  };

  return {
    getCharity,
    createCharity,
    editCharity,
    getCharityDetail,
    updateCharityStatus,
    getCharityPublic
  };
};

export default Api;
