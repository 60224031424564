import React, { useCallback, useEffect, useState } from 'react';
import QrReader from 'react-qr-reader';
import { useHistory } from 'react-router-dom';
import useToast from 'components/toast/useToast';
import cardService from 'services/card';
import authService from 'services/authorization';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import useLoading from 'components/loading/useLoading';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';
import Button from 'components/button';
import { SCAN_VENDOR_TRANSACTIONS_URL } from '../../url';

const useStyles = makeStyles(() => ({
  closeButton: {
    position: 'absolute',
    top: '5px',
    left: 0,
    right: '14px',
    zIndex: 1003,
    textAlign: 'right'
  },
  closeIcon: {
    color: theme.colors.white.button
  }
}));

const MobileScan = () => {
  const history = useHistory();
  const [cardCode, setCardCode] = useState('');
  const [showLoading, hideLoading] = useLoading();
  const { toastError } = useToast();
  const { closeButton, closeIcon } = useStyles();

  const handleGetCard = useCallback(async (code) => {
    showLoading();
    const resp = await cardService.getCardByCodes(code);
    hideLoading();
    if (resp?.errorCode || !resp?.id || resp.status === 404) {
      toastError(ERROR_MESSAGE.CARD_CODE.VALID);
      setCardCode('');
    } else {
      const { id } = resp;
      if (authService.isEmployee()) {
        history.push(SCAN_VENDOR_TRANSACTIONS_URL.DETAIL.URL.replace(':id', id));
      } else if (authService.isManager()) {
        history.push(ADMIN_PORTAL_URL.VENDOR_CARD_TRANSACTION_DETAIL.URL.replace(':id', id));
      }
    }
  }, [hideLoading, history, showLoading, toastError]);

  useEffect(() => {
    if (cardCode) {
      handleGetCard(cardCode);
    }
  }, [cardCode, handleGetCard]);

  const handleScan = useCallback((data) => {
    setCardCode(data);
  }, []);

  const handleError = () => { };

  const onBack = useCallback(() => {
    history.push(SCAN_VENDOR_TRANSACTIONS_URL.LIST.URL);
  }, [history]);

  return (
    <div className="position-relative">
      <div className={closeButton}>
        <Button kind="icon" onClick={onBack}>
          <CloseIcon className={closeIcon} fontSize="large" />
        </Button>
      </div>
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default MobileScan;
