export const defaultState = {
  items: [],
  pageIndex: 0,
  pageSize: 10,
  totalPage: 0,
  totalItems: 0
};

export const mapPage = (data) => {
  if (!data) {
    return defaultState;
  }

  return data;
};
