import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { PURPOSE, STATUS } from 'constants/index';
import userService from 'services/user';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import { CircularProgress } from '@mui/material';
import useLoading from 'components/loading/useLoading';
import { gS } from 'constants/helperMessage';
import UserInfo from '../userInfo';

// TO DO: will update at Assign role task
const MOCK_ROW_CELLS = [];

const AdminPortalUserEditForm = ({ notFoundRedirectUrl, redirectUrl, backUrl, hasTenantField = true, includeAdminRole = true }) => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [roleActive, setRoleActive] = useState(false);
  const [isRoleActiveSwitchDisable, setIsRoleActiveSwitchDisable] = useState(false);
  const userRoleRef = useRef(null);
  const { toastSuccess, toastError } = useToast();
  const [showLoading, hideLoading] = useLoading();

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    const fetchId = async () => {
      const resp = await userService.getAccountUserDetail(id);
      if (resp?.errorCode) {
        history.push(notFoundRedirectUrl);
      } else {
        const { email, firstName, lastName, roleId, tenantId, userRoleId, isActive } = resp;
        userRoleRef.current = userRoleId;
        setLoading(false);
        setProfile({ email, firstName, lastName, role: roleId, tenant: tenantId });
        setRoleActive(isActive);
      }
    };
    fetchId();
  }, [history, id, notFoundRedirectUrl, toastError]);

  const onSubmit = useCallback(
    async ({ firstName, lastName, role, tenant }) => {
      setIsDisabled(true);
      showLoading();
      const resp = await userService.editUser(id, {
        firstName,
        lastName,
        roleId: role,
        tenantId: tenant,
        userRoleId: userRoleRef.current
      });
      hideLoading();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        history.push(redirectUrl);
        toastSuccess(gS('Edit user'));
      }
      setIsDisabled(false);
    }, [hideLoading, history, id, redirectUrl, showLoading, toastError, toastSuccess]);

  const handleRoleActiveSwitch = async () => {
    setIsRoleActiveSwitchDisable(true);
    const resp = await userService.statusRole(
      userRoleRef.current,
      roleActive ? STATUS.DEACTIVATE : STATUS.ACTIVATE
    );
    if (resp?.errorCode) {
      toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
    } else {
      toastSuccess(gS(roleActive ? 'Inactive Role' : 'Active Role'));
      setRoleActive((value) => !value);
    }
    setIsRoleActiveSwitchDisable(false);
  };

  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <UserInfo
      purpose={PURPOSE.MANAGE}
      rowCells={MOCK_ROW_CELLS}
      profile={profile}
      cancelUrl={backUrl}
      onSubmit={onSubmit}
      isDisabled={isDisabled}
      roleActive={roleActive}
      handleRoleActiveSwitch={handleRoleActiveSwitch}
      isRoleActiveSwitchDisable={isRoleActiveSwitchDisable}
      hasTenantField={hasTenantField}
      includeAdminRole={includeAdminRole}
    />
  );
};

export default AdminPortalUserEditForm;
