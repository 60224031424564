import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BUCKET, HTTPCODES, IMG_LOCAL } from 'constants/index';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import { CircularProgress } from '@mui/material';
import publicService from 'services/public';
import fileService from 'services/file';
import cardService from 'services/card';
import CardTransactionInfo from 'pages/adminPortal/vendor/cardAndTransaction/cardTransactionInfo';

const ConsumerCardTransaction = ({
  tenantId,
  notFoundRedirectUrl,
  haveRedeem = true,
  isMine = false
}) => {
  const [loading, setLoading] = useState(true);
  const [card, setCard] = useState({});
  const [vendor, setVendor] = useState({});

  const { id, isView } = useParams();

  const { push } = useHistory();
  const { toastError } = useToast();

  useEffect(() => {
    const getVendor = async () => {
      const resp = await publicService.getVendor(tenantId);
      if (resp?.errorCode) {
        push(notFoundRedirectUrl);
      } else {
        const { displayName, address, logoPath } = resp;

        setVendor({
          displayName,
          logoPath: logoPath
            ? `${fileService._baseUrl}/${BUCKET.VENDOR}/${logoPath}`
            : IMG_LOCAL.DEFAULT_VENDOR,
          address: address?.addressLine1
        });
      }
    };
    if (tenantId) {
      getVendor();
    }
  }, [tenantId, push, toastError, notFoundRedirectUrl]);

  useEffect(() => {
    const getUser = async () => {
      const resp = await cardService.getCardDetail(id, isMine);
      if (resp?.errorCode) {
        if (resp?.status === HTTPCODES.BAD_REQUEST) {
          toastError(resp.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        }
        push(notFoundRedirectUrl);
      } else {
        const {
          vendorId,
          cardNumber,
          firstName,
          lastName,
          email,
          cardBalance,
          cardType,
          charityId,
          charityName,
          charityLogoPath,
          campaignId,
          campaignName,
          campaignLogoPath,
          isGift,
          to,
          qrCode,
          recipientEmail
        } = resp;
        setLoading(false);
        setCard({
          vendorId,
          cardNumber,
          firstName,
          lastName,
          email,
          cardBalance,
          cardType,
          charity: charityId
            ? {
                charityId,
                name: charityName,
                logoPath: charityLogoPath
              }
            : {},
          campaign: campaignId
            ? {
                campaignId,
                name: campaignName,
                logoPath: campaignLogoPath
              }
            : {},
          isGift,
          to,
          recipientEmail,
          qrCode
        });
      }
    };
    getUser();
  }, [push, id, toastError, notFoundRedirectUrl, isMine]);

  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <CardTransactionInfo
      id={id}
      vendor={vendor}
      card={card}
      haveRedeem={haveRedeem}
      isMine={isMine}
      isView={isView}
      isStorefront={true}
    />
  );
};

export default ConsumerCardTransaction;
