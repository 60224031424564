export const SCAN_VENDOR_TRANSACTIONS_URL = {
  LIST: {
    URL: '/scan-vendor-transactions',
    NAME: 'Scan & Vendor Transactions'
  },
  DETAIL: {
    URL: '/scan-vendor-transactions/:id',
    NAME: 'Scan & Vendor Transaction Detail'
  },
  SCAN: {
    URL: '/scan-code',
    NAME: 'Scan Code'
  }
};
