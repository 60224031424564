import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, useTable } from 'components/table';
import { Container } from 'reactstrap';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import Button from 'components/button';
import { Popup, usePopup } from 'components/popup';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import vendorService from 'services/vendor';
import useToast from 'components/toast/useToast';
import { STATUS } from 'constants/index';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { gS } from 'constants/helperMessage';
import { theme } from 'constants/theme';

const headCells = [
  { id: 'displayName', label: 'Display Name' },
  { id: 'businessNumber', label: 'Business Number' },
  { id: 'isActive', label: 'Active' },
  { id: 'action', label: '', className: 'w-37 w-xl-31' }
];

const AdminPortalVendor = () => {
  const [selectedVendor, setSelectedVendor] = useState({});
  const [isDisabledConfirm, setIsDisabledConfirm] = useState(false);

  const [data, setData] = useTable();
  const [isOpen, open, close] = usePopup();
  const { toastSuccess, toastError } = useToast();

  const isActiveVendor = selectedVendor?.isActive?.key;

  const handleActiVendor = (index) => {
    setSelectedVendor(data.items[index]);
    open();
    setIsDisabledConfirm(false);
  };

  const renderActive = (isActive) => {
    return !isActive ? (
      <CheckBoxOutlineBlankOutlinedIcon color="tertiary" sx={{ fontSize: 28 }} />
    ) : (
      <CheckBoxOutlinedIcon color="tertiary" sx={{ fontSize: 28 }} key={true} />
    );
  };

  const renderAction = (isActive, index, id) => {
    return (
      <div style={{ minWidth: '250px' }}>
        <Button
          color={!isActive ? 'tertiary' : 'secondary'}
          size="small"
          className="mr-1"
          onClick={() => {
            handleActiVendor(index);
          }}
          minWidth={theme.button.minWidth}
        >
          {!isActive ? 'Activate' : 'Deactivate'}
        </Button>

        <Button
          size="small"
          color="warning"
          className="ml-1 text-white-hover"
          component={Link}
          to={ADMIN_PORTAL_URL.VENDOR_VIEW_SUMMARY.URL.replace(':vid', id)}
        >
          View Details
        </Button>

        <Button
          size="small"
          color="quaternary"
          className="ml-1 text-white-hover"
          component={Link}
          to={ADMIN_PORTAL_URL.VENDOR_DETAIL.URL.replace(':id', id)}
        >
          Edit
        </Button>
      </div>
    );
  };
  const handleOnClickConfirm = async (vendor) => {
    if (isDisabledConfirm) return;
    if (!vendor) return;
    setIsDisabledConfirm(true);
    const resp = await vendorService.statusVendor(
      vendor.id,
      isActiveVendor ? STATUS.DEACTIVATE : STATUS.ACTIVATE
    );
    if (resp?.errorCode) {
      toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
    } else {
      vendor.isActive = renderActive(!isActiveVendor);
      vendor.action = renderAction(!isActiveVendor, vendor.index, vendor.id);
      toastSuccess(gS(isActiveVendor ? 'Deactivated' : 'Activated'));
      close();
    }
  };

  const convertToRowCells = (arr) => {
    if (!arr) return [];

    return arr.map((value, index) => {
      const keys = Object.keys(value);
      keys.forEach((key) => {
        if (typeof value[key] === 'boolean') {
          if (key === 'isActive') {
            value.index = index;
            value.action = renderAction(value[key], index, value.id);
            value[key] = renderActive(value[key]);
          }
        }
      });
      if (value.sharePercentage[value.sharePercentage.length - 1] !== '%') {
        value.sharePercentage = `${value.sharePercentage}%`;
      }
      return value;
    });
  };

  const dataConvert = convertToRowCells(data.items);

  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <h3 className="fr-page-title">Admin Portal - Vendors</h3>

        <div className="fr-page-container  bg-transparent" style={{ minHeight: '900px' }}>
          <Table
            headCells={headCells}
            rowCells={dataConvert}
            totalCount={data?.totalItems ?? 0}
            havePagination={true}
            title="Vendors"
            haveSearch
            haveHeaderAction={
              <>
                <h2 className="h6 text-info font-weight-bold m-0">Add Vendor</h2>
                <Button
                  className="mx-3 text-white-hover"
                  size="small"
                  color="tertiary"
                  component={Link}
                  to={ADMIN_PORTAL_URL.VENDOR_ADD.URL}
                >
                  Add
                </Button>
              </>
            }
            service={vendorService}
            api="getVendor"
            setDataFetch={setData}
          />
        </div>
      </Container>
      <Popup
        kind="confirm"
        isOpen={isOpen}
        onConfirm={() => {
          handleOnClickConfirm(selectedVendor);
        }}
        isDisabledConfirm={isDisabledConfirm}
        onCancel={close}
        size="sm"
        title={`${isActiveVendor ? 'Deactivate Vendor' : 'Activate Vendor'}`}
        confirm="Yes"
      >
        {`Are you sure you want to ${isActiveVendor ? 'deactivate' : 'activate'} ${
          selectedVendor?.displayName
        }?`}
      </Popup>
    </div>
  );
};

export default AdminPortalVendor;
