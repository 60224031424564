import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Popup, usePopup } from 'components/popup';
import { isHaveValues } from 'utils/helper';

const UnsavedPopup = ({ values, initialValues, isSubmit, resetForm }) => {
  const [path, setPath] = useState();
  const unblockHandle = useRef();

  const history = useHistory();
  const location = useLocation();
  const [isOpen, open, close] = usePopup();

  useEffect(() => {
    unblockHandle.current = history.block((loca) => {
      if (
        [
          isHaveValues(values),
          JSON.stringify(initialValues) !== JSON.stringify(values),
          !isSubmit
        ].every((boolean) => boolean === true)
      ) {
        setPath(loca.pathname);
        open();
        return false;
      }

      return true;
    });

    return () => {
      unblockHandle.current();
    };
  }, [values, history, open, initialValues, isSubmit]);

  const handleConfirm = () => {
    if (history.location.pathname === location.pathname) {
      close();
      resetForm(initialValues);
    }
    if (unblockHandle) {
      unblockHandle.current();
    }
    history.push(path);
  };

  return (
    <Popup
      kind="confirm"
      isOpen={isOpen}
      onConfirm={handleConfirm}
      onCancel={close}
      size="sm"
      title="Unsaved Changes"
      cancel="Stay"
      confirm="Discard changes"
      colorConfirm="tertiary"
    >
      <>
        <p className="mt-4">
          You are about to leave this page without saving. All changes will be lost.
        </p>
        <p>
          <b>Do you want to leave?</b>
        </p>
      </>
    </Popup>
  );
};

export default UnsavedPopup;
