import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import Input from 'components/input';
import * as Yup from 'yup';
import { gR } from 'constants/helperMessage';
import { Popup } from 'components/popup';
import { useHistory } from 'react-router-dom';
import useToast from 'components/toast/useToast';
import cardService from 'services/card';
import authService from 'services/authorization';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { HTTPCODES } from 'constants/index';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import { SCAN_VENDOR_TRANSACTIONS_URL } from '../../url';

const DesktopScanPopup = ({ isOpen, close, redirectUrl }) => {
  const history = useHistory();
  const { toastError } = useToast();

  const onSubmit = useCallback(
    async (code) => {
      const resp = await cardService.getCardByCodes(code);
      if (resp?.errorCode || !resp?.id) {
        if (resp?.status === HTTPCODES.BAD_REQUEST) {
          toastError(resp.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        } else {
          toastError(ERROR_MESSAGE.CARD_CODE.VALID);
        }
      } else {
        const { id } = resp;
        if (redirectUrl) {
          history.push(redirectUrl.replace(':id', id));
        } else if (authService.isEmployee()) {
          history.push(SCAN_VENDOR_TRANSACTIONS_URL.DETAIL.URL.replace(':id', id));
        } else if (authService.isManager()) {
          history.push(ADMIN_PORTAL_URL.VENDOR_CARD_TRANSACTION_DETAIL.URL.replace(':id', id));
        }
      }
    },
    [history, redirectUrl, toastError]
  );

  const handleOnSubmit = useCallback(
    (values) => {
      if (onSubmit) {
        onSubmit(values.code);
      }
    },
    [onSubmit]
  );

  const initialValues = {
    code: ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      code: Yup.string().required(gR('Code'))
    }),
    onSubmit: handleOnSubmit
  });
  const handleOnClose = () => {
    formik.resetForm();
    close();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Popup
        closeIcon
        kind="confirm"
        isOpen={isOpen}
        onConfirm={formik.submitForm}
        onCancel={handleOnClose}
        size="sm"
        title="Input Code"
        onClose={handleOnClose}
      >
        <Input
          color="tertiary"
          size="small"
          label="Code*"
          name="code"
          onChange={formik.handleChange}
          value={formik.values.code}
          error={formik.touched.code && !!formik.errors.code}
          helperText={formik.touched.code && formik.errors.code}
        />
      </Popup>
    </form>
  );
};

export default DesktopScanPopup;
