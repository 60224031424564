import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EDIT_TYPE, PURPOSE } from 'constants/index';
import useLoading from 'components/loading/useLoading';
import vendorService from 'services/vendor';
import categoryService from 'services/category';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { gS } from 'constants/helperMessage';
import { ERROR_CODES } from 'utils/axios/helper';
import publicService from 'services/public';
import VendorInfo from '../vendorInfo';

const AdminPortalVendorAdd = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [categories, setCategories] = useState([{}]);
  const [regions, setRegions] = useState([{}]);

  const history = useHistory();
  const [showLoading, hideLoading] = useLoading();
  const { toastSuccess, toastError } = useToast();

  const onSubmit = useCallback(
    async (values) => {
      setIsDisabled(true);

      showLoading();

      const {
        legalName,
        firstName,
        lastName,
        displayName,
        workPhoneNumber,
        phoneNumber,
        businessNumber,
        email,
        categoryId,
        regionId,
        website,
        institutionNumber,
        transitNumber,
        accountNumber,
        addressLine1,
        addressLine2,
        city,
        province,
        postalCode,
        charityRate
      } = values;

      const vendor = {
        legalName,
        displayName,
        businessNumber,
        categoryId,
        regionId,
        website,
        institutionNumber,
        transitNumber,
        accountNumber,
        address: { addressLine1, addressLine2, city, province, postalCode },
        primaryContact: { firstName, lastName, email, workPhoneNumber, phoneNumber },
        editType: EDIT_TYPE.BY_ADMIN,
        charityRate
      };

      const resp = await vendorService.createVendor(vendor);
      hideLoading();

      if (resp?.errorCode) {
        toastError(resp.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        setIsDisabled(false);
        return;
      }

      if (ERROR_CODES.includes(resp?.status)) {
        toastError(resp?.statusText || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        setIsDisabled(false);
        return;
      }

      history.push(ADMIN_PORTAL_URL.VENDOR.URL);
      toastSuccess(gS('Created vendor'));
      setIsDisabled(false);
    },
    [hideLoading, history, showLoading, toastError, toastSuccess]
  );

  useEffect(() => {
    const fetchCategories = async () => {
      const resp = await categoryService.getCategories();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        return;
      }
      setCategories(resp);
    };

    const fetchRegions = async () => {
      const resp = await publicService.getRegions();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        return;
      }
      setRegions(resp);
    };

    fetchCategories();
    fetchRegions();
  }, [toastError]);

  return (
    <VendorInfo
      purpose={PURPOSE.ADD}
      onSubmit={onSubmit}
      isDisabled={isDisabled}
      categories={categories}
      regions={regions}
    />
  );
};

export default AdminPortalVendorAdd;
