import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import React from 'react';
import { useParams } from 'react-router-dom';
import AdminPortalCharityView, { InformationTab } from '..';
import AdminPortalCharityInformationForm from '../../information/informationForm';

const AdminPortalCharityViewInformation = () => {
  const { cid } = useParams();

  return (
    <>
      <AdminPortalCharityView tab={InformationTab.value}>
        <AdminPortalCharityInformationForm
          charityId={cid}
          notFoundRedirectUrl={ADMIN_PORTAL_URL.CHARITY.URL}
        />
      </AdminPortalCharityView>
    </>
  );
};

export default AdminPortalCharityViewInformation;
