import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { BUCKET, IMG_LOCAL } from 'constants/index';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import { CircularProgress } from '@mui/material';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import publicService from 'services/public';
import fileService from 'services/file';
import { getIsFunRayZR } from 'utils/helper';
import ShopCharitiyDetailComponent from './component';

const ShopCharitiyDetail = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [vendor, setVendor] = useState({});
  const isFunRayZR = getIsFunRayZR();

  const { toastError } = useToast();

  useEffect(() => {
    const fetchId = async () => {
      setLoading(true);
      const resp = await publicService.getCharity(match.params.id);

      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        history.push(ADMIN_PORTAL_URL.VENDOR.URL);
      } else {
        const { displayName, address, website, description, logoPath, id } = resp;

        setLoading(false);

        setVendor({
          displayName,
          addressLine1: address?.addressLine1,
          city: address?.city,
          province: address?.province,
          postalCode: address?.postalCode,
          website,
          description,
          logoSrc: logoPath
            ? `${fileService._baseUrl}/${BUCKET.CHARITY}/${logoPath}`
            : (isFunRayZR ? IMG_LOCAL.FUNRAYZR_LOGO : IMG_LOCAL.RAYZR_LOGO),
          id
        });
      }
    };
    fetchId();
  }, [history, isFunRayZR, match.params.id, toastError]);

  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <ShopCharitiyDetailComponent vendor={vendor} />
  );
};

export default withRouter(ShopCharitiyDetail);
