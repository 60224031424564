import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { default as MButton } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { COLOR, theme } from 'constants/theme';

const TOTAL_PADDING_X = {
  SMALL: 40,
  MEDIUM: 28,
  LARGE: 20
};

const KIND = {
  BUTTON: 'button',
  LINK: 'link',
  ICON: 'icon'
};

const useStyles = makeStyles(() => ({
  button: {
    fontFamily: `${theme.fontFamily.quicksandMedium}!important`,
    textAlign: 'center',
    textTransform: 'none !important',
    '& .MuiTouchRipple-root': {
      zIndex: 10
    },
    '& .z-text': {
      zIndex: 20
    },
    '&.Mui-disabled': {
      background: `${theme.colors.gray.button}!important`,
      color: `${theme.colors.white.text}!important`
    }
  },

  minMedium: ({ minWidth }) => ({
    minWidth: `${minWidth - TOTAL_PADDING_X.MEDIUM}px`
  }),
  minLarge: ({ minWidth }) => ({
    minWidth: `${minWidth - TOTAL_PADDING_X.LARGE}px`,
    '& .large': {
      padding: '20px 10px !important',
      lineHeight: '25px !important',
      fontSize: '20px !important'
    }
  }),
  minSmall: ({ minWidth }) => ({
    minWidth: `${minWidth - TOTAL_PADDING_X.SMALL}px`
  }),
  bgHoverAplly: ({ bgHover }) => ({
    '&:hover': {
      backgroundColor: `${bgHover[0]}!important`,
      color: bgHover?.[1]
    }
  }),
  colorOnClickApply: ({ colorOnClick }) => ({
    '& .MuiTouchRipple-root span': {
      backgroundColor: colorOnClick[0],
      opacity: colorOnClick?.[1] ?? 1
    }
  }),
  large: {
    padding: '20px 10px !important',
    lineHeight: '25px !important',
    fontSize: '20px !important'
  },
  medium: {
    padding: '7px 14px !important',
    lineHeight: '25px !important',
    fontSize: '20px !important'
  },
  small: {
    padding: '8px 20px !important',
    lineHeight: '16px !important',
    fontSize: '12px !important'
  },
  wideHover: {
    '&::before': {
      content: '"."',
      color: 'transparent',
      width: '0px'
    },

    '&::after': {
      content: '". "',
      color: 'transparent',
      width: '0px'
    },
    '&:hover': {
      '&::before': {
        width: '25px',
        transition: 'all 0.3s ease'
      },
      '&::after': {
        width: '25px',
        transition: 'all 0.3s ease'
      }
    }
  }
}));

const Button = ({
  kind,
  color,
  variant,
  size,
  disabled,
  children,
  className,
  variantLink,
  colorLink,
  minWidth,
  wider,
  colorOnClick,
  bgHover,
  ...rest
}) => {
  const {
    button,
    minMedium,
    minLarge,
    minSmall,
    bgHoverAplly,
    colorOnClickApply,
    large,
    medium,
    small,
    wideHover
  } = useStyles({
    minWidth,
    colorOnClick,
    bgHover
  });

  const getMinWidth = (sizeSt, isMinWidth) => {
    if (!isMinWidth) return;

    const miniWidth = {
      medium: isMinWidth ? minMedium : null,
      large: isMinWidth ? minLarge : null,
      small: isMinWidth ? minSmall : null
    };
    return miniWidth[sizeSt];
  };

  const getSize = (sizeProp) => {
    if (!sizeProp) return;

    const res = {
      large,
      medium,
      small
    };

    return res[sizeProp];
  };

  if (kind === KIND.BUTTON) {
    return (
      <MButton
        color={color}
        variant={variant}
        disabled={disabled}
        className={`${button} ${wider && wideHover} ${getSize(size)} ${
          colorOnClick.length && colorOnClickApply
        } ${bgHover.length && bgHoverAplly} ${className}`}
        {...rest}
      >
        <span className={`${getMinWidth(size, minWidth)} z-text`}>{children}</span>
      </MButton>
    );
  }

  if (kind === KIND.LINK) {
    return (
      <Link
        color={colorLink}
        variant={variantLink}
        component="button"
        className={`text-decoration-none ${className}`}
        {...rest}
      >
        {children}
      </Link>
    );
  }

  return (
    <IconButton color={color} size={size} disabled={disabled} className={className} {...rest}>
      {children}
    </IconButton>
  );
};

Button.defaultProps = {
  kind: KIND.BUTTON,
  color: COLOR.PRIMARY,
  variant: 'contained',
  size: 'medium',
  disabled: false,
  minWidth: 0,
  wider: false,
  colorOnClick: [],
  bgHover: []
};

Button.propTypes = {
  kind: PropTypes.oneOf([KIND.BUTTON, KIND.LINK, KIND.ICON]),
  color: PropTypes.oneOf([
    COLOR.INHERIT,
    COLOR.PRIMARY,
    COLOR.SECONDARY,
    COLOR.SUCCESS,
    COLOR.ERROR,
    COLOR.INFO,
    COLOR.WARNING,
    COLOR.TERTIARY,
    COLOR.QUATERNARY,
    COLOR.BLACK,
    COLOR.OCEAN
  ]),
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  disabled: PropTypes.bool,
  minWidth: PropTypes.number,
  wider: PropTypes.bool,
  colorOnClick: PropTypes.arrayOf(PropTypes.any),
  bgHover: PropTypes.arrayOf(PropTypes.string)
};

export default Button;
