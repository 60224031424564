import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Carousel as BootCarousel,
  CarouselItem,
  CarouselIndicators,
  Row,
  Col,
  Container
} from 'reactstrap';
import { theme } from 'constants/theme';
import Button from 'components/button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useStyles = makeStyles(() => ({
  title: {
    padding: '0 25px',
    marginBottom: '20px',
    marginTop: '12px',
    color: theme.colors.white.title,
    fontFamily: theme.fontFamily.quicksandMedium.trim,
    '@media(max-width: 414px)': {
      textAlign: 'center'
    }
  },
  carousel: {
    padding: '0 0 0 0'
  },
  navigateControl: {
    width: '30px',
    bottom: '0',
    '& button': {
      '& svg': {
        color: theme.colors.violet.button
      }
    },
    '& span:first-child': {
      color: theme.colors.black.button
    },
    '&.carousel-control-prev': {
      left: '5px'
    },
    '&.carousel-control-next': {
      right: '5px'
    }
  },
  indicator: {
    bottom: '-25px',
    '& li': {
      height: '12px',
      width: '12px',
      borderRadius: '50%',
      margin: '0 8px'
    }
  }
}));

const Carousel = ({
  title,
  items,
  itemRender,
  indicator,
  cycleNavigation,
  navigateControl,
  ...rest
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const classes = useStyles();

  const next = () => {
    if (animating) return;
    if (!cycleNavigation && activeIndex === items.length - 1) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    if (!cycleNavigation && activeIndex === 0) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  let keyIndex = 0;
  const slides = items.map((item) => {
    keyIndex += 1;
    const key = `key-${keyIndex}`;
    return (
      <CarouselItem
        slide={true}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={key}
      >
        {itemRender(item)}
      </CarouselItem>
    );
  });

  return (
    <div className="w-100">
      <Container className="x-container">
        <Row>
          <Col xs={12} className="d-flex align-items-center">
            {title && <h2 className={classes.title}>{title}</h2>}
          </Col>
          <div className="w-100">
            <div className={classes.carouselContainer}>
              <BootCarousel
                className={classes.carousel}
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                {...rest}
              >
                {indicator && (
                  <CarouselIndicators
                    className={classes.indicator}
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                )}
                {slides}
                {navigateControl && (
                  <div className={`${classes.navigateControl} carousel-control-prev`}>
                    <Button kind="icon" onClick={previous}>
                      <ArrowBackIosNewIcon fontSize="large" />
                    </Button>
                  </div>
                )}
                {navigateControl && (
                  <div className={`${classes.navigateControl} carousel-control-next`}>
                    <Button kind="icon" onClick={next}>
                      <ArrowForwardIosIcon fontSize="large" />
                    </Button>
                  </div>
                )}
              </BootCarousel>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

Carousel.defaultProps = {
  title: '',
  items: [],
  cycleNavigation: true,
  indicator: true,
  navigateControl: true
};

Carousel.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
  itemRender: PropTypes.func.isRequired,
  cycleNavigation: PropTypes.bool,
  indicator: PropTypes.bool,
  navigateControl: PropTypes.bool
};

export default Carousel;
