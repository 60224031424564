import React from 'react';
import { Paper } from '@mui/material';
import { Col, Container, Row } from 'reactstrap';
import { CARD_TYPE } from 'constants/index';

import { FR_APP_KEY } from 'configs';
import imageHelper from 'utils/image';
import { theme } from 'constants/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  vendorInfo: {
    '& .display-name, .address': {
      color: theme.colors.black.text,
      fontFamily: `${theme.fontFamily.quicksandMedium} !important`
    }
  }
}));

const VendorCard = ({ name, address, vendorLogo, isChairityCard, className, cardType }) => {
  const logoPath = imageHelper.getLogo();
  const { vendorInfo } = useStyles();
  return (
    <Paper
      elevation={3}
      className={`${isChairityCard ? 'c-charity-card-item' : 'c-vendor-card-item'} ${className}`}
    >
      <Container>
        <Row>
          <Col
            xs={7}
            className={`d-flex justify-content-center align-items-center c-vendor-logo ${
              isChairityCard ? 'mt-4' : 'mt-5'
            }`}
            style={{ minHeght: '116px' }}
          >
            <img
              src={vendorLogo ?? logoPath}
              alt={name ?? FR_APP_KEY}
              loading="lazy"
              className="img-fluid rounded"
              style={{ maxHeght: '124px' }}
            />
          </Col>
          <Col
            xs={5}
            className={`d-flex flex-column justify-content-center align-items-center c-vendor-logo px-0 ${vendorInfo} ${
              isChairityCard ? 'mt-4' : 'mt-5'
            }`}
          >
            <p className="text-center mb-0 display-name">{name}</p>
            <p className="text-center address">{address}</p>
          </Col>
          <Col xs={7} />
          <Col xs={5} className="text-center">
            <img
              src={CARD_TYPE[cardType] ?? logoPath}
              alt={FR_APP_KEY}
              height={isChairityCard ? 40 : 50}
              loading="lazy"
              className="img-fluid"
            />
          </Col>
        </Row>
      </Container>
    </Paper>
  );
};

export default VendorCard;
