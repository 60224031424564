import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Popup } from 'components/popup';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import cardService from 'services/card';
import authService from 'services/authorization';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import Input from 'components/input';
import { gR, gS } from 'constants/helperMessage';
import { HTTPCODES } from 'constants/index';
import { useHistory, useParams } from 'react-router-dom';
import { SCAN_VENDOR_TRANSACTIONS_URL } from '../url';

const RedeemPopup = ({ isOpenRedeem, closeRedeem, id, setRerender, redeemSuccess }) => {
  const { toastSuccess, toastError } = useToast();
  const { push } = useHistory();
  const { vid } = useParams();
  const [isDisabledConfirm, setIsDisabledConfirm] = useState(false);
  const formik = useFormik({
    initialValues: { value: '' },
    validationSchema: Yup.object({
      value: Yup.number()
        .required(gR('Value'))
        .typeError(ERROR_MESSAGE.VALUE.VALID)
        .min(1, ERROR_MESSAGE.VALUE.MIN)
        .max(9999999999, ERROR_MESSAGE.VALUE.MAX)
    }),
    onSubmit: async ({ value }) => {
      setIsDisabledConfirm(true);
      const respone = await cardService.putRedeem(id, { value: parseFloat(value) });
      if (respone?.errorCode) {
        toastError(respone.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        if (respone?.status === HTTPCODES.BAD_REQUEST) {
          if (authService.isEmployee()) {
            push(SCAN_VENDOR_TRANSACTIONS_URL.LIST.URL);
          } else if (authService.isManager()) {
            push(ADMIN_PORTAL_URL.VENDOR_CARD_TRANSACTION.URL);
          } else if (authService.isAdministrator() || authService.isSuperAdministrator()) {
            push(ADMIN_PORTAL_URL.VENDOR_VIEW_CARD_TRANSACTION.URL.replace(':vid', vid));
          }
        }
      } else {
        toastSuccess(gS('Redeem Value'));
        formik.resetForm();
        closeRedeem();
        setRerender(Math.random());
        setIsDisabledConfirm(false);
        if (redeemSuccess) {
          redeemSuccess(parseFloat(value));
        }
      }
    }
  });

  const handleOnClose = () => {
    closeRedeem();
    formik.resetForm();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Popup
        closeIcon
        kind="confirm"
        isOpen={isOpenRedeem}
        onConfirm={formik.submitForm}
        onCancel={closeRedeem}
        size="sm"
        title="Redeem Value"
        onClose={handleOnClose}
        isDisabledConfirm={isDisabledConfirm}
      >
        <Input
          color="tertiary"
          size="small"
          label="Value*"
          name="value"
          onChange={formik.handleChange}
          value={formik.values.value}
          error={formik.touched.value && !!formik.errors.value}
          helperText={formik.touched.value && formik.errors.value}
        />
      </Popup>
    </form>
  );
};

export default RedeemPopup;
