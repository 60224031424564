import React from 'react';
import { Container } from 'reactstrap';
import authService from 'services/authorization';
import { SCAN_VENDOR_TRANSACTIONS_URL } from '../url';
import ConsumerCardTransaction from './cardTransaction';

const CardTranDetail = () => {
  const tenantId = authService.getTenantId();

  return (
    <div className="fr-background quicksand pt-1">
      <Container>
        <div className="fr-page-container mt-5" style={{ minHeight: '400px' }}>
          <ConsumerCardTransaction
            tenantId={tenantId}
            notFoundRedirectUrl={SCAN_VENDOR_TRANSACTIONS_URL.LIST.URL}
          />
        </div>
      </Container>
    </div>
  );
};

export default CardTranDetail;
