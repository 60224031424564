import qs from 'qs';
import { ERROR_MESSAGE } from 'constants/errorMessage';

const getResponseError = (err) => {
  let result = {
    errorCode: ERROR_MESSAGE.UNKNOWN.CODE,
    errorMessage: ERROR_MESSAGE.UNKNOWN.MESSAGE
  };
  const { response } = err;
  if (response?.data) {
    result = {
      errorCode: response.data?.errorCode,
      errorMessage: response.data?.errorMessage
    };
  }
  return result;
};

const getParam = (path, param) => {
  const params = qs.parse(path);
  return params[param];
};

const ERROR_CODES = [400, 401, 403, 404, 500, 502, 503];

export { getResponseError, getParam, ERROR_CODES };
