import React from 'react';
import ContainerFooterLink from 'components/containerFooterLink';
import { TERMS_OF_USE } from 'constants/globalContent';

const TermOfUse = () => {
  return (
    <ContainerFooterLink title={TERMS_OF_USE.termAndCondition}>
      <p>{TERMS_OF_USE.pleaseReadTerm}</p>
      <h1>{TERMS_OF_USE.interpretationAndDef}</h1>
      <h2>{TERMS_OF_USE.interpretation}</h2>
      <p>{TERMS_OF_USE.termInterpretation}</p>
      <h2>{TERMS_OF_USE.definitions}</h2>
      <p>{TERMS_OF_USE.purposeDef}</p>
      <ul>
        <li>
          <p>
            <strong>{TERMS_OF_USE.appDef}</strong>{TERMS_OF_USE.appDefDetail}
          </p>
        </li>
        <li>
          <p>
            <strong>{TERMS_OF_USE.appStoreDef}</strong>{TERMS_OF_USE.appStoreDetail}
          </p>
        </li>
        <li>
          <p>
            <strong>{TERMS_OF_USE.affiliateDef}</strong>{TERMS_OF_USE.affiliateDefDetail}
          </p>
        </li>
        <li>
          <p>
            <strong>{TERMS_OF_USE.accDef}</strong>{TERMS_OF_USE.accDefDetail}
          </p>
        </li>
        <li>
          <p>
            <strong>{TERMS_OF_USE.countryDef}</strong>{TERMS_OF_USE.countryDefDetail}
          </p>
        </li>
        <li>
          <p>
            <strong>{TERMS_OF_USE.companyDef}</strong>{TERMS_OF_USE.companyDefDetail}
          </p>
        </li>
        <li>
          <p>
            <strong>{TERMS_OF_USE.deviceDef}</strong>{TERMS_OF_USE.deviceDefDetail}
          </p>
        </li>
        <li>
          <p>
            <strong>{TERMS_OF_USE.goodsDef}</strong>{TERMS_OF_USE.goodsDefDetail}
          </p>
        </li>
        <li>
          <p>
            <strong>{TERMS_OF_USE.inAppPurchaseDef}</strong>{TERMS_OF_USE.inAppPurchaseDefDetail}
          </p>
        </li>
        <li>
          <p>
            <strong>{TERMS_OF_USE.orderDef}</strong>{TERMS_OF_USE.orderDefDetail}
          </p>
        </li>
        <li>
          <p>
            <strong>{TERMS_OF_USE.serviceDef}</strong>{TERMS_OF_USE.serviceDefDetail}
          </p>
        </li>
        <li>
          <p>
            <strong>{TERMS_OF_USE.termsAndConditionDef}</strong>{TERMS_OF_USE.termsAndConditionDefDetail}
          </p>
        </li>
        <li>
          <p>
            <strong>{TERMS_OF_USE.thirdPartyMediaDef}</strong>{TERMS_OF_USE.thirdPartyMediaDefDetail}
          </p>
        </li>
        <li>
          <p>
            <strong>{TERMS_OF_USE.websiteDef}</strong>{TERMS_OF_USE.websiteDefDetail}
            <a
              href="https://funrayzr.com"
              rel="external nofollow noopener noreferrer"
              target="_blank"
            >
              {TERMS_OF_USE.websiteLink}
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>{TERMS_OF_USE.youDef}</strong>{TERMS_OF_USE.youDefDetail}
          </p>
        </li>
      </ul>
      <h1>{TERMS_OF_USE.acknowledgment}</h1>
      <p>
        {TERMS_OF_USE.acknowledgmentDesc1}
      </p>
      <p>
        {TERMS_OF_USE.acknowledgmentDesc2}
      </p>
      <p>
        {TERMS_OF_USE.acknowledgmentDesc3}
      </p>
      <p>
        {TERMS_OF_USE.acknowledgmentDesc4}
      </p>
      <p>
        {TERMS_OF_USE.acknowledgmentDesc5}
      </p>
      <h1>{TERMS_OF_USE.placeOrdersOrGoods}</h1>
      <p>
        {TERMS_OF_USE.placeOrdersOrGoodsDesc}
      </p>
      <h2>{TERMS_OF_USE.yourInfo}</h2>
      <p>
        {TERMS_OF_USE.yourInfoDesc1}
      </p>
      <p>
        {TERMS_OF_USE.yourInfoDesc2}
      </p>
      <p>
        {TERMS_OF_USE.yourInfoDesc3}
      </p>
      <h2>{TERMS_OF_USE.orderCancellation}</h2>
      <p>
        {TERMS_OF_USE.orderCancellationDesc1}
      </p>
      <ul>
        <li>{TERMS_OF_USE.orderCancellationDesc1Bullets.bullet1}</li>
        <li>{TERMS_OF_USE.orderCancellationDesc1Bullets.bullet2}</li>
        <li>{TERMS_OF_USE.orderCancellationDesc1Bullets.bullet3}</li>
      </ul>
      <p>
        {TERMS_OF_USE.orderCancellationDesc2}
      </p>
      <h2>{TERMS_OF_USE.availabilityErrorInaccuracies}</h2>
      <p>
        {TERMS_OF_USE.availabilityErrorInaccuraciesDesc1}
      </p>
      <p>
        {TERMS_OF_USE.availabilityErrorInaccuraciesDesc2}
      </p>
      <h2>{TERMS_OF_USE.pricesPolicy}</h2>
      <p>
        {TERMS_OF_USE.pricesPolicyDesc1}
      </p>
      <p>
        {TERMS_OF_USE.pricesPolicyDesc2}
      </p>
      <h2>{TERMS_OF_USE.paymentsTerm}</h2>
      <p>
        {TERMS_OF_USE.paymentsTermDesc1}
      </p>
      <p>
        {TERMS_OF_USE.paymentsTermDesc2}
      </p>
      <h1>{TERMS_OF_USE.inAppPurchaseTerm}</h1>
      <p>
        {TERMS_OF_USE.inAppPurchaseTermDesc1}
      </p>
      <p>
        {TERMS_OF_USE.inAppPurchaseTermDesc2}
      </p>
      <p>
        {TERMS_OF_USE.inAppPurchaseTermDesc3}
      </p>
      <p>
        {TERMS_OF_USE.inAppPurchaseTermDesc4}
      </p>
      <p>
        {TERMS_OF_USE.inAppPurchaseTermDesc5}
      </p>
      <p>
        {TERMS_OF_USE.inAppPurchaseTermDesc6}
      </p>
      <h1>{TERMS_OF_USE.userAccount}</h1>
      <p>
        {TERMS_OF_USE.userAccountDesc1}
      </p>
      <p>
        {TERMS_OF_USE.userAccountDesc2}
      </p>
      <p>
        {TERMS_OF_USE.userAccountDesc3}
      </p>
      <p>
        {TERMS_OF_USE.userAccountDesc4}
      </p>
      <h1>{TERMS_OF_USE.intellectualProp}</h1>
      <p>
        {TERMS_OF_USE.intellectualPropDesc1}
      </p>
      <p>
        {TERMS_OF_USE.intellectualPropDesc2}
      </p>
      <p>
        {TERMS_OF_USE.intellectualPropDesc3}
      </p>
      <h1>{TERMS_OF_USE.linkToOtherWeb}</h1>
      <p>
        {TERMS_OF_USE.linkToOtherWebDesc1}
      </p>
      <p>
        {TERMS_OF_USE.linkToOtherWebDesc2}
      </p>
      <p>
        {TERMS_OF_USE.linkToOtherWebDesc3}
      </p>
      <h1>{TERMS_OF_USE.termination}</h1>
      <p>
        {TERMS_OF_USE.terminationDesc1}
      </p>
      <p>
        {TERMS_OF_USE.terminationDesc2}
      </p>
      <h1>{TERMS_OF_USE.limitOfLiability}</h1>
      <p>
        {TERMS_OF_USE.limitOfLiabilityDesc1}
      </p>
      <p>
        {TERMS_OF_USE.limitOfLiabilityDesc2}
      </p>
      <p>
        {TERMS_OF_USE.limitOfLiabilityDesc3}
      </p>
      <h1>{TERMS_OF_USE.disclaimer}</h1>
      <p>
        {TERMS_OF_USE.disclaimerDesc1}
      </p>
      <p>
        {TERMS_OF_USE.disclaimerDesc2}
      </p>
      <p>
        {TERMS_OF_USE.disclaimerDesc3}
      </p>
      <h1>{TERMS_OF_USE.governingLaw}</h1>
      <p>
        {TERMS_OF_USE.governingLawDesc}
      </p>
      <h1>{TERMS_OF_USE.disputesResolution}</h1>
      <p>
        {TERMS_OF_USE.disputesResolutionDesc}
      </p>
      <h1>{TERMS_OF_USE.euUser}</h1>
      <p>
        {TERMS_OF_USE.euUserDesc}
      </p>
      <h1>{TERMS_OF_USE.usLegalCompliance}</h1>
      <p>
        {TERMS_OF_USE.usLegalComplianceDesc}
      </p>
      <h1>{TERMS_OF_USE.severabilityAndWaiver}</h1>
      <h2>{TERMS_OF_USE.severability}</h2>
      <p>
        {TERMS_OF_USE.severabilityDesc}
      </p>
      <h2>{TERMS_OF_USE.waiver}</h2>
      <p>
        {TERMS_OF_USE.waiverDesc}
      </p>
      <h1>{TERMS_OF_USE.transInter}</h1>
      <p>
        {TERMS_OF_USE.transInterDesc}
      </p>
      <h1>{TERMS_OF_USE.changeToTermAndCondition}</h1>
      <p>
        {TERMS_OF_USE.changeToTermAndConditionDesc1}
      </p>
      <p>
        {TERMS_OF_USE.changeToTermAndConditionDesc2}
      </p>
      <h1>{TERMS_OF_USE.contactUsTerm}</h1>
      <p>{TERMS_OF_USE.contactUsTermDesc}</p>
      <ul>
        <li>
          <p>{TERMS_OF_USE.contactUsTermDescBullets.bullet1}</p>
        </li>
        <li>
          <p>{TERMS_OF_USE.contactUsTermDescBullets.bullet2}</p>
        </li>
      </ul>
    </ContainerFooterLink>
  );
};
export default TermOfUse;
