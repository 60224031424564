import PrivacyPolicy from '.';

import { PRIVACY_POLICY_URL } from './url';

export const PRIVACY_POLICY_ROUTES = [
  {
    name: PRIVACY_POLICY_URL.PRIVACY_POLICY.NAME,
    path: PRIVACY_POLICY_URL.PRIVACY_POLICY.URL,
    component: PrivacyPolicy,
    isPrivate: false
  }
];
