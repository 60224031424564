import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PURPOSE } from 'constants/index';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { gS } from 'constants/helperMessage';
import organizationService from 'services/organization';
import useLoading from 'components/loading/useLoading';
import useToast from 'components/toast/useToast';
import OrganizationInfo from '../organizationInfo';

const AdminPortalOrganizationAddForm = ({ redirectUrl, backUrl }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const history = useHistory();
  const { toastSuccess, toastError } = useToast();
  const [showLoading, hideLoading] = useLoading();

  const onSubmit = useCallback(
    async ({ name, description }) => {
      setIsDisabled(true);
      showLoading();
      const resp = await organizationService.createOrganization({
        name,
        description
      });
      hideLoading();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        history.push(redirectUrl);
        toastSuccess(gS('Created organization type'));
      }
      setIsDisabled(false);
    },
    [hideLoading, history, redirectUrl, showLoading, toastError, toastSuccess]
  );

  return (
    <OrganizationInfo
      purpose={PURPOSE.ADD}
      rowCells={[]}
      organization={{ email: '', description: '' }}
      cancelUrl={backUrl}
      onSubmit={onSubmit}
      isDisabled={isDisabled}
    />
  );
};

export default AdminPortalOrganizationAddForm;
