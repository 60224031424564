import { ERROR_MESSAGE } from 'constants/errorMessage';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { gR, gS } from 'constants/helperMessage';
import { Popup } from 'components/popup';
import useToast from 'components/toast/useToast';
import Input from 'components/input';
import { Col, Row } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import Button from 'components/button';
import contactUsService from 'services/contactUs';
import { theme } from 'constants/theme';

const useStyles = makeStyles(() => ({
  contactUsPopup: {
    '& .MuiDialogActions-root': {
      display: 'none'
    },
    '& .MuiInputLabel-root': {
      fontFamily: theme.fontFamily.quicksandBold,
      fontSize: theme.fontSize.s15
    },
    '& .title-popup': {
      fontFamily: theme.fontFamily.quicksandBold,
      fontSize: theme.fontSize.s26,
      color: theme.colors.black.title
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.colors.gray.main,
      borderWidth: '2px'
    }
  },
  button: {
    maxWidth: '250px',
    '&.MuiButton-root': {
      color: theme.colors.gray.text,
      borderColor: theme.colors.gray.main,
      borderWidth: '2px',
      marginBottom: '5px'
    }
  },
  messageArea: {
    '& textarea[name="message"]': {
      minHeight: '167px'
    }
  }
}));

const ContactUsPopup = ({ isOpen, closeContactUs }) => {
  const { toastSuccess, toastError } = useToast();
  const { contactUsPopup, messageArea, button } = useStyles();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      organization: '',
      email: '',
      phone: '',
      message: ''
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(gR('First Name')),
      lastName: Yup.string().required(gR('Last Name')),
      email: Yup.string().required(gR('Your email')).email(ERROR_MESSAGE.EMAIL.VALID),
      phone: Yup.number().typeError(ERROR_MESSAGE.PHONE_NUMBER.VALID),
      message: Yup.string().required(gR('Message'))
    }),
    onSubmit: async (values) => {
      const respone = await contactUsService.createContactUs(values);
      if (respone?.errorCode) {
        toastError(respone.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        toastSuccess(gS('Send Message'));
        formik.resetForm();
        closeContactUs();
      }
    }
  });

  const onCancel = () => {
    closeContactUs();
    formik.resetForm();
    formik.setErrors({});
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Popup
        closeIcon
        kind="confirm"
        isOpen={isOpen}
        onConfirm={() => {}}
        onCancel={closeContactUs}
        size="md"
        title=""
        className={contactUsPopup}
        onClose={() => {}}
      >
        <div style={{ minHeight: '300px' }}>
          <div className="mb-5">
            <p className="title-popup">CONTACT US</p>
            <h6 className="text-black font-bold">
              Please use the contact form below to get in touch.
            </h6>
          </div>
          <Row className="flex-unset">
            <Col md={6} className="flex-unset">
              <div className="mb-2 flex">
                <Input
                  fullWidth
                  variant="outlined"
                  color="black"
                  size="small"
                  label="First Name*"
                  name="firstName"
                  onChange={formik.handleChange}
                  className="mr-2"
                  value={formik.values.firstName}
                  error={formik.touched.firstName && !!formik.errors.firstName}
                  helperText={formik.touched.firstName && formik.errors.firstName}
                />
                <Input
                  fullWidth
                  color="black"
                  size="small"
                  label="Last Name*"
                  name="lastName"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  error={formik.touched.lastName && !!formik.errors.lastName}
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </div>
              <div className="mb-2">
                <Input
                  fullWidth
                  color="black"
                  size="small"
                  label="Organization"
                  name="organization"
                  onChange={formik.handleChange}
                  value={formik.values.organization}
                  error={formik.touched.organization && !!formik.errors.organization}
                  helperText={formik.touched.organization && formik.errors.organization}
                />
              </div>
              <div className="mb-2">
                <Input
                  fullWidth
                  color="black"
                  size="small"
                  label="Your Email*"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.touched.email && !!formik.errors.email}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              <div className="mb-2">
                <Input
                  fullWidth
                  color="black"
                  size="small"
                  label="Your Phone"
                  name="phone"
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  error={formik.touched.phone && !!formik.errors.phone}
                  helperText={formik.touched.phone && formik.errors.phone}
                />
              </div>
            </Col>
            <Col md={6} className="flex-unset">
              <Input
                fullWidth
                color="black"
                size="small"
                label="Message*"
                multiline
                className={messageArea}
                rows={7}
                name="message"
                onChange={formik.handleChange}
                value={formik.values.message}
                error={formik.touched.message && !!formik.errors.message}
                helperText={formik.touched.message && formik.errors.message}
              />
            </Col>
          </Row>
          <Row className="flex-unset my-4">
            <Col md={6} className="flex-unset">
              <Button
                onClick={onCancel}
                color="black"
                variant="outlined"
                autoFocus
                size="small"
                className={`mr-2 ${button} font-bold`}
                fullWidth
                minWidth={250}
              >
                Cancel
              </Button>
            </Col>
            <Col md={6} className="flex-unset">
              <Button
                color="black"
                autoFocus
                size="small"
                className={`mr-2 ${button}`}
                fullWidth
                minWidth={250}
                onClick={formik.submitForm}
              >
                Send Message
              </Button>
            </Col>
          </Row>
        </div>
      </Popup>
    </form>
  );
};

export default ContactUsPopup;
