import Checkout from ".";
import CheckoutSuccess from "./checkoutSuccess";
import { CHECKOUT_URL } from "./url";

export const CHECKOUT_ROUTES = [
  {
    name: CHECKOUT_URL.CHECKOUT.NAME,
    path: CHECKOUT_URL.CHECKOUT.URL,
    component: Checkout,
    isPrivate: true
  },
  {
    name: CHECKOUT_URL.CHECKOUT_SUCCESS.NAME,
    path: CHECKOUT_URL.CHECKOUT_SUCCESS.URL,
    component: CheckoutSuccess,
    isPrivate: true
  }
];
