import qs from 'qs';
import { FR_APP_KEY, FUNRAYZR_API_URL } from 'configs';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import { mapResponse } from 'api/responseMapping';
import { CONFIRM_PURPOSE, SET_PASSWORD_PURPOSE, STATUS, URL_PARAM, VERIFICATION } from 'constants/index';

const Api = (axios) => {
  const accountUrl = `${FUNRAYZR_API_URL}/Accounts`;

  const getAccountUserDetail = async (id) => {
    const resp = await axios.get(`${accountUrl}/${id}`);

    return mapResponse(resp);
  };

  const getAccountUser = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${accountUrl}?${params}`);

    return mapResponse(resp, true);
  };

  const editUser = async (id, user) => {
    const resp = await axios.put(`${accountUrl}/${id}`, {
      ...user
    });

    return mapResponse(resp);
  };

  const statusRole = async (id, status) => {
    const resp = await axios.put(`${accountUrl}/roles/${id}/status/${status}`);

    return mapResponse(resp);
  };

  const resendEmailConfirm = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${accountUrl}/resend-email-confirmation?${params}`);

    return mapResponse(resp);
  };

  const statusUser = async (email, accountStatus) => {
    let params = {
      email,
      accountStatus
    };
    if (accountStatus === STATUS.ACTIVATE) {
      params = {
        ...params,
        successReturnUrl: encodeURIComponent(`${window.location.origin}${ADMIN_PORTAL_URL.SETUP_PASSWORD.URL}?${VERIFICATION.PURPOSE}=${SET_PASSWORD_PURPOSE.APPROVAL}`),
        failedReturnUrl: encodeURIComponent(`${window.location.origin}${ADMIN_PORTAL_URL.SETUP_PASSWORD_FAILED.URL}?${URL_PARAM.EMAIL}=${email}&${URL_PARAM.PURPOSE}=${CONFIRM_PURPOSE.APPROVAL}`)
      };
    }
    const resp = await axios.put(`${accountUrl}/status`, params);

    return mapResponse(resp);
  };

  const invitedUser = async (user) => {
    const { email } = user;
    const resp = await axios.post(`${accountUrl}/invited`, {
      ...user,
      successReturnUrl: `${window.location.origin}${ADMIN_PORTAL_URL.SETUP_PASSWORD.URL}`,
      failedReturnUrl: `${window.location.origin}${ADMIN_PORTAL_URL.SETUP_PASSWORD_FAILED.URL}?${URL_PARAM.EMAIL}=${email}`,
      appName: FR_APP_KEY
    });

    return mapResponse(resp);
  };

  return {
    getAccountUserDetail,
    getAccountUser,
    editUser,
    statusRole,
    resendEmailConfirm,
    statusUser,
    invitedUser
  };
};

export default Api;
