const gR = (field) => {
  return `${field} field is required`;
};

const gMax = (field, max) => {
  return `${field} has a maximum limit of ${max} characters.`;
};

const gMin = (field, min) => {
  return `${field} must be at least ${min} characters`;
};

const gS = (action) => {
  return `${action} successfully`;
};

export { gR, gS, gMax, gMin };
