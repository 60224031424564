import React from 'react';
import { Col, Container, Row } from "reactstrap";
import BoxInfo from 'components/boxInfo';

const RegisterCharitySuccess = () => {

  return (
    <div className="fr-background fr-box-container">
      <div className="fr-box">
        <Container>
          <div className="fr-page-container fr-content">
            <BoxInfo title="Register Charity Or Non-Profit Cause Successully!" />
            <Row className="text-center mt-5">
              <Col md={12} className="my-1">
                <p className="fr-content-sub-title mb-0">Thank you for registering your account with Funrayzr, your request has been sent to our Administrator.</p>
                <p className="fr-content-sub-title mb-0">We will contact you soon</p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default RegisterCharitySuccess;
