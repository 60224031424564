import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import LoginMenu from 'components/userMenu/loginMenu';
import { FR_APP_KEY } from 'configs';
import { ABOUT_URL } from 'pages/about/url';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import { Link, useHistory } from 'react-router-dom';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import authService from 'services/authorization';
import imageHelper from 'utils/image';
import urlHelper from 'utils/url';
import { LANDING_URL } from 'pages/landing/url';

const AdminNavMenu = () => {
  const isCancelled = useRef(false);
  const [collapsed, setCollapsed] = useState(true);
  const [adminPortalPath, setAdminPortalPath] = useState(window.location.pathname);
  const { push, listen } = useHistory();

  useEffect(() => {
    const setStateValue = (location) => {
      const { pathname } = location;
      if (!isCancelled.current) {
        setAdminPortalPath(pathname);
      }
      const isAdministrator = authService.isSuperAdministrator() || authService.isAdministrator() || authService.isManager();
      const isSetupPassword =
        urlHelper.isMatchUrl(ADMIN_PORTAL_URL.SETUP_PASSWORD.URL) ||
        urlHelper.isMatchUrl(ADMIN_PORTAL_URL.SETUP_PASSWORD_FAILED.URL);
      const isSignIn = urlHelper.isSignIn();
      if (!isAdministrator && !isSignIn && !isSetupPassword) {
        push(ABOUT_URL.ABOUT.URL);
      }
    };

    setStateValue(window.location);
    listen(async (location) => {
      const isAdminPortal = urlHelper.isMatchUrl(ADMIN_PORTAL_URL.ADMIN_PORTAL.URL);
      if (isAdminPortal) {
        setStateValue(location);
      }
    });

    return () => {
      isCancelled.current = true;
    };
  }, [listen, push]);

  const adminToggleNavbar = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  const adminLogoView = useMemo(() => {
    const logoPath = imageHelper.getLogo();
    return (
      <NavbarBrand className="fr-navbar-brand" tag={Link} to={LANDING_URL.LANDING.URL}>
        <img src={logoPath} alt={FR_APP_KEY} />
      </NavbarBrand>
    );
  }, []);

  return (
    <header className="fr-header">
      <Navbar className="navbar-expand-md navbar-toggleable-md fr-navbar" light fixed="top">
        <div className="fr-nav-left">{adminLogoView}</div>
        <div className="fr-navbar-toggler">
          <NavbarToggler onClick={adminToggleNavbar} />
        </div>
        <Collapse
          className="d-md-inline-flex flex-md-row-reverse fr-content"
          isOpen={!collapsed}
          navbar
        >
          <div className="container-lg container-md position-relative">
            <ul className="navbar-nav fr-nav fr-admin-nav">
              <NavItem className="fr-nav-item title">{ADMIN_PORTAL_URL.ADMIN_PORTAL.NAME}</NavItem>
            </ul>
          </div>
        </Collapse>
        <div className="fr-nav-right fr-admin">
          <LoginMenu isAdminPortal={true} locationPath={adminPortalPath} />
        </div>
      </Navbar>
    </header>
  );
};

export default AdminNavMenu;
