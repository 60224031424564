import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, useTable } from 'components/table';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import Button from 'components/button';
import { Popup, usePopup } from 'components/popup';
import useToast from 'components/toast/useToast';
import organizationService from 'services/organization';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { STATUS } from 'constants/index';
import { gS } from 'constants/helperMessage';
import { theme } from 'constants/theme';

const headCells = [
  { id: 'name', label: 'Organization Type Names' },
  { id: 'description', label: 'Description', className: 'w-30' },
  { id: 'isActive', label: 'Active' },
  { id: 'action', label: '', className: 'w-30 w-xl-25' }
];

const apiParams = {};

const OrganizationList = ({ tableTitle, addUrl, editUrl }) => {
  const [selectedOrganization, setSelectedorganization] = useState({});
  const [isDisabledConfirm, setIsDisabledConfirm] = useState(false);
  const [data, setData] = useTable();
  const [isOpen, open, close] = usePopup();
  const { toastSuccess, toastError } = useToast();
  const isActiveOrganization = selectedOrganization?.isActive?.key;

  const handleActiorganization = (index) => {
    setSelectedorganization(data.items[index]);
    open();
    setIsDisabledConfirm(false);
  };

  const renderActive = (isActive) => {
    return isActive ? (
      <CheckBoxOutlinedIcon color="tertiary" sx={{ fontSize: 28 }} key={true} />
    ) : (
      <CheckBoxOutlineBlankOutlinedIcon color="tertiary" sx={{ fontSize: 28 }} />
    );
  };

  const renderAction = (isActive, index, id) => {
    return (
      <>
        <Button
          color={isActive ? 'secondary' : 'tertiary'}
          size="small"
          className="mr-1"
          onClick={() => {
            handleActiorganization(index);
          }}
          minWidth={theme.button.minWidth}
        >
          {isActive ? 'Deactivate' : 'Activate'}
        </Button>
        <Button
          size="small"
          color="quaternary"
          className="text-white-hover ml-1"
          component={Link}
          to={editUrl.replace(':id', id)}
        >
          Manage
        </Button>
      </>
    );
  };

  const handleOnClickConfirm = async (organization) => {
    if (isDisabledConfirm) return;
    if (!organization) return;
    setIsDisabledConfirm(true);
    const resp = await organizationService.statusOrganization(
      organization.id,
      isActiveOrganization ? STATUS.DEACTIVATE : STATUS.ACTIVATE
    );
    if (resp?.errorCode) {
      toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
    } else {
      organization.isActive = renderActive(!isActiveOrganization);
      organization.action = renderAction(
        !isActiveOrganization,
        organization.index,
        organization.id
      );
      toastSuccess(gS(isActiveOrganization ? 'Deactivated' : 'Activated'));
      close();
    }
  };

  const convertToRowCells = (arr) => {
    if (!arr) return [];

    return arr.map((value, index) => {
      const keys = Object.keys(value);
      keys.forEach((key) => {
        if (typeof value[key] === 'boolean') {
          if (key === 'isActive') {
            value.index = index;
            value.action = renderAction(value[key], index, value.id);
            value[key] = renderActive(value[key]);
          }
        }
      });

      return value;
    });
  };

  const dataConvert = convertToRowCells(data.items);

  return (
    <>
      <Table
        headCells={headCells}
        rowCells={dataConvert}
        totalCount={data?.totalItems ?? 0}
        havePagination={true}
        title={tableTitle}
        haveSearch
        haveHeaderAction={
          <>
            <h2 className="h6 text-info font-weight-bold m-0">Add Organization Type</h2>
            <Button
              className="mx-3 text-white-hover"
              size="small"
              color="tertiary"
              component={Link}
              to={addUrl}
            >
              Add
            </Button>
          </>
        }
        service={organizationService}
        api="getOrganizations"
        apiParams={apiParams}
        setDataFetch={setData}
      />
      <Popup
        kind="confirm"
        isOpen={isOpen}
        onConfirm={() => {
          handleOnClickConfirm(selectedOrganization);
        }}
        isDisabledConfirm={isDisabledConfirm}
        onCancel={close}
        size="sm"
        title={`${isActiveOrganization ? 'Deactivate Organization ' : 'Activate Organization'} Type`}
        confirm="Yes"
      >
        {`Are you sure you want to ${isActiveOrganization ? 'deactivate' : 'activate'} ${
          selectedOrganization?.name
        }?`}
      </Popup>
    </>
  );
};

export default OrganizationList;
