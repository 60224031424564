import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Loading from 'components/loading';
import { FR_APP_KEY } from 'configs';
import { URL_PARAM, VERIFICATION } from 'constants/index';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { Container } from 'reactstrap';
import authService from 'services/authorization';
import urlHelper from 'utils/url';
import { useHistory } from 'react-router-dom';
import useToast from 'components/toast/useToast';
import imageHelper from 'utils/image';
import SetupPasswordForm from 'components/setupPasswordForm';
import useLoading from 'components/loading/useLoading';
import { ADMIN_PORTAL_URL } from '../url';
import useStyles from './styles';

const SetupPassword = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [verifyCode, setVerifyCode] = useState('');
  const [purpose, setPurpose] = useState('');
  const [email, setEmail] = useState('');
  const [showLoading, hideLoading] = useLoading();
  const history = useHistory();
  const { toastError } = useToast();

  const { title, passwordContainer } = useStyles();
  const contentLogoPath = imageHelper.getContentLogo();

  const setVerificationState = useCallback((verifyRs) => {
    if (verifyRs) {
      setVerifyCode(verifyRs);
    } else {
      history.push(ADMIN_PORTAL_URL.SETUP_PASSWORD_FAILED.URL);
    }
  }, [history]);

  useEffect(() => {
    let isMounted = false;
    const verification = async () => {
      if (isMounted) {
        const code = urlHelper.getSearchParam(VERIFICATION.CODE);
        setVerificationState(code);
        const e = urlHelper.getSearchParam(VERIFICATION.EMAIL);
        setEmail(e);
        const pur = urlHelper.getSearchParam(VERIFICATION.PURPOSE);
        setPurpose(pur);
      }
    };
    if (!verifyCode) {
      isMounted = true;
      if (isMounted) {
        verification();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [setVerificationState, verifyCode]);

  const handleOnSubmit = useCallback(
    async (values) => {
      const { password } = values;
      setIsDisabled(true);
      showLoading();

      const respone = await authService.setNewPassword(email, password, verifyCode, purpose);
      if (respone?.errorCode) {
        toastError(respone.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        const signInPath = `${ADMIN_PORTAL_URL.SIGNIN.URL}?${URL_PARAM.AREA}=admin&${URL_PARAM.REDIRECT_URL}=${ADMIN_PORTAL_URL.USER.URL}`;
        history.push(signInPath);
      }
      setIsDisabled(false);
      hideLoading();
    },
    [email, hideLoading, history, purpose, showLoading, toastError, verifyCode]
  );

  const verifyLoading = useMemo(() => {
    if (verifyCode) {
      return null;
    }
    return <Loading loading />;
  }, [verifyCode]);

  return (
    <>
      <div className="fr-container pb-40 fr-background fr-box-container">
        <div className="fr-box">
          <Container className={passwordContainer}>
            <div className="fr-page-container fr-content">
              <div className="text-center mb-3">
                <img src={contentLogoPath} alt={FR_APP_KEY} />
              </div>
              <h4 className={title}>{ADMIN_PORTAL_URL.SETUP_PASSWORD.NAME}</h4>
              <h6 className="fr-content-sub-title">Please choose your new password.</h6>
              <div>
                <SetupPasswordForm onSubmit={handleOnSubmit} submitDisabled={isDisabled} />
              </div>
              <div>{verifyLoading}</div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default SetupPassword;
