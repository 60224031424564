import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, useTable } from 'components/table';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import Button from 'components/button';
import { Popup, usePopup } from 'components/popup';
import useToast from 'components/toast/useToast';
import userService from 'services/user';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { STATUS, USER_ROLE } from 'constants/index';
import { gS } from 'constants/helperMessage';
import { theme } from 'constants/theme';

const headCells = [
  { id: 'displayName', label: 'Name' },
  { id: 'email', label: 'Email', className: 'w-25' },
  { id: 'role', label: 'Role' },
  { id: 'lockoutEnabled', label: 'Active' }
];

const headCellADelete = [
  { id: 'isDeleted', label: 'Deleted' }
];

const headCellAction = [
  { id: 'action', label: '', className: 'w-30 w-xl-25' }
];

const apiParams = {};

const UserList = ({ tableTitle, addUrl, editUrl, tenantId, showDeleteColumn = false }) => {
  const [selectedUser, setSelectedUser] = useState({});
  const [isDisabledConfirm, setIsDisabledConfirm] = useState(false);
  const [data, setData] = useTable();
  const [isOpen, open, close] = usePopup();
  const { toastSuccess, toastError } = useToast();
  const isActiveUser = selectedUser?.lockoutEnabled?.key;

  apiParams.tenantId = tenantId;

  let cHeadCells = [
    ...headCells
  ];
  if (showDeleteColumn) {
    cHeadCells = [...cHeadCells, ...headCellADelete];
  }
  cHeadCells = [...cHeadCells, ...headCellAction];

  const handleActiUser = (index) => {
    setSelectedUser(data.items[index]);
    open();
    setIsDisabledConfirm(false);
  };

  const renderActive = (isActive) => {
    return isActive ? (
      <CheckBoxOutlineBlankOutlinedIcon color="tertiary" sx={{ fontSize: 28 }} />
    ) : (
      <CheckBoxOutlinedIcon color="tertiary" sx={{ fontSize: 28 }} key={true} />
    );
  };

  const renderDelete = (isDeleted) => {
    return !isDeleted ? (
      <CheckBoxOutlineBlankOutlinedIcon color="tertiary" sx={{ fontSize: 28 }} />
    ) : (
      <CheckBoxOutlinedIcon color="tertiary" sx={{ fontSize: 28 }} key={true} />
    );
  };

  const renderAction = (isActive, index, id, role) => {
    return (
      <>
        <Button
          color={isActive ? 'tertiary' : 'secondary'}
          size="small"
          className="mr-1"
          onClick={() => {
            handleActiUser(index);
          }}
          minWidth={theme.button.minWidth}
        >
          {isActive ? 'Activate' : 'Deactivate'}
        </Button>
        <Button
          size="small"
          color="quaternary"
          className="text-white-hover ml-1"
          style={{ opacity: role === USER_ROLE.CONSUMER ? 0 : 1 }}
          component={Link}
          to={editUrl.replace(':id', id)}
          disabled={role === USER_ROLE.CONSUMER}
        >
          Manage
        </Button>
      </>
    );
  };

  const handleOnClickConfirm = async (user) => {
    if (isDisabledConfirm) return;
    if (!user) return;
    setIsDisabledConfirm(true);
    const resp = await userService.statusUser(
      user.email,
      isActiveUser ? STATUS.DEACTIVATE : STATUS.ACTIVATE
    );
    if (resp?.errorCode) {
      toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
    } else {
      user.lockoutEnabled = renderActive(isActiveUser);
      user.isDeleted = renderDelete(false);
      user.action = renderAction(isActiveUser, user.index, user.id, user.role);
      toastSuccess(gS(isActiveUser ? 'Deactivated' : 'Activated'));
      close();
    }
  };

  const convertToRowCells = (arr) => {
    if (!arr) return [];

    return arr.map((value, index) => {
      const keys = Object.keys(value);
      keys.forEach((key) => {
        if (typeof value[key] === 'boolean') {
          if (key === 'lockoutEnabled') {
            value.index = index;
            value.action = renderAction(value[key], index, value.id, value.role);
            value[key] = renderActive(value[key]);
          } else if (key === 'isDeleted') {
            value[key] = renderDelete(value[key]);
          }
        }
      });

      return value;
    });
  };

  const dataConvert = convertToRowCells(data.items);

  return (
    <>
      <Table
        headCells={cHeadCells}
        rowCells={dataConvert}
        totalCount={data?.totalItems ?? 0}
        havePagination={true}
        title={tableTitle}
        haveSearch
        haveHeaderAction={
          <>
            <h2 className="h6 text-info font-weight-bold m-0">Add User</h2>
            <Button
              className="mx-3 text-white-hover"
              size="small"
              color="tertiary"
              component={Link}
              to={addUrl}
            >
              Add
            </Button>
          </>
        }
        service={userService}
        api="getAccountUser"
        apiParams={apiParams}
        setDataFetch={setData}
      />
      <Popup
        kind="confirm"
        isOpen={isOpen}
        onConfirm={() => {
          handleOnClickConfirm(selectedUser);
        }}
        isDisabledConfirm={isDisabledConfirm}
        onCancel={close}
        size="sm"
        title={`${isActiveUser ? 'Deactivate User' : 'Activate User'}`}
        confirm="Yes"
      >
        {`Are you sure you want to ${isActiveUser ? 'deactivate' : 'activate'} ${selectedUser?.displayName
          }?`}
      </Popup>
    </>
  );
};

export default UserList;
