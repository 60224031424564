import React from 'react';
import NavMenu from './navMenu';
import AdminPortalLayout from './adminPortal';
import Footer from './footer';

const Layout = (props) => {

  return (
    <div>
      <NavMenu />
      <div className="content">
        <div className="fr-container">
          {props.children}
          <Footer />
        </div>
      </div>
    </div>
  );
};

export { Layout, AdminPortalLayout };
