import { ADMIN_PORTAL_BASIC_ROUTES } from './basicRoute';
import AdminPortalCharityCampaign from './charity/campaign';
import AdminPortalCampaignAdd from './charity/campaign/add';
import AdminPortalCampaignEdit from './charity/campaign/edit';
import AdminPortalCharityInformation from './charity/information';
import AdminPortalCharityReporting from './charity/reporting';
import { ADMIN_PORTAL_URL } from './url';

export const CHARITY_MANAGE_ROUTES = [
  ...ADMIN_PORTAL_BASIC_ROUTES,
  {
    name: ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS.NAME,
    path: ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS.URL,
    component: AdminPortalCharityCampaign,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS_ADD.NAME,
    path: ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS_ADD.URL,
    component: AdminPortalCampaignAdd,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS_EDIT.NAME,
    path: ADMIN_PORTAL_URL.CHARITY_CAMPAIGNS_EDIT.URL,
    component: AdminPortalCampaignEdit,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.CHARITY_INFORMATION.NAME,
    path: ADMIN_PORTAL_URL.CHARITY_INFORMATION.URL,
    component: AdminPortalCharityInformation,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.CHARITY_REPORTING.NAME,
    path: ADMIN_PORTAL_URL.CHARITY_REPORTING.URL,
    component: AdminPortalCharityReporting,
    isPrivate: true
  }

];
