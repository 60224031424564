import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container } from 'reactstrap';
import useToast from 'components/toast/useToast';
import publicService from 'services/public';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import Button from 'components/button';

import { SHOP_VENDOR_URL } from './url';
import PopularCategories from './popularCategory';

const ShopVendorList = ({ location, history }) => {
  const [data, setData] = useState([]);

  const { toastError } = useToast();
  const fromVendor = location.pathname.includes(SHOP_VENDOR_URL.SHOP_VENDOR.URL);

  const handleViewAll = () => {
    history.push(SHOP_VENDOR_URL.SHOP_VENDOR_LIST.URL);
  };
  useEffect(() => {
    const fetchCategories = async () => {
      const resp = await publicService.getCategoriesPopular();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        return;
      }
      setData(resp);
    };
    fetchCategories();
  }, [toastError]);
  const element = document.getElementById("content-page");

  useEffect(() => {
    if (element) {
      element.scrollIntoView();
    }
  }, [element]);
  return (
    <div className={`${fromVendor ? 'fr-background' : null}`}>
      <Container fluid className="x-container p-0" id="content-page">
        <div className="p-4 justify-content-center">
          <Col xs="12" className="row justify-content-between align-items-center">
            <div className="col-xs-12 col-md-5" />
            {fromVendor && (
              <h3 className="c-title m-0 titanOne col-xs-12 col-md-2 my-3 h1">Shop Vendors</h3>
            )}
            <div className="col-xs-12 col-md-5 d-flex justify-content-end">
              <Button variant="text" className="text-white h5 quicksand" onClick={handleViewAll}>
                View All &gt;
              </Button>
            </div>
          </Col>
        </div>

        <PopularCategories data={data} />
      </Container>
    </div>
  );
};

export default withRouter(ShopVendorList);
