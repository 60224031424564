import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { isEmptyObj } from 'utils/helper';
import * as Yup from 'yup';
import { gR } from 'constants/helperMessage';
import { Col, Row } from 'reactstrap';
import Button from 'components/button';
import Input from 'components/input';
import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';
import ImageUpload from 'components/imageUpload';
import UnsavedPopup from 'pages/home/unsavedPopup';
import { CHARITY_URL } from 'pages/charity/url';
import qrCodeHelper from 'utils/qrCode';
import QRCodeId from 'components/qrCode';
import { FUNRAYZR_URL } from 'configs';
import { ERROR_MESSAGE } from 'constants/errorMessage';

const useStyles = makeStyles(() => ({
  border: {
    height: '1px',
    background: theme.colors.gray.border,
    margin: '20px 24px 20px 24px'
  },
  paddingCol: {
    padding: '10px 39px 10px 39px'
  }
}));

const ManageCharityForm = ({ initialValues, onSubmit, isDisabled, logo, setLogo, charityId }) => {
  const { border, paddingCol } = useStyles();
  const [imageError, setImageError] = useState(false);

  const handleOnSubmit = useCallback(
    (values) => {
      if (onSubmit && !imageError) {
        onSubmit(values);
      }
    },
    [imageError, onSubmit]
  );

  const blankInitialValues = {
    legalName: '',
    website: '',
    description: '',
    introduction: '',
    city: '',
    province: '',
    logoPath: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: ''
  };
  const checkLogoValid = useCallback((data) => {
    setImageError(data);
  }, []);
  const formik = useFormik({
    initialValues: isEmptyObj(initialValues) ? blankInitialValues : initialValues,
    validationSchema: Yup.object({
      legalName: Yup.string().required(gR('Legal Name')),
      addressLine1: Yup.string().required(gR('Address Line 1')),
      introduction: Yup.string().required(gR('Introduction')),
      city: Yup.string().required(gR('City')),
      province: Yup.string().required(gR('Province')),
      postalCode: Yup.string().required(gR('Postal Code')),
      logoPath: Yup.string().required(gR('Logo')),
      description: Yup.string(),
      website: Yup.string().url(ERROR_MESSAGE.WEBSITE.VALID)
    }),
    onSubmit: handleOnSubmit,
    enableReinitialize: true
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center pt-4 mx-4">
              <div className="flex-1" />

              <h2 className="h6 text-tertiary font-weight-bold flex-1 m-0 text-center">
                Edit Charity Information
              </h2>

              <div className="d-flex  flex-1 justify-content-end align-items-center">
                <Button
                  disabled={isDisabled || imageError}
                  className="mx-3 text-white-hover"
                  size="small"
                  color="tertiary"
                  type="submit"
                >
                  Save
                </Button>
              </div>

              <Button
                className="mr-3 text-white-hover"
                size="small"
                color="warning"
                type="submit"
                component={Link}
                to={CHARITY_URL.DETAIL.URL.replace(':id', charityId)}
              >
                Preview
              </Button>
            </div>
          </Col>
          <Col xs={12}>
            <div className={border} />
          </Col>

          <Col xs={12} className={paddingCol}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Legal Name*"
              name="legalName"
              onChange={formik.handleChange}
              value={formik.values.legalName}
              error={formik.touched.legalName && !!formik.errors.legalName}
              helperText={formik.touched.legalName && formik.errors.legalName}
            />
          </Col>

          <Col xs={6} className={`${paddingCol} pr-2`}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Address Line 1*"
              name="addressLine1"
              onChange={formik.handleChange}
              value={formik.values.addressLine1 ?? ''}
              error={formik.touched.addressLine1 && !!formik.errors.addressLine1}
              helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
            />
          </Col>
          <Col xs={6} className={`${paddingCol} pl-2`}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Address Line 2"
              name="addressLine2"
              onChange={formik.handleChange}
              value={formik.values.addressLine2}
              error={formik.touched.addressLine2 && !!formik.errors.addressLine2}
              helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
            />
          </Col>
          <Col xs={6} className={`${paddingCol} pr-2`}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="City*"
              name="city"
              onChange={formik.handleChange}
              value={formik.values.city}
              error={formik.touched.city && !!formik.errors.city}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Col>
          <Col xs={3} className={`${paddingCol} px-2`}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Province*"
              name="province"
              onChange={formik.handleChange}
              value={formik.values.province}
              error={formik.touched.province && !!formik.errors.province}
              helperText={formik.touched.province && formik.errors.province}
            />
          </Col>

          <Col xs={3} className={`${paddingCol} pl-2`}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Postal Code*"
              name="postalCode"
              onChange={formik.handleChange}
              value={formik.values.postalCode ?? ''}
              error={formik.touched.postalCode && !!formik.errors.postalCode}
              helperText={formik.touched.postalCode && formik.errors.postalCode}
            />
          </Col>

          <Col xs={12} className={paddingCol}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Introduction*"
              multiline
              rows={2}
              name="introduction"
              onChange={formik.handleChange}
              value={formik.values.introduction}
              error={formik.touched.introduction && !!formik.errors.introduction}
              helperText={formik.touched.introduction && formik.errors.introduction}
            />
          </Col>

          <Col xs={12} className={paddingCol}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Details"
              multiline
              rows={4}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              error={formik.touched.description && !!formik.errors.description}
              helperText={formik.touched.description && formik.errors.description}
            />
          </Col>

          <Col xs={12} className={paddingCol}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Website"
              name="website"
              onChange={formik.handleChange}
              value={formik.values.website}
              error={formik.touched.website && !!formik.errors.website}
              helperText={formik.touched.website && formik.errors.website}
            />
          </Col>
          <Col xs={12} className={`${paddingCol} mb-5 `}>
            <Row className="flex">
              <Col xs={6}>
                <ImageUpload
                  label="Logo*"
                  setPreview={setLogo}
                  name="logoPath"
                  logoSrc={{ preview: logo }}
                  onChange={formik.handleChange}
                  checkError={checkLogoValid}
                  error={formik.touched.logoPath && !!formik.errors.logoPath}
                  helperText={formik.touched.logoPath && formik.errors.logoPath}
                />
              </Col>
              <Col xs={6} className="flex justify-content-end">
                <QRCodeId id={charityId} data={`${FUNRAYZR_URL}charities/${charityId}`} />
                <div className="download-group ml-20">
                  <p>
                    Download your <br /> FunRayZR QR code
                  </p>
                  <Button
                    color="tertiary"
                    className="mb-2"
                    onClick={() => qrCodeHelper.downloadQRCode(charityId)}
                  >
                    <span>Get Code</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
      <UnsavedPopup
        values={formik.values}
        initialValues={formik.initialValues}
        isSubmit={isDisabled}
        resetForm={formik.resetForm}
      />
    </>
  );
};

export default ManageCharityForm;
