import React, { useState, useEffect } from 'react';
import ImageIcon from '@mui/icons-material/Image';
import { FormHelperText } from '@mui/material';
import Button from 'components/button';

const ImageUpload = ({ label, helperText, error, onChange, checkError, name, setPreview, logoSrc }) => {
  const [image, setImage] = useState();
  const [errorText, setErrorText] = useState();
  useEffect(() => {
    return () => {
      if (!image) return;
      URL.revokeObjectURL(image.preview);
    };
  }, [image]);

  useEffect(() => {
    if (!logoSrc?.preview) return;
    if (image?.preview) return;
    setImage(logoSrc);
  }, [logoSrc, image]);

  useEffect(() => {
    setErrorText(helperText);
  }, [helperText]);

  const handlePreview = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (file.type !== 'image/png') {
      setImage();
      checkError(true);
      setErrorText('Only png file is accepted');
      return;
    }
    if (file.size > 1048576) {
      setImage();
      checkError(true);
      setErrorText('The maximum file size must be 1MB');
      return;
    }
    file.preview = URL.createObjectURL(file);
    setImage(file);
    setPreview(file.preview);
    const formData = new FormData();
    formData.append('file', file);
    checkError(false);
    setErrorText(helperText);
    const eventSimulator = {
      target: {
        name,
        value: formData
      }
    };
    onChange(eventSimulator);
  };

  return (
    <>
      {label && <p className={error ? 'text-danger' : undefined}>{label}</p>}
      <div className="d-flex">
        <label htmlFor="contained-button-file" className="m-0 mr-5">
          <input
            className="d-none"
            accept="image/png"
            id="contained-button-file"
            type="file"
            onChange={handlePreview}
          />

          <Button className="ml-0 c-image-upload" component="span" color="secondary">
            <ImageIcon style={{ color: 'white' }} />
            <div className="position-absolute p-1 rounded c-image-upload-text">Upload</div>
          </Button>
        </label>

        {image && !errorText ? (
          <img src={image.preview} alt="" style={{ maxWidth: '138px', maxHeight: '80px' }} />
        ) : (
          <FormHelperText className="text-danger" component="span">
            {errorText}
          </FormHelperText>
        )}
      </div>
    </>
  );
};

ImageUpload.defaultProps = {
  onChange: () => {},
  name: '',
  checkError: () => {}
};

export default ImageUpload;
