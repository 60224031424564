export const CHECKOUT_URL = {
  CHECKOUT: {
    URL: '/checkout',
    NAME: 'Proceed Payment'
  },
  CHECKOUT_SUCCESS: {
    URL: '/checkout-success',
    NAME: 'checkout Success'
  }
};
