import About from '.';
import { ABOUT_URL } from './url';

export const ABOUT_ROUTES = [
  {
    name: ABOUT_URL.ABOUT.NAME,
    path: ABOUT_URL.ABOUT.URL,
    component: About,
    isPrivate: false
  }
];
