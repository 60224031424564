import Api from 'api/card/api';
import { FUNRAYZR_API_URL } from 'configs';
import AxiosWrapper from 'utils/axios';

export class CardService {
  constructor() {
    this._baseUrl = `${FUNRAYZR_API_URL}/Cards`;
    const axios = AxiosWrapper();
    this._cardApi = Api(axios);
  }

  getCardDetail(id, isMine = false) {
    return this._cardApi.getCardDetail(id, isMine);
  }

  deactivateCard(id) {
    return this._cardApi.deactivateCard(id);
  }

  getCards(params) {
    return this._cardApi.getCards(params);
  }

  getCardByCodes(params) {
    return this._cardApi.getCardByCodes(params);
  }

  getTransactions(params, id) {
    return this._cardApi.getTransactions(params, id);
  }

  putRedeem(id, value) {
    return this._cardApi.putRedeem(id, value);
  }

  resendEmail(id, recipientEmail) {
    return this._cardApi.resendEmail(id, recipientEmail);
  }
}

const cardService = new CardService();

export default cardService;
