import qs from 'qs';

import { FUNRAYZR_API_URL } from 'configs';
import { mapResponse } from 'api/responseMapping';

const Api = (axios) => {
  const organizationUrl = `${FUNRAYZR_API_URL}/Organizations`;

  const getOrganizations = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${organizationUrl}?${params}`);
    return mapResponse(resp, true);
  };

  const getOrganizationById = async (id) => {
    const resp = await axios.get(`${organizationUrl}/${id}`);

    return mapResponse(resp);
  };

  const statusOrganization = async (id, status) => {
    const resp = await axios.put(`${organizationUrl}/${id}/status/${status}`);
    return mapResponse(resp);
  };

  const createOrganization = async ({ name, description }) => {
    const params = {
      name,
      description
    };

    const resp = await axios.post(organizationUrl, { ...params });

    return mapResponse(resp);
  };

  const editOrganization = async (id, organization) => {
    const resp = await axios.put(`${organizationUrl}/${id}`, organization);

    return mapResponse(resp);
  };

  return {
    getOrganizations,
    statusOrganization,
    createOrganization,
    editOrganization,
    getOrganizationById
  };
};

export default Api;
