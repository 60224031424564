import Api from 'api/contactUs/api';
import { FR_APP_KEY } from 'configs';
import AxiosWrapper from 'utils/axios';

export class ContactUsService {
  constructor() {
    const axios = AxiosWrapper();
    this._contactUsApi = Api(axios);
  }

  createContactUs(contactUs) {
    return this._contactUsApi.createContactUs({ ...contactUs, appName: FR_APP_KEY });
  }

}

const contactUsService = new ContactUsService();

export default contactUsService;
