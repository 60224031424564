import { FUNRAYZR_API_URL } from 'configs';
import { mapResponse } from 'api/responseMapping';

const Api = (axios) => {
  const url = `${FUNRAYZR_API_URL}/ContactUs`;

  const createContactUs = async (contactUs) => {
    const resp = await axios.post(url, contactUs);

    return mapResponse(resp);
  };

  return {
    createContactUs
  };
};

export default Api;
