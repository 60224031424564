import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { TOAST } from 'constants/index';

const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  const toastSuccess = useCallback(
    (mess) => {
      enqueueSnackbar(mess, { variant: TOAST.VARIANT.SUCCESS });
    },
    [enqueueSnackbar]
  );

  const toastInfo = useCallback(
    (mess) => {
      enqueueSnackbar(mess, { variant: TOAST.VARIANT.INFO });
    },
    [enqueueSnackbar]
  );

  const toastWarning = useCallback(
    (mess) => {
      enqueueSnackbar(mess, { variant: TOAST.VARIANT.WARNING });
    },
    [enqueueSnackbar]
  );

  const toastError = useCallback(
    (mess) => {
      enqueueSnackbar(mess, { variant: TOAST.VARIANT.ERROR });
    },
    [enqueueSnackbar]
  );

  return { toastSuccess, toastInfo, toastWarning, toastError };
};

export default useToast;
