import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { PURPOSE } from 'constants/index';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { gS } from 'constants/helperMessage';
import userService from 'services/user';
import useLoading from 'components/loading/useLoading';
import useToast from 'components/toast/useToast';
import UserInfo from '../userInfo';

const AdminPortalUserAddForm = ({ tenantId, redirectUrl, backUrl, includeAdminRole }) => {
  const [isDisabled, setIsDisabled] = useState(false);

  const history = useHistory();
  const { toastSuccess, toastError } = useToast();
  const [showLoading, hideLoading] = useLoading();

  const onSubmit = useCallback(
    async ({ email, firstName, lastName, role, tenant }) => {
      setIsDisabled(true);
      showLoading();
      const resp = await userService.invitedUser({
        email,
        firstName,
        lastName,
        roleId: role,
        tenantId: tenantId || tenant
      });
      hideLoading();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        history.push(redirectUrl);
        toastSuccess(gS('Created user'));
      }
      setIsDisabled(false);
    },
    [hideLoading, history, redirectUrl, showLoading, tenantId, toastError, toastSuccess]
  );

  return (
    <UserInfo
      purpose={PURPOSE.ADD}
      rowCells={[]}
      profile={{ email: '', firstName: '', lastName: '' }}
      cancelUrl={backUrl}
      onSubmit={onSubmit}
      isDisabled={isDisabled}
      hasTenantField={!tenantId}
      includeAdminRole={includeAdminRole}
    />
  );
};

export default AdminPortalUserAddForm;
