import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { TRANSACTION_URL } from 'pages/transaction/url';
import imageHelper from 'utils/image';

const useStyles = makeStyles(() => ({
  paddingCol: {
    padding: '10px 39px'
  }
}));

const CheckoutSuccess = () => {
  const history = useHistory();

  const { paddingCol } = useStyles();

  const handleClickViewMore = () => {
    history.push(TRANSACTION_URL.TRANSACTION.URL);
  };
  const logoPath = imageHelper.getLogo();

  return (
    <div className="fr-background pb-4 pt-4 quicksand">
      <Container
        fluid
        className="x-container p-0 container mt-4 rounded-20 bg-white d-flex align-items-center"
        style={{ minHeight: '500px' }}
      >
        <Row className="p-4 justify-content-center text-center text-md-center mx-0">
          <Col xs={12} className={paddingCol}>
            <img src={logoPath} alt="FunRayZR" style={{ maxWidth: '100%' }} />
          </Col>
          <Col xs={12} className={paddingCol}>
            <h3 className="font-weight-bolder">Congratulations!</h3>
          </Col>
          <Col xs={12}>
            Your order has proceeded successfully. Please check your email for card details
          </Col>
          <Col xs={12} className={`${paddingCol} mb-4`}>
            Click
            <span
              onClick={() => {
                handleClickViewMore();
              }}
              onKeyDown={() => {
                handleClickViewMore();
              }}
              aria-hidden="true"
              className="pointer"
            >
              &nbsp;<u>here</u>&nbsp;
            </span>
            to view transaction detail.
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CheckoutSuccess;
