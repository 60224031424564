import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import Button from 'components/button';
import { theme } from 'constants/theme';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import Input from 'components/input';
import { gR } from 'constants/helperMessage';
import { isEmptyObj } from 'utils/helper';
import UnsavedPopup from 'pages/home/unsavedPopup';
import ImageUpload from 'components/imageUpload';
import { SHOP_VENDOR_URL } from 'pages/shopVendor/url';
import { Col, Row } from 'reactstrap';
import DownloadQRCode from 'components/downloadQrCode';

import VendorCard from '../card';
import VendorCardAmount from './amount';

const useStyles = makeStyles(() => ({
  border: {
    height: '1px',
    background: theme.colors.gray.border,
    margin: '20px 24px 20px 24px'
  },
  paddingCol: {
    padding: '10px 39px 10px 39px'
  }
}));

const ManageEditVendorInfo = ({ vendorId, initialValues, onSubmit, isDisabled, logo, setLogo }) => {
  const { border, paddingCol } = useStyles();
  const [imageError, setImageError] = useState(false);
  const handleOnSubmit = useCallback(
    (values) => {
      if (onSubmit && !imageError) {
        onSubmit(values);
      }
    },
    [imageError, onSubmit]
  );

  const blankInitialValues = {
    legalName: '',
    website: '',
    description: '',
    city: '',
    province: '',
    cardAmount: '',
    logoPath: '',
    addressLine1: '',
    addressLine2: '',
    postalCode: ''
  };
  const formik = useFormik({
    initialValues: isEmptyObj(initialValues) ? blankInitialValues : initialValues,
    validationSchema: Yup.object({
      legalName: Yup.string().required(gR('Legal Name')),
      addressLine1: Yup.string().required(gR('Address Line 1')),
      city: Yup.string().required(gR('City')),
      province: Yup.string().required(gR('Province')),
      postalCode: Yup.string().required(gR('Postal Code')),
      logoPath: Yup.string().required(gR('Logo')),
      cardAmount: Yup.string().required(gR('Card Amount')),
      website: Yup.string().url(ERROR_MESSAGE.WEBSITE.VALID)
    }),
    onSubmit: handleOnSubmit,
    enableReinitialize: true
  });
  const checkLogoValid = useCallback((data) => {
    setImageError(data);
  }, []);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col xs={12}>
            <div className="d-flex justify-content-between align-items-center pt-4 mx-4">
              <div className="flex-1" />

              <h2 className="h6 text-tertiary font-weight-bold flex-1 m-0 text-center">
                Edit Vendor Information
              </h2>

              <div className="d-flex  flex-1 justify-content-end align-items-center">
                <Button
                  disabled={isDisabled || imageError}
                  className="mx-3 text-white-hover"
                  size="small"
                  color="tertiary"
                  type="submit"
                >
                  Save
                </Button>
              </div>

              <Button
                component={Link}
                to={SHOP_VENDOR_URL.DETAIL.URL.replace(':id', vendorId)}
                className="mr-3 text-white-hover"
                size="small"
                color="warning"
                type="submit"
              >
                Preview
              </Button>
            </div>
          </Col>
          <Col xs={12}>
            <div className={border} />
          </Col>

          <Col xs={12} className={paddingCol}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Legal Name*"
              name="legalName"
              onChange={formik.handleChange}
              value={formik.values.legalName}
              error={formik.touched.legalName && !!formik.errors.legalName}
              helperText={formik.touched.legalName && formik.errors.legalName}
            />
          </Col>

          <Col xs={6} className={`${paddingCol} pr-2`}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Address Line 1*"
              name="addressLine1"
              onChange={formik.handleChange}
              value={formik.values.addressLine1 ?? ''}
              error={formik.touched.addressLine1 && !!formik.errors.addressLine1}
              helperText={formik.touched.addressLine1 && formik.errors.addressLine1}
            />
          </Col>
          <Col xs={6} className={`${paddingCol} pl-2`}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Address Line 2"
              name="addressLine2"
              onChange={formik.handleChange}
              value={formik.values.addressLine2}
              error={formik.touched.addressLine2 && !!formik.errors.addressLine2}
              helperText={formik.touched.addressLine2 && formik.errors.addressLine2}
            />
          </Col>
          <Col xs={6} className={`${paddingCol} pr-2`}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="City*"
              name="city"
              onChange={formik.handleChange}
              value={formik.values.city}
              error={formik.touched.city && !!formik.errors.city}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Col>
          <Col xs={3} className={`${paddingCol} px-2`}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Province*"
              name="province"
              onChange={formik.handleChange}
              value={formik.values.province}
              error={formik.touched.province && !!formik.errors.province}
              helperText={formik.touched.province && formik.errors.province}
            />
          </Col>

          <Col xs={3} className={`${paddingCol} pl-2`}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Postal Code*"
              name="postalCode"
              onChange={formik.handleChange}
              value={formik.values.postalCode ?? ''}
              error={formik.touched.postalCode && !!formik.errors.postalCode}
              helperText={formik.touched.postalCode && formik.errors.postalCode}
            />
          </Col>

          <Col xs={12} className={paddingCol}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Details"
              multiline
              rows={4}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
              error={formik.touched.description && !!formik.errors.description}
              helperText={formik.touched.description && formik.errors.description}
            />
          </Col>

          <Col xs={12} className={paddingCol}>
            <Input
              color="tertiary"
              fullWidth
              size="small"
              label="Website"
              name="website"
              onChange={formik.handleChange}
              value={formik.values.website}
              error={formik.touched.website && !!formik.errors.website}
              helperText={formik.touched.website && formik.errors.website}
            />
          </Col>
          <Col xs={6} className={paddingCol}>
            <ImageUpload
              label="Logo*"
              setPreview={setLogo}
              name="logoPath"
              onChange={formik.handleChange}
              value={formik.values.logoPath}
              checkError={checkLogoValid}
              error={formik.touched.logoPath && !!formik.errors.logoPath}
              helperText={formik.touched.logoPath && formik.errors.logoPath}
            />
          </Col>
          <Col xs={6} className={paddingCol}>
            <VendorCardAmount
              label="Card Amount*"
              name="cardAmount"
              onChange={formik.handleChange}
              value={formik.values.cardAmount}
              error={formik.touched.cardAmount && !!formik.errors.cardAmount}
              helperText={formik.touched.cardAmount && formik.errors.cardAmount}
            />
          </Col>
          <Col xs={6} className={`${paddingCol} mb-3 mb-xxl-5`}>
            <DownloadQRCode id={vendorId} />
          </Col>
          <Col xs={6} className={`${paddingCol} mb-3 mb-xxl-5`}>
            <p>Card Review</p>
            <VendorCard
              vendorLogo={logo}
              name={formik.values.legalName}
              address={formik.values.addressLine1}
            />
          </Col>
        </Row>
      </form>
      <UnsavedPopup
        values={formik.values}
        initialValues={formik.initialValues}
        isSubmit={isDisabled}
        resetForm={formik.resetForm}
      />
    </>
  );
};

export default ManageEditVendorInfo;
