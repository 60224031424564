import Api from 'api/order/api';
import { FUNRAYZR_API_URL } from 'configs';
import AxiosWrapper from 'utils/axios';

export class OrderService {
  constructor() {
    this._baseUrl = `${FUNRAYZR_API_URL}/Orders`;
    const axios = AxiosWrapper();
    this._oderApi = Api(axios);
  }

  checkout(card) {
    return this._oderApi.checkout(card);
  }
}

const orderService = new OrderService();

export default orderService;
