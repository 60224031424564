import FUNRAYZR_LOGO from 'assets/images/funrayzr-logo.png';
import FUNRAYZR_CONTENT_LOGO from 'assets/images/funrayzr-content-logo.png';
import FUNRAYZR_ABOUT_BG_1 from 'assets/images/funrayzr-about-bg-1.png';
import FUNRAYZR_ABOUT_MOBILE from 'assets/images/funrayzr-about-bg-mobile.png';
import RAYZR_LOGO from 'assets/images/rayzr-logo.png';
import RAYZR_CONTENT_LOGO from 'assets/images/rayzr-content-logo.png';
import RAYZR_ABOUT_BG_1 from 'assets/images/rayzr-about-bg-1.jpg';
import RAYZR_ABOUT_BG_2 from 'assets/images/rayzr-about-bg-2.jpg';
import RAYZR_ABOUT_BG_PC from 'assets/images/rayzr-about-bg-pc.jpg';
import DEFAULT_VENDOR from 'assets/images/default-vendor.png';
import CREDIT_CARD from 'assets/images/credit-card.png';
import LANDING_STREET_SHOTS from 'assets/images/street-shots.png';
import LANDING_STREET_SHOTS_PC from 'assets/images/street-shots-pc.png';
import FUNRAYZR_FOOTER_LOGO_WHITE from 'assets/images/funrayzr-footer-logo-white.png';
import RAYZR_FOOTER_LOGO_WHITE from 'assets/images/rayzr-footer-logo-white.png';
import ICON_VISA from 'assets/images/icons-visa.svg';
import ICON_VISA_DEBIT from 'assets/images/icons-visa-debit-2.svg';

import ICON_MASTERCARD from 'assets/images/icons-mastercard.svg';

import ICON_AMERICAN_EXPRESS from 'assets/images/icons-american-express.svg';
import ICON_DISCOVER from 'assets/images/icons-discover-card.svg';

import FINANCIAL_INFORMATION from 'assets/images/financial-nformation.png';
import FOOTER_ICON_PHONE from 'assets/images/footer-icon-phone.svg';
import FOOTER_ICON_MAIL from 'assets/images/footer-icon-mail.svg';
import FOOTER_ICON_LOCATION from 'assets/images/footer-icon-location.svg';
import RESEND_MAIL_ICON from 'assets/images/resend-mail.svg';

export const ORDER_TYPE = {
  ASC: 'Asc',
  DESC: 'Desc'
};

export const IMG_LOCAL = {
  FUNRAYZR_LOGO,
  FUNRAYZR_CONTENT_LOGO,
  FUNRAYZR_ABOUT_BG_1,
  FUNRAYZR_ABOUT_MOBILE,
  FUNRAYZR_FOOTER_LOGO_WHITE,
  RAYZR_FOOTER_LOGO_WHITE,
  RAYZR_LOGO,
  RAYZR_CONTENT_LOGO,
  RAYZR_ABOUT_BG_1,
  RAYZR_ABOUT_BG_2,
  RAYZR_ABOUT_BG_PC,
  DEFAULT_VENDOR,
  CREDIT_CARD,
  LANDING_STREET_SHOTS,
  LANDING_STREET_SHOTS_PC,
  FINANCIAL_INFORMATION,
  FOOTER_ICON_PHONE,
  FOOTER_ICON_MAIL,
  FOOTER_ICON_LOCATION,
  ICON_VISA,
  ICON_AMERICAN_EXPRESS,
  ICON_DISCOVER,
  ICON_MASTERCARD,
  ICON_VISA_DEBIT,
  RESEND_MAIL_ICON
};

export const APP_KEY = {
  FUNRAY_ZR: 'FunRayZR',
  RAY_ZR: 'RayZR'
};

export const TOAST = {
  MAX_ALERT_NOTI: 3,
  ANCHOR: { horizontal: 'right', vertical: 'top' },
  VARIANT: {
    SUCCESS: 'success',
    INFO: 'info',
    WARNING: 'warning',
    ERROR: 'error'
  }
};

export const URL_PARAM = {
  AREA: 'a',
  EMAIL: 'e',
  REDIRECT_URL: 'ru',
  PURPOSE: 'pur',
  SUPPORT: 'sup'
};

export const URL_PARAM_VALUE = {
  SHOW: 'show'
};

export const VERIFICATION = {
  CODE: 'code',
  EMAIL: 'email',
  PURPOSE: 'purpose'
};

export const STORAGE_KEY = {
  KEY: 'fr-k',
  USER: 'fr-u',
  REGION: 'fr-rg'
};

export const PURPOSE = {
  ADD: 'add',
  MANAGE: 'manage',
  EDIT: 'edit'
};

export const CONFIRM_PURPOSE = {
  REGISTER: 'Register',
  INVITE: 'Invite',
  RESET: 'Reset',
  APPROVAL: 'Approval'
};

export const SET_PASSWORD_PURPOSE = {
  RESET: 0,
  INVITE: 1,
  APPROVAL: 2
};

export const USER_ROLE = {
  SUPER_ADMINISTRATOR: 'Super Administrator',
  ADMINISTRATOR: 'Administrator',
  MANAGER: 'Manager',
  EMPLOYEE: 'Employee',
  CONSUMER: 'Consumer'
};

export const TENANT_TYPE = {
  VENDOR: 'Vendor',
  CHARITY: 'Charity'
};

export const CARD_AMOUNT = {
  AMOUNT_25: 'Amount25',
  AMOUNT_50: 'Amount50',
  AMOUNT_75: 'Amount75',
  AMOUNT_100: 'Amount100'
};

export const ACCOUNT_STATUS = {
  ACTIVATE: 'Activate',
  DEACTIVATE: 'Deactivate'
};

export const EDIT_TYPE = {
  BY_ADMIN: 0,
  BY_MANAGER: 1
};

export const STATUS = {
  ACTIVATE: 0,
  DEACTIVATE: 1
};

export const CARD_TYPE = ['None', FUNRAYZR_LOGO, RAYZR_LOGO];

export const TRANSACTION_TYPE = ['Purchased', 'Redeemed', 'Credit', 'Debit'];

export const CART_DETAILS_TYPE = {
  FUNRAY_ZR: 1,
  RAY_ZR: 2
};

export const BUCKET = {
  AVATAR: 0,
  LOGO: 1,
  VENDOR: 2,
  CHARITY: 3,
  CAMPAIGN: 4,
  CATEGORY: 5
};

export const DATE_FORMAT = 'MM/DD/YYYY';

export const ADMIN_URL = '/admin';

export const TAB_VALUE = {
  CAMPAIGNS: 0,
  SUPPORTING_VENDORS: 1
};

export const HTTPCODES = {
  NOT_FOUND: 404,
  SUCCESS: 200,
  BAD_REQUEST: 400
};
