import qs from 'qs';
import { mapResponse } from "api/responseMapping";

const { FUNRAYZR_API_URL } = require("configs");

const Api = (axios) => {
  const roleUrl = `${FUNRAYZR_API_URL}/OAuth`;

  const getRoles = async (pa) => {
    const params = qs.stringify(pa);
    const resp = await axios.get(`${roleUrl}/roles?${params}`);
    return mapResponse(resp);
  };

  return {
    getRoles
  };
};

export default Api;
