import ContainerFooterLink from 'components/containerFooterLink';
import React from 'react';
import { REFUND_POLICY } from 'constants/globalContent';

const RefundOnly = () => {
  return (
    <ContainerFooterLink title="Refund Policy">
      <h2 className="h3 font-weight-bolder">{REFUND_POLICY.refund_policy}</h2>

      <p>{REFUND_POLICY.not_refundable_policy}</p>

      <p>{REFUND_POLICY.never_expire_policy}</p>

      <p>{REFUND_POLICY.not_transferable_policy}</p>

      <p>{REFUND_POLICY.delivered_policy}</p>

      <p>{REFUND_POLICY.redeemed_policy}</p>

      <p>{REFUND_POLICY.denominated_policy}</p>

      <p>{REFUND_POLICY.qr_policy}</p>

      <p>{REFUND_POLICY.taxable_policy}</p>

      <h2 className="h3 font-weight-bolder">{REFUND_POLICY.close_policy}</h2>

      <p>{REFUND_POLICY.certificate_policy}</p>

      <h2 className="h3 font-weight-bolder">{REFUND_POLICY.not_happy_policy}</h2>

      <p id="bottom">{REFUND_POLICY.customers_policy}</p>
    </ContainerFooterLink>
  );
};

export default RefundOnly;
