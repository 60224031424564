import React from 'react';
import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';
import { APP_KEY } from 'constants/index';

const useStyles = makeStyles(() => ({
  titleDescription: {
    color: theme.colors.black.title,
    fontSize: theme.fontSize.s16,
    fontFamily: theme.fontFamily.quicksandBold,
    textAlign: 'start',
    marginBottom: '20px',
    fontWeight: '700',
    '@media (min-width: 1024px)': {
      textAlign: 'center'
    }
  },
  textContent: {
    fontFamily: theme.fontFamily.quicksandMedium,
    fontSize: theme.fontSize.s14,
    lineHeight: '16px',
    color: theme.colors.black.text,
    textAlign: 'start',
    '@media (min-width: 1024px)': {
      textAlign: 'center'
    }
  }
}));
const Description = ({ title, type }) => {
  const { titleDescription, textContent } = useStyles();
  return (
    <div className="w-100 my-3 d-block bg-white" xs={12}>
      <h5 className={titleDescription}>{title}</h5>
      <p className={textContent}>
        {type} gift cards can be used like cash at your chosen vendor&apos;s business. The entire
        balance of your gift card is available to pay for goods and services from the vendor.{' '}
        {type === APP_KEY.FUNRAY_ZR
          ? `10% of the value of your gift card is given to the local cause of your choice. By purchasing a
        ${type} gift card, you are supporting a local business as well as a local organization!`
          : `By purchasing a ${type} gift card, you are supporting a local business in your community!`}
      </p>
    </div>
  );
};

export default Description;
