export const ERROR_MESSAGE = {
  EMAIL: {
    VALID: 'Email is not in correct format'
  },
  PASSWORD: {
    VALID: 'The Password must be at least 6 and at max 100 characters long'
  },
  NEW_PASSWORD: {
    VALID: 'The New Password must be at least 6 and at max 100 characters long'
  },
  CONFIRM_PASSWORD: {
    VALID: 'Password and Confirm Password didn’t match',
    NEW_VALID: 'New Password and Confirm Password didn’t match'
  },
  VALUE: {
    MIN: 'Value field must be greater than or equal to 1',
    MAX: 'Value field must be less than or equal to 9999999999',
    VALID: 'Invalid value number'
  },
  QUANTITY: {
    MIN: 'Quantity field must be greater than or equal to 1',
    MAX: 'Quantity field must be less than or equal to 999',
    VALID: 'Invalid Quantity number'
  },
  CONFIRM_EMAIL: {
    VALID: "Confirm email didn't match Recipient Email"
  },
  PHONE_NUMBER: {
    VALID: 'Invalid phone number'
  },
  UNKNOWN: {
    CODE: 'Unknown',
    MESSAGE: 'Unable to connect to server'
  },
  CARD_CODE: {
    VALID: 'Invalid code'
  },
  WEBSITE: {
    VALID: 'Invalid URL format'
  }
};
