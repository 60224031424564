import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { PURPOSE } from 'constants/index';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import { CircularProgress } from '@mui/material';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import charityService from 'services/charity';
import CharityInfo from '../charityInfo';

const AdminPortalCharityEdit = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState({});

  const { toastError } = useToast();

  useEffect(() => {
    const fetchId = async () => {
      const resp = await charityService.getCharityDetail(match.params.id);
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        history.push(ADMIN_PORTAL_URL.USER.URL);
      } else {
        const {
          legalName,
          displayName,
          businessNumber,
          institutionNumber,
          transitNumber,
          accountNumber,
          organizationId,
          regionId,
          website,
          address = {},
          primaryContact = {}
        } = resp;
        setProfile({
          legalName,
          firstName: primaryContact.firstName,
          lastName: primaryContact.lastName,
          displayName,
          workPhoneNumber: primaryContact.workPhoneNumber,
          phoneNumber: primaryContact.phoneNumber,
          businessNumber,
          email: primaryContact.email,
          organizationId,
          regionId,
          website,
          institutionNumber,
          transitNumber,
          accountNumber,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          city: address.city,
          province: address.province,
          postalCode: address.postalCode
        });
        setLoading(false);
      }
    };
    fetchId();
  }, [history, match.params.id, toastError]);
  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <CharityInfo
      purpose={PURPOSE.EDIT}
      charityInfo={profile}
      id={match.params.id}
    />
  );
};

export default withRouter(AdminPortalCharityEdit);
