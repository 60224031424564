import React from 'react';
import { useParams } from 'react-router-dom';
import AdminPortalVendorViev, { SummaryTab } from '..';
import SummaryForm from '../../summary/summaryForm';

const AdminPortalVendorViewSummary = () => {
  const { vid } = useParams();

  return (
    <>
      <AdminPortalVendorViev vendorTab={SummaryTab.value}>
        <SummaryForm tenantId={vid} />
      </AdminPortalVendorViev>
    </>
  );
};

export default AdminPortalVendorViewSummary;
