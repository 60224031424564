import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { theme } from 'constants/theme';

const useStyles = makeStyles(() => ({
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.colors.black.loadingBg,
    zIndex: 1031,
    transition: 'all 0.3s ease'
  },
  overlayOp: {
    opacity: 0.75
  },
  progress: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    marginTop: '10px',
    marginLeft: '-20px',
    color: `${theme.colors.white.text}!important`
  }
}));

const Loading = ({ loading, overlayOpacity }) => {
  const { overlay, progress, overlayOp } = useStyles();
  return (
    loading && (
      <div className={`${overlay} ${overlayOpacity ? overlayOp : ''}`}>
        <CircularProgress className={progress} disableShrink />
      </div>
    )
  );
};

export default memo(Loading);
