import React from 'react';
import ContainerFooterLink from 'components/containerFooterLink';
import { EULA } from 'constants/globalContent';

const EulaPage = () => {
  return (
    <ContainerFooterLink title={EULA.title}>
      <h1>{EULA.interpretationAndDef}</h1>
      <h2>{EULA.interpretation}</h2>
      <p>
        {EULA.termInterpretation}
      </p>
      <h2>{EULA.definitions}</h2>
      <p>{EULA.definitionsDesc}</p>
      <ul>
        <li>
          <p>
            <strong>{EULA.defDescBullet.bullet1.subject}</strong>{EULA.defDescBullet.bullet1.desc}
          </p>
        </li>
        <li>
          <p>
            <strong>{EULA.defDescBullet.bullet2.subject}</strong>{EULA.defDescBullet.bullet2.desc}
          </p>
        </li>
        <li>
          <p>
            <strong>{EULA.defDescBullet.bullet3.subject}</strong>{EULA.defDescBullet.bullet3.desc}
          </p>
        </li>
        <li>
          <p>
            <strong>{EULA.defDescBullet.bullet4.subject}</strong>{EULA.defDescBullet.bullet4.desc}
          </p>
        </li>
        <li>
          <p>
            <strong>{EULA.defDescBullet.bullet5.subject}</strong>{EULA.defDescBullet.bullet5.desc}
          </p>
        </li>
        <li>
          <p>
            <strong>{EULA.defDescBullet.bullet6.subject}</strong>{EULA.defDescBullet.bullet6.desc}
          </p>
        </li>
        <li>
          <p>
            <strong>{EULA.defDescBullet.bullet7.subject}</strong>{EULA.defDescBullet.bullet7.desc}
          </p>
        </li>
        <li>
          <p>
            <strong>{EULA.defDescBullet.bullet8.subject}</strong>{EULA.defDescBullet.bullet8.desc}
          </p>
        </li>
        <li>
          <p>
            <strong>{EULA.defDescBullet.bullet9.subject}</strong>{EULA.defDescBullet.bullet9.desc}
          </p>
        </li>
        <li>
          <p>
            <strong>{EULA.defDescBullet.bullet10.subject}</strong>{EULA.defDescBullet.bullet10.desc}
          </p>
        </li>
      </ul>
      <h1>{EULA.acknowledgment}</h1>
      <p>
        {EULA.acknowledgmentDesc1}
      </p>
      <p>
        {EULA.acknowledgmentDesc2}
      </p>
      <p>
        {EULA.acknowledgmentDesc3}
      </p>
      <p>
        {EULA.acknowledgmentDesc4}
      </p>
      <p>
        {EULA.acknowledgmentDesc5}
      </p>
      <h1>{EULA.license}</h1>
      <h2>{EULA.scopeOfLicense}</h2>
      <p>
        {EULA.scopeOfLicenseDesc1}
      </p>
      <p>
        {EULA.scopeOfLicenseDesc2}
      </p>
      <p>
        {EULA.scopeOfLicenseDesc3}
      </p>
      <h1>{EULA.intellectualProp}</h1>
      <p>
        {EULA.intellectualPropDesc1}
      </p>
      <p>
        {EULA.intellectualPropDesc2}
      </p>
      <h1>{EULA.thirdPartyService}</h1>
      <p>
        {EULA.thirdPartyServiceDesc1}
      </p>
      <p>
        {EULA.thirdPartyServiceDesc2}
      </p>
      <p>
        {EULA.thirdPartyServiceDesc3}
      </p>
      <h1>{EULA.privacyPolicy}</h1>
      <p>
        {EULA.privacyPolicyDesc1.desc}
        <a
          href="https://funrayzr.com/privacy-policy"
          rel="external nofollow noopener noreferrer"
          target="_blank"
        >
          {EULA.privacyPolicyDesc1.link}
        </a>
      </p>
      <p>
        {EULA.privacyPolicyDesc2}
      </p>
      <h1>{EULA.termAndTermination}</h1>
      <p>
        {EULA.termAndTerminationDesc1}
      </p>
      <p>
        {EULA.termAndTerminationDesc2}
      </p>
      <p>
        {EULA.termAndTerminationDesc3}
      </p>
      <p>
        {EULA.termAndTerminationDesc4}
      </p>
      <h1>{EULA.indemnification}</h1>
      <p>
        {EULA.indemnificationDesc}
      </p>
      <h1>{EULA.noWarranties}</h1>
      <p>
        {EULA.noWarrantiesDesc1}
      </p>
      <p>
        {EULA.noWarrantiesDesc2}
      </p>
      <p>
        {EULA.noWarrantiesDesc3}
      </p>
      <h1>{EULA.limitOfLiability}</h1>
      <p>
        {EULA.limitOfLiabilityDesc1}
      </p>
      <p>
        {EULA.limitOfLiabilityDesc2}
      </p>
      <p>
        {EULA.limitOfLiabilityDesc3}
      </p>
      <p>
        {EULA.limitOfLiabilityDesc4}
      </p>
      <h1>{EULA.severabilityAndWaiver}</h1>
      <h2>{EULA.severability}</h2>
      <p>
        {EULA.severabilityDesc}
      </p>
      <h2>{EULA.waiver}</h2>
      <p>
        {EULA.waiverDesc}
      </p>
      <h1>{EULA.productClaim}</h1>
      <p>
        {EULA.productClaimDesc}
      </p>
      <h1>{EULA.usLegalCompliance}</h1>
      <p>
        {EULA.usLegalComplianceDesc}
      </p>
      <h1>{EULA.changeToAgreement}</h1>
      <p>
        {EULA.changeToAgreementDesc1}
      </p>
      <p>
        {EULA.changeToAgreementDesc2}
      </p>
      <h1>{EULA.governingLaw}</h1>
      <p>
        {EULA.governingLawDesc}
      </p>
      <h1>{EULA.entireAgreement}</h1>
      <p>
        {EULA.entireAgreementDesc1}
      </p>
      <p>
        {EULA.entireAgreementDesc2}
      </p>
      <h1>{EULA.contactUs}</h1>
      <p>{EULA.contactUsDesc}</p>
      <ul>
        <li>
          <p>{EULA.contactUsDescBullets.bullet1}</p>
        </li>
        <li>
          <p>{EULA.contactUsDescBullets.bullet2}</p>
        </li>
      </ul>
    </ContainerFooterLink>
  );
};
export default EulaPage;
