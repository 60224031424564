import { TENANT_TYPE } from "constants/index";
import { ABOUT_URL } from "pages/about/url";
import { ADMIN_PORTAL_URL } from "pages/adminPortal/url";

const isMatchUrl = (search, pathname) => {
  let path = pathname;
  if (!path) {
    path = window.location.href;
  }
  return path.indexOf(search) !== -1;
};

const isSignIn = (pathname) => {
  let path = pathname;
  if (!path) {
    path = window.location.href;
  }
  return path.indexOf('/signin') !== -1;
};

const isRegister = (pathname) => {
  let path = pathname;
  if (!path) {
    path = window.location.href;
  }
  return path.indexOf('/register') !== -1;
};

const isSignedOut = (pathname) => {
  let path = pathname;
  if (!path) {
    path = window.location.href;
  }
  return path.indexOf('/signed-out') !== -1;
};

const getUrlStatus = (path, locationPath) => {
  let status = '';
  let pathname = locationPath;
  if (!pathname) {
    pathname = window.location.pathname;
  }
  if (pathname.indexOf(path) !== -1) {
    status = 'active';
  }
  return status;
};

const getSearchParam = (param) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(param);
};

const getTenantUrl = (tenantType) => {
  let result = ABOUT_URL.ABOUT.URL;
  switch (tenantType) {
    case TENANT_TYPE.VENDOR:
      result = ADMIN_PORTAL_URL.VENDOR_SUMMARY.URL;
      break;
    case TENANT_TYPE.CHARITY:
      result = ADMIN_PORTAL_URL.CHARITY_INFORMATION.URL;
      break;
    default:
      break;
  }
  return result;
};

const urlHelper = {
  isSignIn,
  isRegister,
  isSignedOut,
  isMatchUrl,
  getUrlStatus,
  getSearchParam,
  getTenantUrl
};

export default urlHelper;
