import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import { Box, Typography, Tabs, Tab, Paper, InputBase } from '@mui/material';
import moment from 'moment';
import { makeStyles } from '@mui/styles';
import { FR_APP_KEY } from 'configs';
import { theme } from 'constants/theme';
import { GLOBAL_ELEMENT } from 'constants/globalElement';
import { BUCKET, DATE_FORMAT, TAB_VALUE } from 'constants/index';
import { CHARITY_URL } from 'pages/charity/url';
import ShopVendorList from 'pages/shopVendor/list';
import fileService from 'services/file';
import Select from 'components/select';
import { isMobile, isURL } from 'utils/helper';
import Button from 'components/button';
import CampaignsTab from './campaignsTab';

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box>
        <Typography component="div">{children}</Typography>
      </Box>
    )}
  </div>
);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles(() => ({
  tabs: {
    '@media (max-width: 576px)': {
      '& .MuiTabs-flexContainer': {
        justifyContent: 'space-between'
      }
    },
    color: theme.colors.white.text,
    '& .MuiTabs-scroller': {
      borderBottom: '1px solid'
    },
    '& button': {
      color: `${theme.colors.white.text} !important`,
      fontWeight: 700,
      fontSize: '1.2rem'
    },
    '& .MuiTabs-indicator': {
      height: 3,
      background: theme.colors.white.background
    }
  },
  tab: {
    '@media (max-width: 576px)': {
      fontSize: '1rem',
      marginRight: '0px'
    },
    color: 'white !important',
    fontSize: '1.6rem',
    textTransform: 'none',
    fontWeight: 'bolder',
    marginRight: '50px'
  }
}));

const ALL_CHARITY = 'all';

const ShopCharitiyDetailComponent = ({ vendor }) => {
  const [value, setValue] = useState(TAB_VALUE.CAMPAIGNS);
  const [campaignSelected, setCampaignSelected] = useState({});
  const [searchTempCharity, setSearchTempCharity] = useState('');
  const [categoryDataCharity, setCategoryDataCharity] = useState([]);
  const [categoryIdCharity, setCategoryIdCharity] = useState(ALL_CHARITY);
  const childRef = useRef();

  const history = useHistory();
  const { tab, tabs } = useStyles();
  const isMobileDevice = isMobile();
  const [toTop, setToTop] = useState(false);

  const element = document.getElementById(GLOBAL_ELEMENT.top_page);

  useEffect(() => {
    if (element) {
      element.scrollIntoView();
    }
  }, [element, toTop]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleBackClick = () => {
    history.push(CHARITY_URL.CHARITY.URL);
  };

  const handleChangeCateCharity = (e) => {
    childRef.current.handleChangeCate(e);
  };

  const handleBackCampaigns = () => {
    setValue(TAB_VALUE.CAMPAIGNS);
    setToTop(!toTop);
  };

  return (
    <div className="fr-background pb-0 text-white quicksand" id={GLOBAL_ELEMENT.top_page}>
      <Container fluid className="x-container p-0">
        <Row className="mx-0">
          <Col
            xs={12}
            md={3}
            className="white-bg-o2 px-0 py-sm-0 py-md-5 d-flex flex-column align-items-center c-shop-vendor-sidebar"
          >
            <div className="p-4 w-100 text-center violet-bg-o7">
              <span
                className="pointer"
                onKeyDown={handleBackClick}
                aria-hidden="true"
                onClick={handleBackClick}
              >
                &lt; Back to Charities
              </span>
            </div>
            <div className="mx-sm-0 mx-md-5 mb-md-5 mt-5 text-center d-flex flex-column align-items-center w-100">
              {value === TAB_VALUE.SUPPORTING_VENDORS && campaignSelected?.id && (
                <>
                  <p>Campaign Selection</p>
                  <Paper
                    elevation={5}
                    className="c-charity-campaign-image d-flex justify-content-center align-items-center mw-85"
                  >
                    {campaignSelected?.logoPath ? (
                      <img
                        src={
                          fileService._baseUrl +
                          '/' +
                          BUCKET.CAMPAIGN +
                          '/' +
                          campaignSelected.logoPath
                        }
                        alt={campaignSelected?.displayName}
                        loading="lazy"
                        className="img-fluid mw-95"
                      />
                    ) : (
                      <div className="d-flex justify-content-between align-items-center text-center c-vendor-campaign-name">
                        {campaignSelected?.name}
                      </div>
                    )}
                  </Paper>

                  <div className="mb-5 mt-2 mx-4">
                    <p className="text-white text-break mb-0">{campaignSelected?.name}</p>
                    <div>
                      {moment(campaignSelected?.startDate).format(DATE_FORMAT)}&nbsp;-&nbsp;
                      {moment(campaignSelected?.endDate).format(DATE_FORMAT)}
                    </div>
                  </div>

                  <p className="mt-md-5">Sponsored by:</p>
                </>
              )}

              <Paper
                elevation={5}
                className="c-charity-campaign-image d-flex justify-content-center align-items-center mw-85"
              >
                <img src={vendor.logoSrc} alt={FR_APP_KEY} className="img-fluid" loading="lazy" />
              </Paper>
              <p className="mb-4 m-md-0 mx-4 pt-4">{vendor.displayName}</p>
              {(value === TAB_VALUE.CAMPAIGNS || !campaignSelected?.id) && (
                <div className="mx-4 text-break">
                  <p className="m-0">{vendor.addressLine1}</p>
                  <p className="m-0">
                    {vendor.city}, {vendor.province}, {vendor.postalCode}
                  </p>
                  {isURL(vendor.website) ? (
                    <a
                      href={vendor.website}
                      target="blank"
                      className="white-title text-white-hover"
                    >
                      <u>{vendor.website}</u>
                    </a>
                  ) : (
                    <p className="m-0"><u>{vendor.website}</u></p>
                  )}
                </div>
              )}

              {value === TAB_VALUE.CAMPAIGNS && (
                <p className="m-0 pt-5 mx-4">{vendor.description}</p>
              )}

              {value === TAB_VALUE.SUPPORTING_VENDORS && !campaignSelected?.id && (
                <div className="mt-5 py-5 bg-danger w-100">
                  <p className="mb-5"> Campaign Not Selected</p>

                  <Button
                    fullWidth
                    variant="text"
                    className="text-white"
                    onClick={handleBackCampaigns}
                  >
                    &lt; Back to Campaigns
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col xs={12} md={9} className="px-4 py-5">
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{ borderBottom: !isMobileDevice && 1, borderColor: theme.colors.white.o5 }}
                className="d-flex justify-content-between flex-column flex-xl-row"
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className={tabs}
                >
                  <Tab label="CAMPAIGNS" {...a11yProps(0)} className={tab} />
                  <Tab label="SUPPORTING VENDORS" {...a11yProps(1)} className={tab} />
                </Tabs>
                {value === TAB_VALUE.SUPPORTING_VENDORS && (
                  <div className="col-xs-12 col-xl-5 d-sm-flex justify-content-end mt-0 mt-md-4 mt-xl-0">
                    <div className="text-right pr-xs-5 mt-4 mt-md-0 mr-md-2 d-flex d-sm-block justify-content-center w-100 flex-1">
                      <Select
                        color="transparent"
                        haveSearch
                        fullWidth
                        size="small"
                        className="bg-white rounded c-vendor-list-category c-custom-select"
                        name="role"
                        value={categoryIdCharity}
                        data={categoryDataCharity}
                        labelInput={!categoryIdCharity ? 'Choose Category' : ''}
                        onChange={handleChangeCateCharity}
                      />
                    </div>
                    <div className="text-center text-sm-right pr-xs-5 ml-md-2 mt-4 mt-md-0 d-flex d-sm-block justify-content-center flex-1">
                      <Paper className="c-input w-100 flex-1 py-1 rounded">
                        <InputBase
                          value={searchTempCharity}
                          placeholder="Search Vendors"
                          fullWidth
                          onChange={(e) => {
                            setSearchTempCharity(e.target.value);
                          }}
                        />
                      </Paper>
                    </div>
                  </div>
                )}
              </Box>
              <TabPanel value={value} index={TAB_VALUE.CAMPAIGNS}>
                <CampaignsTab
                  campaignSelected={campaignSelected}
                  onSetValue={setValue}
                  onSetCampaignSelected={setCampaignSelected}
                />
              </TabPanel>
              <TabPanel value={value} index={TAB_VALUE.SUPPORTING_VENDORS}>
                <ShopVendorList
                  campaignSelected={campaignSelected}
                  searchTempCharity={searchTempCharity}
                  categoryIdCharity={categoryIdCharity}
                  onSetSearchTemp={setSearchTempCharity}
                  onSetCategoryDataCharity={setCategoryDataCharity}
                  onSetCategoryIdCharity={setCategoryIdCharity}
                  ref={childRef}
                />
              </TabPanel>
            </Box>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ShopCharitiyDetailComponent;
