import { makeStyles } from '@mui/styles';
import Input from 'components/input';
import { gR } from 'constants/helperMessage';
import { useFormik } from 'formik';
import UnsavedPopup from 'pages/home/unsavedPopup';
import React, { useCallback, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import * as Yup from 'yup';
import { theme } from 'constants/theme';
import { Link } from 'react-router-dom';
import Button from 'components/button';
import { usePlacesWidget } from 'react-google-autocomplete';
import { GOOGLE_API_KEY } from 'configs';

const useStyles = makeStyles(() => ({
  border: {
    height: '1px',
    background: theme.colors.gray.border,
    margin: '0px 24px 20px 24px'
  },
  action: {
    marginBottom: '14px',
    justifyContent: 'center',
    display: 'flex'
  }
}));

const RegionForm = ({ title, initialValues, onSubmit, cancelUrl, isDisabled }) => {
  const { border, action } = useStyles();

  const handleOnSubmit = useCallback(
    (values) => {
      if (onSubmit) {
        onSubmit(values);
      }
    },
    [onSubmit]
  );

  const { values, errors, touched, handleChange, handleSubmit, resetForm, setFieldValue } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required(gR('Region / Territory name')),
      province: Yup.string().required(gR('Province')),
      country: Yup.string().required(gR('Country')),
      postalCode: Yup.string().required(gR('Postal Code'))
    }),
    onSubmit: handleOnSubmit
  });

  useEffect(() => {
    setTimeout(() => {
      if (!initialValues.name) {
        setFieldValue('name', ' ');
        setFieldValue('name', '');
      }
    }, 200);
  }, [initialValues.name, setFieldValue]);

  const { ref: placeRef } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    options: {
      fields: [],
      types: []
    },
    onPlaceSelected: (place) => {
      if (place) {

        const { address_components: addressComponents = [] } = place;

        let name = addressComponents.find((add) => {
          return (add.types || []).find((type) => { return type.indexOf('route') !== -1; });
        });
        if (!name?.long_name) {
          name = addressComponents.find((add) => {
            return (add.types || []).find((type) => { return type.indexOf('locality') !== -1; });
          });
        }
        setFieldValue('name', name?.long_name || '');

        const province = addressComponents.find((add) => {
          return (add.types || []).find((type) => { return type.indexOf('administrative_area_level_1') !== -1; });
        });
        setFieldValue('province', province?.long_name || '');

        const country = addressComponents.find((add) => {
          return (add.types || []).find((type) => { return type.indexOf('country') !== -1; });
        });
        setFieldValue('country', country?.long_name || '');

        const postalCode = addressComponents.find((add) => {
          return (add.types || []).find((type) => { return type.indexOf('postal_code') !== -1; });
        });
        setFieldValue('postalCode', postalCode?.long_name || '');
      }
    }
  });

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} className="d-flex justify-content-between align-items-center">
            <h3 className="fr-page-title text-tertiary quicksand flex-1">
              {title}
            </h3>
          </Col>
          <Col xs={12}>
            <div className={border} />
          </Col>
        </Row>

        <form onSubmit={handleSubmit}>
          <Row className="pt-3 pb-4">
            <Col xs={12} className="d-flex flex-column align-items-center">
              <Input
                inputRef={placeRef}
                placeholder=""
                color="tertiary"
                size="small"
                label="Region / Territory name*"
                className="m-2 min-w-300"
                name="name"
                onChange={handleChange}
                value={values.name}
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />

              <Input
                color="tertiary"
                size="small"
                label="Province*"
                className="m-2 min-w-300"
                name="province"
                onChange={handleChange}
                value={values.province}
                error={touched.province && !!errors.province}
                helperText={touched.province && errors.province}
              />
              <Input
                color="tertiary"
                size="small"
                label="Country*"
                className="m-2 min-w-300"
                name="country"
                onChange={handleChange}
                value={values.country}
                error={touched.country && !!errors.country}
                helperText={touched.country && errors.country}
              />
              <Input
                color="tertiary"
                size="small"
                label="Postal Code*"
                className="m-2 min-w-300"
                name="postalCode"
                onChange={handleChange}
                value={values.postalCode}
                error={touched.postalCode && !!errors.postalCode}
                helperText={touched.postalCode && errors.postalCode}
              />

              <div className={`${action} mt-5`}>
                <Button
                  size="small"
                  color="secondary"
                  className="mr-1 text-white-hover"
                  component={Link}
                  to={cancelUrl}
                >
                  Cancel
                </Button>
                <Button
                  color="tertiary"
                  size="small"
                  className="ml-1"
                  type="submit"
                  disabled={isDisabled}
                  minWidth={79.86}
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
      <UnsavedPopup
        values={values}
        initialValues={initialValues}
        isSubmit={isDisabled}
        resetForm={resetForm}
      />
    </>
  );
};

export default RegionForm;
