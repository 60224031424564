import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { gR } from 'constants/helperMessage';
import Input from 'components/input';
import Button from 'components/button';
import AmountRadio from 'pages/shopVendor/detail/component/amountRadio';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import GiftRadio from 'pages/shopVendor/detail/component/giftRadio';

const useStyles = makeStyles(() => ({
  paddingCol: {
    padding: '10px',
    '@media (min-width: 1024px)': {
      padding: '0px 39px 8px 39px'
    }
  }
}));

const VendorCardForm = ({ vendor, onSubmit, isDisabled }) => {
  const { paddingCol } = useStyles();

  const initialValues = {
    amount: '',
    quantity: '1',
    isGift: false,
    to: '',
    recipientEmail: '',
    confirmEmail: '',
    from: '',
    message: ''
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      amount: Yup.string().required(gR('Card Amount')),
      // WE STASH THIS CODE IN CASE NEW REQUIREMNT
      // quantity: Yup.number()
      //   .typeError(ERROR_MESSAGE.QUANTITY.VALID)
      //   .required(gR('Quantity'))
      //   .min(1, ERROR_MESSAGE.QUANTITY.MIN)
      //   .max(999, ERROR_MESSAGE.QUANTITY.MAX),
      to: Yup.string().when('isGift', {
        is: true,
        then: Yup.string().required(gR('To')),
        otherwise: Yup.string()
      }),
      recipientEmail: Yup.string().when('isGift', {
        is: true,
        then: Yup.string().email(ERROR_MESSAGE.EMAIL.VALID).required(gR('Recipient Email')),
        otherwise: Yup.string()
      }),
      confirmEmail: Yup.string().when('isGift', {
        is: true,
        then: Yup.string()
          .email(ERROR_MESSAGE.EMAIL.VALID)
          .required(gR('Confirm Email'))
          .oneOf([Yup.ref('recipientEmail')], ERROR_MESSAGE.CONFIRM_EMAIL.VALID),
        otherwise: Yup.string()
      }),
      from: Yup.string().when('isGift', {
        is: true,
        then: Yup.string().required(gR('From')),
        otherwise: Yup.string()
      })
    }),
    enableReinitialize: true,
    onSubmit
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <Container fluid>
        <Row>
          <Col xs={12} className={paddingCol}>
            <AmountRadio
              label="Select Amount*"
              name="amount"
              data={vendor?.amount}
              onChange={formik.handleChange}
              value={formik.values.quantity}
            />
          </Col>
          {/* WE STASH THIS CODE IN CASE NEW REQUIREMNT */}
          {/* <Col xs={12} className={`${paddingCol} mt-4 mb-3`}>
                  <Input
                    size="small"
                    label="Quantity"
                    name="quantity"
                    onChange={formik.handleChange}
                    value={formik.values.quantity}
                    error={formik.touched.quantity && !!formik.errors.quantity}
                    helperText={formik.touched.quantity && formik.errors.quantity}
                  />
                </Col> */}
          <Col xs={12} className={paddingCol}>
            <GiftRadio
              label="Is this a gift?"
              name="isGift"
              onChange={formik.handleChange}
              value={formik.values.isGift}
            />
          </Col>
          {formik.values.isGift && (
            <>
              <Col xs={12} className={paddingCol}>
                <Input
                  fullWidth
                  size="small"
                  label="To*"
                  name="to"
                  onChange={formik.handleChange}
                  value={formik.values.to}
                  error={formik.touched.to && !!formik.errors.to}
                  helperText={formik.touched.to && formik.errors.to}
                />
              </Col>
              <Col xs={12} className={paddingCol}>
                <Input
                  fullWidth
                  size="small"
                  label="Recipient Email*"
                  name="recipientEmail"
                  onChange={formik.handleChange}
                  value={formik.values.recipientEmail}
                  error={formik.touched.recipientEmail && !!formik.errors.recipientEmail}
                  helperText={formik.touched.recipientEmail && formik.errors.recipientEmail}
                />
              </Col>
              <Col xs={12} className={paddingCol}>
                <Input
                  fullWidth
                  size="small"
                  label="Confirm Email*"
                  name="confirmEmail"
                  onChange={formik.handleChange}
                  value={formik.values.confirmEmail}
                  error={formik.touched.confirmEmail && !!formik.errors.confirmEmail}
                  helperText={formik.touched.confirmEmail && formik.errors.confirmEmail}
                />
              </Col>
              <Col xs={12} className={paddingCol}>
                <Input
                  fullWidth
                  size="small"
                  label="From*"
                  name="from"
                  onChange={formik.handleChange}
                  value={formik.values.from}
                  error={formik.touched.from && !!formik.errors.from}
                  helperText={formik.touched.from && formik.errors.from}
                />
              </Col>
              <Col xs={12} className={paddingCol}>
                <Input
                  fullWidth
                  multiline
                  rows={3}
                  size="small"
                  label="Gift Card Message"
                  name="message"
                  onChange={formik.handleChange}
                  value={formik.values.message}
                  error={formik.touched.message && !!formik.errors.message}
                  helperText={formik.touched.message && formik.errors.message}
                />
              </Col>
            </>
          )}

          <Col xs={12} className={paddingCol}>
            <Button
              color="black"
              className="text-white-hover mt-4"
              size="small"
              type="submit"
              disabled={isDisabled}
            >
              Add to Cart
            </Button>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default VendorCardForm;
