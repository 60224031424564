import { PURPOSE } from 'constants/index';
import CampaignForm from 'pages/adminPortal/charity/campaign/campaignForm';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import React from 'react';
import { useParams } from 'react-router-dom';
import AdminPortalCharityView, { CampaignTab } from '../..';

const AdminPortalCharityViewCampaignAdd = () => {
  const { cid } = useParams();

  return (
    <>
      <AdminPortalCharityView tab={CampaignTab.value}>
        <CampaignForm
          charityId={cid}
          purpose={PURPOSE.ADD}
          redirectUrl={ADMIN_PORTAL_URL.CHARITY_VIEW_CAMPAIGNS.URL.replace(':cid', cid)}
          backUrl={ADMIN_PORTAL_URL.CHARITY_VIEW_CAMPAIGNS.URL.replace(':cid', cid)}
        />
      </AdminPortalCharityView>
    </>
  );
};

export default AdminPortalCharityViewCampaignAdd;
