import Api from 'api/vendor/api';
import { FUNRAYZR_API_URL } from 'configs';
import { STORAGE_KEY } from 'constants/index';
import AxiosWrapper from 'utils/axios';

export class VendorService {
  constructor() {
    this._baseUrl = `${FUNRAYZR_API_URL}/Vendors`;
    this._cateUrl = `${FUNRAYZR_API_URL}/Categories`;
    const axios = AxiosWrapper();
    this._vendorApi = Api(axios);
  }

  createVendor(vendor) {
    return this._vendorApi.createVendor(vendor);
  }

  editVendor(id, vendor) {
    return this._vendorApi.editVendor(id, vendor);
  }

  getVendorDetail(id) {
    return this._vendorApi.getVendorDetail(id);
  }

  getVendor(params) {
    return this._vendorApi.getVendor(params);
  }

  getVendorsPublic(params) {
    const pa = {
      ...params,
      regionId: localStorage.getItem(STORAGE_KEY.REGION)
    };
    return this._vendorApi.getVendorsPublic(pa);
  }

  statusVendor(id, status) {
    return this._vendorApi.statusVendor(id, status);
  }

  getSummary(params) {
    return this._vendorApi.getSummary(params);
  }
}

const vendorService = new VendorService();

export default vendorService;
