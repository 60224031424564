import React from 'react';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import { Container } from 'reactstrap';
import AdminPortalUserEditForm from 'pages/adminPortal/user/detail/userEditForm';

const AdminPortalVendorUserDetail = () => {

  return (
    <div className="fr-container pb-40 fr-background quicksand">
      <Container>
        <div className="fr-page-container mt-5" style={{ minHeight: '400px' }}>
          <AdminPortalUserEditForm
            hasTenantField={false}
            includeAdminRole={false}
            notFoundRedirectUrl={ADMIN_PORTAL_URL.VENDOR_USER.URL}
            redirectUrl={ADMIN_PORTAL_URL.VENDOR_USER.URL}
            backUrl={ADMIN_PORTAL_URL.VENDOR_USER.URL}
          />
        </div>
      </Container>
    </div>
  );
};

export default AdminPortalVendorUserDetail;
