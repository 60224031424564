import React from 'react';
import { Container } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import TabUrl from 'components/tabUrl';
import PropTypes from 'prop-types';

export const InformationTab = {
  name: ADMIN_PORTAL_URL.CHARITY_VIEW_INFORMATION.NAME,
  value: 'information'
};

export const CampaignTab = {
  name: ADMIN_PORTAL_URL.CHARITY_VIEW_CAMPAIGNS.NAME,
  value: 'campaigns'
};

export const ReportingTab = {
  name: ADMIN_PORTAL_URL.CHARITY_VIEW_REPORTING.NAME,
  value: 'reporting'
};

const AdminPortalCharityView = ({ tab, children, className }) => {
  const { cid } = useParams();

  const tabs = [
    {
      ...InformationTab,
      url: ADMIN_PORTAL_URL.CHARITY_VIEW_INFORMATION.URL.replace(':cid', cid)
    },
    {
      ...CampaignTab,
      url: ADMIN_PORTAL_URL.CHARITY_VIEW_CAMPAIGNS.URL.replace(':cid', cid)
    },
    {
      ...ReportingTab,
      url: ADMIN_PORTAL_URL.CHARITY_VIEW_REPORTING.URL.replace(':cid', cid)
    }
  ];

  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <div
          className={`tab-background fr-page-container bg-white mt-5 ${className}`}
          style={{ minHeight: '400px' }}
        >
          <TabUrl tabs={tabs} tab={tab}>
            {children}
          </TabUrl>
        </div>
      </Container>
    </div>
  );
};

AdminPortalCharityView.propTypes = {
  tab: PropTypes.oneOf([InformationTab.value, CampaignTab.value, ReportingTab.value]).isRequired
};

export default AdminPortalCharityView;
