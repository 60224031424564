import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Table as MTable,
  CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import Input from 'components/input';
import { theme, COLOR } from 'constants/theme';
import DateRangePicker from 'components/dateRangePicker';
import { Col, Container, Row } from 'reactstrap';
import _ from 'lodash';
import { isMobileByWatchMedia } from 'utils/helper';

import { ADMIN_URL, ORDER_TYPE } from '../../constants';

const DELAY_DEBOUNCUE_MS = 500;

const UI_SORT_TYPE = {
  ASC: 'asc',
  DESC: 'desc'
};

const useStyles = makeStyles(() => ({
  table: {
    marginTop: '16px',
    borderCollapse: 'collapse'
  },
  trHead: ({ noBorderTop }) => ({
    '& th': {
      fontWeight: '600',
      color: theme.colors.gray.title,
      backgroundColor: theme.colors.white.background,
      paddingTop: '20px',
      borderTop: !noBorderTop ? `1px solid ${theme.colors.gray.border}` : 0
    }
  }),
  tr: {
    '&:hover': {
      backgroundColor: theme.colors.yellow.hover
    }
  },
  td: {
    fontWeight: '300',
    background: theme.colors.blue.background,
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    backgroundClip: 'padding-box',
    wordBreak: 'break-word'
  },
  emailTd: {
    maxWidth: 0,
    wordWrap: 'break-word'
  },
  search: {
    '& .MuiInputBase-root': {
      width: '75%'
    }
  },
  pagination: ({ colorPagination }) => ({
    color: colorPagination
      ? `${colorPagination} !important`
      : `${theme.colors.white.text} !important`,
    '& .MuiTablePagination-displayedRows': {
      marginBottom: 0
    },
    '& .MuiTablePagination-selectLabel': {
      marginBottom: 0
    },
    '@media (max-width: 375px)': {
      '& .MuiToolbar-root': {
        paddingLeft: 1,
        paddingRight: 0
      }
    }
  })
}));

const useTable = () => {
  const [data, setData] = useState({ items: [], totalItems: 0 });
  const setDataFetch = useCallback((dataFetch) => setData(dataFetch), [setData]);

  return [data, setDataFetch];
};

const Table = ({
  headCells,
  rowCells,
  pageSize,
  totalCount,
  service,
  havePagination,
  title,
  noBorderTop,
  colorPagination,
  haveSearch,
  haveFilterByDate,
  initDate,
  searchFields,
  haveHeaderAction,
  setDataFetch,
  api,
  id,
  apiParams,
  rerender,
  ...rest
}) => {
  const [page, setPage] = useState(0);
  const [orderType, setOrderType] = useState();
  const [orderBy, setOrderBy] = useState();
  const [searchTemp, setSearchTemp] = useState();
  const [searchText, setSearchText] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  const [loading, setLoading] = useState(false);
  const [inputDate, setInputDate] = useState(initDate ?? [null, null]);
  const showLoading = useCallback(() => {
    setLoading(true);
  }, [setLoading]);
  const hideLoading = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  const { table, trHead, tr, td, emailTd, search, pagination } = useStyles({
    noBorderTop,
    colorPagination
  });
  const { location } = useHistory();

  const isMobile = isMobileByWatchMedia();
  const isAdmin = location.pathname.includes(ADMIN_URL);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearchText(searchTemp ?? '');
    }, DELAY_DEBOUNCUE_MS);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTemp]);

  useEffect(() => {
    const fetch = async () => {
      const [startDate, eDate] = inputDate;
      let endDate = null;
      if (eDate) {
        endDate = new Date(eDate.getTime());
        endDate.setHours(0);
        endDate.setMinutes(0);
        endDate.setSeconds(0);
      }
      const params = {
        ...apiParams,
        PageIndex: page,
        PageSize: rowsPerPage,
        SearchText: searchText || undefined,
        SearchFields: searchText ? searchFields : undefined,
        StartDate: startDate ?? undefined,
        EndDate: endDate ?? undefined
      };
      showLoading();
      const resp = await service[api](params, id);
      hideLoading();
      setDataFetch(resp);
    };

    fetch();
  }, [
    service,
    setDataFetch,
    api,
    apiParams,
    id,
    showLoading,
    hideLoading,
    page,
    rowsPerPage,
    searchText,
    searchFields,
    rerender,
    inputDate
  ]);

  // eslint-disable-next-line no-unused-vars
  const convertOrderType = (type) => {
    const orderTypeBE = {
      [UI_SORT_TYPE.ASC]: ORDER_TYPE.ASC,
      [UI_SORT_TYPE.DESC]: ORDER_TYPE.DESC
    };
    return orderTypeBE[type];
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSortRequest = (cellId) => {
    const isAsc = orderBy === cellId && orderType === UI_SORT_TYPE.ASC;
    setOrderType(isAsc ? UI_SORT_TYPE.DESC : UI_SORT_TYPE.ASC);
    setOrderBy(cellId);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tablePagingRender = useMemo(() => {
    if (!havePagination) {
      return null;
    }

    let rowPerPageOptions = [10, 25, 50, 100];
    if (rowPerPageOptions.indexOf(pageSize) === -1) {
      rowPerPageOptions.push(pageSize);
      rowPerPageOptions = _.sortBy(rowPerPageOptions);
    }

    return (
      <TablePagination
        className={pagination}
        component="div"
        page={page}
        rowsPerPage={rowsPerPage}
        count={totalCount}
        onPageChange={handleChangePage}
        rowsPerPageOptions={rowPerPageOptions}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    );
  }, [havePagination, page, pageSize, pagination, rowsPerPage, totalCount]);

  return (
    <>
      <div className={`py-3 px-3 ${haveFilterByDate && 'pt-4'} px-md-4 bg-white rounded`}>
        {haveFilterByDate ? (
          <Container fluid>
            <Row>
              <Col xs={12} className="px-0">
                <div
                  className={`${isMobile && 'flex-column'
                    } w-100 d-flex justify-content-between align-items-center`}
                >
                  {haveSearch && (
                    <Input
                      color={isAdmin ? 'tertiary' : 'primary'}
                      label="Search"
                      size="small"
                      onChange={(e) => {
                        setSearchTemp(e.target.value);
                        setPage(0);
                      }}
                      style={{ minWidth: '180px' }}
                      className={`${isMobile && 'w-100'}`}
                    />
                  )}

                  <DateRangePicker
                    color={isAdmin ? 'tertiary' : 'primary'}
                    value={inputDate}
                    onChange={(newValue) => {
                      setInputDate(newValue);
                      setPage(0);
                    }}
                    size="small"
                    className={`${isMobile && 'w-100 mt-3'}`}
                  />

                  <div
                    className={`${isMobile && 'w-100'
                      } d-flex justify-content-end align-items-center`}
                  >
                    {haveHeaderAction && haveHeaderAction}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <div className="w-100 d-flex justify-content-between align-items-center">
            <h2
              className={`h6 text-${!isAdmin ? 'primary' : 'tertiary'} font-weight-bold flex-1 m-0`}
            >
              {title}
            </h2>
            {haveSearch && (
              <Input
                label="Search"
                size="small"
                color={isAdmin ? COLOR.TERTIARY : COLOR.PRIMARY}
                className={`${search} flex-1`}
                onChange={(e) => {
                  setSearchTemp(e.target.value);
                  setPage(0);
                }}
              />
            )}
            <div className="d-flex flex-1 justify-content-end align-items-center">
              {haveHeaderAction && haveHeaderAction}
            </div>
          </div>
        )}

        <MTable className={table} {...rest}>
          <TableHead>
            <TableRow className={trHead}>
              {headCells?.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? orderType : false}
                  align="center"
                  className={headCell.className ? headCell.className : null}
                >
                  {headCell.sorting ? (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? orderType : UI_SORT_TYPE.ASC}
                      key={headCell.id}
                      onClick={() => {
                        handleSortRequest(headCell.id);
                      }}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  ) : (
                    headCell.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!loading && (
            <TableBody>
              {rowCells?.map((item) => (
                <TableRow key={item.id} className={tr}>
                  {headCells?.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align="center"
                      className={td + ' ' + (headCell.id === 'email' ? emailTd : '')}
                    >
                      {item[headCell.id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          )}
        </MTable>
      </div>

      {loading && (
        <div
          className="w-100 d-flex justify-content-center align-items-center bg-white"
          style={{ minHeight: '200px' }}
        >
          <CircularProgress disableShrink />
        </div>
      )}

      {!totalCount && !loading && (
        <div
          className="w-100 d-flex justify-content-center align-items-center bg-white"
          style={{ minHeight: '200px' }}
        >
          No Data
        </div>
      )}

      {tablePagingRender}
    </>
  );
};

Table.defaultProps = {
  pageSize: 10,
  service: {},
  havePagination: false,
  colorPagination: '',
  haveFilterByDate: false,
  noBorderTop: false,
  api: null,
  id: null,
  searchFields: undefined,
  setDataFetch: () => { }
};

Table.propTypes = {
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowCells: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number.isRequired,
  pageSize: PropTypes.number,
  service: PropTypes.objectOf(PropTypes.any),
  colorPagination: PropTypes.string,
  id: PropTypes.string,
  havePagination: PropTypes.bool,
  haveFilterByDate: PropTypes.bool,
  noBorderTop: PropTypes.bool,
  api: PropTypes.string,
  searchFields: PropTypes.string,
  setDataFetch: PropTypes.func
};

export { Table, useTable };
