import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from "reactstrap";
import BoxInfo from 'components/boxInfo';
import Button from 'components/button';
import { Link } from 'react-router-dom';
import { theme } from 'constants/theme';
import { SIGNIN_URL } from 'pages/signIn/url';
import urlHelper from 'utils/url';
import { URL_PARAM } from 'constants/index';

const RegisterConfirmation = () => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    const e = urlHelper.getSearchParam(URL_PARAM.EMAIL) || '';
    setEmail(e);
  }, []);

  return (
    <div className="fr-background fr-box-container">
      <div className="fr-box">
        <Container>
          <div className="fr-page-container fr-content">
            <BoxInfo title="Congratulations!" />
            <Row className="text-center mt-5">
              <Col md={12} className="my-1">
                <p className="fr-content-sub-title mb-0">Email has been confirmed.</p>
              </Col>
            </Row>
            <Row className="text-center mt-5">
              <Col md={12}>
                <Button
                  component={Link}
                  to={`${SIGNIN_URL.SIGNIN.URL}?${URL_PARAM.EMAIL}=${email}`}
                  kind="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  minWidth={130}
                  bgHover={[theme.colors.orange.button, theme.colors.white.text]}
                  colorOnClick={[theme.colors.darkcyan.button]}
                >
                  <span className="text-uppercase">Continue</span>
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default RegisterConfirmation;
