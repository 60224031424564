import React, { useEffect, useMemo, useState } from 'react';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import vendorService from 'services/vendor';
import authService from 'services/authorization';
import UserList from 'pages/adminPortal/user/userList';
import { Container } from 'reactstrap';

const AdminPortalVendorUser = () => {
  const [vendor, setVendor] = useState({});
  const tenantId = authService.getTenantId();

  useEffect(() => {
    const getVendor = async () => {
      const vend = await vendorService.getVendorDetail(tenantId);
      if (vend) {
        setVendor(vend);
      }
    };
    if (tenantId) {
      getVendor();
    }
  }, [tenantId]);

  const renderView = useMemo(() => {
    return (
      <UserList
        tableTitle={vendor.displayName}
        addUrl={ADMIN_PORTAL_URL.VENDOR_USER_ADD.URL}
        editUrl={ADMIN_PORTAL_URL.VENDOR_USER_DETAIL.URL}
      />
    );
  }, [vendor.displayName]);

  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <h3 className="fr-page-title">Vendor Management - Users</h3>
        <div className="fr-page-container  bg-transparent" style={{ minHeight: '400px' }}>
          {renderView}
        </div>
      </Container>
    </div>
  );
};

export default AdminPortalVendorUser;
