import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import Button from 'components/button';
import { usePopup } from 'components/popup';
import { PURPOSE } from 'constants/index';
import { theme } from 'constants/theme';
import Switch from 'components/switch';
import tenantService from 'services/tenant';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import roleService from 'services/role';
import AdministrationPopup from './administrationPopup';
import ProfileForm from './profileForm';

const useStyles = makeStyles(() => ({
  border: {
    height: '1px',
    background: theme.colors.gray.border,
    margin: '0px 24px 20px 24px'
  }
}));

const UserInfo = ({
  purpose,
  profile,
  onSubmit,
  isDisabled,
  roleActive,
  cancelUrl,
  handleRoleActiveSwitch,
  isRoleActiveSwitchDisable,
  hasTenantField = true,
  includeAdminRole = true
}) => {
  const [roles, setRoles] = useState([]);
  const [tenants, setTenants] = useState([]);

  const { border } = useStyles();
  const [isOpenAdmin, openAdmin, closeAdmin] = usePopup();
  const { toastError } = useToast();
  const isAdd = purpose === PURPOSE.ADD;

  useEffect(() => {
    const getRoles = async () => {
      const resp = await roleService.getRoles({ isAdmin: includeAdminRole });
      if (resp?.errorCode) {
        toastError(resp.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        setRoles(resp);
      }
    };

    getRoles();
  }, [includeAdminRole, toastError]);

  useEffect(() => {
    const getTenants = async () => {
      const resp = await tenantService.getTenants();
      if (resp?.errorCode) {
        toastError(resp.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        setTenants(resp);
      }
    };
    if (hasTenantField) {
      getTenants();
    }
  }, [hasTenantField, toastError]);

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} className="d-flex justify-content-between align-items-center">
            <div className="flex-1" />
            <h3 className="fr-page-title text-tertiary quicksand flex-1">
              {isAdd ? 'Add' : 'Manage'} User
            </h3>
            <div className="flex-1 text-right mr-4">
              {!isAdd && (
                <Switch
                  color="tertiary"
                  checked={roleActive}
                  label={`Role ${roleActive ? 'Active' : 'Inactive'}`}
                  onChange={handleRoleActiveSwitch}
                  disabled={isRoleActiveSwitchDisable}
                />
              )}
              <Button
                color="tertiary"
                disabled={isAdd}
                size="small"
                onClick={openAdmin}
                className="ml-2"
              >
                Send Password Reset
              </Button>
            </div>
          </Col>
          <Col xs={12}>
            <div className={border} />
          </Col>
        </Row>

        <Row className="pt-3 pb-4">
          <Col xs={12}>
            <ProfileForm
              profile={profile}
              onSubmit={onSubmit}
              isAdd={isAdd}
              isDisabled={isDisabled}
              roles={roles}
              tenants={tenants}
              hasTenantField={hasTenantField}
              cancelUrl={cancelUrl}
            />
          </Col>
        </Row>
      </Container>

      {!isAdd && (
        <AdministrationPopup
          isOpenAdmin={isOpenAdmin}
          closeAdmin={closeAdmin}
          email={profile?.email}
        />
      )}
    </>
  );
};

export default UserInfo;
