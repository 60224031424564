import Api from 'api/category/api';
import { FUNRAYZR_API_URL } from 'configs';
import AxiosWrapper from 'utils/axios';

export class CategoryService {
  constructor() {
    this._baseUrl = `${FUNRAYZR_API_URL}/Categories`;
    const axios = AxiosWrapper();
    this._categoryApi = Api(axios);
  }

  getCategories() {
    return this._categoryApi.getCategories();
  }
}

const categoryService = new CategoryService();

export default categoryService;
