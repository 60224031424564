import React, { useCallback, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { EDIT_TYPE, PURPOSE } from 'constants/index';
import vendorService from 'services/vendor';
import categoryService from 'services/category';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import { CircularProgress } from '@mui/material';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import useLoading from 'components/loading/useLoading';
import { gS } from 'constants/helperMessage';
import publicService from 'services/public';
import VendorInfo from '../vendorInfo';

const AdminPortalVendorID = ({ match, history }) => {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [categories, setCategories] = useState([{}]);
  const [regions, setRegions] = useState([{}]);

  const { toastSuccess, toastError } = useToast();
  const [showLoading, hideLoading] = useLoading();

  useEffect(() => {
    const fetchId = async () => {
      const resp = await vendorService.getVendorDetail(match.params.id);
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        history.push(ADMIN_PORTAL_URL.VENDOR.URL);
      } else {
        const {
          legalName,
          displayName,
          businessNumber,
          institutionNumber,
          transitNumber,
          accountNumber,
          categoryId,
          regionId,
          website,
          address = {},
          primaryContact = {},
          charityRate
        } = resp;

        setLoading(false);

        setInitialValues({
          legalName,
          firstName: primaryContact.firstName,
          lastName: primaryContact.lastName,
          displayName,
          workPhoneNumber: primaryContact.workPhoneNumber,
          phoneNumber: primaryContact.phoneNumber,
          businessNumber,
          email: primaryContact.email,
          categoryId,
          regionId,
          website,
          institutionNumber,
          transitNumber,
          accountNumber,
          addressLine1: address.addressLine1,
          addressLine2: address.addressLine2,
          city: address.city,
          province: address.province,
          postalCode: address.postalCode,
          charityRate
        });
      }
    };
    fetchId();
  }, [history, match.params.id, toastError]);

  const onSubmit = useCallback(
    async (values) => {
      setIsDisabled(true);
      showLoading();

      const {
        legalName,
        firstName,
        lastName,
        displayName,
        workPhoneNumber,
        phoneNumber,
        businessNumber,
        email,
        categoryId,
        regionId,
        website,
        institutionNumber,
        transitNumber,
        accountNumber,
        addressLine1,
        addressLine2,
        city,
        province,
        postalCode,
        charityRate
      } = values;

      const vendor = {
        legalName,
        displayName,
        businessNumber,
        categoryId,
        regionId,
        website,
        institutionNumber,
        transitNumber,
        accountNumber,
        address: { addressLine1, addressLine2, city, province, postalCode },
        primaryContact: { firstName, lastName, email, workPhoneNumber, phoneNumber },
        editType: EDIT_TYPE.BY_ADMIN,
        charityRate
      };
      const resp = await vendorService.editVendor(match.params.id, vendor);
      hideLoading();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        toastSuccess(gS('Edit vendor'));
        history.push(ADMIN_PORTAL_URL.VENDOR.URL);
      }
      setIsDisabled(false);
    },
    [match.params.id, hideLoading, showLoading, toastError, toastSuccess, history]
  );

  useEffect(() => {
    const fetchCategories = async () => {
      const resp = await categoryService.getCategories();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        setCategories(resp);
      }
    };

    const fetchRegions = async () => {
      const resp = await publicService.getRegions();
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
        return;
      }
      setRegions(resp);
    };

    fetchCategories();
    fetchRegions();
  }, [toastError]);

  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <VendorInfo
      purpose={PURPOSE.EDIT}
      initialValues={initialValues}
      onSubmit={onSubmit}
      isDisabled={isDisabled}
      categories={categories}
      regions={regions}
    />
  );
};

export default withRouter(AdminPortalVendorID);
