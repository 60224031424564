import axios from 'axios';
import { ENV, FR_APP_KEY, FUNRAYZR_API_URL, FUNRAYZR_ENDPOINT } from 'configs';
import { URL_PARAM, USER_ROLE, ACCOUNT_STATUS, TENANT_TYPE, STORAGE_KEY } from 'constants/index';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { REGISTER_URL } from 'pages/register/url';
import imageHelper from 'utils/image';
import jwtHelper from 'utils/jwt';
import storageHelper from 'utils/storage';
import { RESET_PASSWORD_URL } from 'pages/resetPassword/url';
import { getResponseError } from 'utils/axios/helper';
import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';

export class AuthorizeService {
  constructor() {
    this._callbacks = [];
    this._nextSubscriptionId = 1;
    this._user = null;
    this._isAuthenticated = false;
    this.connection = null;
    this._axios = axios;
  }

  async signIn(email, password) {
    let result = {
      errorCode: ERROR_MESSAGE.UNKNOWN.CODE,
      errorMessage: ERROR_MESSAGE.UNKNOWN.MESSAGE
    };
    try {
      const resp = await axios.post(`${FUNRAYZR_API_URL}/OAuth/login`, { email, password });
      const data = resp?.data;
      if (data) {
        const { accessToken, refreshToken } = data;
        const userProfile = await this.getUser(accessToken);
        if (userProfile) {
          userProfile.displayName = userProfile.displayName || email;
          const user = jwtHelper.decodeUser(accessToken);
          storageHelper.setEncryptUser({
            ...user,
            ...userProfile,
            avatarText: imageHelper.getAvatarText(userProfile.displayName || ''),
            email,
            refreshToken
          });
          this.notifySubscribers();
          result = {};
        }
      }
    } catch (err) {
      result = getResponseError(err);
    }
    return result;
  }

  async getUser(accessToken) {
    if (!accessToken) {
      accessToken = this.getAccessToken();
    }
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    };
    const resp = await axios.get(`${FUNRAYZR_API_URL}/OAuth/profile`, config);
    const user = resp?.data;

    const cart = await axios.get(`${FUNRAYZR_API_URL}/Carts/me`, config);

    user.cartTotalCount = 0;

    if (cart?.data?.cartDetails?.length) {
      user.cartTotalCount = cart.data.cartDetails.length;
    }

    if (user?.roles) {
      user.roles = user.roles.split(',').map((role) => role.trim());
    }
    return user;
  }

  signOut() {
    localStorage.removeItem(STORAGE_KEY.KEY);
    localStorage.removeItem(STORAGE_KEY.USER);
    this.notifySubscribers();
  }

  async register(user) {
    let result = {
      errorCode: ERROR_MESSAGE.UNKNOWN.CODE,
      errorMessage: ERROR_MESSAGE.UNKNOWN.MESSAGE
    };
    try {
      const { email } = user;
      const registerUser = {
        ...user,
        appName: FR_APP_KEY,
        successReturnUrl: `${window.location.origin}${REGISTER_URL.REGISTER_CONFIRMATION.URL}?${URL_PARAM.EMAIL}=${email}`,
        failedReturnUrl: `${window.location.origin}${REGISTER_URL.REGISTER_CONFIRMATION_FAILED.URL}?${URL_PARAM.EMAIL}=${email}`
      };
      const resp = await axios.post(`${FUNRAYZR_API_URL}/Accounts`, registerUser);
      const data = resp?.data;
      result = data || {};
    } catch (err) {
      result = getResponseError(err);
    }
    return result;
  }

  async registerVendor(vendorInfo) {
    let result = {
      errorCode: ERROR_MESSAGE.UNKNOWN.CODE,
      errorMessage: ERROR_MESSAGE.UNKNOWN.MESSAGE
    };
    try {
      const resp = await axios.post(`${FUNRAYZR_API_URL}/Accounts/vendor`, vendorInfo);
      const data = resp?.data;
      result = data || {};
    } catch (err) {
      result = getResponseError(err);
    }
    return result;
  }

  async registerCharity(charityInfo) {
    let result = {
      errorCode: ERROR_MESSAGE.UNKNOWN.CODE,
      errorMessage: ERROR_MESSAGE.UNKNOWN.MESSAGE
    };
    try {
      const resp = await axios.post(`${FUNRAYZR_API_URL}/Accounts/charity`, charityInfo);
      const data = resp?.data;
      result = data || {};
    } catch (err) {
      result = getResponseError(err);
    }
    return result;
  }

  async setNewPassword(email, password, verifyCode, setPasswordPurpose) {
    let result = {
      errorCode: ERROR_MESSAGE.UNKNOWN.CODE,
      errorMessage: ERROR_MESSAGE.UNKNOWN.MESSAGE
    };
    try {
      let passworded = {
        email,
        password
      };
      if (setPasswordPurpose) {
        passworded = {
          ...passworded,
          setPasswordPurpose
        };
      }
      const code = encodeURIComponent(encodeURIComponent(verifyCode));
      const passwordedUrl = `${FUNRAYZR_API_URL}/Accounts/passworded?code=${code}`;
      const resp = await axios.post(passwordedUrl, passworded);
      const data = resp?.data;
      result = data || {};
    } catch (err) {
      result = getResponseError(err);
    }
    return result;
  }

  async forgotPassword(email) {
    let result = {
      errorCode: ERROR_MESSAGE.UNKNOWN.CODE,
      errorMessage: ERROR_MESSAGE.UNKNOWN.MESSAGE
    };
    try {
      const forgotPassword = {
        email,
        successReturnUrl: `${window.location.origin}${RESET_PASSWORD_URL.RESET_PASSWORD.URL}`,
        failedReturnUrl: `${window.location.origin}${RESET_PASSWORD_URL.RESET_PASSWORD_FAILED.URL}?${URL_PARAM.EMAIL}=${email}`,
        appName: FR_APP_KEY
      };
      const forgotPasswordUrl = `${FUNRAYZR_API_URL}/Accounts/forgot-password`;
      const resp = await axios.post(forgotPasswordUrl, forgotPassword);
      const data = resp?.data;
      result = data || {};
    } catch (err) {
      result = getResponseError(err);
    }
    return result;
  }

  async getAccessTokenSilent() {
    let result = {
      errorCode: ERROR_MESSAGE.UNKNOWN.CODE,
      errorMessage: ERROR_MESSAGE.UNKNOWN.MESSAGE
    };
    try {
      const { refreshToken } = this.getDecryptedUser();
      const resp = await axios.post(`${FUNRAYZR_API_URL}/OAuth/silent-login/${refreshToken}`);
      const data = resp?.data?.result;
      if (data) {
        const { accessToken, newRefreshToken } = data;
        if (accessToken && newRefreshToken) {
          const user = this.getDecryptedUser();
          storageHelper.setEncryptUser({
            ...user,
            refreshToken: newRefreshToken,
            accessToken
          });
          this.notifySubscribers();
          result = {};
        } else {
          this.signOut();
        }
      } else {
        this.signOut();
      }
    } catch (err) {
      result = getResponseError(err);
      this.signOut();
    }
    return result;
  }

  isAuthenticated() {
    const user = this.getDecryptedUser();
    return !!user?.userId && !!user?.accessToken;
  }

  isSuperAdministrator() {
    return this.hasRole(USER_ROLE.SUPER_ADMINISTRATOR);
  }

  isAdministrator() {
    return this.hasRole(USER_ROLE.ADMINISTRATOR);
  }

  isManager() {
    return this.hasRole(USER_ROLE.MANAGER);
  }

  isEmployee() {
    return this.hasRole(USER_ROLE.EMPLOYEE);
  }

  isVendor() {
    return this.isTenantType(TENANT_TYPE.VENDOR);
  }

  isCharity() {
    return this.isTenantType(TENANT_TYPE.CHARITY);
  }

  isTenantType(type) {
    const { tenantType } = this.getDecryptedUser() || {};
    return tenantType === type;
  }

  hasRole(role) {
    const user = this.getDecryptedUser();
    const roles = user?.roles || [];
    return roles.indexOf(role) !== -1;
  }

  isLogin() {
    return !!this.getDecryptedUser();
  }

  isExpired() {
    const decryptedUser = this.getDecryptedUser();
    if (decryptedUser) {
      const { expiredAt } = decryptedUser;
      const currentTime = new Date().getTime();
      return currentTime > expiredAt * 1000;
    }
    return true;
  }

  getTenantType() {
    return this.getDecryptedUser()?.tenantType;
  }

  getTenantId() {
    return this.getDecryptedUser()?.tenantIds?.[0];
  }

  getDecryptedUser() {
    return storageHelper.getDecryptedUser();
  }

  getAccessToken() {
    const user = this.getDecryptedUser();
    return user && user.accessToken;
  }

  subscribe(callback) {
    this._nextSubscriptionId += 1;
    this._callbacks.push({ callback, subscription: this._nextSubscriptionId });
    return this._nextSubscriptionId;
  }

  unsubscribe(subscriptionId) {
    const subscriptionIndex = this._callbacks
      .map((element, index) =>
        element.subscription === subscriptionId ? { found: true, index } : { found: false }
      )
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
    }

    this._callbacks.splice(subscriptionIndex[0].index, 1);
  }

  notifySubscribers() {
    const subcribers = this._callbacks || [];

    subcribers.forEach((v, i) => {
      if (this._callbacks[i]) {
        const { callback } = this._callbacks[i];
        callback();
      }
    });
  }

  subscribeAccountStatus() {
    const user = this.getDecryptedUser();
    const userId = user?.userId;
    if (userId) {
      const builder = new HubConnectionBuilder();
      const connection = builder
        .withUrl(`${FUNRAYZR_ENDPOINT}/hubs/notifications?userId=${userId}`)
        .withAutomaticReconnect()
        .configureLogging(ENV === 'development' ? LogLevel.Information : LogLevel.None)
        .build();
      connection.serverTimeoutInMilliseconds = 5 * 60 * 1000; // 5 minutes
      connection.onreconnected(() => {
        connection.invoke('GetConnectionId').catch(() => { });
      });
      connection.on('onChangeStatusUser', (data) => {
        if (data) {
          const { accountStatus, userId: accountId } = data;
          if (userId === accountId && accountStatus === ACCOUNT_STATUS.DEACTIVATE) {
            this.signOut();
          }
        }
      });
      connection
        .start()
        .then(() => {
          connection.invoke('GetConnectionId').catch(() => { });
        })
        .catch(() => { });
      this.connection = connection;
    } else {
      if (this.connection?.state === HubConnectionState.Connected) {
        this.connection.stop();
        this.connection.off('onChangeStatusUser');
      }
      this.connection = null;
    }
  }

  radomToken() {
    const user = storageHelper.getDecryptedUser();
    const date = new Date();
    storageHelper.setEncryptUser({
      ...user,
      accessToken: `${user.accessToken} ${date.getTime()}`
    });
  }

  static get instance() {
    return authService;
  }
}

const authService = new AuthorizeService();

export default authService;

window.radomToken = authService.radomToken;

export const AuthenticationResultStatus = {
  Redirect: 'redirect',
  Success: 'success',
  Fail: 'fail'
};
