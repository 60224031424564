import React, { useCallback } from 'react';
import { Col } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import { APP_KEY, IMG_LOCAL } from 'constants/index';
import Button from 'components/button';
import { theme } from 'constants/theme';
import { FR_APP_KEY, FUNRAYZR_URL, RAYZR_URL } from 'configs';
import qrCodeHelper from 'utils/qrCode';
import QRCodeId from 'components/qrCode';
import { toPng } from 'html-to-image';
import imageHelper from 'utils/image';

const useStyle = makeStyles(() => ({
  codeArea: {
    border: '1px solid #33333330',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    '& .logo': {
      maxWidth: '100px',
      marginBottom: '10px',
      '& img': {
        width: '100%'
      }
    },
    '& span': {
      marginBottom: '10px',

      fontSize: theme.fontSize.s14
    },
    '& p': {
      fontWeight: 'bold',
      fontSize: theme.fontSize.s20,
      textAlign: 'center',
      lineHeight: '0.75rem',
      margin: '10px 0',
      '& span': {
        fontSize: theme.fontSize.s12,
        fontWeight: 'normal'
      }
    }
  },
  downloadArea: {
    '& .download-group': {
      '& p': {
        color: theme.colors.black.text,
        fontFamily: theme.fontFamily.quicksandRegular,
        fontSize: '14px',
        marginBottom: '10px'
      },
      '& .MuiButton-root': {
        '& span': {
          fontSize: theme.fontSize.s12,
          color: theme.colors.white.text
        }
      }
    }
  }
}));

const DownloadQRCode = ({ id }) => {
  const classes = useStyle();
  const dataVendorUrl = `${APP_KEY.FUNRAY_ZR === FR_APP_KEY ? FUNRAYZR_URL : RAYZR_URL}shop-vendors/detail/${id}`;
  const currentAppName = APP_KEY.FUNRAY_ZR === FR_APP_KEY ? APP_KEY.FUNRAY_ZR : APP_KEY.RAY_ZR;

  const onGetPoster = useCallback(() => {
    const poster = document.getElementById('poster');
    toPng(poster, { pixelRatio: 5, backgroundColor: '#FFFFFF' }).then((dataUrl) => {
      imageHelper.downloadImage(dataUrl, 'poster.png');
    });
  }, []);

  return (
    <div className="flex">
      <Col id="poster" xs={6} className="py-2">
        <div className={`${classes.codeArea} p-2`}>
          <div className="logo">
            <img src={APP_KEY.FUNRAY_ZR === FR_APP_KEY ? IMG_LOCAL.FUNRAYZR_CONTENT_LOGO : IMG_LOCAL.RAYZR_CONTENT_LOGO} alt="rayzrLogo" />
          </div>
          <span>Instantly, grab an eGift Card for you or a friend!</span>
          <div className="qr-code">
            <QRCodeId id={id} data={dataVendorUrl} className="mt-5" />
          </div>
          <p>
            SCAN ME!
            <br />
            <span>with your phone</span>
          </p>
        </div>
      </Col>
      <Col xs={6}>
        <div className={`${classes.downloadArea}`}>
          <div className="download-group mb-5 ">
            <p>
              Download your <br /> {currentAppName} QR code poster to display in your business
            </p>
            <Button color="tertiary" className="mb-2" onClick={onGetPoster}>
              <span>Get Poster</span>
            </Button>
          </div>

          <div className="download-group">
            <p>
              Download your <br /> {currentAppName} QR code only
            </p>
            <Button
              color="tertiary"
              className="mb-2"
              onClick={() => qrCodeHelper.downloadQRCode(id)}
            >
              <span>Get Code</span>
            </Button>
          </div>
        </div>
      </Col>
    </div>
  );
};

export default DownloadQRCode;
