import Api from 'api/report';
import AxiosWrapper from 'utils/axios';

export class ReportService {
  constructor() {
    const axios = AxiosWrapper();
    this._reportApi = Api(axios);
  }

  getVendorReport(params) {
    return this._reportApi.getVendorReport(params);
  }

  getVendorReportByAdmin(params) {
    return this._reportApi.getVendorReportByAdmin(params);
  }

  getCharityReportByAdmin(params) {
    return this._reportApi.getCharityReportByAdmin(params);
  }

  getCharityReport(params) {
    return this._reportApi.getCharityReport(params);
  }
}

const reportService = new ReportService();

export default reportService;
