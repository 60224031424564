import imageHelper from "utils/image";

const downloadQRCode = (id) => {
  const qrCodeURL = document
    .getElementById(`qrCodeEl-${id}`)
    .toDataURL('image/png')
    .replace('image/png', 'image/funrayzr');

  imageHelper.downloadImage(qrCodeURL, 'QR_Code.png');
};

const qrCodeHelper = {
  downloadQRCode
};

export default qrCodeHelper;
