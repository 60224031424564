import qs from 'qs';
import { FUNRAYZR_API_URL } from 'configs';
import { mapResponse } from 'api/responseMapping';

const Api = (axios) => {
  const url = `${FUNRAYZR_API_URL}/Vendors`;
  const publicUrl = `${FUNRAYZR_API_URL}/Public/Vendors`;

  const getVendor = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${url}?${params}`);

    return mapResponse(resp, true);
  };

  const getVendorDetail = async (id) => {
    const resp = await axios.get(`${url}/${id}`);

    return mapResponse(resp);
  };

  const editVendor = async (id, vendor) => {
    const resp = await axios.put(`${url}/${id}`, vendor);

    return mapResponse(resp);
  };

  const getVendorsPublic = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${publicUrl}?${params}`);

    return mapResponse(resp, true);
  };

  const createVendor = async (vendor) => {
    const resp = await axios.post(url, vendor);

    return mapResponse(resp);
  };

  const statusVendor = async (id, status) => {
    const resp = await axios.put(`${url}/${id}/status/${status}`);

    return mapResponse(resp);
  };

  const getSummary = async (pa) => {
    if (!pa) return;

    const params = qs.stringify(pa);
    const resp = await axios.get(`${url}/summary?${params}`);

    return mapResponse(resp);
  };

  return {
    getVendor,
    getVendorDetail,
    editVendor,
    getVendorsPublic,
    createVendor,
    statusVendor,
    getSummary
  };
};

export default Api;
