import AdminPortal from '.';
import AdminPortalCharity from './charity';
import AdminPortalReporting from './reporting';
import AdminPortalUser from './user';
import AdminPortalUserAdd from './user/add';
import AdminPortalUserID from './user/detail';
import AdminPortalVendor from './vendor';
import AdminPortalVendorAdd from './vendor/add';
import AdminPortalCharityAdd from './charity/add';
import AdminPortalCharityEdit from './charity/edit';
import AdminPortalVendorID from './vendor/detail';
import { ADMIN_PORTAL_URL } from './url';
import { ADMIN_PORTAL_BASIC_ROUTES } from './basicRoute';
import AdminPortalCharityViewInformation from './charity/view/information';
import AdminPortalCharityViewCampaign from './charity/view/campaign';
import AdminPortalCharityViewReporting from './charity/view/reporting';
import AdminPortalCharityViewCampaignAdd from './charity/view/campaign/add';
import AdminPortalCharityViewCampaignEdit from './charity/view/campaign/edit';
import AdminPortalVendorViewSummary from './vendor/view/summary';
import AdminPortalVendorViewInformation from './vendor/view/information';
import AdminPortalVendorViewUser from './vendor/view/users';
import AdminPortalVendorViewUserAdd from './vendor/view/users/add';
import AdminPortalVendorViewUserEdit from './vendor/view/users/edit';
import AdminPortalVendorViewCardAndTransaction from './vendor/view/cardAndTransaction';
import AdminPortalVendorViewCardAndTransactionDetail from './vendor/view/cardAndTransaction/detail';
import AdminPortalVendorViewReporting from './vendor/view/reporting';
import AdminPortalCharityReportByAd from './reporting/charity';
import AdminPortalVendorReportByAd from './reporting/vendor';
import AdminPortalRegionMangement from './region';
import AdminPortalOrganizationMangement from './organization';
import AdminPortalOrganizationAdd from './organization/add';
import AdminPortalOrganizationEdit from './organization/detail';
import AdminPortalRegionAdd from './region/add';
import AdminPortalRegionEdit from './region/edit';

export const ADMIN_PORTAL_ROUTES = [
  ...ADMIN_PORTAL_BASIC_ROUTES,
  {
    name: ADMIN_PORTAL_URL.ADMIN_PORTAL.NAME,
    path: ADMIN_PORTAL_URL.ADMIN_PORTAL.URL,
    component: AdminPortal,
    isPrivate: false
  },
  {
    name: ADMIN_PORTAL_URL.USER.NAME,
    path: ADMIN_PORTAL_URL.USER.URL,
    component: AdminPortalUser,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.USER_ADD.NAME,
    path: ADMIN_PORTAL_URL.USER_ADD.URL,
    component: AdminPortalUserAdd,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.USER_DETAIL.NAME,
    path: ADMIN_PORTAL_URL.USER_DETAIL.URL,
    component: AdminPortalUserID,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR.NAME,
    path: ADMIN_PORTAL_URL.VENDOR.URL,
    component: AdminPortalVendor,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_REPORT.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_REPORT.URL,
    component: AdminPortalVendorReportByAd,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_ADD.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_ADD.URL,
    component: AdminPortalVendorAdd,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_DETAIL.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_DETAIL.URL,
    component: AdminPortalVendorID,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_VIEW_SUMMARY.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_VIEW_SUMMARY.URL,
    component: AdminPortalVendorViewSummary,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_VIEW_USERS.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_VIEW_USERS.URL,
    component: AdminPortalVendorViewUser,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_VIEW_USERS_ADD.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_VIEW_USERS_ADD.URL,
    component: AdminPortalVendorViewUserAdd,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_VIEW_USERS_EDIT.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_VIEW_USERS_EDIT.URL,
    component: AdminPortalVendorViewUserEdit,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_VIEW_INFORMATION.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_VIEW_INFORMATION.URL,
    component: AdminPortalVendorViewInformation,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_VIEW_CARD_TRANSACTION.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_VIEW_CARD_TRANSACTION.URL,
    component: AdminPortalVendorViewCardAndTransaction,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_VIEW_CARD_TRANSACTION_DETAIL.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_VIEW_CARD_TRANSACTION_DETAIL.URL,
    component: AdminPortalVendorViewCardAndTransactionDetail,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.VENDOR_VIEW_REPORTING.NAME,
    path: ADMIN_PORTAL_URL.VENDOR_VIEW_REPORTING.URL,
    component: AdminPortalVendorViewReporting,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.CHARITY.NAME,
    path: ADMIN_PORTAL_URL.CHARITY.URL,
    component: AdminPortalCharity,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.CHARITY_REPORT.NAME,
    path: ADMIN_PORTAL_URL.CHARITY_REPORT.URL,
    component: AdminPortalCharityReportByAd,
    isPrivate: true
  },

  {
    name: ADMIN_PORTAL_URL.CHARITY_ADD.NAME,
    path: ADMIN_PORTAL_URL.CHARITY_ADD.URL,
    component: AdminPortalCharityAdd,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.CHARITY_VIEW_INFORMATION.NAME,
    path: ADMIN_PORTAL_URL.CHARITY_VIEW_INFORMATION.URL,
    component: AdminPortalCharityViewInformation,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.CHARITY_VIEW_CAMPAIGNS.NAME,
    path: ADMIN_PORTAL_URL.CHARITY_VIEW_CAMPAIGNS.URL,
    component: AdminPortalCharityViewCampaign,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.CHARITY_VIEW_CAMPAIGNS_ADD.NAME,
    path: ADMIN_PORTAL_URL.CHARITY_VIEW_CAMPAIGNS_ADD.URL,
    component: AdminPortalCharityViewCampaignAdd,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.CHARITY_VIEW_CAMPAIGNS_EDIT.NAME,
    path: ADMIN_PORTAL_URL.CHARITY_VIEW_CAMPAIGNS_EDIT.URL,
    component: AdminPortalCharityViewCampaignEdit,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.CHARITY_VIEW_REPORTING.NAME,
    path: ADMIN_PORTAL_URL.CHARITY_VIEW_REPORTING.URL,
    component: AdminPortalCharityViewReporting,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.CHARITY_EDIT.NAME,
    path: ADMIN_PORTAL_URL.CHARITY_EDIT.URL,
    component: AdminPortalCharityEdit,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.REPORTING.NAME,
    path: ADMIN_PORTAL_URL.REPORTING.URL,
    component: AdminPortalReporting,
    isPrivate: true
  }
];

export const ADDTIONAL_ADMIN_PORTAL_ROUTES = [
  {
    name: ADMIN_PORTAL_URL.REGION.NAME,
    path: ADMIN_PORTAL_URL.REGION.URL,
    component: AdminPortalRegionMangement,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.REGION_ADD.NAME,
    path: ADMIN_PORTAL_URL.REGION_ADD.URL,
    component: AdminPortalRegionAdd,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.REGION_EDIT.NAME,
    path: ADMIN_PORTAL_URL.REGION_EDIT.URL,
    component: AdminPortalRegionEdit,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.ORGANIZATION.NAME,
    path: ADMIN_PORTAL_URL.ORGANIZATION.URL,
    component: AdminPortalOrganizationMangement,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.ORGANIZATION_ADD.NAME,
    path: ADMIN_PORTAL_URL.ORGANIZATION_ADD.URL,
    component: AdminPortalOrganizationAdd,
    isPrivate: true
  },
  {
    name: ADMIN_PORTAL_URL.ORGANIZATION_DETAIL.NAME,
    path: ADMIN_PORTAL_URL.ORGANIZATION_DETAIL.URL,
    component: AdminPortalOrganizationEdit,
    isPrivate: true
  }
];
