import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@mui/styles';
import { theme } from 'constants/theme';

const VARIANT = {
  FILLED: 'filled',
  OUTLINED: 'outlined',
  STANDARD: 'standard'
};

const useStyles = makeStyles(() => ({
  textColor: ({ maskValue, maskPlaceholder }) => ({
    '& div input': {
      color:
        maskValue !== maskPlaceholder ? theme.colors.black.text : theme.colors.black.placeHolder
    }
  }),
  input: () => ({
    '& .MuiFormHelperText-root': {
      marginLeft: '5px'
    }
  })
}));

const Input = ({
  haveMask,
  iconComponent: Icon,
  InputProps,
  mask,
  maskPlaceholder,
  className,
  onChange,
  variant,
  ...rest
}) => {
  const [maskValue, setMaskValue] = useState(maskPlaceholder);

  const { textColor, input } = useStyles({ maskValue, maskPlaceholder });

  const getInputProps = () => {
    if (!Icon) {
      return InputProps;
    }
    return {
      ...InputProps,
      startAdornment: (
        <InputAdornment position="start">
          <Icon />
        </InputAdornment>
      )
    };
  };

  const handleOnChange = (e) => {
    setMaskValue(e.target.value);
    onChange();
  };

  return haveMask ? (
    <InputMask
      mask={mask}
      maskPlaceholder={maskPlaceholder}
      alwaysShowMask
      onChange={handleOnChange}
      className={`${textColor} ${className}`}
      variant={variant}
      {...rest}
    >
      <TextField />
    </InputMask>
  ) : (
    <TextField
      className={`${input} ${className}`}
      variant={variant}
      onChange={onChange}
      InputProps={getInputProps()}
      {...rest}
    />
  );
};

Input.defaultProps = {
  haveMask: false,
  iconComponent: null,
  InputProps: null,
  mask: null,
  maskPlaceholder: null,
  className: null,
  onChange: () => {},
  variant: VARIANT.OUTLINED
};

Input.propTypes = {
  haveMask: PropTypes.bool,
  iconComponent: PropTypes.objectOf(PropTypes.any),
  InputProps: PropTypes.objectOf(PropTypes.any),
  mask: PropTypes.string,
  maskPlaceholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf([VARIANT.OUTLINED, VARIANT.STANDARD, VARIANT.FILLED])
};

export default Input;
