import React from 'react';
import { Container } from 'reactstrap';
import Button from 'components/button';
import { theme } from 'constants/theme';
import { SIGNIN_URL } from 'pages/signIn/url';
import { Link } from 'react-router-dom';
import BoxInfo from 'components/boxInfo';

const SignedOut = () => {

  return (
    <div className="fr-background fr-box-container">
      <div className="fr-box">
        <Container>
          <div className="fr-page-container fr-content">
            <BoxInfo title="You have been signed out." />
            <div className="fr-box-button">
              <Button
                component={Link}
                to={SIGNIN_URL.SIGNIN.URL}
                kind="button"
                variant="contained"
                color="primary"
                size="large"
                minWidth={130}
                bgHover={[theme.colors.orange.button, theme.colors.white.text]}
                colorOnClick={[theme.colors.darkcyan.button]}
              >
                <span className="text-uppercase">{SIGNIN_URL.SIGNIN.NAME}</span>
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default SignedOut;
