import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import React from 'react';
import { useParams } from 'react-router-dom';
import AdminPortalVendorView, { VendorReportingTab } from '..';
import AdminPortalVendorReportingForm from '../../reporting/reportingForm';

const AdminPortalVendorViewReporting = () => {
  const { vid } = useParams();

  return (
    <>
      <AdminPortalVendorView vendorTab={VendorReportingTab.value}>
        <AdminPortalVendorReportingForm
          noTitle
          tenantId={vid}
          notFoundRedirectUrl={ADMIN_PORTAL_URL.VENDOR.URL}
        />
      </AdminPortalVendorView>
    </>
  );
};

export default AdminPortalVendorViewReporting;
