import React from 'react';
import { makeStyles } from '@mui/styles';
import { Popup } from 'components/popup';
import { theme } from 'constants/theme';
import { Input } from 'reactstrap';
import Button from 'components/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { gR, gS } from 'constants/helperMessage';
import cardService from 'services/card';
import useToast from 'components/toast/useToast';

const useStyles = makeStyles(() => ({
  textContent: {
    fontFamily: theme.fontFamily.quicksandRegular,
    fontSize: theme.fontSize.s16,
    color: theme.colors.black.text
  },
  buttonConfirm: {
    '&.Mui-disabled': {
      backgroundColor: `${theme.colors.orange.o7} !important`
    }
  },
  resendPopup: {
    '& .form-control-small': {
      maxWidth: theme.popup.maxWidth,
      margin: '0 auto'
    }
  },
  textError: {
    fontSize: theme.fontSize.s12,
    textAlign: 'start',
    color: theme.colors.red.toast,
    maxWidth: theme.popup.maxWidth,
    margin: '0 auto'
  }
}));
const ResendPopup = ({ isOpen, close, cardId }) => {
  const { toastError, toastSuccess } = useToast();
  const { textContent, resendPopup, buttonConfirm, textError } = useStyles();
  const handleOnClose = () => {
    formik.resetForm();
    close();
  };
  const initialValues = {
    email: ''
  };

  const validateSchema = Yup.object().shape({
    email: Yup.string().required(gR('Email')).email(ERROR_MESSAGE.EMAIL.VALID)
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validateSchema,
    onSubmit: async ({ email }) => {
      const respone = await cardService.resendEmail(cardId, { recipientEmail: email });
      if (respone?.errorCode) {
        toastError(respone.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        toastSuccess(gS('Resent mail'));
        formik.resetForm();
        close();
      }
    }
  });

  const { touched, errors, values, handleSubmit, handleChange, handleBlur } = formik;
  return (
    <form onSubmit={handleSubmit}>
      <Popup
        closeIcon
        kind="confirm"
        isOpen={isOpen}
        onCancel={handleOnClose}
        onConfirm={() => {}}
        size="sm"
        title="Resend email"
        className={resendPopup}
        haveConfirm={false}
        haveCancel={false}
      >
        <>
          <p className={textContent}>
            Please input the recipient’s email address to resend the email.
          </p>
          <Input
            name="email"
            type="email"
            label="Email*"
            bsSize="small"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && touched.email && <p className={`mt-1 ${textError}`}>{errors.email}</p>}
          <Button color="secondary" size="small" className="mr-2 mt-3" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            color="warning"
            size="small"
            className={`mr-2 ml-2 mt-3 text-white-hover ${buttonConfirm}`}
            onClick={formik.submitForm}
            disabled={values.email.length === 0}
          >
            Confirm
          </Button>
        </>
      </Popup>
    </form>
  );
};

export default ResendPopup;
