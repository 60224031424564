import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Nav, NavItem, NavLink, UncontrolledCollapse } from 'reactstrap';
import urlHelper from 'utils/url';

const SideBar = ({ menus = [], collapseMenu = false }) => {
  const isCancelled = useRef(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const [locationPath, setLocationPath] = useState(window.location.pathname);
  const history = useHistory();

  useEffect(() => {
    if (!isCancelled.current) {
      setAdminStateValue(window.location);
    }
    return () => {
      isCancelled.current = true;
    };
  }, []);

  useEffect(() => {
    history.listen(async (location) => {
      if (!isCancelled.current) {
        setAdminStateValue(location);
      }
    });

    return () => {
      isCancelled.current = true;
    };
  }, [history]);

  const setAdminStateValue = (location) => {
    const { pathname } = location;
    setLocationPath(pathname);
  };

  const hasMatchedUrl = useCallback((menuPaths, path) => {
    const filters = menuPaths.filter((menuPath) => {
      return urlHelper.getUrlStatus(menuPath.url || menuPath.subUrl, path) === 'active';
    });
    return filters.length > 0;
  }, []);

  const getMenuItemStatus = useCallback(
    (menuPath, subMenus, path) => {
      const result = {
        active: hasMatchedUrl([menuPath], path) ? 'active' : '',
        show: ''
      };
      if (!menuPath) {
        return {};
      }
      if (subMenus && subMenus.length > 0) {
        const matchedOnSubMenu = hasMatchedUrl(menuPath.subMenus, path);
        if (matchedOnSubMenu) {
          result.open = 'show';
          result.active = '';
        }
      }
      return result;
    },
    [hasMatchedUrl]
  );

  const sidebarView = useMemo(() => {
    return (
      <div className="fr-sidebar-content">
        <Nav vertical>
          {menus.map((menu) => {
            const { name, url, disabled, subMenus = [] } = menu;
            const linkDisabled = disabled || false;
            const key = uuidv4().replace(/-/g, '');
            const { active, open } = getMenuItemStatus(menu, subMenus, locationPath);
            return (
              <NavItem className={`fr-nav-item ${active}`} key={key} title={name}>
                <NavLink disabled={linkDisabled} tag={Link} className="fr-nav-link" to={url}>
                  {name}
                </NavLink>
                {subMenus && subMenus.length > 0 && (
                  <>
                    <div className="fr-nav-ico">
                      <ArrowDropDown id={`target${key}`} />
                    </div>
                    <UncontrolledCollapse className={open} toggler={`#target${key}`}>
                      <ul className="fr-nav-sub-item">
                        {subMenus.map((subMenu) => {
                          const { subName, subUrl, disabled: subDisabled } = subMenu;
                          const subLinkDisabled = subDisabled || false;
                          const subKey = uuidv4();
                          const { active: subActive } = getMenuItemStatus(
                            subMenu,
                            [],
                            locationPath
                          );
                          return (
                            <NavItem
                              className={`fr-nav-item ${subActive}`}
                              key={`sub-${subKey}`}
                              title={subName}
                            >
                              <NavLink
                                disabled={subLinkDisabled}
                                tag={Link}
                                className="fr-nav-link"
                                to={subUrl}
                              >
                                {subName}
                              </NavLink>
                            </NavItem>
                          );
                        })}
                      </ul>
                    </UncontrolledCollapse>
                  </>
                )}
              </NavItem>
            );
          })}
        </Nav>
      </div>
    );
  }, [getMenuItemStatus, locationPath, menus]);

  const handleSidebarCollapse = useCallback(() => {
    setIsCollapse(!isCollapse);
  }, [isCollapse]);

  const togglerButton = useMemo(() => {
    return (
      <div className="fr-sidebar-icon">
        <button type="button" onClick={handleSidebarCollapse} className={isCollapse ? 'close' : ''}>
          <MenuOpenIcon fontSize="large" />
        </button>
      </div>
    );
  }, [handleSidebarCollapse, isCollapse]);

  return (
    <div className={`fr-sidebar ${isCollapse ? 'sidebar-collapse' : ''}`}>
      {collapseMenu && togglerButton}
      {sidebarView}
    </div>
  );
};

export default SideBar;
