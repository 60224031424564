import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DateRangePicker from 'components/dateRangePicker';
import Button from 'components/button';
import { CircularProgress } from '@mui/material';
import charityService from 'services/charity';
import campaignService from 'services/charity/campaign';
import reportService from 'services/report';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import useToast from 'components/toast/useToast';
import TableReporting from 'components/tableReporting';
import { formatDate, numberFormat, printContent, toUtc } from 'utils/helper';
import { useHistory } from 'react-router-dom';
import Select from 'components/select';

const summaryHeadCells = [
  { id: 'totalPurchasedAmount', label: 'Total Purchased Amount', align: 'left', width: '40%' },
  { id: 'totalFundraising', label: 'Total Fundraising', align: 'left', width: '60%' }
];

const detailHeadCells = [
  { id: 'campaignName', label: 'Campaign Name', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'customerName', label: 'Customer Name', align: 'left' },
  { id: 'cardNumber', label: 'Card Number', align: 'left' },
  { id: 'purchasedAmount', label: 'Purchased Amount', align: 'left' },
  { id: 'charityRate', label: 'Charity Rate', align: 'left' },
  { id: 'totalFundraising', label: 'Total Fundraising', align: 'left' },
  { id: 'vendorSupported', label: 'Vendor Supported', align: 'left' }
];

const allOption = {
  text: 'All campaigns',
  value: 'all'
};

const AdminPortalCharityReportingForm = ({ tenantId, notFoundRedirectUrl, noTitle }) => {
  const history = useHistory();
  const { toastError } = useToast();
  const date = new Date();
  const y = date.getFullYear();
  const m = date.getMonth();
  const initDate = [new Date(y, m, 1), new Date(y, m + 1, 0)];
  const [inputDate, setInputDate] = useState(initDate ?? [null, null]);
  const [loading, setLoading] = useState(true);
  const [campaignId, setCampaignId] = useState(allOption.value);
  const [campaigns, setCampaigns] = useState([]);
  const [reporting, setReporting] = useState({
    summary: {
      totalPurchasedAmount: 0,
      totalFundraising: 0
    },
    transactions: []
  });
  const [charity, setCharity] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      // Get charity detail
      const cResp = await charityService.getCharityDetail(tenantId);
      if (cResp?.errorCode) {
        if (notFoundRedirectUrl) {
          history.push(notFoundRedirectUrl);
        }
      } else if (cResp) {
        setCharity(cResp);
      }

      setTimeout(() => {
        setLoading(false);
      }, 100);
    };

    fetch();
  }, [history, notFoundRedirectUrl, tenantId]);

  useEffect(() => {
    const fetchReporting = async () => {
      // Get report
      const [startDate, eDate] = inputDate;
      let endDate = null;
      if (eDate) {
        endDate = new Date(eDate.getTime());
        endDate.setHours(0);
        endDate.setMinutes(0);
        endDate.setSeconds(0);
      }

      const params = {
        tenantId,
        startDate,
        endDate,
        campaignId: campaignId === allOption.value ? '' : campaignId
      };

      const resp = await reportService.getCharityReport(params);
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else if (resp) {
        const { totalPurchasedAmount, totalFundraising, transactions } = resp;
        const res = {
          summary: { totalPurchasedAmount, totalFundraising },
          transactions: transactions || []
        };
        setReporting(res);
      }
    };

    fetchReporting();
  }, [campaignId, inputDate, tenantId, toastError]);

  const handleChangeCampaign = (e) => {
    setCampaignId(e.target.value);
  };

  useEffect(() => {
    const fetchCampaigns = async () => {
      const cResp = await campaignService.getCampaign({
        charityId: tenantId,
        pageSize: 1000,
        orderBy: 'name',
        direction: 'asc'
      });
      if (!cResp?.errorCode) {
        const camps = cResp.items.map((item) => {
          return {
            text: item.name,
            value: item.id
          };
        });
        setCampaigns([allOption, ...camps]);
      }
    };

    fetchCampaigns();
  }, [history, notFoundRedirectUrl, tenantId]);

  const campaignSelectRender = useMemo(() => {
    return (
      <Select
        color="tertiary"
        haveSearch
        width={215}
        size="small"
        className="bg-white rounded c-vendor-list-category"
        labelInput="Choose Campaign"
        value={campaignId}
        data={campaigns}
        onChange={handleChangeCampaign}
      />
    );
  }, [campaignId, campaigns]);

  const controlRender = useMemo(() => {
    return (
      <div className="d-flex">
        <DateRangePicker
          color="tertiary"
          startText="Date"
          endText="Date"
          value={inputDate}
          onChange={(newValue) => {
            setInputDate(newValue);
          }}
          size="small"
        />
        <div style={{ width: '20px' }} />
        {campaignSelectRender}
      </div>
    );
  }, [campaignSelectRender, inputDate]);

  const summaryConvertToRowCells = (arr) => {
    if (!arr) return [];

    return arr.map((value) => {
      if (!value.converted) {
        value.totalPurchasedAmount = `$${numberFormat(parseFloat(value?.totalPurchasedAmount), 2)}`;
        value.totalFundraising = `$${numberFormat(parseFloat(value?.totalFundraising), 2)}`;
        value.converted = true;
      }
      return value;
    });
  };

  const reportSummaryRender = useMemo(() => {
    const { summary } = reporting;
    const rowCells = summaryConvertToRowCells([summary]);
    return <TableReporting noBorderTop headCells={summaryHeadCells} rowCells={rowCells} />;
  }, [reporting]);

  const detailConvertToRowCells = (arr) => {
    if (!arr) return [];

    return arr.map((value) => {
      if (!value.converted) {
        value.date = formatDate(toUtc(value.date));
        value.purchasedAmount = `$${numberFormat(parseFloat(value?.purchasedAmount), 2)}`;
        value.totalFundraising = `$${numberFormat(parseFloat(value?.totalFundraising), 2)}`;
        value.charityRate = `${(value.charityRate * 100).toFixed(0)}%`;
        value.converted = true;
      }
      return value;
    });
  };
  const reportDetailRender = useMemo(() => {
    const { transactions } = reporting;

    const rowCells = detailConvertToRowCells(transactions);

    return (
      <TableReporting stickyHeader noBorderTop headCells={detailHeadCells} rowCells={rowCells} />
    );
  }, [reporting]);

  const downloadHandle = useCallback(() => {
    printContent('report-view', `${charity?.displayName || ''} - Report`);
  }, [charity?.displayName]);

  return loading ? (
    <div
      className="w-100 d-flex justify-content-center align-items-center bg-white"
      style={{ minHeight: '200px' }}
    >
      <CircularProgress />
    </div>
  ) : (
    <>
      {!noTitle && <h5 className="text-uppercase font-weight-bold mb-5">Reporting</h5>}
      <div className="mb-2 text-right">
        <Button size="small" onClick={downloadHandle} color="tertiary">
          Download
        </Button>
      </div>

      <div id="report-view">
        <div className="my-4">{controlRender}</div>

        <h6 className="mt-4 font-weight-bold">Summary</h6>
        <div>{reportSummaryRender}</div>
        <h6 className="mt-5 font-weight-bold">Details</h6>
        <div>{reportDetailRender}</div>
      </div>
    </>
  );
};

export default AdminPortalCharityReportingForm;
