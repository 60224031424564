import useLoading from 'components/loading/useLoading';
import useToast from 'components/toast/useToast';
import { ERROR_MESSAGE } from 'constants/errorMessage';
import { gS } from 'constants/helperMessage';
import { ADMIN_PORTAL_URL } from 'pages/adminPortal/url';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';
import regionService from 'services/region';
import RegionForm from '../regionForm';

const AdminPortalRegionAdd = () => {
  const [isDisabled, setIsDisabled] = useState(false);

  const history = useHistory();
  const { toastSuccess, toastError } = useToast();
  const [showLoading, hideLoading] = useLoading();

  const initialValues = {
    name: '',
    province: '',
    country: '',
    postalCode: ''
  };

  const onSubmit = useCallback(
    async (values) => {
      setIsDisabled(true);
      showLoading();

      const resp = await regionService.createRegion(values);
      if (resp?.errorCode) {
        toastError(resp?.errorMessage || ERROR_MESSAGE.UNKNOWN.MESSAGE);
      } else {
        history.push(ADMIN_PORTAL_URL.REGION.URL);
        toastSuccess(gS('Save'));
      }

      hideLoading();
      setIsDisabled(false);
    },
    [hideLoading, history, showLoading, toastError, toastSuccess]
  );

  return (
    <div className="fr-container pb-40 fr-bg-admin quicksand">
      <Container>
        <div className="fr-page-container mt-5" style={{ minHeight: '400px' }}>
          <RegionForm
            initialValues={initialValues}
            title="Add Region"
            cancelUrl={ADMIN_PORTAL_URL.REGION.URL}
            isDisabled={isDisabled}
            onSubmit={onSubmit}
          />
        </div>
      </Container>
    </div>
  );
};

export default AdminPortalRegionAdd;
