import ScanVendorTransactions from '.';
import CardTranDetail from './detail';
import MobileScan from './qrCode/mobileScan';
import { SCAN_VENDOR_TRANSACTIONS_URL } from './url';

export const SCAN_VENDOR_TRANSACTIONS_ROUTES = [
  {
    name: SCAN_VENDOR_TRANSACTIONS_URL.LIST.NAME,
    path: SCAN_VENDOR_TRANSACTIONS_URL.LIST.URL,
    component: ScanVendorTransactions,
    isPrivate: true
  },
  {
    name: SCAN_VENDOR_TRANSACTIONS_URL.DETAIL.NAME,
    path: SCAN_VENDOR_TRANSACTIONS_URL.DETAIL.URL,
    component: CardTranDetail,
    isPrivate: true
  },
  {
    name: SCAN_VENDOR_TRANSACTIONS_URL.SCAN.NAME,
    path: SCAN_VENDOR_TRANSACTIONS_URL.SCAN.URL,
    component: MobileScan,
    isPrivate: true
  }
];
